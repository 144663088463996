import React, { useEffect, useState } from 'react'
import "./sidebar.css"
import {LineStyle, Timeline, TrendingUp, AttachMoney, Layers, Leaderboard, Storefront, Person, MailOutline, ChatBubbleOutline, WorkOutline, Report, ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import { Link } from 'react-router-dom'
import { ApiUrl, setGlobalPermission } from '../../Global';

export default function Sidebar() {
  const [activeMenu, setActiveMenu] = useState('Home');
  const [roleId, setRoleId] = useState('');
  const [permissions, setPermissions] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState('');
  const [roleName, setRoleName] = useState('');
  const [userName, setUserName] = useState('');

  const handleDropdownClick = (title) => {
    setActiveDropdown(title === activeDropdown ? '' : title);
  };

  useEffect(() => {
    setUserName(sessionStorage.getItem("username"));
    fetch(ApiUrl + '/api/Role/GetRoleByUserId?userId=' + sessionStorage.getItem('UserId'), {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
    //   console.log(data);
      setRoleId(data.id);
    //   console.log(roleId);
      });
  }, []);

  useEffect(() => {
    fetch(ApiUrl + '/api/Role/GetRoleByUserId?userId=' + sessionStorage.getItem('UserId'), {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      setRoleName(data.name);
      setRoleId(data.id);
    });
  }, []);
  
  useEffect(() => {
    if (roleId) {
      fetch(ApiUrl + '/api/Role/GetRolePermissionsByRoleId?roleId=' + roleId, {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const filtered = data.filter(si => si.isDeletedPermission === false);
        // console.log("Permissions")
        console.log(filtered);
        setGlobalPermission(filtered);
        if (filtered) {
          const groupedAndSortedItems = filtered.reduce((groups, item) => {
            const group = groups.find(
              (group) =>
                group.title === item.permissionTitle &&
                group.orderTitleNumber === item.permissionOrderTitleNumber
            );
          
            if (group) {
              group.items.push(item);
            } else {
              groups.push({
                title: item.permissionTitle,
                orderTitleNumber: item.permissionOrderTitleNumber,
                items: [item],
              });
            }
          
            return groups;
          }, []).sort((a, b) => a.orderTitleNumber - b.orderTitleNumber).map((group) => ({
            ...group,
            items: group.items.sort((a, b) => a.permissionOrderNameNumber - b.permissionOrderNameNumber),
          }));
          
          console.log(groupedAndSortedItems);
          setPermissions(groupedAndSortedItems);
        } else {
          console.log('Invalid response from API:', filtered);
        }
      })
      .catch(error => {
        console.error('Error fetching role permissions:', error);
      });
    }
  }, [roleId]);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  }

  return (
    <div className='sidebar'>
        <div className="sidebarWrapper">
          <div className='userContent'>
            <div className='userImageTitle'>
              <img src="images/carpet-for-less-logo-41308e5f-360w.png" alt="" className="logoSide" />
            </div>
            <span>{userName} ({roleName})</span>
          </div>
          {permissions.map((group) => (
            <div className="sidebarMenu" key={group.title}>
              <div className="sidebarTitleDropdown" onClick={() => handleDropdownClick(group.title)}>
                <h3 className="sidebarTitle">{group.title}</h3>
                {activeDropdown === group.title ? (
                  <ArrowDropUp className="sidebarDropdownIcon" />
                ) : (
                  <ArrowDropDown className="sidebarDropdownIcon" />
                )}
              </div>

              {activeDropdown === group.title && (
                <ul className="sidebarList">
                  {group.items.map((item) => (
                    <Link to={item.permissionUrl} className="sidebarListItemLink" key={item.id}>
                      <li
                        className={`sidebarListItem ${activeMenu === item.permissionPageName ? 'active' : ''}`}
                        onClick={() => handleMenuClick(item.permissionPageName)}
                      >
                        <LineStyle className="sidebarIcon" />
                        {item.permissionName}
                      </li>
                    </Link>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
    </div>
  )
}