import React, { useState, useEffect } from 'react'
import "./userList.css"
import {DeleteOutline} from "@mui/icons-material"
import Table from '../../table/Table';
import { userRows } from '../../../dummyData';
import { Link } from 'react-router-dom';
import { ApiUrl } from '../../../Global';
import Swal from 'sweetalert2';

export default function UserList() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    GetAllUsers();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(ApiUrl + '/api/Auth/DeleteUser?Id=' + id, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      });
      const text = await response.text();
      console.log('response text:', text);
      const data = JSON.parse(text);
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'User has been Deleted',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          GetAllUsers();
          console.log(data);
        });
      } else {
        console.error(data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function GetAllUsers(){
    fetch(ApiUrl + '/api/Auth/GetUsers', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setUsers(data);
    });
  }

  const columns = [
    { key: 'id', title: 'ID' },
    { key: 'username', title: 'Username' },
    { key: 'email', title: 'Email' },
    // { key: 'dateOfBirth', title: 'D.O.B' },
    { key: 'phoneNumber', title: 'Phone' },
    { key: 'gender', title: 'Gender' },
    { key: 'active', title: 'Active' },
  ];
      
 
  const onCheckBoxChange = async (rowId) => {
    console.log(rowId);
      try {
        const response = await fetch(ApiUrl + '/api/Auth/ChangeActive?Id=' + rowId, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        //console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              GetAllUsers();
          });
        } else {
          console.error(data);
        }
    } catch (error) {
        console.error(error);
    }
  };

  return (
    <div className='userList'>
      <div className="userTitleContainer">
            <h1 className="userTitle">Users</h1>
            <Link to="/newUser">
              <button className="userAddButton">Create</button>
            </Link>
        </div>
        <Table
          data={users}
          columns={columns}
          onDelete={handleDelete}
          canDelete={true}
          onCheckBoxChange={onCheckBoxChange}
        />
    </div>
  )
}
