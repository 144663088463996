import React, { useEffect, useState } from 'react'
import "./rolePermission.css"
import { useParams } from 'react-router-dom';
import { ApiUrl, WebUrl } from '../../../Global';


export default function RolePermission() {
    const { roleId } = useParams();
    const [role, setRole] = useState({ id: '', name: '' });
    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [canView, setCanView] = useState();

    useEffect(() => {
        fetch(ApiUrl + '/api/Role/GetRoleById?roleid=' + roleId, {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            setRole(data);
        });
    }, []);

    useEffect(() => {
        fetch(ApiUrl + '/api/Permissions/GetAllPermissions', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            setPermissions(data.item2);
        });
    }, []);

    useEffect(() => {
        fetch(ApiUrl + '/api/Role/GetRolePermissionsByRoleId?roleid=' + roleId, {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setSelectedPermissions(Array.isArray(data) ? data : []);
        });
    }, []);


    const handlePermissionChange = (event) => {
      const permissionId = parseInt(event.target.id);
      const isChecked = event.target.checked;
    
      setSelectedPermissions((prevSelectedData) => {
        const existingPermissionIndex = prevSelectedData.findIndex(
          (p) => p.id === permissionId
        );
    
        if (existingPermissionIndex !== -1) {
          // Permission already exists in the selectedPermissions array
          const updatedPermissions = [...prevSelectedData];
          const existingPermission = updatedPermissions[existingPermissionIndex];
    
          if (event.target.id.includes('-View')) {
            // Update 'canView' property
            existingPermission.canView = isChecked;
          } else if (event.target.id.includes('-Edit')) {
            // Update 'canEdit' property
            existingPermission.canEdit = isChecked;
          } else if (event.target.id.includes('-Delete')) {
            // Update 'canDelete' property
            existingPermission.canDelete = isChecked;
          }
    
          updatedPermissions[existingPermissionIndex] = existingPermission;
    
          // Check if all View, Edit, and Delete checkboxes are unchecked
          const allUnchecked = !updatedPermissions.some(
            (p) =>
              p.id === permissionId &&
              (p.canView || p.canEdit || p.canDelete)
          );
    
          // Uncheck the permission name checkbox if all are unchecked
          if (allUnchecked) {
            const permissionNameCheckbox = document.getElementById(
              permissionId.toString()
            );
            if (permissionNameCheckbox) {
              permissionNameCheckbox.checked = false;
    
              // Update the selectedPermissions array to reflect the change
              const updatedPermissions = prevSelectedData.filter(
                (p) => p.id !== permissionId
              );
              return updatedPermissions;
            }
          }
    
          return updatedPermissions;
        } else {
          // Permission doesn't exist in the selectedPermissions array
          const permission = permissions.find((p) => p.id === permissionId);
    
          if (isChecked) {
            // If checked, add the permission to the array with initial values
            const addRolePermission = {
              id: permissionId,
              permissionId: permissionId,
              roleId: roleId,
              canView: event.target.id.includes('-View') ? true : false,
              canEdit: event.target.id.includes('-Edit') ? true : false,
              canDelete: event.target.id.includes('-Delete') ? true : false,
              permissionName: permission.name,
              permissionPageName: permission.pageName,
              permissionTitle: permission.title,
              permissionTitleName: permission.titleName,
              permissionUrl: permission.url,
            };
            return [...prevSelectedData, addRolePermission];
          }
        }
    
        return prevSelectedData;
      });
      console.log(selectedPermissions);
    };

    //   const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const permissionsIds = selectedPermissions.map(p => p.id).join(',')
    //     //console.log(selectedPermissions);
    //     try {
    //         const response = await fetch( ApiUrl +'/api/Role/AddPermissionToRole?roleId='+roleId + '&permissionsId=' + permissionsIds, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ` + sessionStorage.getItem("token")
    //             }
    //         });
    //         const data = await response.json();
    //         if (response.ok) {
    //             //console.log(data);
    //             window.location.href = WebUrl + "/roles";
    //         } else {
    //             console.error(data);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    const handleNewSubmit = async (e) => {
      e.preventDefault();
      //console.log(selectedPermissions);
      const updatedPermissions = selectedPermissions
      .map((permission) => {
        const permissionId = permission.id;
        const canView = document.getElementById(`${permissionId}-View`).checked;
        const canEdit = document.getElementById(`${permissionId}-Edit`).checked;
        const canDelete = document.getElementById(`${permissionId}-Delete`).checked;

        if (canView || canEdit || canDelete) {
          return {
            roleId,
            permissionId,
            canView,
            canEdit,
            canDelete,
          };
        }

        return null; // Exclude permissions that don't have any action checked
      })
      .filter(Boolean); // Remove null values from the array
      console.log(updatedPermissions);
      try {
        const response = await fetch(ApiUrl + '/api/Role/AddPermissionsToRole?roleId='+ roleId, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ` + sessionStorage.getItem('token'),
          },
          body: JSON.stringify(updatedPermissions),
        });
        const data = await response.json();
        if (response.ok) {
          window.location.href = WebUrl + '/roles';
        } else {
          console.error(data);
        }
      } catch (error) {
        console.error(error);
      }
  }

  return (
    <div className='rolePermission'>
        <h3>{role.name}</h3>
        <form onSubmit={handleNewSubmit} >
            <div className='permission-list'>
              <table>
                <thead>
                </thead>
                <tbody>
                {permissions.map((permission) => (
                  <tr key={permission.id}>
                    <td key={permission.id + '-Per'}>
                    <input
                      className='checkPermissionhide'
                      type='checkbox'
                      id={permission.id}
                      onChange={handlePermissionChange}
                      checked={selectedPermissions.some((p) => p.id === permission.id)}
                      />
                      <label className='labelPermissionName' htmlFor={permission.id}>{permission.name}</label>
                    </td>
                    <td key={permission.id + '-View'}>
                    <input
                      className='checkPermissionhide'
                      type='checkbox'
                      id={permission.id + "-View"}
                      onChange={handlePermissionChange}
                      checked={selectedPermissions.some((p) => p.id === permission.id && p.canView ===true)}
                      />
                      <label className='labelPermission' htmlFor={permission.id + "-View"}>View</label>
                    </td>
                    <td key={permission.id + '-Edit'}>
                    <input
                      className='checkPermissionhide'
                      type='checkbox'
                      id={permission.id + "-Edit"}
                      onChange={handlePermissionChange}
                      checked={selectedPermissions.some((p) => p.id === permission.id && p.canEdit ===true)}
                      />
                      <label className='labelPermission' htmlFor={permission.id + "-Edit"}>Edit</label>
                    </td>
                    <td key={permission.id + '-Delete'}>
                    <input
                      className='checkPermissionhide'
                      type='checkbox'
                      id={permission.id + "-Delete"}
                      onChange={handlePermissionChange}
                      checked={selectedPermissions.some((p) => p.id === permission.id && p.canDelete ===true)}
                      />
                      <label className='labelPermission' htmlFor={permission.id + "-Delete"}>Delete</label>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
                
            </div>
            <div className='submitBtn'>
                <button type='submit' className="newPermissionButton">Save</button>
            </div>
        </form>
    </div>
  )
}