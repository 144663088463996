import React from 'react'
import './private.css'
import { useState } from 'react';
import Swal from 'sweetalert2';
import { ApiUrl, GlobalPermission } from '../../../Global';
import Table from '../../table/Table';
import { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import PrintableTable from '../reports/PrintableTable';


export default function Private() {
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();


    useEffect(() => {
        const desiredPermissionPageName = 'Private'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
            (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);
        GetAllPrivates();
        //console.log(supplierId);
    }, [searchText]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch( ApiUrl +'/api/Privates/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    title: title,
                    username: username,
                    password: pass,
                    description: description
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    ClearTextBoxes();
                    GetAllPrivates();
                });
            } else {
                console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function ClearTextBoxes(){
        setDescription('');
        setUsername('');
        setPass('');
        setTitle('');
        setId(0);
    }

    function GetAllPrivates() {
        let Url = '/api/Privates/GetAllPrivates';
      
        fetch(ApiUrl + Url, {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          })
          .then(response => response.json())
          .then(data => {
            //console.log(data);
            const filteredData = data.item2.filter(item =>
              item.title.toLowerCase().includes(searchText.toLowerCase())
            );
            setData(filteredData);
        });
    }

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'title', title: 'Title' },
        { key: 'username', title: 'Username' },
        { key: 'password', title: 'Password' },
        { key: 'description', title: 'Description' },
    ];

    const handleDelete = async (id) => {
        // Perform delete action using the selected row IDs
        //console.log(id)
        try {
            const response = await fetch(ApiUrl + '/api/Privates/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            //console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
              Swal.fire({
                  icon: 'success',
                  title: data.message,
                  showConfirmButton: false,
                  timer: 2000
                }).then(() => {
                  ClearTextBoxes();
                  GetAllPrivates();
              });
            } else {
              console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleEdit = (id) => {
        // console.log(supplier);
        const filtered = data.filter(item => item.id === id);
        setUsername(filtered[0].username);
        setTitle(filtered[0].title)
        setId(filtered[0].id);
        setPass(filtered[0].password);
        setDescription(filtered[0].description);
    };

    const handleNew = () => {
        ClearTextBoxes();
    }


    const columnsPrint = [
        { key: 'title', title: 'Title' },
        { key: 'username', title: 'Username' },
        { key: 'password', title: 'Password' },
        { key: 'description', title: 'Description' },
    ];

    const handlePrintClick = () => {
        

        const printableTableComponent = (
          <PrintableTable data={data} columns={columnsPrint} title="Accounts" />
        );
    
        const newWindow = window.open("", "_blank");
        newWindow.document.open();
        newWindow.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <title>Sales People</title>
              <link rel="stylesheet" type="text/css" href="printtableTable.css">
            </head>
            <body>${ReactDOMServer.renderToString(printableTableComponent)}</body>
          </html>`);
        newWindow.print();
        newWindow.document.close();
    };

  return (
    <div className='private'>
        <h1>Private Details</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newDetailForm" onSubmit={handleSubmit}>
            <div className="form-row">
                <div className="form-group">
                    <input type="text" id="title" className='title' name="title" value={title} placeholder="title" onChange={(e) => setTitle(e.target.value)} required />
                </div>
                <div className="form-group">
                    <input type="text" id="username" className='username' name="username" value={username} placeholder="username" onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="form-group">
                    <input type="text" id="password" className='password' name="password" value={pass} placeholder="password" onChange={(e) => setPass(e.target.value)} />
                </div>
                <div className="form-group">
                    <button type='submit' className="detailSubmitBtn">{id == '0' ? 'Create' : 'Save'}</button>
                </div>
                <div className="form-group">
                    <button type='button' className="detailNewBtn" id="detailNewBtn" onClick={handleNew}>New</button>
                </div>
            </div>
            <div className="form-group">
                <textarea id="message" className='message' name="message" value={description} placeholder="Enter your description" onChange={(e) => setDescription(e.target.value)}></textarea>
            </div>
        </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
        <>
        <div className="searchContainer">
            <div className="searchInputContainer">
                <label className="searchLabel" htmlFor="searchInput">Search:</label>
                <input
                id="searchInput"
                className="searchPrivateInput"
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <button onClick={handlePrintClick} className="PrintBtn"> Print</button>
        </div>
        <Table
            data={data}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
            canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
        />
        </>
        ) : (
            <></>
        )}
    </div>
  )
}
