import React, {useState, useEffect} from 'react'
import './dynamicFieldDefinition.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function DynamicFieldDefinition() {
    const [id, setId] = useState(0);
    const [fieldName, setFieldName] = useState('');
    const [fieldType, setFieldType] = useState('Decimal');
    const [fieldLabel, setFieldLabel] = useState('');
    const [categoryId, setCategoryId] = useState(0);
    const [dynamicFieldDefinitions, setDynamicFieldDefinitions] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isFieldNameValid, setIsFieldNameValid] = useState(true);
    const [isFieldLabelValid, setIsFieldLabelValid] = useState(true);
    const currentDateTime = new Date();
    const [searchQuery, setSearchQuery] = useState('');
    const [canPermission, setCanPermission] = useState();

    useEffect(() => {
        const desiredPermissionPageName = 'DynamicFieldDefinitions'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);

        GetAllDynamicFieldDefinitions();
        GetAllCategories();
    }, []);

    function GetAllDynamicFieldDefinitions(){
        fetch(ApiUrl + '/api/DynamicFieldDefinitions/GetAllGlobalDynamicFieldDefinition', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            setDynamicFieldDefinitions(data.item2);
        });
    }
    function GetAllCategories(){
        fetch(ApiUrl + '/api/Categories/GetAllCategories', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            setCategories(data.item2);
            setCategoryId(data.item2[0].id);
        });
    }

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'fieldName', title: 'Field Name' },
        { key: 'fieldType', title: 'Field Type' },
        { key: 'fieldLabel', title: 'Field Label' },
        { key: 'categoryName', title: 'Category' },
    ];
    const handleNew = async (e) => {
        e.preventDefault();
        clearTextBoxes();
    }
    function clearTextBoxes(){
        setId(0);
        setFieldName('');
        setFieldType('Decimal');
        setFieldLabel('');
        setCategoryId(categories[0].id);
        setIsFieldNameValid(true);
        setIsFieldLabelValid(true);
    }
    const handleEdit = async (id) => {
        const filtered = dynamicFieldDefinitions.filter(item => item.id === id);
        setId(id);
        setFieldName(filtered[0].fieldName);
        setFieldType(filtered[0].fieldType);
        setFieldLabel(filtered[0].fieldLabel);
        setCategoryId(filtered[0].categoryId);
        // console.log('Edit')
    }
    const handleDelete = async (id) => {
        try {
            const response = await fetch(ApiUrl + '/api/DynamicFieldDefinitions/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllDynamicFieldDefinitions();
                });
            } else {
              console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (fieldName.trim() === '' || fieldLabel.trim() === '') {
            if(fieldName.trim() === '')
                setIsFieldNameValid(false);
            else
                setIsFieldNameValid(true);
            if(fieldLabel.trim() === '')
                setIsFieldLabelValid(false);
            else
                setIsFieldLabelValid(true);
            return;
        }
        else{
            setIsFieldNameValid(true);
            setIsFieldLabelValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/DynamicFieldDefinitions/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    fieldName: fieldName,
                    fieldType: fieldType,
                    fieldLabel: fieldLabel,
                    categoryId: categoryId,
                    createdBy: '0',
                    CreatedDate: currentDateTime
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllDynamicFieldDefinitions();
                });
            } else {
                console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }


  return (
    <div className='dynamicFieldDefinition'>
        <h1>DynamicFieldDefinition</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newCustomerForm" onSubmit={handleSubmit} >
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div className="newCustomerItem">
                <label htmlFor='fieldName'>Customer Name</label>
                <input type="text" placeholder="Field Name" name="fieldName" id="fieldName" className={!isFieldNameValid ? 'InvalidInputtext' : 'validInputtext'} value={fieldName} onChange={(e) => setFieldName(e.target.value)} />
            </div>
            <div className="newCustomerItem">
                <label htmlFor='fieldType'>Field Type</label>
                <select className='newStateSelect' name="fieldType" id="fieldType" value={fieldType} onChange={(e) => setFieldType(e.target.value)}>
                    <option key="Decimal" value="Decimal">Decimal</option>
                    <option key="int" value="int">Integer</option>
                    <option key="char" value="char">Character</option>
                    <option key="datetime" value="datetime">DateTime</option>
                </select>
                {/* <input type="text" placeholder="Field Type" name="fieldType" id="fieldType" className={!isFieldTypeValid ? 'InvalidInputtext' : 'validInputtext'} value={fieldType} onChange={(e) => setFieldType(e.target.value)} /> */}
            </div>
            <div className="newCustomerItem">
                <label htmlFor='fieldLabel'>Field Label</label>
                <input type="text" placeholder='Field Label' name="fieldLabel" id="fieldLabel" className={!isFieldLabelValid ? 'InvalidInputtext' : 'validInputtext'} value={fieldLabel} onChange={(e) => setFieldLabel(e.target.value)} />
            </div>
            <div className='newCustomerItem'>
                <label htmlFor='category'>Category</label>
                <select className='newStateSelect' name="category" id="category" value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>
            </div>
            
            <div className='newCustomerItem'>
                <button type='button' className="newCustomerBtn" id="newCustomerBtn" onClick={handleNew}>New</button>
                <button type='submit' className="newCustomerButton">{id == '0' ? 'Create' : 'Save'}</button>
            </div>
        </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
        <Table
            data={dynamicFieldDefinitions}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            viewEstimateFileBtn="File"
            canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
            canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
        />
        ) : (
            <></>
        )}
    </div>
  )
}
