import React, {useState, useEffect} from 'react'
import './country.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function Country() {
    const [countries, setCountries] = useState([]);
    const [countriesToShow, setCountriesToShow] = useState([]);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [id, setId] = useState('0');
    const [canPermission, setCanPermission] = useState();
    const [isNameValid, setIsNameValid] = useState(true);
    const [isCodeValid, setIsCodeValid] = useState(true);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
      const desiredPermissionPageName = 'Country'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
    
      setCanPermission(desiredRow);
      GetAllCountries();
    }, []);

    useEffect(() => {
        const filteredData = countries.filter(item =>
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.code.toLowerCase().includes(searchText.toLowerCase())
        );
        setCountriesToShow(filteredData);
      }, [searchText]);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'code', title: 'Code' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
      // Perform edit action using the ID of the row being edited
      const filtered = countries.filter(item => item.id === id);
      setName(filtered[0].name);
      setId(filtered[0].id);
      setCode(filtered[0].code)
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/Countries/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllCountries();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() === '' || code.trim() === '' ) {
          if(name.trim() === '')
            setIsNameValid(false);
          else
          setIsNameValid(true);
          if(code.trim() === '')
            setIsCodeValid(false);
          else
            setIsCodeValid(true);
          return;
        }
        else{
          setIsNameValid(true);
          setIsCodeValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/Countries/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    Name: name,
                    Code: code,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllCountries();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsNameValid(false);
                setIsCodeValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setName('');
        setCode('');
        setIsNameValid(true);
        setIsCodeValid(true);
    }

    function GetAllCountries(){
      fetch(ApiUrl + '/api/Countries/GetAllCountries', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        // const filteredData = data.item2.filter(item =>
        //     item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        //     item.code.toLowerCase().includes(searchText.toLowerCase())
        // );
        // setCountries(filteredData);
        setCountriesToShow(data.item2);
        setCountries(data.item2);
      });
    }

    const handleUndo = async (id) => {
      try {
        const response = await fetch(ApiUrl + '/api/Countries/Undo?Id=' + id, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              clearTextBoxes();
              GetAllCountries();
          });
        } else {
          console.error(data);
        }
      } catch (error) {
        console.error(error);
      }
    }


  return (
    <div className='country'>
        <h1>Country</h1>
        {canPermission && canPermission.canEdit ? (
            <form className="CountryForm" onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
                <div className="CountryItem">
                    <label htmlFor="name">Name:</label>
                    <input type="text" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} placeholder="Name" name="name" id="name" onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="CountryItem">
                    <label htmlFor="code">Code:</label>
                    <input type="text" className={!isCodeValid ? 'InvalidInputtext' : 'validInputtext'} value={code} placeholder="Code" name="code" id="code" onChange={(e) => setCode(e.target.value)} />
                </div>
                <div className='CountryItem'>
                    <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
                    <button type="submit" className='saveCountryBtn' >{id == '0' ? 'Create' : 'Save'}</button>
                </div>
            </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
            <>
                <div className="searchContainer">
                    <div className="searchInputContainer">
                        <label className="searchLabel" htmlFor="searchInput">Search:</label>
                        <input
                        id="searchInput"
                        className="searchSalesInput"
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                </div>
            <Table
                data={countriesToShow}
                columns={columns}
                onDelete={handleDelete}
                onEdit={handleEdit}
                canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                onUndo={handleUndo}
            /> 
            </>
            ) : (
                <></>
        )}
    </div>
  )
}
