import React, {useState, useEffect} from 'react'
import './wareHouse.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function WareHouse() {
    const [name, setName] = useState('');//pageName
    const [phoneNumber, setPhoneNumber] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [streetName, setStreetName] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [stateId, setStateId] = useState(0);
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    const [wareHouses, setWareHouses] = useState([]);
    const [states, setStates] = useState([]);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [isStreetNumberValid, setIsStreetNumberValid] = useState(true);
    const [isStreetNameValid, setIsStreetNameValid] = useState(true);
    const [isCityValid, setIsCityValid] = useState(true);
    const [isZipCodeValid, setIsZipCodeValid] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const currentDateTime = new Date();

    useEffect(() => {
        const desiredPermissionPageName = 'WareHouses'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);

        GetAllWareHouses();
        GetAllStates();
    }, []);

    function GetAllWareHouses(){
        fetch(ApiUrl + '/api/WareHouses/GetAllGlobalWareHouses', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setWareHouses(data.item2);
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() === '' || phoneNumber.trim() === '' || streetNumber.trim() === '' || streetName.trim() === '' ||
        city.trim() === '' || zipCode.trim() === '') {
            if(name.trim() === '')
                setIsNameValid(false);
            else
                setIsNameValid(true);
            if(phoneNumber.trim() === '')
                setIsPhoneNumberValid(false);
            else
                setIsPhoneNumberValid(true);
            if(streetNumber.trim() === '')
                setIsStreetNumberValid(false);
            else
                setIsStreetNumberValid(true);
            if(streetName.trim() === '')
                setIsStreetNameValid(false);
            else
                setIsStreetNameValid(true);
            if(city.trim() === '')
                setIsCityValid(false);
            else
                setIsCityValid(true);
            if(zipCode.trim() === '')
                setIsZipCodeValid(false);
            else
                setIsZipCodeValid(true);
            return;
        }
        else{
            setIsNameValid(true);
            setIsPhoneNumberValid(true);
            setIsStreetNumberValid(true);
            setIsStreetNameValid(true);
            setIsCityValid(true);
            setIsZipCodeValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/WareHouses/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    name: name,
                    phoneNumber: phoneNumber,
                    streetNumber: streetNumber,
                    streetName: streetName,
                    city: city,
                    zipCode: zipCode,
                    stateId: stateId,
                    createdBy: '0',
                    CreatedDate: currentDateTime
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'wareHouse has been saved',
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllWareHouses();
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    if(data.message == "A wareHouse with the same name already exists!"){
                        setIsNameValid(false);
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleNew = async (e) => {
        e.preventDefault();
        clearTextBoxes();
    }

    function clearTextBoxes(){
        setName('');
        setPhoneNumber('');
        setStreetNumber('');
        setStreetName('');
        setCity('');
        setZipCode('');
        setId(0);
        setStateId(states[0].id);
        setIsNameValid(true);
        setIsPhoneNumberValid(true);
        setIsStreetNumberValid(true);
        setIsStreetNameValid(true);
        setIsCityValid(true);
        setIsZipCodeValid(true);
    }

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'phoneNumber', title: 'Phone Number' },
        { key: 'streetNumber', title: 'Street Number' },
        { key: 'streetName', title: 'Street Name' },
        { key: 'city', title: 'City' },
        { key: 'stateCode', title: 'State Code' },
        { key: 'zipCode', title: 'ZipCode' },
    ];

    const formatStreetNumber = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
      
        const match = cleaned.match(/^(\d{1,5})$/); // Match the desired format (1-5 digits)
        if (match) {
          return match[1]; // Return the matched digits
        }
      
        return cleaned; // Return the input as is if it doesn't match the desired format
    };
      
      
    const formatZipCode = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
      
        const match = cleaned.match(/^(\d{5})(\d{4})?$/); // Match the desired format (5 or 9 digits)
        if (match) {
          return match[1] + (match[2] ? `-${match[2]}` : ''); // Apply the format (XXXXX or XXXXX-XXXX)
        }
      
        return cleaned; // Return the input as is if it doesn't match the desired format
    };


    const formatPhoneNumber = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
    
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match the desired format
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`; // Apply the format (XXX) XXX-XXXX
        }
    
        return cleaned; // Return the input as is if it doesn't match the desired format
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(ApiUrl + '/api/WareHouses/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'WareHouses has been Deleted',
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllWareHouses();
                });
            } else {
              console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleEdit = async (id) => {
        const filtered = wareHouses.filter(item => item.id === id);
        setName(filtered[0].name);
        setPhoneNumber(filtered[0].phoneNumber);
        setStreetNumber(filtered[0].streetNumber);
        setStreetName(filtered[0].streetName);
        setCity(filtered[0].city);
        setZipCode(filtered[0].zipCode);
        setId(id);
        setStateId(filtered[0].stateId);
    }

    function GetAllStates(){
        fetch(ApiUrl + '/api/States/GetAllStates', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            const filteredEstimate = data.item2.filter(est => est.isDeleted === false);
            setStates(filteredEstimate);
            // setStates(data.item2);
            setStateId(data.item2[0].id);
        });
    }

    const handleUndo = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/WareHouses/Undo?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllWareHouses();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
      }

  return (
    <div className='WareHouse'>
        <h1>WareHouse</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newForm" onSubmit={handleSubmit} >
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div className="newBoxItem">
                <label htmlFor='wareHouseName'>Name</label>
                <input type="text" placeholder="Name" name="wareHouseName" id="wareHouseName" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="newBoxItem">
                <label htmlFor='phoneNumber'>Phone Number</label>
                <input type="text" placeholder="Phone Number" name="phoneNumber" id="phoneNumber" className={!isPhoneNumberValid ? 'InvalidInputtext' : 'validInputtext'} value={phoneNumber} onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))} />
            </div>
            <div className="newBoxItem">
                <label htmlFor='streetNumber'>Street Number</label>
                <input type="text" placeholder="Street Number" name="streetNumber" id="streetNumber" className={!isStreetNumberValid ? 'InvalidInputtext' : 'validInputtext'} value={streetNumber} onChange={(e) => setStreetNumber(formatStreetNumber(e.target.value))} />
            </div>
            <div className="newBoxItem">
                <label htmlFor='streetName'>Street Name</label>
                <input type="text" placeholder="Street Name" name="streetName" id="streetName" className={!isStreetNameValid ? 'InvalidInputtext' : 'validInputtext'} value={streetName} onChange={(e) => setStreetName(e.target.value)} />
            </div>
            <div className="newBoxItem">
                <label htmlFor='city'>City</label>
                <input type="text" placeholder='City' name="city" id="city" className={!isCityValid ? 'InvalidInputtext' : 'validInputtext'} value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div className='newBoxItem'>
                <label htmlFor='state'>State</label>
                <select className='newStateSelect' name="state" id="state" value={stateId} onChange={(e) => setStateId(e.target.value)}>
                    {states.map((state) => (
                        <option key={state.id} value={state.id}>{state.name + ', ' + state.code}</option>
                    ))}
                </select>
            </div>
            <div className="newBoxItem">
                <label htmlFor='zipCode'>Zip Code</label>
                <input type="text" placeholder='Zip Code' name="zipCode" id="zipCode" className={!isZipCodeValid ? 'InvalidInputtext' : 'validInputtext'} value={zipCode} onChange={(e) => setZipCode(formatZipCode(e.target.value))} />
            </div>
            <div className="newBoxItem">
                <label htmlFor=''>&nbsp;</label>
            </div>
            
            <div className='newBoxItem'>
                <label> &nbsp;</label>
                <button type='button' className="newCustomerBtn" id="newCustomerBtn" onClick={handleNew}>New</button>
            </div>
            <div className='newBoxItem'>
                <label> &nbsp;</label>
                <button type='submit' className="newCustomerButton">{id == '0' ? 'Create' : 'Save'}</button>
            </div>
        </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
        <Table
            data={wareHouses}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            viewEstimateFileBtn="File"
            canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
            canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
            onUndo={handleUndo}
        />
        ) : (
            <></>
        )}
    </div>
  )
}
