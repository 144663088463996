import React, { useEffect, useState } from 'react'
import './state.css'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ApiUrl, GlobalPermission } from '../../../Global';
import { DeleteOutline } from '@mui/icons-material';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function State() {

    const [states, setStates] = useState([]);
    const [stateName, setStateName] = useState('');
    const [stateCode, setStateCode] = useState('');
    const [id, setId] = useState('0');
    const [canPermission, setCanPermission] = useState();
    const [isStateNameValid, setIsStateNameValid] = useState(true);
    const [isStateCodeValid, setIsStateCodeValid] = useState(true);

    useEffect(() => {
      const desiredPermissionPageName = 'State'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
    
      setCanPermission(desiredRow);
      GetAllStates();
    }, []);

    // const columns = [
    //     { Id: 'id', headerName: 'ID', width: 200 },
    //     { field: 'name', headerName: 'Name', width: 200 },
    //     { field: 'code', headerName: 'Code', width: 200 },
    //     {
    //       field: 'action',
    //       headerName: 'Action',
    //       width: 250,
    //       renderCell: (params) => {
    //         return(
    //           <>
    //             <button className='roleListEdit' onClick={() => handleEdit(params.row.id, params.row.name, params.row.code)}>Edit</button>
    //             <DeleteOutline className='roleListDelete' onClick={() => handleDelete(params.row.id)} />
    //           </>
    //         )
    //       }
    //     },
    //   ];

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'code', title: 'Code' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
      // Perform edit action using the ID of the row being edited
      const filtered = states.filter(item => item.id === id);
      setStateName(filtered[0].name);
      setId(filtered[0].id);
      setStateCode(filtered[0].code)
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/States/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: 'State has been Deleted!',
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllStates();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (stateName.trim() === '' || stateCode.trim() === '' ) {
            if(stateName.trim() === '')
                setIsStateNameValid(false);
            else
                setIsStateNameValid(true);
            if(stateCode.trim() === '')
                setIsStateCodeValid(false);
            else
                setIsStateCodeValid(true);
            return;
        }
        else{
            setIsStateNameValid(true);
            setIsStateCodeValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/States/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    Name: stateName,
                    Code: stateCode,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    setIsStateNameValid(true);
                    setIsStateCodeValid(true);
                    clearTextBoxes();
                    GetAllStates();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                  setIsStateNameValid(false);
                  setIsStateCodeValid(false);
            });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setStateName('');
        setStateCode('');
        setIsStateNameValid(true);
        setIsStateCodeValid(true);
    }

    function GetAllStates(){
      fetch(ApiUrl + '/api/States/GetAllStates', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        setStates(data.item2);
      });
    }

    const handleUndo = async (id) => {
      try {
        const response = await fetch(ApiUrl + '/api/States/Undo?Id=' + id, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              clearTextBoxes();
              GetAllStates();
          });
        } else {
          console.error(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

  return (
    <div className='state'>
      <h1>States</h1>
      
      {canPermission && canPermission.canEdit ? (
      <form className="newSupplierForm" onSubmit={handleSubmit}>
      <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
          <div className="newStateItem">
            <label htmlFor="stateName">State:</label>
            <input type="text" className={!isStateNameValid ? 'InvalidInputtext' : 'validInputtext'} value={stateName} placeholder="Name" name="stateName" id="stateName" onChange={(e) => setStateName(e.target.value)} />
          </div>
          <div className="newStateItem">
            <label htmlFor="stateName">Code:</label>
            <input type="text" className={!isStateCodeValid ? 'InvalidInputtext' : 'validInputtext'} value={stateCode} placeholder="Code" name="stateCode" id="stateCode" onChange={(e) => setStateCode(e.target.value)} />
          </div>
          <div className='newStateItem'>
            <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
            <button type="submit" className='saveStateBtn' >{id == '0' ? 'Create' : 'Save'}</button>
          </div>
        </form> ) : (
        <></>
      )}
      <br />
        {/* <DataGrid
            rows={states}
            columns={columns}
            disableRowSelectionOnClick
            initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 5 },
            },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
        /> */}
        {canPermission && canPermission.canView ? (
        <Table
            data={states}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
            canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
            onUndo={handleUndo}
        /> 
        ) : (
          <></>
        )}
    </div>
  )
}
