export const userData = [
    {
      name: 'Jan',
      "Total": 4000,
    },
    {
      name: 'Feb',
      "Total": 3000,
    },
    {
      name: 'Mar',
      "Total": 2000,
    },
    {
      name: 'Apr',
      "Total": 2780,
    },
    {
      name: 'May',
      "Total": 1890,
    },
    {
      name: 'Jun',
      "Total": 2390,
    },
    {
      name: 'Jul',
      "Total": 3490,
    },
    {
      name: 'Aug',
      "Total": 3000,
    },
    {
      name: 'Sep',
      "Total": 2000,
    },
    {
      name: 'Oct',
      "Total": 1000,
    },
    {
      name: 'Nov',
      "Total": 750,
    },
    {
      name: 'Dec',
      "Total": 250,
    },
  ];

  export const userRows = [
    { id: 1, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
    { id: 2, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
    { id: 3, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
    { id: 4, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
    { id: 5, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
    { id: 6, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
    { id: 7, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
    { id: 8, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
    { id: 9, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
    { id: 10, 
      username: 'Jaafar Farhat', 
      avatar: 'images/res_1624449912451.jpg', 
      email: "jaafarfarhat23@gmail.com", 
      status: "active",
      transaction: "$120.00" 
    },
  ];
