import React, {useEffect, useState} from 'react'
import './payment.css'
import { useParams } from 'react-router-dom';
import Table from '../../../table/Table';
import { ApiUrl } from '../../../../Global';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import SelectReact from 'react-select';

export default function Payment() {
    const { invoiceId } = useParams();
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [paymentDate, setPaymentDate] = useState(null);
    const [invoicePayments, setInvoicePayments] = useState([]);
    const [invoice, setInvoice] = useState({});
    const [id, setId] = useState(0);
    const [isMeasuredDateValid, setIsMeasuredDateValid] = useState(true);
    const [paymentLeft, setPaymentLeft] = useState(0);
    const [maxPayment , setMaxPayment ] = useState(0);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethodId, setPaymentMethodId] = useState(0);
    const [paymentMethodOptionValue, setpaymentMethodOptionValue] = useState();
    const [paymentMethodName, setPaymentMethodName] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [isPaymentAmountValid, setIsPaymentAmountValid] = useState(true);
    const [isPaymentDateValid, setIsPaymentDateValid] = useState(true);
    const currentDateTime = new Date();
    

    useEffect(() => {
        GetAllInvoicePayments();
        GetInvoiceDataById();
        GetAllPaymentMethods();
    }, []);

    useEffect(() => {
      console.log(invoicePayments);
      const paymentAmountTotal = invoicePayments.reduce((total, item) => total + parseFloat(item.paymentAmount), 0).toFixed(2);
      console.log(parseFloat(paymentAmountTotal).toFixed(2));

      const numberLeft = (parseFloat(invoice.total) - parseFloat(paymentAmountTotal));
      console.log(numberLeft);
      setPaymentAmount(parseFloat(numberLeft).toFixed(2));
      setPaymentLeft(parseFloat(numberLeft).toFixed(2));
    }, [invoice]);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'paymentType', title: 'Payment Type' },
        { key: 'paymentMethodName', title: 'Payment Method' },
        { key: 'paymentAmount', title: 'PaymentAmount' },
        { key: 'paymentDate', title: 'PaymentDate' },
        { key: 'paymentId', title: 'Payment ID' },
        { key: 'isDeleted', title: 'IsDeleted' },
    ];

    function GetAllInvoicePayments(){
        fetch(ApiUrl + '/api/InvoicePayments/GetAllInvoicePaymentsByInvoiceId?invoiceId=' + invoiceId, {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            // const filtered = data.item2.filter(obj => obj.isDeleted === false);
            setInvoicePayments(data.item2);
            
        });
    }

    function GetAllPaymentMethods(){
      fetch(ApiUrl + '/api/PaymentMethods/GetAllPaymentMethods', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        console.log(data.item2);
        const filteredData = data.item2.filter(item => item.isDeleted === false);
        setPaymentMethods(filteredData);
        setpaymentMethodOptionValue({value: filteredData[0].id, label: (filteredData[0].paymentMethodName)});
        setPaymentMethodId(filteredData[0].id);
        setPaymentMethodName(filteredData[0].paymentMethodName);
      });
    }

    function GetInvoiceDataById(){
        if (typeof invoiceId !== "undefined" || invoiceId !== null || invoiceId !== "" || invoiceId === undefined){
          fetch(ApiUrl + '/api/Invoices/GetInvoiceDataById?Id='+ invoiceId, {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          })
          .then(response => response.json())
          .then(data => {
            const filtered = data.item2;
            filtered.formFields.forEach((field) => {
              field.categoryOptionValue = {value: field.categoryId, label: field.categoryName};
              field.productOptionValue = {value: field.productId, label: field.productName};
            });
            // console.log(filtered.id + " / " + filtered.name);
            console.log(filtered);
            setInvoice(filtered);
            setPaymentAmount(filtered.balanceCOD);
            setPaymentDate(currentDateTime);
          });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(paymentAmount === 0);
        console.log(typeof paymentAmount);
        if ( Number(paymentAmount) === 0) {
          if(Number(paymentAmount) === 0)
            setIsPaymentAmountValid(false);
          else
            setIsPaymentAmountValid(true);
          return;
        }
        else{
          setIsPaymentAmountValid(true);
        }
        try{
            const response = await fetch( ApiUrl +'/api/InvoicePayments/Save', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    InvoiceId: invoiceId,
                    PaymentAmount: paymentAmount,
                    PaymentDate: paymentDate,
                    paymentMethodId: paymentMethodId,
                    paymentId: paymentId,
                    paymentType: "Other"
                })
            });
            const data = await response.json();
            clearTextBoxes();
            GetAllInvoicePayments();
            GetInvoiceDataById();
            console.log(data);
        } catch (error) {
            console.error(error);
        }
    }

    const handleNew = async (e) => {
        e.preventDefault();
        clearTextBoxes();
    }

    const handleDelete = async (id) => {
        console.log(id);
        try {
          const response = await fetch(ApiUrl + '/api/InvoicePayments/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                GetAllInvoicePayments();
                GetInvoiceDataById();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }
    
    const handleUndo = async (id) => {
        try {
            const response = await fetch(ApiUrl + '/api/InvoicePayments/Undo?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
              Swal.fire({
                  icon: 'success',
                  title: data.message,
                  showConfirmButton: false,
                  timer: 2000
                }).then(() => {
                  clearTextBoxes();
                  GetAllInvoicePayments();
              });
            } else {
              console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    
    function clearTextBoxes(){
        setId(0);
        setPaymentAmount(0);
        setPaymentDate(currentDateTime);
    }

  const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
  };

  const handleEdit = (id) => {
        // navigate('/SaveEmployee/'+ id); // Replace '/edit-page' with the desired page URL
        const filtered = invoicePayments.filter(item => item.id === id);
        setId(filtered[0].id);
        setPaymentAmount(filtered[0].paymentAmount);
        setPaymentDate(new Date(filtered[0].paymentDate));
  }

  const paymentMethodOptions = paymentMethods.map((paymentmethod) => ({
    value: paymentmethod.id,
    label: paymentmethod.paymentMethodName,
  }));
  const handlePaymentMethodSelectChange = (selectedOption) => {
      setpaymentMethodOptionValue(selectedOption);
      setPaymentMethodId(selectedOption.value);
      setPaymentMethodName(selectedOption.label);
  };

  const handlePaymentDateChange = (date) => {
    const invoiceDate = new Date(invoice.invoiceDate);
    if (date < invoiceDate) {
      setIsPaymentDateValid(false);
    } else {
      setPaymentDate(date);
      setIsPaymentDateValid(true);
    }
  };

  return (
    <div className='Payment'>
        <h1>Payment</h1>

        <div className='newHeaderForm'>
          <div className='headerItem'>
            <input type="text" id='invoiceNumber' name="invoiceNumber" placeholder="InvoiceNumber" value={invoice.invoiceNumber + "/" + invoice.invoiceYear} disabled={true} />
          </div>
          <div className='headerItem'>
            <input type="number" id='paymentLeft' name="paymentLeft" placeholder="paymentLeft" value={paymentLeft} disabled={true} />
          </div>
        </div>
        
        {/* <div className='ColorItem'>
          <input type="number" id='payment' name="payment" placeholder="Payment" value={paymentAmount} />
        </div>
        <div className='ColorItem'>
          <input type="number" id='payment' name="payment" placeholder="Payment" value={paymentAmount} />
        </div> */}

        <form className="newCustomerForm" onSubmit={handleSubmit} >
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />

            <div className='PaymentItem'>
              <label htmlFor="payment">Payment Method </label>
              <SelectReact
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  value={paymentMethodOptionValue}
                  name="salesPerson"
                  options={paymentMethodOptions}
                  onChange={handlePaymentMethodSelectChange}
                />
            </div>

            <div className='PaymentItem'>
              <label htmlFor="payment">Payment Amount </label>
              <input
                type="number"
                id='payment'
                name="payment"
                className={!isPaymentAmountValid ? 'InvalidInputtext' : 'validInputtext'}
                placeholder="Payment"
                value={paymentAmount}
                onChange={(e) => {
                  if(parseFloat(e.target.value) >= parseFloat(paymentLeft)){
                    console.log("1 / " + paymentLeft );
                    setPaymentAmount(paymentLeft);
                  }
                  else{
                    if(parseFloat(e.target.value) <= 1){
                      console.log("2 / " + paymentLeft);
                      setPaymentAmount(1);
                    }
                    else{
                      console.log("3 / " + paymentLeft);
                      setPaymentAmount(e.target.value);
                    }
                  }
                }}
              />
            </div>
            
            <div className="PaymentItem">
                <label htmlFor="paymentDate">Payment Date</label>
                <DatePicker
                  selected={paymentDate}
                  onChange={handlePaymentDateChange}
                  dateFormat="MMMM d, yyyy"
                  placeholderText="Select Date"
                  id="measureDate"
                  name="measureDate"
                  className={!isPaymentDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  showMonthDropdown
                  scrollableMonthYearDropdown
                />
                {!isPaymentDateValid && <span className="ErrorMessage">Payment date cannot be less than invoice Date.</span>}
            </div>

            <div className="PaymentItem">
                <label htmlFor="paymentID">Payment ID</label>
                <input type="text" value={paymentId} placeholder="Payment ID" name="paymentID" id="paymentID" onChange={(e) => setPaymentId(e.target.value)} />
              </div>
            
            <div className='newCustomerItem'>
                <button type='button' className="newCustomerBtn" id="newCustomerBtn" onClick={handleNew}>New</button>
                <button type='submit' className="newCustomerButton">{id == '0' ? 'Create' : 'Save'}</button>
            </div>
        </form> 
        <br />
        <Table
            data={invoicePayments}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            canEdit={true}
            canDelete={true}
            onUndo={handleUndo}
        />
    </div>
  )
}
