import React, { useState, useEffect } from 'react'
import { ApiUrl, GlobalPermission } from '../../../../Global';
import SelectReact from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './installerPayment.css'

export default function InstallerPayment() {
    const [installDate, setInstallDate] = useState(null);
    const [installers, setInstallers] = useState([]);
    const [installerId, setInstallerId] = useState(0);
    const [installerName, setInstallerName] = useState('');
    const [installerOptionValue, setInstallerOptionValue] = useState();
    const [invoices, setInvoices] = useState([]);
    const [InvoiceOptionValue, setInvoiceOptionValue] = useState();
    const [payments, setPayments] = useState([
        {
          invoiceId: 0,
          invoiceNumber: '',
          paymentAmount: 0,
        }
    ]);

    useEffect(() => {
        GetAllInstallers();
        GetAllInvoices();
    }, [])

    function GetAllInstallers(){
        fetch(ApiUrl + '/api/Installers/GetAllInstallers', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          const filtered = data.item2.filter(installer => installer.isDeleted === false);
          // console.log(filtered);
          setInstallers(filtered);
          setInstallerOptionValue({value: 0, label: 'Select...'});
          setInstallerId(0);
          setInstallerName('')
        });
    }

    function GetAllInvoices(){
        fetch(ApiUrl + '/api/Invoices/GetAllGlobalInvoices', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          const filtered = data.item2.filter(invoice => invoice.isDeleted === false);
          setInvoices(filtered);
        });
    }

    const installersOptions = installers.map((installer) => ({
        value: installer.id,
        label: installer.fullName,
    }));
    installersOptions.unshift({
        value: 0,
        label: 'Select...',
    });
    const handleInstallerSelectChange = (selectedOption) => {
        setInstallerOptionValue(selectedOption);
        setInstallerId(selectedOption.value);
        setInstallerName(selectedOption.label);
    };

    const addFields = () => {
        let payment = {
            invoiceId: 0,
            paymentAmount: 0,
        };
        setPayments([...payments, payment]);
    };

    const saveFields = () => {
        setPayments([...payments]);
    };
    
    const removeField = (index) => {
        let data = [...payments];
        data.splice(index, 1);
        setPayments(data);
    };

    const invoicesOptions = invoices.map((invoice) => ({
        value: invoice.id,
        label: invoice.invoiceNumber + " / " + invoice.companyCode,
      }));
      const handleInvoicesSelectChange = (selectedOption, index) => {
        let data = [...payments];
        data[index].invoiceId = selectedOption.value;
        data[index].invoiceNumber = selectedOption.label;
        data[index].invoiceOptionValue = { value: selectedOption.value, label: selectedOption.label };
        setPayments(data);
      };

    return (
        <div className='installerPayment'>
            <h1>Installer Payment</h1>
            <div className="InvoiceItem">
                <label htmlFor="installer">Installer</label>
                <SelectReact
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    value={installerOptionValue}
                    name="installer"
                    options={installersOptions}
                    onChange={handleInstallerSelectChange}
                />
            </div>
            <br />
            <button type="button" className='addBtn' onClick={addFields}>Add</button>
            {payments.map((payment, index) => {
                return (
                    <>
                        <div className='dynamicFields'>
                            <div className='dynamicSubFields'>
                                <SelectReact
                                    className="textboxField"
                                    classNamePrefix="select"
                                    isClearable={false}
                                    isSearchable={true}
                                    value={payment.invoiceOptionValue}
                                    name="category"
                                    id={index}
                                    options={invoicesOptions}
                                    onChange={(selectedOption) => handleInvoicesSelectChange(selectedOption, index)}
                                />
                            </div>
                            <div className='dynamicSubFields'>
                                <input type="number" name="paymentAmount" placeholder="Payment Amount" value={payment.paymentAmount} onChange={(event) => payment.paymentAmount = event.target.value} />
                            </div>
                            <div className='fieldWrapper '>
                                <button type="button"  className='removeBtn' onClick={() => removeField(index)}>Remove</button>
                            </div>
                        </div>
                    </>
                )
            })}
        </div>
    )
}
