import React, { useEffect, useState } from 'react'
import './permission.css'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import { ApiUrl, GlobalPermission } from '../../../Global';
import Table from '../../table/Table';

export default function Permission() {
    const [permissionName, setPermissionName] = useState('');//pageName
    const [permissionUrl, setPermissionUrl] = useState('');
    const [pageName, setPageName] = useState('');
    const [title, setTitle] = useState('');
    const [titleName, setTitleName] = useState('');
    const [orderNameNumber, setOrderNameNumber] = useState(0);
    const [orderTitleNumber, setOrderTitleNumber] = useState(0);
    const [permissions, setPermissions] = useState([]);
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();

    useEffect(() => {
        const desiredPermissionPageName = 'permissions'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
            (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);

        fetch(ApiUrl + '/api/Permissions/GetAllPermissions', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            setPermissions(data.item2);
        });
      }, []);

    const handleDelete = async (id) => {
        try {
            const response = await fetch(ApiUrl + '/api/Permissions/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
              clearTextBoxes();
              GetAllPermissions();
            } else {
              console.error(data);
            }
          } catch (error) {
            console.error(error);
          }
      }

      function clearTextBoxes(){
        setPermissionName('');
        setPermissionUrl('');
        setPageName('');
        setTitle('');
        setTitleName('');
        setOrderNameNumber(0);
        setOrderTitleNumber(0);
        setId(0);
      }

      const handleEdit = async (id) => {
        // console.log(supplier);
        const filtered = permissions.filter(item => item.id === id);
        setId(id);
        setPermissionName(filtered[0].name);
        setPermissionUrl(filtered[0].url)
        setPageName(filtered[0].pageName);
        setTitle(filtered[0].title);
        setTitleName(filtered[0].titleName);
        setOrderNameNumber(filtered[0].orderNameNumber);
        setOrderTitleNumber(filtered[0].orderTitleNumber);
      }

      const handleNew = async (e) => {
        e.preventDefault();
        setId(0);
        setPermissionName('');
        setPermissionUrl('');
        setPageName('');
        setTitle('');
        setTitleName('');
        setOrderNameNumber(0);
        setOrderTitleNumber(0);
      }

      function GetAllPermissions(){
        fetch(ApiUrl + '/api/Permissions/GetAllPermissions', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            setPermissions(data.item2);
        });
      }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch( ApiUrl +'/api/Permissions/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    name: permissionName,
                    url: permissionUrl,
                    pageName: pageName,
                    title: title,
                    titleName: titleName,
                    orderNameNumber: orderNameNumber,
                    orderTitleNumber: orderTitleNumber
                })
            });
            const data = await response.json();
            if (response.ok) {
                clearTextBoxes();
                GetAllPermissions();
                console.log(data);
            } else {
                console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

      const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'url', title: 'Url' },
        { key: 'title', title: 'Title' },
        { key: 'titleName', title: 'Title Name' },
        { key: 'orderNameNumber', title: 'Order Name Number' },
        { key: 'orderTitleNumber', title: 'Order Title Number' },
    ];

  return (
    <div className='permission'>
        <h1>Permission</h1>
        {canPermission && canPermission.canEdit ? (
          <form className="newPermissionForm" onSubmit={handleSubmit} >
              <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
              <div className="newPermissionItem">
                  <label>Permission Name</label>
                  <input type="text" placeholder="Permission Name" name="permissionName" id="permissionName" value={permissionName} onChange={(e) => setPermissionName(e.target.value)} />
              </div>
              <div className="newPermissionItem">
                  <label>Url</label>
                  <input type="text" placeholder="Url" name="permissionUrl" id="permissionUrl" value={permissionUrl} onChange={(e) => setPermissionUrl(e.target.value)} />
              </div>
              <div className="newPermissionItem">
                  <label>Page Name</label>
                  <input type="text" placeholder="Page Name" name="pageName" id="pageName" value={pageName} onChange={(e) => setPageName(e.target.value)} />
              </div>
              <div className="newPermissionItem">
                  <label>Title</label>
                  <input type="text" placeholder="Title" name="title" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
              </div>
              <div className="newPermissionItem">
                  <label>Title Name</label>
                  <input type="text" placeholder="Title Name" name="titleName" id="titleName" value={titleName} onChange={(e) => setTitleName(e.target.value)} />
              </div>
              <div className="newPermissionItem">
                  <label>Order Name</label>
                  <input type="number" name="orderNameNumber" id="orderNameNumber" value={orderNameNumber} onChange={(e) => setOrderNameNumber(e.target.value)} />
              </div>
              <div className="newPermissionItem">
                  <label>Order Title</label>
                  <input type="number" name="orderTitleNumber" id="orderTitleNumber" value={orderTitleNumber} onChange={(e) => setOrderTitleNumber(e.target.value)} />
              </div>
              
              <div className='newPermissionItem'>
                  <button type='button' className="newPermissionBtn" id="newPermissionBtn" onClick={handleNew}>New</button>
                  <button type='submit' className="newPermissionButton">{id == '0' ? 'Create' : 'Save'}</button>
              </div>
          </form>
          ) : (
          <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
          <Table
              data={permissions}
              columns={columns}
              onDelete={handleDelete}
              onEdit={handleEdit}
              canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
              canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
          />
        ) : (
          <></>
        )}
    </div>
  )
}
