import React, {useState, useEffect} from 'react'
import './inventory.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';
import SelectReact from 'react-select';

export default function Inventory() {
    const [productId, setProductId] = useState(0);
    const [wareHouseId, setWareHouseId] = useState(0);
    const [id, setId] = useState(0);
    const [productTypes, setProductTypes] = useState([]);
    const [wareHouses, setWareHouses] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [inventories, setInventories] = useState([]);
    const currentDateTime = new Date();
    const [canPermission, setCanPermission] = useState();
    const [link, setLink] = useState('');
    const [productTypeOptionValue, setProductTypeOptionValue] = useState();

    useEffect(() => {
        const desiredPermissionPageName = 'Inventory'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);
        GetAllGlobalProductTypes();
        GetAllGlobalWareHouses();
        GetAllInventories();

        setLink("/products")
    }, []);

    function GetAllGlobalProductTypes(){
        fetch(ApiUrl + '/api/ProductTypes/GetAllGlobalProductTypes', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.item2);
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setProductTypes(filtered);
            setProductId(filtered[0].id);
            setProductTypeOptionValue({value: filtered[0].id, label: (filtered[0].styleName + "(" + filtered[0].styleCode + ")" + ' ' + filtered[0].colorName + "(" + filtered[0].colorHexCode + ") " + filtered[0].name + (filtered[0].length !== null ? ( " (" + filtered[0].length + " x " + filtered[0].width + ")") : (filtered[0].squareFoot === null ? '' : (" (" + filtered[0].squareFoot + ")"))))});
        });
    }
    function GetAllGlobalWareHouses(){
        fetch(ApiUrl + '/api/WareHouses/GetAllGlobalWareHouses', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            setWareHouses(data.item2);
            setWareHouseId(data.item2[0].id);
        });
    }
    function GetAllInventories(){
        fetch(ApiUrl + '/api/Inventories/GetAllGlobalInventories', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            setInventories(data.item2);
        });
    }

    const handleNew = async (e) => {
        e.preventDefault();
        clearTextBoxes();
    }

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'styleColorProductType', title: 'ProductType' },
        { key: 'wareHouseName', title: 'WareHouse' },
        { key: 'quantity', title: 'Quantity' },
        { key: 'createdDate', title: 'Created' },
    ];

    const handleDelete = async (id) => {
        try {
            const response = await fetch(ApiUrl + '/api/Inventories/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllInventories();
                });
            } else {
              console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setProductId(productTypes[0].id);
        setProductTypeOptionValue({value: productTypes[0].id, label: (productTypes[0].styleName + "(" + productTypes[0].styleCode + ")" + ' ' + productTypes[0].colorName + "(" + productTypes[0].colorHexCode + ") " + productTypes[0].name + (productTypes[0].length !== null ? ( " (" + productTypes[0].length + " x " + productTypes[0].width + ")") : (productTypes[0].squareFoot === null ? '' : (" (" + productTypes[0].squareFoot + ")"))))});
        setWareHouseId(wareHouses[0].id);
        setQuantity(1);
    }

    const handleEdit = async (id) => {
        const filtered = inventories.filter(item => item.id === id);
        setId(id);
        setProductId(filtered[0].productId);
        setProductTypeOptionValue({value: filtered[0].id, label: (filtered[0].styleName + "(" + filtered[0].styleCode + ")" + ' ' + filtered[0].colorName + "(" + filtered[0].colorHexCode + ") " + filtered[0].name + (filtered[0].length !== null ? ( " (" + filtered[0].length + " x " + filtered[0].width + ")") : (filtered[0].squareFoot === null ? '' : (" (" + filtered[0].squareFoot + ")"))))});
        setWareHouseId(filtered[0].wareHouseId);
        setQuantity(filtered[0].quantity);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(quantity <= 0){
            return;
        }
        try {
            const response = await fetch( ApiUrl +'/api/Inventories/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    ProductId: productId,
                    WareHouseId: wareHouseId,
                    Quantity: quantity,
                    createdBy: '0',
                    CreatedDate: currentDateTime
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllInventories();
                });
            } else {
                console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const productTypesOptions = productTypes.map((productType) => ({
        value: productType.id,
        label: (productType.styleName + "(" + productType.styleCode + ")" + ' ' + productType.colorName + "(" + productType.colorHexCode + ") " + productType.name + (productType.length !== null ? ( " (" + productType.length + " x " + productType.width + ")") : (productType.squareFoot === null ? '' : (" (" + productType.squareFoot + ")")))),
    }));
    const handleProductTypeSelectChange = (selectedOption) => {
        setProductTypeOptionValue(selectedOption);
        setProductId(selectedOption.value);
    };


  return (
    <div className='inventory'>
        <h1>Inventory</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newCustomerForm" onSubmit={handleSubmit} >
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />

            <div className="newCustomerItemSelectReact">
                <label htmlFor="productType">ProductType</label>
                <SelectReact
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  value={productTypeOptionValue}
                  name="productType"
                  options={productTypesOptions}
                  onChange={handleProductTypeSelectChange}
                />
            </div>
            
            {/* <div className='newCustomerItem'>
                <label htmlFor='product'>productTypes</label>
                <select className='newStateSelect' name="product" id="product" value={productId} onChange={(e) => setProductId(e.target.value)}>
                    {productTypes.map((product) => (
                        <option key={product.id} value={product.id}>{product.styleName + "(" + product.styleCode + ")" + ' ' + product.colorName + "(" + product.colorHexCode + ") " + product.name + (product.length !== null ? ( " (" + product.length + " x " + product.width + ")") : (product.squareFoot === null ? '' : (" (" + product.squareFoot + ")")))  }</option>
                    ))}
                </select>
            </div> */}
            <div className='newCustomerItem'>
                <label htmlFor='wareHouse'>WareHouse</label>
                <select className='newStateSelect' name="wareHouse" id="wareHouse" value={wareHouseId} onChange={(e) => setWareHouseId(e.target.value)}>
                    {wareHouses.map((wareHouse) => (
                        <option key={wareHouse.id} value={wareHouse.id}>{wareHouse.name}</option>
                    ))}
                </select>
            </div>
            <div className="newPermissionItem">
                <label htmlFor='quantity'>Quantity</label>
                <input type="number" name="quantity" id="quantity" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                {quantity === '' || parseInt(quantity) <= 0 ? (
                    <span style={{ color: 'red' }}>Quantity must be greater than 0</span>
                ) : null}
            </div>
            <div className='newCustomerItem'>
                <button type='button' className="newCustomerBtn" id="newCustomerBtn" onClick={handleNew}>New</button>
                <button type='submit' className="newCustomerButton">{id == '0' ? 'Create' : 'Save'}</button>
            </div>
        </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
        <Table
            data={inventories}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            viewEstimateFileBtn="File"
            viewLink = "Products"
            viewLinkPath = {link}
            canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
            canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
        />
        ) : (
            <></>
        )}
    </div>
  )
}
