import React from 'react'
import './printtableTable.css'

export default function PrintableTable({ data, columns, title }) {

  const divStyle = {
    width: '100%',
  };

  const thStyle = {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    backgroundColor: '#f2f2f2',
  };

  const tdStyle = {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  };
  const SalesPersonTableReport = {
    width: '100%',
    bordercollapse: 'collapse',
    padding: '25px'
  }

  const handlePrint = () => {
    console.log("Print")
    window.print();
  };

  const formatDatePayment = (dateString) => {
    const dateParts = dateString.split('T')[0].split('-');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1;
    const day = parseInt(dateParts[2]);
    const formattedDate = new Date(year, month, day);
    
    return formattedDate.toLocaleDateString();
  };

  return (
    <div style={divStyle}>
      <h1>{title}</h1>
      <table style={SalesPersonTableReport}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key} style={thStyle}>
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              {columns.map((column) => {
                if (column.key === "totalPaymentAmount") {
                  const totalPaymentAmount = parseFloat(row[column.key]);
                  const formattedTotalPaymentAmount = totalPaymentAmount.toLocaleString();
                  return <td key={`${row.id}-${column.key}`} style={tdStyle}>{formattedTotalPaymentAmount}</td>;
                }
                if(column.key === 'invoicePaymentView'){
                  return <td key={`${row.invoiceId}-${column.key}`}>
                    <ul>
                    {row.invoicePaymentView.map((row1) => (
                        <li>{row1.paymentMethodName} {row1.paymentType} {row1.paymentAmount.toLocaleString()} {formatDatePayment(row1.paymentDate)}</li>
                    ))}
                    </ul>
                  </td>
                }
                else{
                  return(
                  <td key={column.key} style={tdStyle}>
                    {row[column.key]}
                  </td>)
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
