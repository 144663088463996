import React, {useState, useEffect} from 'react'
import './nationality.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';
import SelectReact from 'react-select';

export default function Nationality() {
    const [nationalities, setNationalities] = useState([]);
    const [name, setName] = useState('');
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    const [isNameValid, setIsNameValid] = useState(true);
    const [countries, setCountries] = useState([]);
    const [countryId, setCountryId] = useState();
    const [countryOptionValue, setCountryOptionValue] = useState();

    useEffect(() => {
      const desiredPermissionPageName = 'Nationality'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
    
      setCanPermission(desiredRow);
      GetAllGlobalNationalities();
      GetAllCountries();
    }, []);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'countryNameCode', title: 'Country' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
      // Perform edit action using the ID of the row being edited
      const filtered = nationalities.filter(item => item.id === id);
      setName(filtered[0].name);
      setId(filtered[0].id);
      const filteredOptions = options.filter((option) => option.value === filtered[0].countryId);
      setCountryOptionValue(filteredOptions);
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/Nationalities/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllGlobalNationalities();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() === '') {
          if(name.trim() === '')
            setIsNameValid(false);
          else
            setIsNameValid(true);
          return;
        }
        else{
            setIsNameValid(true);
        }
        // console.log(countryId + " / " + name);
        try {
            const response = await fetch( ApiUrl +'/api/Nationalities/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    Name: name,
                    CountryId: countryId,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllGlobalNationalities();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsNameValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setName('');
        setIsNameValid(true);
    }

    function GetAllGlobalNationalities(){
      fetch(ApiUrl + '/api/Nationalities/GetAllGlobalNationalities', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        setNationalities(data.item2);
      });
    }

    const handleUndo = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/Nationalities/Undo?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllGlobalNationalities();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    function GetAllCountries(){
        fetch(ApiUrl + '/api/Countries/GetAllCountries', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          setCountries(data.item2);
          setCountryId(data.item2[0].id);
          setCountryOptionValue({value: data.item2[0].id, label: ( data.item2[0].name + " (" + data.item2[0].code + ")" ) })
        });
    }

    const options = countries.map((country) => ({
        value: country.id,
        label: country.name + " (" + country.code + ")",
    }));

    const handleSelectChange = (selectedOption) => {
        console.log(selectedOption)
        setCountryOptionValue(selectedOption);
        setCountryId(selectedOption.value);
      };

  return (
    <div className='nationality'>
        <h1>Nationality</h1>
        {canPermission && canPermission.canEdit ? (
            <form className="NationalityForm" onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
                <div className="NationalityItem">
                    <label htmlFor="name">Name:</label>
                    <input type="text" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} placeholder="Name" name="name" id="name" onChange={(e) => setName(e.target.value)} />
                </div>

                {/* <div className="newSalesPersonItem">
                    <label>Country</label>
                    <select className="newSalesPersonSelect" name="supplier" id="supplier" value={countryId} onChange={(e) => setCountryId(e.target.value)}>
                        {countries.map((country) => (
                            <option key={country.id} value={country.id}>{country.name + " (" + country.code + ")"}</option>
                        ))}
                    </select>
                </div> */}

                <div className="NationalityItem">
                    <label>Country</label>
                    <SelectReact
                        className="basic-single"
                        classNamePrefix="select"
                        value={countryOptionValue}
                        isClearable={false}
                        isSearchable={true}
                        name="color"
                        options={options}
                        onChange={handleSelectChange}
                    />
                </div>

                <div className='NationalityItem'>
                    <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
                    <button type="submit" className='saveNationalityBtn' >{id == '0' ? 'Create' : 'Save'}</button>
                </div>
            </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
            <Table
                data={nationalities}
                columns={columns}
                onDelete={handleDelete}
                onEdit={handleEdit}
                canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                onUndo={handleUndo}
            /> 
            ) : (
                <></>
        )}
    </div>
  )
}
