import React, {useState, useEffect} from 'react'
import "./invoicesPaymentDetailReport.css"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ApiUrl, GlobalPermission } from '../../../../Global';
import Table from '../../../table/Table';
import PrintableTable from '../PrintableTable';
import ReactDOMServer from 'react-dom/server';

export default function InvoicesPaymentDetailReport() {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isFromDateValid, setIsFromDateValid] = useState(true);
    const [isToDateValid, setIsToDateValid] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [invoicesFiltered, setInvoicesFiltered] = useState([]);
    const [canPermission, setCanPermission] = useState();
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
      
      const desiredPermissionPageName = 'InvoicesPaymentDetailReport'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
    
      setCanPermission(desiredRow);

      const currentDateTime = new Date();
      // currentDateTime.setMonth((0)); // Set the month to January (0 represents January)
      currentDateTime.setDate(1); // Set the day to the 1st
      currentDateTime.setHours(0, 0, 0, 0); // Set the time to the beginning of the day (midnight)

      setFromDate(currentDateTime);
      setToDate(new Date());
    }, [])

    useEffect(() => {
      const filtered = invoices.filter(item =>
        (item.customerName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.invoiceNumber.toLowerCase().includes(searchText.toLowerCase()))
      );
      setInvoicesFiltered(filtered);
    }, [searchText])

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(fromDate);
        if (fromDate === null || toDate === null ) {
          if(fromDate === null)
            setIsFromDateValid(false);
          else
            setIsFromDateValid(true);
          if(toDate === null)
            setIsToDateValid(false);
          else
            setIsToDateValid(true);
          return;
        }
        else{
            setIsFromDateValid(true);
            setIsToDateValid(true);
        }
        try {
            const formattedFromDate = fromDate.toLocaleDateString('en-US');
            const formattedToDate = toDate.toLocaleDateString('en-US');

            const apiUrl = ApiUrl + '/api/InvoicePayments/GetInvoicesPaymentDetailReport';
            const url = `${apiUrl}?fromDate=${formattedFromDate}&toDate=${formattedToDate}`;
            fetch(url, {
                method: 'GET',
                headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
            })
            .then(response => response.json())
            .then(data => {
                console.log(data.item2);
                setInvoices(data.item2);
                setInvoicesFiltered(data.item2);
            });
        } catch (error) {
            console.error(error);
        }
      }


    const handleFromDateChange = (date) => {
        if (toDate && date > toDate) {
          // setIsFromDateValid(false);
        } else {
          setFromDate(date);
          // setIsFromDateValid(true);
        }
    };
    
    const handleToDateChange = (date) => {
        if (fromDate && date < fromDate) {
          // setIsToDateValid(false);
        } else {
          setToDate(date);
          // setIsToDateValid(true);
        }
    };

    const columns = [
        { key: 'invoiceNumber', title: 'Invoice Number' },
        { key: 'invoiceDate', title: 'Invoice Date' },
        { key: 'customerName', title: 'Customer' },
        { key: 'salesPerson', title: 'Sales Person' },
        { key: 'companyCode', title: 'Company' },
        { key: 'total', title: 'Total' },
        { key: 'paidAmount', title: 'Paid Amount' },
        { key: 'unPaidAmount', title: 'unPaid Amount' },
        { key: 'invoicePaymentView', title: 'Payments' },
    ];

  const handlePrintClick = () => {

    const printableTableComponent = (
      <PrintableTable data={invoices} columns={columns} title={"Payment Details Report"} />
    );
  
    const newWindow = window.open("", "_blank");
    newWindow.document.open();
    newWindow.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <title>Sales People</title>
          <link rel="stylesheet" type="text/css" href="printtableTable.css">
        </head>
        <body>${ReactDOMServer.renderToString(printableTableComponent)}</body>
      </html>`);
    newWindow.print();
    newWindow.document.close();
  };

  return (
    <div className='InvoicesPaymentDetailReport'>
        <h1>Invoices Payment Detail</h1>
        {canPermission && canPermission.canEdit ? (
            <form className="newInvoicesReportForm" onSubmit={handleSubmit} >
                <div className="invoiceReportForm">
                    <div className="invoiceReportItem">
                        <label htmlFor="fromDate">From Date:</label>
                        <DatePicker
                            selected={fromDate}
                            onChange={handleFromDateChange}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select Date"
                            id="fromDate"
                            name="fromDate"
                            className={!isFromDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                            // showMonthYearPicker
                            scrollableMonthYearDropdown
                        />
                    </div>
                    <div className="invoiceReportItem">
                        <label htmlFor="fromDate">To Date:</label>
                        <DatePicker
                            selected={toDate}
                            onChange={handleToDateChange}
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select Date"
                            id="toDate"
                            name="toDate"
                            className={!isToDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                            showYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={15}
                            // showMonthYearPicker
                            scrollableMonthYearDropdown
                        />
                    </div>
                    <div className='invoiceReportItem'>
                        <label htmlFor="fromDate">&nbsp;</label>
                        <button type="submit" className='InvoicesReportSubmitBtn' >Filter</button>
                    </div>
                </div>
            </form>
        ) : (<>
        </>
        )}

        {canPermission && canPermission.canView ? (
            <>
            <div className="searchContainer">
                <div className="searchInputContainer">
                    <label className="searchLabel" htmlFor="searchInput">Search:</label>
                    <input
                        id="searchInput"
                        className="searchPrivateInput"
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
                <button onClick={handlePrintClick} className="PrintBtn"> Print</button>
            </div>
            <Table
                data={invoicesFiltered}
                columns={columns}
                // onDelete={handleDelete}
                // onEdit={handleEdit}
                // canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                // canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                // onUndo={handleUndo}
            /> 
            </>
            ) : (
                <></>
        )}


    </div>
  )
}
