import React, {useState, useEffect} from 'react'
import './supplierPayment.css'
import { useParams } from 'react-router-dom';
import Table from '../../../table/Table';
import { ApiUrl } from '../../../../Global';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import SelectReact from 'react-select';

export default function SupplierPayment() {
  const { supplierInvoiceId } = useParams();
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [paymentDate, setPaymentDate] = useState(null);
  const [supplierPayments, setSupplierPayments] = useState([]);
  const [supplierInvoice, setSupplierInvoice] = useState({});
  const [id, setId] = useState(0);//
  const [isMeasuredDateValid, setIsMeasuredDateValid] = useState(true);
  const [paymentLeft, setPaymentLeft] = useState(0);
  const [maxPayment , setMaxPayment ] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const [paymentMethodOptionValue, setpaymentMethodOptionValue] = useState();
  const [paymentMethodName, setPaymentMethodName] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [isPaymentAmountValid, setIsPaymentAmountValid] = useState(true);
  const [isPaymentDateValid, setIsPaymentDateValid] = useState(true);
  const currentDateTime = new Date();

  useEffect(() => {
    GetAllSupplierInvoicePayments();
    GetSupplierInvoiceDataById();
    GetAllPaymentMethods();
  }, []);

  useEffect(() => {
    console.log(supplierPayments);
    const paymentAmountTotal = supplierPayments.reduce((totalInvoice, item) => totalInvoice + parseFloat(item.paymentAmount), 0).toFixed(2);
    console.log(parseFloat(paymentAmountTotal).toFixed(2));

    const numberLeft = (parseFloat(supplierInvoice.totalInvoice) - parseFloat(paymentAmountTotal));
    console.log(numberLeft);
    setPaymentAmount(parseFloat(numberLeft).toFixed(2));
    setPaymentLeft(parseFloat(numberLeft).toFixed(2));
  }, [supplierInvoice]);

  function GetAllPaymentMethods(){
    fetch(ApiUrl + '/api/PaymentMethods/GetAllPaymentMethods', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.item2);
      const filteredData = data.item2.filter(item => item.isDeleted === false);
      setPaymentMethods(filteredData);
      setpaymentMethodOptionValue({value: filteredData[0].id, label: (filteredData[0].paymentMethodName)});
      setPaymentMethodId(filteredData[0].id);
      setPaymentMethodName(filteredData[0].paymentMethodName);
    });
  }

  const columns = [
    { key: 'id', title: 'ID' },
    { key: 'paymentType', title: 'Payment Type' },
    { key: 'paymentMethodName', title: 'Payment Method' },
    { key: 'paymentAmount', title: 'PaymentAmount' },
    { key: 'paymentDate', title: 'PaymentDate' },
    { key: 'paymentId', title: 'Payment ID' },
    { key: 'isDeleted', title: 'IsDeleted' },
  ];

  function GetAllSupplierInvoicePayments(){
    fetch(ApiUrl + '/api/SupplierPayments/GetAllSupplierInvoicePaymentsBySupplierInvoiceId?supplierInvoiceId=' + supplierInvoiceId, {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log("data");
        console.log(data);
        // const filtered = data.item2.filter(obj => obj.isDeleted === false);
        setSupplierPayments(data.item2);
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(paymentAmount === 0);
    console.log(typeof paymentAmount);
    if ( Number(paymentAmount) === 0) {
      if(Number(paymentAmount) === 0)
        setIsPaymentAmountValid(false);
      else
        setIsPaymentAmountValid(true);
      return;
    }
    else{
      setIsPaymentAmountValid(true);
    }
    try{
        const response = await fetch( ApiUrl +'/api/SupplierPayments/Save', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            },
            body: JSON.stringify({
                id: id,
                supplierInvoiceId: supplierInvoiceId,
                PaymentAmount: paymentAmount,
                PaymentDate: paymentDate,
                paymentMethodId: paymentMethodId,
                paymentId: paymentId,
                paymentType: "Other"
            })
        });
        const data = await response.json();
        clearTextBoxes();
        GetAllSupplierInvoicePayments();
        GetSupplierInvoiceDataById();
        console.log(data);
    } catch (error) {
        console.error(error);
    }
  }

  function clearTextBoxes(){
    setId(0);
    setPaymentAmount(0);
    setPaymentDate(currentDateTime);
  }

  const handleNew = async (e) => {
    e.preventDefault();
    clearTextBoxes();
  }

  function GetSupplierInvoiceDataById(){
    if (typeof supplierInvoice !== "undefined" || supplierInvoice !== null || supplierInvoice !== "" || supplierInvoice === undefined){
      fetch(ApiUrl + '/api/SupplierInvoices/GetSupplierInvoiceDataById?supplierInvoiceId='+ supplierInvoiceId, {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        const filtered = data.item2;
        console.log(filtered);
        // filtered.products.forEach((field) => {
        //   field.categoryOptionValue = {value: field.categoryId, label: field.categoryName};
        //   field.productOptionValue = {value: field.productId, label: field.productName};
        // });
        // console.log(filtered.id + " / " + filtered.name);
        // console.log(filtered);
        setSupplierInvoice(filtered);
        setPaymentAmount(filtered.totalInvoice);
        setPaymentDate(currentDateTime);
      });
    }
  }

  const paymentMethodOptions = paymentMethods.map((paymentmethod) => ({
    value: paymentmethod.id,
    label: paymentmethod.paymentMethodName,
  }));
  const handlePaymentMethodSelectChange = (selectedOption) => {
      setpaymentMethodOptionValue(selectedOption);
      setPaymentMethodId(selectedOption.value);
      setPaymentMethodName(selectedOption.label);
  };

  const handlePaymentDateChange = (date) => {
    const invoiceDate = new Date(supplierInvoice.invoiceDate);
    if (date < invoiceDate) {
      setIsPaymentDateValid(false);
    } else {
      setPaymentDate(date);
      setIsPaymentDateValid(true);
    }
  };

  const handleDelete = async (id) => {
    console.log(id);
    try {
      const response = await fetch(ApiUrl + '/api/SupplierPayments/Delete?Id=' + id, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      });
      const text = await response.text();
      console.log('response text:', text);
      const data = JSON.parse(text);
      if (response.ok) {
        Swal.fire({
            icon: 'success',
            title: data.message,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            GetAllSupplierInvoicePayments();
            GetSupplierInvoiceDataById();
        });
      } else {
        console.error(data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const handleEdit = (id) => {
    // navigate('/SaveEmployee/'+ id); // Replace '/edit-page' with the desired page URL
    const filtered = supplierPayments.filter(item => item.id === id);
    setId(filtered[0].id);
    setPaymentAmount(filtered[0].paymentAmount);
    setPaymentDate(new Date(filtered[0].paymentDate));
  }

  return (
    <div className='SupplierPayment'>
        <h1>Supplier Payment</h1>

        <div className='newHeaderForm'>
          <div className='headerItem'>
            <input type="text" id='invoiceNumber' name="invoiceNumber" placeholder="InvoiceNumber" value={supplierInvoice.invoiceNumber} disabled={true} />
          </div>
          <div className='headerItem'>
            <input type="number" id='paymentLeft' name="paymentLeft" placeholder="paymentLeft" value={paymentLeft} disabled={true} />
          </div>
        </div>

        <form className="newCustomerForm" onSubmit={handleSubmit} >
          <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />

          <div className='PaymentItem'>
            <label htmlFor="payment">Payment Method </label>
            <SelectReact
              className="basic-single"
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
              value={paymentMethodOptionValue}
              name="salesPerson"
              options={paymentMethodOptions}
              onChange={handlePaymentMethodSelectChange}
            />
          </div>

          <div className='PaymentItem'>
            <label htmlFor="payment">Payment Amount </label>
            <input
              type="number"
              id='payment'
              name="payment"
              className={!isPaymentAmountValid ? 'InvalidInputtext' : 'validInputtext'}
              placeholder="Payment"
              value={paymentAmount}
              onChange={(e) => {
                if(parseFloat(e.target.value) >= parseFloat(paymentLeft)){
                  console.log("1 / " + paymentLeft );
                  setPaymentAmount(paymentLeft);
                }
                else{
                  if(parseFloat(e.target.value) <= 1){
                    console.log("2 / " + paymentLeft);
                    setPaymentAmount(1);
                  }
                  else{
                    console.log("3 / " + paymentLeft);
                    setPaymentAmount(e.target.value);
                  }
                }
              }}
            />
          </div>
            
          <div className="PaymentItem">
            <label htmlFor="paymentDate">Payment Date</label>
            <DatePicker
              selected={paymentDate}
              onChange={handlePaymentDateChange}
              dateFormat="MMMM d, yyyy"
              placeholderText="Select Date"
              id="measureDate"
              name="measureDate"
              className={!isPaymentDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              showMonthDropdown
              scrollableMonthYearDropdown
            />
            {!isPaymentDateValid && <span className="ErrorMessage">Payment date cannot be less than invoice Date.</span>}
          </div>

          <div className="PaymentItem">
            <label htmlFor="paymentID">Payment ID</label>
            <input type="text" value={paymentId} placeholder="Payment ID" name="paymentID" id="paymentID" onChange={(e) => setPaymentId(e.target.value)} />
          </div>
            
          <div className='newCustomerItem'>
            <button type='button' className="newCustomerBtn" id="newCustomerBtn" onClick={handleNew}>New</button>
            <button type='submit' className="newCustomerButton">{id == '0' ? 'Create' : 'Save'}</button>
          </div>
        </form> 
        <br />
        <Table
            data={supplierPayments}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            canEdit={true}
            canDelete={true}
            // onUndo={handleUndo}
        />
    </div>
  )
}
