import React, { useEffect, useState } from 'react'
import "./home.css"
import FeaturedInfo from '../../featuredInfo/FeaturedInfo'
import Chart from '../../chart/Chart'
import SalesManChart from '../../barCharts/salesManChart'
import { userData } from '../../../dummyData'
import WidgetSm from '../../widgetSm/WidgetSm'
import WidgetLg from '../../widgetLg/WidgetLg'
import SelectReact from 'react-select';
import { ApiUrl, GlobalPermission } from '../../../Global';

export default function Home() {
  const currentYear = new Date().getFullYear();
  const [selectedYears, setSelectedYears] = useState([currentYear]);
  const [selectedYearsOption, setSelectedYearsOption] = useState([{value: currentYear, label: (currentYear)}]);
  const [userName, setUserName] = useState('');
  const [roleName, setRoleName] = useState('');
  const [roleId, setRoleId] = useState(0);
  // const [dashboardData, setDashboardData] = useState();
  const startYear = currentYear - 10; // Adjust the range as needed
  const availableYears = [];
  for (let year = currentYear; year >= startYear; year--) {
    availableYears.push(
      {
        value: year,
        label: (year),
      }
    );
  }

  useEffect(() => {
    setUserName(sessionStorage.getItem("username"));
    fetch(ApiUrl + '/api/Role/GetRoleByUserId?userId=' + sessionStorage.getItem('UserId'), {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      // console.log("roleId");
      // console.log(data);
      setRoleId(data.id);
      setRoleName(data.name);
    //   console.log(roleId);
      });
  }, []);

  const handleChange = (selectedOption, index) => {
    if (selectedOption && selectedOption.length > 0) {
      const selectedYearValues = selectedOption.map((option) => option.value);
      setSelectedYearsOption(selectedOption);
      setSelectedYears(selectedYearValues);
      // console.log(selectedOption);
    }
  };

  return (
    <div className='home'>
        <div className='div-year'>
          <label htmlFor="year" className='div-year-label'>Year:</label>
          <SelectReact
            isMulti
            className="basic-single-home"
            classNamePrefix="select-home"
            isClearable={false}
            isSearchable={true}
            value={selectedYearsOption}
            name="year"
            options={availableYears}
            onChange={handleChange}
          />
        </div>
        
        <FeaturedInfo years={selectedYears} roleName={roleName} />
        <Chart data={userData} years={selectedYears} roleName={roleName} title="Income Per Month" grid dataKey="Total" />
        <div>
          <SalesManChart years={selectedYears} roleName={roleName} />
            {/* <WidgetLg /> */}
        </div>
        
        <div className="homeWidgets">
            <WidgetSm />
            <WidgetLg />
        </div>
    </div>
  )
}
