import React from 'react'
import "./widgetLg.css"

export default function WidgetLg() {
    const Button = ({type}) => {
        return <button className={'widgetLgButton ' + type}>{type}</button>
    }
  return (
    <div className='widgetLg'>
        <h3 className="widgetLgTitle">Latest translations</h3>
        <table className="widgetLgTable">
            <thead>
                <tr className="widgetLgTr">
                    <th className="widgetLgTh">Customer</th>
                    <th className="widgetLgTh">Date</th>
                    <th className="widgetLgTh">Amount</th>
                    <th className="widgetLgTh">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr className="widgetTr">
                    <td className="widgetLgUser">
                        <img src="images/carpet-for-less-logo-41308e5f-360w.png" alt="" className="widgetLgImg" />
                        <span className="widgetLgName">Jaafar Farhat</span>
                    </td>
                    <td className="widgetLgDate">24 July 2023</td>
                    <td className="widgetLgAmount">$122.00</td>
                    <td className="widgetLgStatus">
                        <Button type="Approved" />
                    </td>
                </tr>
                <tr className="widgetTr">
                    <td className="widgetLgUser">
                        <img src="images/carpet-for-less-logo-41308e5f-360w.png" alt="" className="widgetLgImg" />
                        <span className="widgetLgName">Jaafar Farhat</span>
                    </td>
                    <td className="widgetLgDate">24 July 2023</td>
                    <td className="widgetLgAmount">$122.00</td>
                    <td className="widgetLgStatus">
                        <Button type="Declined" />
                    </td>
                </tr>
                <tr className="widgetTr">
                    <td className="widgetLgUser">
                        <img src="images/carpet-for-less-logo-41308e5f-360w.png" alt="" className="widgetLgImg" />
                        <span className="widgetLgName">Jaafar Farhat</span>
                    </td>
                    <td className="widgetLgDate">24 July 2023</td>
                    <td className="widgetLgAmount">$122.00</td>
                    <td className="widgetLgStatus">
                        <Button type="Pending" />
                    </td>
                </tr>
            </tbody>
            <tfoot>
                
            </tfoot>
        </table>
    </div>
  )
}
