import React, { useState } from 'react'
import "./topbar.css"
import {NotificationsNone, Language, Settings} from '@mui/icons-material';

export default function Topbar() {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    function handleDropdownClick() {
      setIsDropdownOpen(!isDropdownOpen);
    }
  
    function handleLogout() {
      sessionStorage.removeItem('UserId');
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('authenticated');
      window.location.reload();
    }
  return (
    <div className='topbar'>
        <div className="topbarWrapper">
            <div className="topLeft">
                <span className="logo"><img src="images/carpet-for-less-logo-41308e5f-360w.png" alt="" className="logoTopLeft" /></span>
            </div>
            <div className="topRight">
                <div className="topbarIconContainer">
                    <NotificationsNone />
                    <span className="topIconBadge">2</span>
                </div>
                <div className="topbarIconContainer">
                    <Language />
                    <span className="topIconBadge">2</span>
                </div>
                <div className="topbarIconContainer" onClick={handleDropdownClick}>
                    <Settings />
                    {isDropdownOpen && (
                    <div className="dropdownMenu">
                        <button className='logoutBtn' onClick={handleLogout}>Logout</button>
                    </div>
                    )}
                </div>
                <img src="/images/carpet-for-less-logo-41308e5f-360w.png" width={"50px"} height={"50px"} alt="logo" className='topAvatar' />
            </div>
        </div>
    </div>
  )
}
