import React, {useState, useEffect} from 'react'
import './product.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Table from '../../table/Table';
import Swal from 'sweetalert2';
import PrintableTable from '../reports/PrintableTable';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import Modal from '../../modal/Modal';

export default function Product() {
    const { product } = useParams();
    const [id, setId] = useState(0);
    const [productId, setProductId] = useState(0);
    const [products, setProducts] = useState([]);
    const [dataProduct, setDataProduct] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [canPermission, setCanPermission] = useState();
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [categories, setCategories] = useState([]);
    const [dynamicFieldsMenus, setDynamicFieldsMenus] = useState([]);
    const [columns, setColumns] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [productUpdateId, setProductUpdateId] = useState(0);
    const [fieldValues, setFieldValues] = useState({});
    const [action, setAction] = useState('-');
    const [dynamicFieldIds, setDynamicFieldIds] = useState('');
    const [productActions, setProductActions] = useState([]);
    const [productActionsColumns, setProductActionsColumns] = useState([]);
    const currentDateTime = new Date();

    useEffect(() => {
      const desiredPermissionPageName = "Products"; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );

      setCanPermission(desiredRow);

      GetAllProducts();
      GetAllCategories();
      //console.log(supplierId);
  }, [product, searchText]);


  useEffect(() => {
    const filteredProducts = dataProduct.filter((d) => d.categoryId === selectedCategoryId);
    setProducts(filteredProducts);

     const categoryToColumns = categories.find((category) => category.id === selectedCategoryId);
        
        if (categoryToColumns !== null && categoryToColumns !== undefined) {
          const columnsUpdate = [
            { key: 'id', title: 'ID' },
            { key: 'productTypeNameCode', title: 'Product' },
            { key: 'wareHouseName', title: 'WareHouse' },
            ...(categoryToColumns.hasColor ? [{ key: 'colorNameCode', title: 'Color' }] : []),
            ...(categoryToColumns.hasUnit ? [{ key: 'measureUnitNameCode', title: 'Measure' }] : []),
            { key: 'createdDate', title: 'Created' },
            { key: 'isDeleted', title: 'Deleted' },
            { key: 'finished', title: 'Finished' },
          ];
          // Add dynamic field columns
          dynamicFieldsMenus.forEach((field) => {
            if (field.fieldLabel) {
              const key = field.fieldLabel.charAt(0).toLowerCase() + field.fieldLabel.slice(1);
              columnsUpdate.push({ key: key, title: field.fieldLabel });
            }
          });

          const productActionColumns = [
            { key: 'id', title: 'ID' },
            { key: 'actionType', title: 'Action' },
          ];
          dynamicFieldsMenus.forEach((field) => {
            if (field.fieldLabel && field.fieldLabel.toLowerCase() !== 'width') {
              const key = field.fieldLabel.charAt(0).toLowerCase() + field.fieldLabel.slice(1);
              productActionColumns.push({ key: key, title: field.fieldLabel });
            }
          });
          setProductActionsColumns(productActionColumns);
          setColumns(columnsUpdate);
        }

  }, [selectedCategoryId, dynamicFieldsMenus, dataProduct]);

    function GetAllProducts() {
      let Url = '/api/Products/GetAllProducts';
      //console.log(supplier);
      if (typeof product === "undefined" || product === null || product === "") {
        Url = '/api/Products/GetAllGlobalProducts';
      } else {
        Url = '/api/Products/GetAllProductsByInventoryId?inventoryId=' + product;
      }
    
      fetch(ApiUrl + Url, {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
      })
      .then(response => response.json())
      .then(data => {
        console.log(data.item2);
        setDataProduct(data.item2);
      });
    }

    const handleDelete = async (id) => {
        try {
            const response = await fetch(ApiUrl + '/api/Products/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            //console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
              Swal.fire({
                  icon: 'success',
                  title: data.message,
                  showConfirmButton: false,
                  timer: 2000
                }).then(() => {
                //   ClearTextBoxes();
                GetAllProducts();
              });
            } else {
              console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onCheckBoxChange = async (rowId) => {
      console.log(rowId);
      try {
        const response = await fetch(ApiUrl + '/api/Products/ChangeFinished?productId=' + rowId, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        //console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              GetAllProducts();
          });
        } else {
          console.error(data);
        }
    } catch (error) {
        console.error(error);
    }
  };

  function GetAllCategories(){
    fetch(ApiUrl + '/api/Categories/GetAllCategories', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
    })
    .then(response => response.json())
    .then(data => {
        // console.log(data.item2)
        setCategories(data.item2);
        handleChangeCategoryForMenus(data.item2[0].id);
        setSelectedCategoryId(data.item2[0].id);
    });
  }

  function handleChangeCategoryForMenus(categoryIdMenus) {
    setDynamicFieldsMenus([]); // Clear the dynamicFields state before fetching new fields
    setSelectedCategoryId(parseInt(categoryIdMenus, 10));
    GetDynamicFieldDefinitionByCategoryIdForMenus(parseInt(categoryIdMenus, 10));
    // console.log(data);
  }

  function GetDynamicFieldDefinitionByCategoryIdForMenus(categoryIdMenus) {
    fetch(ApiUrl + '/api/DynamicFieldDefinitions/GetDynamicFieldDefinitionByCategoryId?Category=' + categoryIdMenus, {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
    })
    .then(response => response.json())
    .then(data => {
        // console.log(data.item2);
        setDynamicFieldsMenus(data.item2);
    });
  }

  const openModal = (rowId) => {
    // console.log(rowId);
    setProductId(rowId);
    GetAllGlobalProductActionsByProductId(rowId);
    setProductUpdateId(rowId);
    setShowModal(true);
  };

  const closeModal = () => {
    setProductActions([]);
    setProductId(0);
    resetFields(); // Call the resetFields function to reset the field values
    setProductUpdateId(0);
    setShowModal(false);
  };

  const resetFields = () => {
    // Reset the field values based on the dynamicFieldsMenus array
    const resetValues = dynamicFieldsMenus.reduce((acc, dynamicField) => {
      acc[dynamicField.fieldLabel] = '';
      return acc;
    }, {});
    
    setFieldValues((prevFieldValues) => ({
      ...prevFieldValues,
      ...resetValues,
    }));
  };

  const handleSaveAction = async (e) => {
    e.preventDefault();
  
    const productActions = fieldValues.array.map((item) => ({
      id: 0,
      dynamicFieldDefinitionId: item.dynamicFieldDefinitionId,
      productId: productUpdateId,
      actionType: action,
      fieldValue: item.fieldValue,
      createdBy: "0",
      createdDate: currentDateTime,
    }));
  
    const requestBody = productActions;
  
    console.log(JSON.stringify(requestBody));
  
    fetch(ApiUrl + '/api/ProductActions/Save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => response.json())
      .then(data => {
        Swal.fire({
          icon: 'success',
          title: data.message,
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          GetAllProducts();
          closeModal();
      });
      });
  };

  const handleFieldChange = (fieldName, id, value) => {
    setDynamicFieldIds((prevIds) => {
      const newIds = Array.isArray(id)
        ? [...new Set([...prevIds, ...id])]
        : [...new Set([...prevIds, id])];
  
      setFieldValues((prevFieldValues) => ({
        ...prevFieldValues,
        [fieldName]: value,
        array: newIds.map((idItem) => ({
          id: 0,
          dynamicFieldDefinitionId: idItem,
          fieldValue: idItem === id ? value : prevFieldValues.array.find(obj => obj.dynamicFieldDefinitionId === idItem)?.fieldValue || '',
          productId: productUpdateId,
          actionType: action,
          createdBy: 0,
          createdDate: currentDateTime,
        })),
      }));
  
      return newIds;
    });
  };

  function GetAllGlobalProductActionsByProductId(productId){
    fetch(ApiUrl + '/api/ProductActions/GetAllGlobalProductActionsByProductId?productId=' + productId, {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
  })
  .then(response => response.json())
  .then(data => {
      setProductActions(data.item2);
  });
  }


  const handleDeleteActions = async (id) => {
    try {
        const response = await fetch(ApiUrl + '/api/ProductActions/DeleteByIds?Ids=' + id, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              GetAllGlobalProductActionsByProductId(productId);
          });
        } else {
          console.error(data);
        }
    } catch (error) {
        console.error(error);
    }
  };

  return (
    <div className='Product'>
        <h1>Products</h1>
        {canPermission && canPermission.canView ? (
        <>
          <div className="categoryTabs">
            {categories.map((category) => (
              <div
                key={category.id}
                className={`categoryTab ${selectedCategoryId === category.id ? 'activeCategory' : ''}`}
                onClick={() => handleChangeCategoryForMenus(category.id)}
              >
                {category.name}
              </div>
            ))}
          </div>
          {/* <button onClick={openModal}>Open Modal</button> */}
          {showModal && (
            <Modal>
              <div className="modal-header">
                <h2>Action</h2>
                <button onClick={closeModal}>X</button>
              </div>
              <div className="modal-body newProductTypeForm">
                <div className='newProductItem' key='Action'>
                  <label>Action</label>
                  <select className='newStateSelect' name="product" id="product" value={action} onChange={(e) => setAction(e.target.value)}>
                        <option key='-' value='-'>-</option>
                        <option key='+' value='+'>+</option>
                        <option key='/' value='/'>/</option>
                        <option key='x' value='x'>x</option>
                  </select>
                </div>
                {dynamicFieldsMenus.map((dynamicField) => (
                  <div className='newProductItem' key={`${dynamicField.categoryId}-${dynamicField.fieldLabel}`}>
                    <label className={dynamicField.fieldLabel.toLowerCase() === 'width' ? 'hiddenInput' : ''}>{dynamicField.fieldLabel}</label>
                    <input
                      type={dynamicField.fieldType === "Decimal" ? 'number' : 'text'}
                      placeholder={dynamicField.fieldLabel}
                      name={dynamicField.fieldLabel}
                      id={`${dynamicField.fieldLabel}`}
                      value={fieldValues[dynamicField.fieldLabel] || ''}
                      className={dynamicField.fieldLabel.toLowerCase() === 'width' ? 'hiddenInput' : ''}
                      onChange={(e) => handleFieldChange(dynamicField.fieldLabel, dynamicField.id, e.target.value)}
                    />
                  </div>
                ))}
                <div className='newProductItem' key="newProductItem">
                  <label>&nbsp;</label>
                  <button type="button" className="SaveProductBtn" id="SaveProductBtn" onClick={handleSaveAction}>
                    Save
                  </button>
                </div>
                <br />
                <div className='ModalTable'>
                  <Table
                    data={productActions}
                    columns={productActionsColumns}
                    onDelete={handleDeleteActions}
                    canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                  />
                </div>
                
              </div>
              <div className="modal-footer">
                  
              </div>
            </Modal>
          )}
          <div id="modal-root"></div>

          <br />
            <Table
                data={products}
                columns={columns}
                onDelete={handleDelete}
                // onEdit={handleEdit}
                // canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                onCheckBoxChange={onCheckBoxChange}
                onPopUp={openModal}
                popUp={true}
            />
        </> 
        ) : (
          <></>
        )}
    </div>
  )
}
