import React, { useState, useEffect } from 'react'
import './invoicePrint.css'
import { ApiUrl } from '../../../../Global';

export default function InvoicePrint({dataToPrint, productDataById}) {
    const currentDateTime = new Date();

    const divAllStyle = {
        width: '100%',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
    };
    const divStyle = {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
    };

    const InvoiceForm = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '90%',
        margin: 'auto',
    };
    
    const InvoiceItem = {
        width: '45%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
        marginRight: '100px',
    };
    const InvoiceItem2 = {
        width: '35%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
    };

    const invoiceNumberStyle = {
        marginBottom: '10px',
    };
    const SalesPersonTableReport = {
        width: '100%',
        bordercollapse: 'collapse',
        padding: '25px'
    }
    const tdStyle = {
        textAlign: 'center',
        border: '1px solid black',
    };
    const thStyle = {
        background: 'black',
        color: 'white',
        textAlign: 'center',
        padding: '8px',
    };
    const tdBlackStyle = {
        background: 'black',
        color: 'white',
        textAlign: 'right',
    };

    const columnWidths = {
        column1: '15%',
        column2: '50%',
        column3: '20%',
        column4: '60%',
    };

    const smallText = {
        fontSize: '9px',
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    // console.log(productDataById);

  return (
    <div style={divAllStyle}>
        <div style={divStyle}>
            <div style={{width:"30%"}}>
                Residential / Commerial<br />
                Carpet & Hardwood<br />
                Vinyl & Ceramic Tiles<br />
            </div>
            <div style={{ width: "40%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                <br />
                <img src="/images/carpet-for-less-logo-41308e5f-360w.png" alt="" style={{ width: '50%' }} />
            </div>
            <div style={{width:"30%"}}>
                FREE ESTIMATES<br />
                Next Day Installation<br />
                <span style={{fontSize: 16, fontWeight: 600, color: 'red'}}>{dataToPrint.invoiceNumber + " - " + dataToPrint.invoiceYear}</span>
            </div>
        </div>
        <div style={{...divStyle, textAlign: 'center'}}>
            <br />
            24490 5 Mile Road - Redford, MI 48239<br />
            Tel. 313-532-5700 - Tel. 313-535-7500 - Direct 586-447-6220<br />
            Email:RedFordCarpetforless@gmail.com
        </div>
        <br />
        <div style={InvoiceForm}>
            <div style={InvoiceItem}>
                <span>{'Name : ' + dataToPrint.name}</span>
            </div>
            <div style={InvoiceItem2}>
                <span>{'Sales Person : ' + dataToPrint.employeeName}</span>
            </div>
            <div style={InvoiceItem}>
                <span>{'Address: ' + dataToPrint.streetNumber + ' ' + dataToPrint.streetName }</span>
            </div>
            <div style={InvoiceItem2}>
                <span>{'Measure By : ' + dataToPrint.measuredBy}</span>
            </div>
            <div style={InvoiceItem}>
                <span>{'city : ' + dataToPrint.city + ' St. ' + dataToPrint.stateName + ' Zip ' + dataToPrint.zipCode }</span>
            </div>
            <div style={InvoiceItem2}>
                <span>{'Measure Date : ' + formatDate(dataToPrint.measureDate)}</span>
            </div>
            <div style={InvoiceItem}>
                <span>{'Tel. : ' + dataToPrint.phoneNumber }</span>
            </div>
            <div style={InvoiceItem2}>
                <span>{'Install Date : ' + (dataToPrint.installDate === null ? '' : formatDate(dataToPrint.installDate))}</span>
            </div>
            <div style={InvoiceItem}>
                <span>{'Near : ' + dataToPrint.near}</span>
            </div>
            <div style={InvoiceItem2}>
                <span>{'Installer : ' + dataToPrint.installerName}</span>
            </div>
        </div>
        <table style={SalesPersonTableReport}>
        <thead>
          <tr>
            <th style={{ ...thStyle, width: columnWidths.column1 }}>Qty.</th>
            <th style={{ ...thStyle, width: columnWidths.column2 }}>Description</th>
            <th style={{ ...thStyle, width: columnWidths.column1 }}>Price</th>
            <th style={{ ...thStyle, width: columnWidths.column3 }}>Amount</th>
          </tr>
        </thead>
        <tbody>
            {dataToPrint.formFields.map((form, index) => {
                const varProduct = productDataById.find(obj => obj.productId === form.productId);
                return (
                    <tr key={index}>
                        <td style={tdStyle}>{form.area}</td>
                        <td style={tdStyle}>{form.productName + " - " + varProduct.supplierName }</td>
                        <td style={tdStyle}>{form.pricePerUnit}</td>
                        <td style={tdStyle}>{form.price}</td>
                    </tr>
                );
            })}
            {dataToPrint.labor !== 0 ?
                <tr>
                    <td style={tdStyle}></td>
                    <td style={tdStyle}>Labor</td>
                    <td style={tdStyle}>{dataToPrint.labor}</td>
                    <td style={tdStyle}>{dataToPrint.labor}</td>
                </tr> : <></>
            }
        </tbody>
      </table>

      <table style={SalesPersonTableReport}>
        <thead>
			<th style={{ width: columnWidths.column4 }}></th>
			<th style={{ width: columnWidths.column3 }}></th>
			<th style={{ width: columnWidths.column3 }}></th>
		</thead>
		<tbody>
			<tr>
				<td style={smallText} rowspan="7">
                        Carpet For Less guarantees that all goods received are 1st quality with all warranties pursuant to manufacture 
                        warranty. Carpet For Less warranties quality installation for one full year but not on visual seams. Carpet For 
                        Less is not responsible for any Liability resulting from labor not performed by Carpet For Less installers. All
                        labor and financial obligations lie on responsibility of said customers & carpet installer | contractor. All 
                        parties here in agree that this releases Carpet For Less from any financial responsibility.
                        <br />
                        All Products remian property of Carpet For Less until paid in full.
                        <br />
                        All remnant sales are final. No exchanges . No refund. In the event of a cancellation by the customer after the 
                        material has been cut or ordered. The customer agrees to pay as liquidated damages sum equal to 25% of the total 
                        purchase paid. All refunds are paid by check. No exception can be made. All products are guaranted to be free form 
                        manufacturing defects at the time of sale. Products are guaranted in accordance with the guarantee extended by the 
                        particular. and decision relating to claims on such products are subject to the inspection and approval by the 
                        manufacture.
                        <br />
                        FULL BALANCE MUST BE PAID BEFORE INSTILLATION.
                        <br />
                        <br />
                        <br />
                        <br />
                        <span style={{fontSize: 14}}>Signature:</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp; <span style={{fontSize: 14}}>Date: {formatDate(currentDateTime)}</span> 
                </td>
			</tr>
            <tr>
				<td style={tdBlackStyle}>Material</td>
				<td style={tdStyle}>{dataToPrint.totalMaterial}</td>
			</tr>
            <tr>
				<td style={tdBlackStyle}>Tax</td>
				<td style={tdStyle}>{dataToPrint.tax}</td>
			</tr>
            <tr>
                <td style={tdBlackStyle}>Labor</td>
				<td style={tdStyle}>{dataToPrint.labor}</td>
			</tr>
            <tr>
                <td style={tdBlackStyle}>Total</td>
				<td style={tdStyle}>{dataToPrint.total}</td>
			</tr>
            <tr>
                <td style={tdBlackStyle}>Deposit</td>
				<td style={tdStyle}>{dataToPrint.deposit}</td>
			</tr>
            <tr>
                <td style={tdBlackStyle}>Balance C.O.D</td>
				<td style={tdStyle}>{dataToPrint.balanceCOD}</td>
			</tr>
		</tbody>
      </table>

    </div>
  )
}
