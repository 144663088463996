import React, {useEffect, useState} from 'react'
import './supplierInvoice.css'
import Swal from 'sweetalert2';
import SelectReact from 'react-select';
import { GlobalPermission } from '../../../../Global';
import { ApiUrl } from '../../../../Global';
import DatePicker from 'react-datepicker';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import Modal from '../../../modal/Modal';

export default function SupplierInvoice() {
    const { supplierInvoiceId } = useParams();
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    const [suppliers, setSuppliers] = useState([]);
    const [supplierId, setSupplierId] = useState(0);
    const [supplierOptionValue, setSupplierOptionValue] = useState();
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [isInvoiceNumberValid, setIsInvoiceNumberValid] = useState(true);
    const [invoiceDate, setInvoiceDate] = useState(null);
    const [isInvoiceDateValid, setIsInvoiceDateValid] = useState(true);
    const [accountNumber, setAccountNumber] = useState();
    const [customerPO, setCustomerPO] = useState('');
    const [terms, setTerms] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyDBA, setCompanyDBA] = useState('');
    const [soldToCompanyName, setsoldToCompanyName] = useState('');
    const [soldToAddress, setsoldToAddress] = useState('');
    const [shippedToCompanyName, setShippedToCompanyName] = useState('');
    const [shippedToAddress, setShippedToAddress] = useState('');
    const [salesRepSupplier, setSalesRepSupplier] = useState([]);
    const [salesRepSupplierOptionValue, setSalesRepSupplierOptionValue] = useState();
    const [salesRepId, setSalesRepId] = useState(0);
    const [fOB, setFOB] = useState('');
    const [bOL, setBOL] = useState('');
    const [carrier, setCarrier] = useState('');
    const [shipFrom, setShipFrom] = useState('');
    const [formFields, setFormFields] = useState([
        {
            categoryId: 0,
            categoryName: '',
            styleId: 0,
            colorId: 0,
            categoryOptionValue: null,
            styleOptionValue: null,
            colorOptionValue: null,
            productcategoryId: 0,
            bK: '',
            width: 12,
            length: 1.00,
            squarefoot: 0.00,
            measureUnitId: 0,
            price: false,
            unitOptionValue: null,
            rollNumber:'',
            lot:'',
            amount: 0.00,
            formFieldValid: false,
            productCategoryOptionValue: null,
            thickness : '',
        }
    ]);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(0);
    const [categoryOptionValue, setCategoryOptionValue] = useState();
    const [styleId, setStyleId] = useState(0);
    const [styles, setStyles] = useState([]);
    const [styleOptionValue, setStyleOptionValue] = useState();
    const [colors, setColors] = useState([]);
    const [colorId, setColorId] = useState(0);
    const [colorOptionValue, setColorOptionValue] = useState();
    const [subTotal, setSubTotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [deposit, setDeposit] = useState(0);
    const [totalInvoice, setTotalInvoice] = useState(0);
    const [isValidTotal, setIsValidTotal] = useState(true);
    const [isValidInvoiceNumber, setIsValidInvoiceNumber] = useState(true);
    const [isValidInvoiceDate, setIsValidInvoiceDate] = useState(true);
    const [isValidSoldToCompanyName, setIsValidSoldToCompanyName] = useState(true);
    const [isValidSoldToCompanyDBA, setIsValidSoldToCompanyDBA] = useState(true);
    const [isValidSoldToAddress, setIsValidSoldToAddress] = useState(true);
    const [isValidShippedToCompanyName, setIsValidShippedToCompanyName] = useState(true);
    const [isValidShippedToAddress, setIsValidShippedToAddress] = useState(true);
    const [isValidSalesRep, setIsValidSalesRep] = useState(true);
    const [isValidFormFields, setIsValidFormFields] = useState(true);
    const [isValidTotalInvoice, setIsValidTotalInvoice] = useState(true);
    const [unitId, setUnitId] = useState(0);
    const [units, setUnits] = useState([]);
    const [unitOptionValue, setUnitOptionValue] = useState();
    const [productCategories, setProductCategories] = useState([]);
    const [productCategoryOptionValue, setProductCategoryOptionValue] = useState();
    const [wareHouses, setWareHouses] = useState([]);
    const [wareHouseId, setWareHouseId] = useState([]);
    const [wareHousesOptionValue, setWareHousesOptionValue] = useState();
    const [isFirstEffectCompleted, setIsFirstEffectCompleted] = useState(false);
    const [dynamicFieldDefinitions, setDynamicFieldDefinitions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethodId, setPaymentMethodId] = useState(0);
    const [paymentMethodOptionValue, setpaymentMethodOptionValue] = useState();
    const [paymentId, setPaymentId] = useState('');



    useEffect(() => {
        const desiredPermissionPageName = 'SupplierInvoice'; // Replace with the desired permissionPageName value
        const desiredRow = GlobalPermission.find((item) => item.permissionPageName === desiredPermissionPageName);
        setCanPermission(desiredRow);
    
        const fetchData = async () => {
          try {
            await Promise.all([
              GetAllSuppliers(),
              GetAllCategories(),
              GetAllStyles(),
              GetAllColors(),
              GetAllUnits(),
              GetAllProductCategories(),
              GetAllWareHouses(),
              GetAllDynamicFieldDefinitions(),
              GetAllPaymentMethods(),
            ]);
            setIsFirstEffectCompleted(true);
          } catch (error) {
            console.error(error);
            setIsFirstEffectCompleted(true); // Set to true even if there's an error to avoid blocking the second effect
          }
        };
    
        fetchData();
    }, []);

    useEffect(() => {
        if (isFirstEffectCompleted && typeof supplierInvoiceId !== "undefined" && supplierInvoiceId !== null && supplierInvoiceId !== "") {
          console.log(supplierInvoiceId);
          setId(supplierInvoiceId);
          GetSupplierInvoiceDataById();
        }
    }, [isFirstEffectCompleted, supplierInvoiceId]);
    

    useEffect(() => {
        let subTot = 0;
        // console.log("formFields");
        formFields.forEach((form) => {
        //   console.log(form.amount);
          if (!isNaN(form.amount)) {
            subTot += parseFloat(form.amount);
          }
        });
        // console.log(subTot);
        setSubTotal(parseFloat(subTot.toFixed(2)));
        const tot = parseFloat(shipping) + parseFloat(tax) + parseFloat(subTot);
        const formattedTot = tot.toFixed(2);
        // console.log(formattedTot);
        setTotalInvoice(parseFloat((tot).toFixed(2)));
      }, [formFields, shipping, tax, deposit]);

    function GetAllCategories(){
        fetch(ApiUrl + '/api/Categories/GetAllCategories', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          const filtered = data.item2.filter(category => category.isDeleted === false);
        //   console.log("Get Categ");
        //   console.log(filtered);
          setCategories(filtered);
          setCategoryId(filtered.id);
          setCategoryOptionValue({value: filtered[0].id, label: filtered[0].name});
        });
    }
    function GetAllStyles(){
        fetch(ApiUrl + '/api/Styles/GetAllStyles', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2);
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setStyles(filtered);
            // console.log(data.item2);
            setStyleId(filtered[0].id);
            setStyleOptionValue({value: filtered[0].id, label: filtered[0].name + "(" + filtered[0].code + ")"});
        });
    }

    function GetAllColors(){
        fetch(ApiUrl + '/api/Colors/GetAllColors', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2)
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setColors(filtered);
            // setColors(data.item2);
            setColorId(filtered[0].id);
            setColorOptionValue({value: filtered[0].id, label: filtered[0].name + "(" + filtered[0].hex_code + ")"});
        });
    }
    function GetAllUnits(){
        fetch(ApiUrl + '/api/MeasureUnits/GetAllMeasureUnits', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2);
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setUnits(filtered);
            setUnitId(filtered[0].id);
            setUnitOptionValue({value: filtered[0].id, label: filtered[0].code });
        });
    }

    function GetAllWareHouses(){
        fetch(ApiUrl + '/api/WareHouses/GetAllGlobalWareHouses', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            const filtered = data.item2.filter(est => est.isDeleted === false);
            // console.log(filtered);
            setWareHouses(filtered);
            setWareHouseId(filtered[0].id);
            setWareHousesOptionValue({value: filtered[0].id, label: filtered[0].name });
            setShippedToAddress(filtered[0].streetNumber + " " + filtered[0].streetName + " " + filtered[0].city + " " + filtered[0].zipCode);
        });
    }
    function GetAllDynamicFieldDefinitions(){
        fetch(ApiUrl + '/api/DynamicFieldDefinitions/GetAllDynamicFieldDefinitions', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            const filtered = data.item2.filter(est => est.isDeleted === false);
            console.log(filtered);
            setDynamicFieldDefinitions(filtered);
        });
    }

    // useEffect(() => {
    //     GetAllSalesRepresentative(supplierId);
    // }, [supplierId]);

    const resetFormFields = () => {
        let object = {
          categoryId: 0,
          categoryName: '',
          categoryOptionValue: null,
          styleOptionValue: null,
          colorOptionValue: null,
          styleId: 0,
          colorId: 0,
          productcategoryId: 0,
          bK: '',
          width: 12,
          length: 1.00,
          squarefoot: 0.00,
          measureUnitId: 0,
          unitOptionValue: null,
          price: false,
          rollNumber: '',
          lot: '',
          amount: 0.00,
          formFieldValid: false,
          productCategoryOptionValue: null,
          thickness : '',
        };
        setFormFields([object]);
    };

    const handleNew = async (e) => {
        e.preventDefault();
        const filtered = suppliers.filter(est => est.isDeleted === false);
        setSuppliers(filtered);
        setSupplierOptionValue({value: filtered[0].id, label: filtered[0].name });
        setSupplierId(filtered[0].Id);
        setAccountNumber(filtered[0].accountNumber);
        GetAllSalesRepresentative(filtered[0].id);
        // console.log(formFields);
        resetFormFields();
        setsoldToCompanyName('');
        setCompanyDBA('');
        setsoldToAddress('');
        setShippedToCompanyName('');
        setShippedToAddress('');
        setInvoiceNumber('');
        setInvoiceDate(null);
        setCustomerPO('');
        setTerms('');
        setFOB('');
        setBOL('');
        setCarrier('');
        setShipFrom('');
        setTax(0);
        setShipping(0);
        setDeposit(0);
        // console.log(formFields);
        // clearTextBoxes();
    }

    function GetAllSuppliers(){
        return new Promise((resolve, reject) => {
            // console.log("Working");
            fetch(ApiUrl + '/api/Suppliers/GetAllSuppliers', {
                headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
                }
            })
            .then(response => response.json())
            .then(data => {
                // console.log(data);
                const filtered = data.item2.filter(est => est.isDeleted === false);
                setSuppliers(filtered);
                setSupplierId(filtered[0].id);
                setSupplierOptionValue({value: filtered[0].id, label: filtered[0].name });
                setAccountNumber(filtered[0].accountNumber);
                GetAllSalesRepresentative(filtered[0].id);
                resolve(); // Resolve the promise when the dynamic field definitions are set
            })
            .catch(error => {
                reject(error); // Reject the promise if an error occurs during the API call
            });
        });
    }

    function GetAllProductCategories(){
        fetch(ApiUrl + '/api/ProductCategories/GetAllProductCategories', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2);
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setProductCategories(filtered);
            setProductCategoryOptionValue({value: filtered[0].id, label: filtered[0].name });
        });
    }

    function clearTextBoxes(){
        const filtered = suppliers.filter(est => est.isDeleted === false);
        setSuppliers(filtered);
        setSupplierOptionValue({value: filtered[0].id, label: filtered[0].name });
        setSupplierId(filtered[0].Id);
        setAccountNumber(filtered[0].accountNumber);
        GetAllSalesRepresentative(filtered[0].id);
        // console.log(formFields);
        resetFormFields();
        setsoldToCompanyName('');
        setCompanyDBA('');
        setsoldToAddress('');
        setShippedToCompanyName('');
        setShippedToAddress('');
        setInvoiceNumber('');
        setInvoiceDate(null);
        setCustomerPO('');
        setTerms('');
        setFOB('');
        setBOL('');
        setCarrier('');
        setShipFrom('');
        setTax(0);
        setShipping(0);
        setDeposit(0);
    }
    const saveFields = () => {
        setFormFields([...formFields]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formFields);
        let validForm = true;
        formFields.forEach(field => {
            // console.log(field.formFieldValid);
            // Access each field object and perform operations
            if(parseInt(field.categoryId) === 0 || parseInt(field.styleId) === 0 || parseInt(field.colorId) === 0 || parseFloat(field.amount) === 0 || parseInt(field.measureUnitId) === 0){
                field.formFieldValid = false;
                validForm = false;
            }
            else{
                saveFields();
            }
        });
        if (
            (invoiceNumber && invoiceNumber.trim() === '') || invoiceDate === null ||
            (soldToCompanyName && soldToCompanyName.trim() === '') || (companyDBA && companyDBA.trim() === '') || (soldToAddress && soldToAddress.trim() === '') ||
            (shippedToCompanyName && shippedToCompanyName.trim() === '') || (shippedToAddress && shippedToAddress.trim() === '') ||
            salesRepId === 0 || (formFields.length === 0)) {
          if(invoiceNumber.trim() === '')
            setIsValidInvoiceNumber(false);
          else
            setIsValidInvoiceNumber(true);
          if(invoiceDate === null)
            setIsValidInvoiceDate(false);
          else{
            setIsValidInvoiceDate(true);
          }
          if(soldToCompanyName.trim() === '')
            setIsValidSoldToCompanyName(false);
          else
            setIsValidSoldToCompanyName(true);
          if(companyDBA.trim() === '')
            setIsValidSoldToCompanyDBA(false);
          else
            setIsValidSoldToCompanyDBA(true);
          if(soldToAddress.trim() === '')
            setIsValidSoldToAddress(false);
          else
            setIsValidSoldToAddress(true);
          if(shippedToCompanyName.trim() === '')
            setIsValidShippedToCompanyName(false);
          else
            setIsValidShippedToCompanyName(true);
          if(shippedToAddress.trim() === '')
            setIsValidShippedToAddress(false);
          else
            setIsValidShippedToAddress(true);
        //   if(totalInvoice.trim() === 0)
        //     setIsValidTotalInvoice(false);
        //   else
        //     setIsValidTotalInvoice(true);
          if(salesRepId === 0)
            setIsValidSalesRep(false);
          else
            setIsValidSalesRep(true);
          if(formFields.length === 0 ){
            setIsValidFormFields(true);
          }
          else{
            formFields.forEach(field => {
            // console.log(field.formFieldValid);
            // Access each field object and perform operations
            if(parseInt(field.categoryId) === 0 || parseInt(field.styleId) === 0 || parseInt(field.colorId) === 0 || parseFloat(field.amount) === 0  || parseInt(field.measureUnitId) === 0 ){
                field.formFieldValid = false;
                saveFields();
                // console.log(field.categoryName + " \ true " + (parseFloat(field.amount) === 0) );
            }
            else{
                field.formFieldValid = true;
                saveFields();
                // console.log(field.categoryName + " \ false" + parseFloat(field.amount) );
            }
            });
          }
          return;
        }
        else{
            setIsValidInvoiceNumber(true);
            setIsValidInvoiceDate(true);
            setIsValidSoldToCompanyName(true);
            setIsValidSoldToCompanyDBA(true);
            setIsValidSoldToAddress(true);
            setIsValidShippedToCompanyName(true);
            setIsValidShippedToAddress(true);
            // setIsValidTotalInvoice(true);
            formFields.forEach(field => {
                field.formFieldValid = true;
            });
        }
        
        
        
        // console.log(ObjectToSend);

        try{
            if (deposit !== 0) {
                setShowModal(true);
              } else {
                Save()
              }
            // setDataToPrint(data);
            // setInvoiceNumber(data.invoiceNumber);
            // setSelectedYear(data.invoiceYear);
            // if(data.id !== 0){
            //     setShowPrint(true);
            // }
        } catch (error) {
            console.error(error);
        }
    }
    async function Save(){
        const products = formFields.map((field) => {
            const { productCategoryOptionValue, categoryOptionValue, styleOptionValue, colorOptionValue, unitOptionValue, ...rest } = field;
            return { ...rest };
        });
        const ObjectToSend = {
            Id: id,
            SupplierId: supplierId,
            InvoiceDate: invoiceDate,
            InvoiceNumber: invoiceNumber,
            CustomerNumber: accountNumber,
            CustomerPO: customerPO,
            Terms: terms,
            WareHouseId: wareHouseId,
            SalesRepId: salesRepId,
            FOB: fOB,
            BOL: bOL,
            Carrier: carrier,
            ShipFrom: shipFrom,
            SoldToCompanyName: soldToCompanyName,
            SoldToCompanyDBA: companyDBA,
            SoldToAddress: soldToAddress,
            ShippedToCompanyName: shippedToCompanyName,
            ShippedToAddress: shippedToAddress,
            SubTotal: subTotal,
            Tax: tax,
            Shipping: shipping,
            Deposit: deposit,
            TotalInvoice: totalInvoice,
            Products: products,
            paymentMethodId: paymentMethodId,
            paymentId: paymentId,
        }
        const response = await fetch( ApiUrl +'/api/SupplierInvoices/Save', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
            },
            body: JSON.stringify(ObjectToSend)
        });
        const data = await response.json();
        console.log(data);
        if(data.isSuccess){
            Swal.fire({
                icon: 'success',
                title: 'Data has been saved Successfully!',
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
            });
        }
        else{
            Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
            });
        }
    }

    const suppliersOptions = suppliers.map((supp) => ({
        value: supp.id,
        label: supp.name,
    }));
    const handleSupplierSelectChange = (selectedOption) => {
        setSupplierOptionValue(selectedOption);
        setSupplierId(selectedOption.value);
        // console.log(suppliers.filter(item => item.id === selectedOption.value));
        setAccountNumber(suppliers.filter(item => item.id === selectedOption.value)[0].accountNumber);
        GetAllSalesRepresentative(selectedOption.value);
    };

    const salesRepSupplierOptions = salesRepSupplier.map((supp) => ({
        value: supp.id,
        label: supp.name,
    }));
    const handleSalesRepSupplierSelectChange = (selectedOption) => {
        setSalesRepSupplierOptionValue(selectedOption);
        setSalesRepId(selectedOption.value);
    };

    function GetAllSalesRepresentative(Id){
        ///
        fetch(ApiUrl + '/api/SalesPeople/GetAllSalesPeopleBySupplier?supplierId='+ Id, {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            const filtered = data.item2.filter(est => est.isDeleted === false);
            if(filtered.length === 0){
                setSalesRepSupplier([]);
                // console.log(Id);
                setSalesRepId(0);
                setSalesRepSupplierOptionValue({});
            }
            else{
                setSalesRepSupplier(filtered);
                // console.log(Id);
                setSalesRepId(filtered[0].id);
                setSalesRepSupplierOptionValue({value: filtered[0].id, label: filtered[0].name });
            }
        });
    }

    const removeField = (index) => {
        let data = [...formFields];
        data.splice(index, 1);
        setFormFields(data);
    };

    const categoriesOptions = categories.map((category) => ({
        value: category.id,
        label: category.name,
    }));

    const handleCategoriesSelectChange = (selectedOption, index) => {
        let data = [...formFields];
        data[index].categoryId = selectedOption.value;
        data[index].categoryName = selectedOption.label;
        data[index].categoryOptionValue = selectedOption;
        if(selectedOption.label === "Carpet"){
            data[index].squarefoot = parseFloat(0);
            data[index].price = parseFloat(0);
            // data[index].amount = parseFloat(0);
            // data[index].totalInvoice = parseFloat(0);
        }
        else{
            data[index].length = parseFloat(0);
            data[index].width = parseFloat(12);
            data[index].price = parseFloat(0);
            // data[index].amount = parseFloat(0);
            // data[index].totalInvoice = parseFloat(0);
        }
        setFormFields(data);
        setCategoryId(selectedOption.value);
        // console.log(data);
    };
    const stylesOptions = styles.map((style) => ({
        value: style.id,
        label: style.name + "(" + style.code + ")",
    }));
    const handleStyleSelectChange = (selectedOption, index) => {
        let data = [...formFields];
        data[index].styleId = selectedOption.value;
        data[index].styleOptionValue = selectedOption;
        setFormFields(data);
        // setCategoryId(selectedOption.value);
        // console.log(data);

        // setStyleOptionValue(selectedOption);
        // setStyleId(selectedOption.value);
    };
    const colorsOptions = colors.map((color) => ({
        value: color.id,
        label: color.name + "(" + color.hex_code + ")",
    }));
    const handleColorSelectChange = (selectedOption, index) => {
        let data = [...formFields];
        data[index].colorId = selectedOption.value;
        data[index].colorOptionValue = selectedOption;
        setFormFields(data);
        // setCategoryId(selectedOption.value);
        // console.log(data);
    };

    const unitsOptions = units.map((unit) => ({
        value: unit.id,
        label: unit.code,
    }));
    const handleUnitSelectChange = (selectedOption, index) => {
        let data = [...formFields];
        data[index].measureUnitId = selectedOption.value;
        data[index].unitOptionValue = selectedOption;
        setFormFields(data);
        // setCategoryId(selectedOption.value);
        // console.log(data);
    };

    const handleBKSelectChange = (value, index) => {
        const updatedFormFields = [...formFields];
        updatedFormFields[index].bK = value;
        setFormFields(updatedFormFields);
        // console.log(updatedFormFields);
      };

    const handleSizeSelectChange = (selectedOption, index) => {
        const updatedFormFields = [...formFields];
        updatedFormFields[index].width = parseFloat(selectedOption);
        updatedFormFields[index].amount = parseFloat(selectedOption*updatedFormFields[index].price*updatedFormFields[index].length).toFixed(2);
        setFormFields(updatedFormFields);
        // console.log(updatedFormFields);
    };
    const handleOrderQuantitySelectChange = (selectedOption, index) => {
        // console.log(selectedOption);
        const updatedFormFields = [...formFields];
        updatedFormFields[index].squarefoot = parseFloat(0);
        updatedFormFields[index].length = parseFloat(selectedOption);
        updatedFormFields[index].amount = parseFloat(selectedOption*updatedFormFields[index].price*updatedFormFields[index].width).toFixed(2);
        setFormFields(updatedFormFields);
        // console.log(updatedFormFields);
    };
    const handleSquareFootSelectChange = (selectedOption, index) => {
        // console.log(selectedOption);
        const updatedFormFields = [...formFields];  
        updatedFormFields[index].width = parseFloat(12);
        updatedFormFields[index].length = parseFloat(0);
        updatedFormFields[index].squarefoot = parseFloat(selectedOption);
        updatedFormFields[index].amount = parseFloat(selectedOption*updatedFormFields[index].price).toFixed(2);
        setFormFields(updatedFormFields);
        // console.log(updatedFormFields);
    };

    const handleThicknessChange = (selectedOption, index) => {
        const updatedFormFields = [...formFields];
        updatedFormFields[index].thickness = selectedOption;
        setFormFields(updatedFormFields);
        // console.log(updatedFormFields);
    };

    const addFields = () => {
        let object = {
            categoryId: 0,
            categoryName: '',
            styleId: 0,
            colorId: 0,
            categoryOptionValue: null,
            styleOptionValue: null,
            colorOptionValue: null,
            productcategoryId: 0,
            bK: '',
            width: 12,
            length: 1.00,
            squarefoot: 0.00,
            measureUnitId: 0,
            price: false,
            unitOptionValue: null,
            rollNumber:'',
            lot: '',
            amount: 0.00,
            formFieldValid: false,
            productCategoryOptionValue: null,
            thickness : '',
        };
        setFormFields([...formFields, object]);
    };

    const productCategoriesOptions = productCategories.map((obj) => ({
        value: obj.id,
        label: obj.name,
    }));
    const handleProductCategoriesSelectChange = (selectedOption, index) => {
        let data = [...formFields];
        data[index].productcategoryId = selectedOption.value;
        data[index].productCategoryOptionValue = selectedOption;
        setFormFields(data);
        // setCategoryId(selectedOption.value);
        // console.log(data);
    };

    const wareHousesOptions = wareHouses.map((obj) => ({
        value: obj.id,
        label: obj.name,
    }));
    const handleWareHousesSelectChange = (selectedOption) => {
        setWareHousesOptionValue(selectedOption);
        setWareHouseId(selectedOption.value);
        const filtered = wareHouses.filter(est => est.id === selectedOption.value);
        setShippedToAddress(filtered[0].streetNumber + " " + filtered[0].streetName + " " + filtered[0].city + " " + filtered[0].zipCode);
    };



    function GetSupplierInvoiceDataById(){
        if (typeof supplierInvoiceId !== "undefined" || supplierInvoiceId !== null || supplierInvoiceId !== "" || supplierInvoiceId === undefined){
          fetch(ApiUrl + '/api/supplierInvoices/GetSupplierInvoiceDataById?supplierInvoiceId='+ supplierInvoiceId, {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          })
          .then(response => response.json())
          .then(data => {
            const filtered = data.item2;
            console.log(filtered);
            setInvoiceNumber(filtered.invoiceNumber);
            setInvoiceDate((new Date(filtered.invoiceDate)));
            setCarrier(filtered.carrier);
            setBOL(filtered.bOL);
            setSalesRepId(filtered.salesRepId);
            setSupplierId(filtered.supplierId);
            setSalesRepSupplierOptionValue(filtered.salesRepSupplierOptionValue);
            setTerms(filtered.terms);
            setCustomerPO(filtered.customerPO);
            setDeposit(filtered.deposit);
            setFOB(filtered.fOB);
            setShipFrom(filtered.shipFrom);
            setId(supplierInvoiceId);
            setsoldToAddress(filtered.soldToAddress);
            setsoldToCompanyName(filtered.soldToCompanyName);
            setShippedToAddress(filtered.shippedToAddress);
            setShippedToCompanyName(filtered.shippedToCompanyName);
            setShipping(filtered.shipping);
            setFOB(filtered.fob);
            setBOL(filtered.bol);
            setCompanyDBA(filtered.soldToCompanyDBA);
            setWareHouseId(filtered.wareHouseId);
            const filteredSupplier = suppliers.filter(sup => sup.id === filtered.supplierId);
            // console.log("ID TO SHOW");
            // console.log(filtered);
            // console.log(suppliers);
            // console.log(filteredSupplier);
            if(suppliers.length > 0){
                handleSupplierSelectChange({value: filtered.supplierId, label: filteredSupplier[0].name })
                // setSupplierOptionValue({value: filtered.supplierId, label: filteredSupplier[0].name })
                // setAccountNumber(filteredSupplier[0].accountNumber);
            }
            if(salesRepSupplier.length > 0){
                const filteredSaleRep = salesRepSupplier.filter(supRep => supRep.id === filtered.salesRepId);
                setSalesRepSupplierOptionValue({value: filteredSaleRep[0].id, label: filteredSaleRep[0].name });
            }
            if(wareHouses.length > 0){
                const filteredWareHouses = wareHouses.filter(ware => ware.id === filtered.wareHouseId);
                setWareHousesOptionValue({value: filteredWareHouses[0].id, label: filteredWareHouses[0].name });
            }

            filtered.products.forEach((field) => {
                console.log(field);
                field.categoryName = field.categoryOptionValue.label
            });
            setFormFields(filtered.products);

          });
        }
    }

    const handleModalSubmit = () => {
        // Handle submit logic for adding payment method
        // using the paymentId value
        // ...
        Save();
        setShowModal(false);
    };

    const paymentMethodOptions = paymentMethods.map((paymentmethod) => ({
        value: paymentmethod.id,
        label: paymentmethod.paymentMethodName,
    }));
    const handlePaymentMethodSelectChange = (selectedOption) => {
        setpaymentMethodOptionValue(selectedOption);
        setPaymentMethodId(selectedOption.value);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    function GetAllPaymentMethods(){
        fetch(ApiUrl + '/api/PaymentMethods/GetAllPaymentMethods', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          console.log(data.item2);
          const filteredData = data.item2.filter(item => item.isDeleted === false);
          setPaymentMethods(filteredData);
          setpaymentMethodOptionValue({value: filteredData[0].id, label: (filteredData[0].paymentMethodName)});
          setPaymentMethodId(filteredData[0].id);
        });
      }

  return (
    <div className='SupplierInvoice'>
        <h1>SupplierInvoice</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newCustomerForm" onSubmit={handleSubmit} >
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div class="container">
                <div class="box-1">
                    <div>
                        <label htmlFor="productType">Supplier</label>
                        <SelectReact
                            className="basic-single-supplier"
                            classNamePrefix="select"
                            isClearable={false}
                            isSearchable={true}
                            value={supplierOptionValue}
                            name="productType"
                            options={suppliersOptions}
                            onChange={handleSupplierSelectChange}
                        />
                    </div>
                    <div className='container'>
                        <div className="inner-box-1">
                            <div className='content-div-box'>
                                <div className='center-text'>
                                    Sold To
                                </div>
                                <div className='content-divs'>
                                    <div>
                                        <input type="text" placeholder="Company Name" name="soldToCompanyName" id="soldToCompanyName" className={!isValidSoldToCompanyName ? 'InvalidInputtext input-content-divs' : 'validInputtext input-content-divs'} value={soldToCompanyName} onChange={(e) => setsoldToCompanyName(e.target.value)} />
                                    </div>
                                    <div>
                                        <input type="text" placeholder="DBA" name="companyDBA" id="companyDBA" className={!isValidSoldToCompanyDBA ? 'InvalidInputtext input-content-divs' : 'validInputtext input-content-divs'} value={companyDBA} onChange={(e) => setCompanyDBA(e.target.value)} />
                                    </div>
                                    <div>
                                        <input type="text" placeholder="Address" name="soldToCompanyName" id="soldToCompanyName" className={!isValidSoldToAddress ? 'InvalidInputtext input-content-divs' : 'validInputtext input-content-divs'} value={soldToAddress} onChange={(e) => setsoldToAddress(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="inner-box-2">
                            <div className='content-div-box'>
                                <div className='center-text'>
                                    Shipped To
                                </div>
                                <div className='content-divs'>
                                    <div>
                                        <input type="text" placeholder="Company Name" name="shippedToCompanyName" id="shippedToCompanyName" className={!isValidShippedToCompanyName ? 'InvalidInputtext input-content-divs' : 'validInputtext input-content-divs'} value={shippedToCompanyName} onChange={(e) => setShippedToCompanyName(e.target.value)} />
                                    </div>
                                    {/* <div>
                                        <input type="text" placeholder="DBA" name="companyDBA" id="companyDBA" className='validInputtext input-content-divs' value={companyDBA} onChange={(e) => setCompanyDBA(e.target.value)} />
                                    </div> */}
                                    <div>
                                        <SelectReact
                                            className="basic-single-supplier"
                                            classNamePrefix="select"
                                            isClearable={false}
                                            isSearchable={true}
                                            value={wareHousesOptionValue}
                                            name="productType"
                                            options={wareHousesOptions}
                                            onChange={handleWareHousesSelectChange}
                                        />
                                    </div>
                                    <div>
                                        <input type="text" placeholder="Address" name="shippedToCompanyName" id="shippedToCompanyName" className={!isValidShippedToAddress ? 'InvalidInputtext input-content-divs' : 'validInputtext input-content-divs'} value={shippedToAddress}  />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="box-2">
                    <div className=''>
                        <div className=''>
                            <table className='table-Invoice'>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'center' }} colSpan={2}>Invoice Number: <input type="text" placeholder="Invoice Number" name="invoicenumber" id="invoicenumber" className={!isValidInvoiceNumber ? 'InvalidInputtext input-supplierInvoice' : 'validInputtext input-supplierInvoice'} value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} /></td>
                                    </tr>
                                    <tr>
                                        <td>Invoice Date</td>
                                        <td>
                                            <DatePicker
                                                selected={invoiceDate}
                                                onChange={date => {setInvoiceDate(date);}}
                                                dateFormat="MMMM d, yyyy"
                                                placeholderText="Select Date"
                                                id="invoiceDate"
                                                name="invoiceDate"
                                                className={!isValidInvoiceDate ? 'CustomDatePickerSupplierInvoice InvalidInputtext' : 'CustomDatePickerSupplierInvoice validInputtext'}
                                                showYearDropdown
                                                scrollableYearDropdown
                                                yearDropdownItemNumber={15}
                                                showMonthDropdown
                                                scrollableMonthYearDropdown
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Customer #</td>
                                        <td>{accountNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Customer PO#</td>
                                        <td><input type="text" placeholder="Customer PO" name="customerPO" id="customerPO" className='validInputtext input-supplierInvoice' value={customerPO} onChange={(e) => setCustomerPO(e.target.value)} /></td>
                                    </tr>
                                    <tr>
                                        <td>TERMS</td>
                                        <td><input type="text" placeholder="TERMS" name="terms" id="terms" className='validInputtext input-supplierInvoice' value={terms} onChange={(e) => setTerms(e.target.value)} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content-divs'>
                <table className='table-Invoice'>
                    <thead>
                        <th style={{width: '30%'}}>Sales Representative</th>
                        <th style={{width: '15%'}}>FOB</th>
                        <th style={{width: '15%'}}>BOL</th>
                        <th style={{width: '20%'}}>Carrier</th>
                        <th style={{width: '10%'}}>Ship From</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <SelectReact
                                    className={!isValidSalesRep ? 'basic-single-salesRepsupplier InvalidInputtext' : 'basic-single-salesRepsupplier validInputtext'}
                                    classNamePrefix="select"
                                    isClearable={false}
                                    isSearchable={true}
                                    value={salesRepSupplierOptionValue}
                                    name="productType"
                                    options={salesRepSupplierOptions}
                                    onChange={handleSalesRepSupplierSelectChange}
                                />
                            </td>
                            <td><input type="text" placeholder="fob" name="fob" id="fob" className='validInputtext input-content-divs' value={fOB} onChange={(e) => setFOB(e.target.value)} /></td>
                            <td><input type="text" placeholder="bol" name="bol" id="bol" className='validInputtext input-content-divs' value={bOL} onChange={(e) => setBOL(e.target.value)} /></td>
                            <td><input type="text" placeholder="carrier" name="carrier" id="carrier" className='validInputtext input-content-divs' value={carrier} onChange={(e) => setCarrier(e.target.value)} /></td>
                            <td><input type="text" placeholder="shipFrom" name="shipFrom" id="shipFrom" className='validInputtext input-content-divs' value={shipFrom} onChange={(e) => setShipFrom(e.target.value)} /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='content-divs'>
                <table>
                    <thead>
                        <th style={{width: '10%'}}>Type</th>
                        <th style={{width: '25%'}}>Line</th>
                        <th style={{width: '5%'}}>Type</th>
                        {/* <th>Style</th> */}
                        {/* <th>Color</th> */}
                        <th style={{width: '6%'}}>BK</th>
                        <th style={{width: '7%'}}>Size</th>
                        <th style={{width: '7%'}}>Order Qty</th>
                        <th style={{width: '8%'}}>Unit</th>
                        <th style={{width: '6%'}}>Price</th>
                        <th style={{width: '9%'}}>Roll Number</th>
                        <th style={{width: '10%'}}>Amount</th>
                        <th style={{width: '7%'}}>Action</th>
                    </thead>
                    <tbody>
                    {formFields.map((form, index) => {
                        return (
                        <tr key={index} className={form.formFieldValid === false ? 'InvalidInputtext' : ''} >
                            <td>
                                <SelectReact
                                    className="textboxField"
                                    classNamePrefix="select"
                                    isClearable={false}
                                    isSearchable={true}
                                    value={form.categoryOptionValue}
                                    name="category"
                                    id={index}
                                    options={categoriesOptions}
                                    onChange={(selectedOption) => handleCategoriesSelectChange(selectedOption, index)}
                                />
                            </td>
                            <td>
                                {form.categoryId !== 0 && (
                                    <div style={{ display: 'flex' }}>
                                        {categories
                                        .filter(category => category.id === form.categoryId)
                                        .map(category => {
                                            if (category.hasStyle && category.hasColor) {
                                            return (
                                                <React.Fragment key={category.id}>
                                                <SelectReact
                                                    className="textboxField Line"
                                                    classNamePrefix="select"
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    value={form.styleOptionValue}
                                                    name="styles"
                                                    id={index}
                                                    options={stylesOptions}
                                                    onChange={(selectedOption) => handleStyleSelectChange(selectedOption, index)}
                                                />
                                                <SelectReact
                                                    className="textboxField Line"
                                                    classNamePrefix="select"
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    value={form.colorOptionValue}
                                                    name="styles"
                                                    id={index}
                                                    options={colorsOptions}
                                                    onChange={(selectedOption) => handleColorSelectChange(selectedOption, index)}
                                                />
                                                </React.Fragment>
                                            );
                                            } else if (category.hasStyle) {
                                            return (
                                                <SelectReact
                                                key={category.id}
                                                className="textboxField Line"
                                                classNamePrefix="select"
                                                isClearable={false}
                                                isSearchable={true}
                                                value={form.styleOptionValue}
                                                name="styles"
                                                id={index}
                                                options={stylesOptions}
                                                onChange={(selectedOption) => handleStyleSelectChange(selectedOption, index)}
                                                />
                                            );
                                            } else if (category.hasColor) {
                                            return (
                                                <SelectReact
                                                key={category.id}
                                                className="textboxField Line"
                                                classNamePrefix="select"
                                                isClearable={false}
                                                isSearchable={true}
                                                value={form.colorOptionValue}
                                                name="styles"
                                                id={index}
                                                options={colorsOptions}
                                                onChange={(selectedOption) => handleColorSelectChange(selectedOption, index)}
                                                />
                                            );
                                            } else {
                                            return null;
                                            }
                                        })}

                                        {categories
                                            .filter(category => category.id === form.categoryId)
                                            .map(category => {
                                                if (category.hasThickness) {
                                                    return (
                                                        <input 
                                                            type="text"
                                                            name="thickness"
                                                            placeholder="Thickness"
                                                            className='validInputtext input-content-divs-table'
                                                            // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                                                            value={form.thickness}
                                                            // step={0.01}
                                                            // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                                                            onChange={(e) => handleThicknessChange(e.target.value,index)} 
                                                            // min={0.00}
                                                        />
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </div>
                                    )
                                }
                            </td>
                            <td>
                                <SelectReact
                                    className="textboxField"
                                    classNamePrefix="select"
                                    isClearable={false}
                                    isSearchable={true}
                                    value={form.productCategoryOptionValue}
                                    name="styles"
                                    id={index}
                                    options={productCategoriesOptions}
                                    onChange={(selectedOption) => handleProductCategoriesSelectChange(selectedOption, index)}
                                />
                            </td>
                            <td>
                                <input type="text" placeholder="Bk" name="Bk" id="Bk" className='validInputtext input-content-divs-table' value={form.bk} onChange={(e) => handleBKSelectChange(e.target.value, index)} />
                            </td>
                            
                            {form.categoryId !== 0 && (dynamicFieldDefinitions.filter(dynamic => dynamic.categoryId === form.categoryId)[0].fieldLabel === "Length") ? (
                                <>
                                    <td>
                                        {dynamicFieldDefinitions.filter(dynamic => dynamic.categoryId === form.categoryId)
                                        .map(dynamicDefinition => {
                                            if(dynamicDefinition.fieldLabel === "Width"){
                                                return(
                                                    <div style={{display:'flex'}}>
                                                        <input 
                                                            type="number"
                                                            name="size"
                                                            placeholder="size"
                                                            className='validInputtext input-content-divs-table'
                                                            // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                                                            value={form.width}
                                                            step={0.01}
                                                            // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                                                            onChange={(e) => handleSizeSelectChange(e.target.value,index)} 
                                                            min={1.00}
                                                        />
                                                    </div>
                                                )
                                                
                                            }
                                            
                                        })}
                                    </td>
                                    <td>
                                    {dynamicFieldDefinitions.filter(dynamic => dynamic.categoryId === form.categoryId)
                                        .map(dynamicDefinition => {
                                            if(dynamicDefinition.fieldLabel === "Length"){
                                                return(
                                                    <div style={{display:'flex'}}>
                                                        <input 
                                                            type="number"
                                                            name="orderqty"
                                                            placeholder="orderqty"
                                                            className='validInputtext input-content-divs-table'
                                                            // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                                                            value={form.length}
                                                            step={0.01}
                                                            // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                                                            onChange={(e) => handleOrderQuantitySelectChange(e.target.value, index)} 
                                                            min={1.00}
                                                        />
                                                    </div>
                                                )
                                                
                                            }
                                        })}
                                    </td>
                                </> ) : 
                                <>
                                    <td>
                                        {dynamicFieldDefinitions.filter(dynamic => dynamic.categoryId === form.categoryId)
                                        .map(dynamicDefinition => {
                                            if(dynamicDefinition.fieldLabel === "SquareFoot"){
                                                return(
                                                    <input 
                                                        type="number"
                                                        name="size"
                                                        placeholder="size"
                                                        className='validInputtext input-content-divs-table'
                                                        // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                                                        value={form.squarefoot}
                                                        step={0.01}
                                                        // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                                                        onChange={(e) => handleSquareFootSelectChange(e.target.value,index)} 
                                                        min={1.00}
                                                    />
                                                )
                                            }})}
                                    </td>
                                    <td></td>
                                </>
                            }
                            <td>
                                <SelectReact
                                    className="textboxField small-arrow"
                                    classNamePrefix="select"
                                    isClearable={false}
                                    isSearchable={true}
                                    // value={form.categoryName === "Carpet" ? unitsOptions.find(option => option.label === "LF") : null}
                                    value={form.unitOptionValue}
                                    name="styles"
                                    id={index}
                                    options={unitsOptions}
                                    onChange={(selectedOption) => handleUnitSelectChange(selectedOption, index)}
                                    required 
                                />
                            </td>
                            {/* {(form.categoryName !== 'Carpet' && form.categoryName !== '') ? (
                                <>
                                    <td>
                                        <input 
                                            type="number"
                                            name="size"
                                            placeholder="size"
                                            className='validInputtext input-content-divs-table'
                                            // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                                            value={form.squarefoot}
                                            // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                                            onChange={(e) => handleSquareFootSelectChange(e.target.value,index)} 
                                            min={0}
                                        />
                                    </td>
                                    <td></td>
                                </> ): <><td></td></>
                            } */}
                            <td>
                                <input 
                                    type="number"
                                    name="price"
                                    placeholder="price"
                                    className='validInputtext input-content-divs-table'
                                    // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                                    value={form.price}
                                    // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                                    onChange={(e) => {const updatedFormFields = [...formFields];  
                                        updatedFormFields[index].price = parseFloat(e.target.value);
                                        console.log(updatedFormFields[index]);
                                        const categoryToCalculate = dynamicFieldDefinitions.filter(dynamic => dynamic.categoryId === form.categoryId);
                                        const hasLength = categoryToCalculate.some(dynamic => dynamic.fieldLabel === "Length");
                                        const hasWidth = categoryToCalculate.some(dynamic => dynamic.fieldLabel === "Width");
                                        const hasSquareFoot = categoryToCalculate.some(dynamic => dynamic.fieldLabel === "SquareFoot");
                                        console.log(categoryToCalculate);
                                        console.log(hasLength);
                                        console.log(hasWidth);

                                        if(hasLength && hasWidth){
                                            updatedFormFields[index].amount = parseFloat(e.target.value*form.length*form.width).toFixed(2);
                                        }
                                        else{
                                            updatedFormFields[index].amount = parseFloat(e.target.value*form.squarefoot).toFixed(2);
                                        }
                                        setFormFields(updatedFormFields);
                                        }} 
                                    // min={0}
                                />
                            </td>
                            <td>
                                <input 
                                    type="text"
                                    name="rollNumber"
                                    placeholder="rollNumber"
                                    className='validInputtext input-content-divs-table td-input'
                                    // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                                    value={form.rollNumber}
                                    // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                                    onChange={(e) => {const updatedFormFields = [...formFields];  
                                        updatedFormFields[index].rollNumber = e.target.value;
                                        setFormFields(updatedFormFields);
                                        }} 
                                    // min={0}
                                />
                                <input 
                                    type="text"
                                    name="lot"
                                    placeholder="LOT"
                                    className='validInputtext input-content-divs-table'
                                    // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                                    value={form.lot}
                                    // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                                    onChange={(e) => {const updatedFormFields = [...formFields];  
                                        updatedFormFields[index].lot = e.target.value;
                                        setFormFields(updatedFormFields);
                                        }} 
                                    // min={0}
                                />
                            </td>
                            <td>
                                <input 
                                    type="number"
                                    name="amount"
                                    placeholder="amount"
                                    className='validInputtext input-content-divs-table'
                                    // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                                    value={form.amount}
                                    // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                                    // onChange={(e) => {const updatedFormFields = [...formFields];  
                                    //     if(form.categoryName === "Carpet"){
                                    //         updatedFormFields[index].amount = parseFloat((form.length*form.width*form.price));
                                    //     }
                                    //     else{
                                    //         updatedFormFields[index].amount = parseFloat((form.squarefoot*form.price));
                                    //     }
                                    //     setFormFields(updatedFormFields);
                                    //     console.log(updatedFormFields);}}
                                    // min={0}
                                />
                            </td>
                            <td>
                                <div className='fieldWrapper '>
                                    <button type="button"  className='removeBtn' onClick={() => removeField(index)}>Remove</button>
                                </div>
                            </td>
                        </tr>
                        )
                    })}
                    </tbody>
                </table>
                <button type="button" className='addBtn' onClick={addFields}>Add</button>

                <div className='content-divs'>
                    <div className='LaborField'>
                        <div className='InvoiceTotalItem'>
                            <label htmlFor="subTotal">SubTotal: </label>
                            <input type="number" id='subTotal' name="subTotal" placeholder="SubTotal" value={subTotal}/>
                        </div>
                        <div className='InvoiceTotalItem'>
                            <label htmlFor="tax">Tax: </label>
                            <input
                                type="number"
                                id="tax"
                                name="tax"
                                placeholder="Tax"
                                value={tax}
                                onChange={(e) => {
                                    setTax(parseFloat(e.target.value));
                                }}
                            />
                        </div>
                        <div className='InvoiceTotalItem'>
                            <label htmlFor="shipping">Shipping: </label>
                            <input type="number" id='shipping' name="shipping" placeholder="Shipping" value={shipping} onChange={(e) => setShipping(parseFloat(e.target.value))}/>
                        </div>
                        <div className='InvoiceTotalItem'>
                            <label htmlFor="deposit">Deposit: </label>
                            <input type="number" id='deposit' name="deposit" placeholder="Deposit" value={deposit} onChange={(e) => {setDeposit((e.target.value)); setTotalInvoice(parseFloat((subTotal + shipping + tax).toFixed(2)))}} />
                        </div>
                        <div className='InvoiceTotalItem'>
                            <label htmlFor="total">Invoice Total: </label>
                            <input type="number" id='total' name="total" placeholder="Total" value={totalInvoice} />
                        </div>
                    </div>
                </div>

            </div>
            
            <div className='newCustomerItem'>
                <button type='button' className="newCustomerBtn" id="newCustomerBtn" onClick={handleNew}>New</button>
                <button type='submit' className="newCustomerButton">{id == '0' ? 'Create' : 'Save'}</button>
            </div>
        </form> ) : (
            <></>
        )}

        {showModal && (
            <Modal>
              <div className="modal-header">
                <h2>Add Payment Method</h2>
                <button onClick={closeModal}>X</button>
              </div>
              <div className="modal-body newProductTypeForm">
                <div className='newProductItem' key='Action'>
                  <label>Payment Method</label>
                  <SelectReact
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  value={paymentMethodOptionValue}
                  name="salesPerson"
                  options={paymentMethodOptions}
                  onChange={handlePaymentMethodSelectChange}
                />
                </div>
                <div className="PaymentItem">
                  <label htmlFor="paymentID">Payment ID</label>
                  <input type="text" value={paymentId} placeholder="Payment ID" name="paymentID" id="paymentID" onChange={(e) => setPaymentId(e.target.value)} />
                </div>
                <div className='newProductItem' key="newProductItem">
                  <label>&nbsp;</label>
                  <button type="button" className="SaveProductBtn" id="SaveProductBtn" onClick={handleModalSubmit}>
                    Save
                  </button>
                </div>
                <br />
              </div>
            </Modal>
        )}
        <div id="modal-root"></div>

    </div>
  )
}
