import React, { useEffect, useState } from 'react'
import './supplierReport.css'

export default function SupplierReport({ data, columns, title }) {

    const [oldValue, setOldValue] = useState();
    const divStyle = {
        width: '100%',
    };
    
    const thStyle = {
        padding: '8px',
        textAlign: 'left',
        borderBottom: '1px solid #ddd',
        backgroundColor: '#f2f2f2',
    };
    
    const tdStyle = {
        padding: '8px',
        textAlign: 'left',
        borderBottom: '1px solid #ddd',
    };
    const SalesPersonTableReport = {
        width: '100%',
        bordercollapse: 'collapse',
        padding: '25px'
    }

    const SalesPersonTableReporttd =  {
        padding: '8px',
        textAlign: 'left',
        borderBottom: '1px solid #ddd',
    }

  return (
    <div className='supplierReport'>
        <h1>{title}</h1>
        <table style={SalesPersonTableReport}>
            <thead>
            <tr>
                {columns.map((column) => (
                <th key={column.key} style={thStyle}>
                    {column.title}
                </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data.map((row) => (
                <tr key={row.id}>
                    {columns.map((column) => {
                    const value = row[column.key];
                    const valuesArray = value.split(', '); // Split values by comma and space

                    return (
                        <td key={column.key} style={SalesPersonTableReporttd}>
                        {valuesArray.map((val, index) => (
                            <div key={index}>{val}</div> // Render each value on a separate line
                        ))}
                        </td>
                    );
                    })}
                </tr>
            ))}
            </tbody>
        </table>
    </div>
  )
}
