import React, { useState, useEffect, useRef  } from 'react'
import './invoice.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';
import SelectReact from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import PrintableTable from '../reports/PrintableTable';
import InvoicePrint from '../reports/invoicePrint/InvoicePrint';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Modal from '../../modal/Modal';

export default function Invoice() {
  const { invoiceId } = useParams();
  const [name, setName] = useState('');
  // const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [streetName, setStreetName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [unitNumber, setUnitNumber] = useState('');
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState(0);
  const [stateName, setStateName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [near, setNear] = useState('');
  const [sales, setSales] = useState([]);
  const [saleId, setSaleId] = useState(0);
  const [employeeName, setEmployeeName] = useState('');
  const [measuredBy, setMeasuredBy] = useState('');
  const [measureDate, setMeasureDate] = useState(null);
  const [installDate, setInstallDate] = useState(null);
  const [installers, setInstallers] = useState([]);
  const [installerId, setInstallerId] = useState(0);
  const [installerName, setInstallerName] = useState('');
  const [id, setId] = useState(0);
  const [canPermission, setCanPermission] = useState();
  const [isNameValid, setIsNameValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);
  const [isStreetNumberValid, setIsStreetNumberValid] = useState(true);
  const [isStreetNameValid, setIsStreetNameValid] = useState(true);
  const [isMeasuredByValid, setIsMeasuredByValid] = useState(true);
  const [isMeasuredDateValid, setIsMeasuredDateValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState(true);
  const [saleOptionValue, setSaleOptionValue] = useState();
  const [installerOptionValue, setInstallerOptionValue] = useState();
  const [stateOptionValue, setStateOptionValue] = useState();
  const [formFields, setFormFields] = useState([
    {
      categoryId: 0,
      categoryName: '',
      productId: 0,
      dynamicFields: [], // Add dynamicFields array for each formField
      price: 0,
      pricePerUnit: 0,
      area: 0,
      formFieldValid: false,
      productName: '',
    }
  ]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(0);
  const [categoryOptionValue, setCategoryOptionValue] = useState();
  const [dynamicFields, setDynamicFields] = useState([]);
  const [dataProduct, setDataProduct] = useState([]);
  const [productId, setProductId] = useState([]);
  const [productOptionValue, setProductOptionValue] = useState();
  const [labor, setLabor] = useState(0);
  const [note, setNote] = useState("");
  const [totalMaterial, setTotalMaterial] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [currencyId, setCurrencyId] = useState(0);
  const [currencyOptionValue, setCurrencyOptionValue] = useState();
  const [taxPercentage, setTaxPercentage] = useState(6);
  const [phoneNumberFormatValid, SetPhoneNumberFormatValid] = useState(true);
  const [zipCodeFormatValid, SetZipCodeFormatValid] = useState(true);
  const [isFormFieldsValid, setIsFormFieldsValid] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [selectedNewOption, setSelectedNewOption] = useState(null);
  const [optionsNew, setOptionsNew] = useState([]);
  const [customerId, setcustomerId] = useState(0);
  const [isValidTotal, setIsValidTotal] = useState(true);
  const [selectedYear, setSelectedYear] = useState(0);
  const [yearOptionValue, setYearOptionValue] = useState(0);
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 10; // Adjust the range as needed
  const [invoiceNumber, setInvoiceNumber] = useState("0");
  const [balance, setBalance] = useState(0);
  const navigate = useNavigate();
  const [showPrint, setShowPrint] = useState(false);
  const [dataToPrint, setDataToPrint] = useState({});
  const [productDataById, setProductDataById] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [isInvoiceDateValid, setIsInvoiceDateValid] = useState(true);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodId, setPaymentMethodId] = useState(0);
  const [paymentMethodOptionValue, setpaymentMethodOptionValue] = useState();
  const [paymentId, setPaymentId] = useState('');
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState(0);
  const [companyOptionValue, setCompanyOptionValue] = useState();


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ids = queryParams.get('ids');
  
  
  


  const yearOptions = [];
  for (let year = currentYear; year >= startYear; year--) {
    yearOptions.push(
      {
        value: year,
        label: (year),
      }
    );
  }

  useEffect(() => {
    const desiredPermissionPageName = 'Invoices'; // Replace with the desired permissionPageName value
  
    const desiredRow = GlobalPermission.find(
      (item) => item.permissionPageName === desiredPermissionPageName
    );
    setYearOptionValue({ value: currentYear, label: currentYear });
    setSelectedYear(currentYear);
    setCanPermission(desiredRow);
  
    const fetchData = async () => {
      await GetAllGlobalProducts();
      GetAllGlobalEmployees();
      GetAllStates();
      GetAllInstallers();
      GetAllCategories();
      GetAllCurrencies();
      GetAllCustomers();
      GetAllPaymentMethods();
      GetAllCompanies();
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    if (typeof invoiceId !== "undefined" && invoiceId !== null && invoiceId !== "") {
      GetInvoiceDataById();
    }
  }, [invoiceId]);

  useEffect(() => {
    let totalMaterial = 0;
    // console.log(taxPercentage);
    formFields.forEach((form) => {
      // console.log(form.price);
      if (!isNaN(form.price)) {
        totalMaterial += parseFloat(form.price);
      }
    });
    setTotalMaterial(parseFloat(totalMaterial.toFixed(2)));
    setTotalTax(parseFloat(parseFloat(totalMaterial*taxPercentage/100).toFixed(2)));
    const tot = (parseFloat(labor) + parseFloat(totalMaterial.toFixed(2)) + parseFloat((totalMaterial*taxPercentage/100).toFixed(2))).toFixed(2);
    setTotal(parseFloat(tot));
    setBalance((tot - deposit).toFixed(2));
  }, [formFields, labor, taxPercentage, deposit]);

  function GetAllStates(){
    fetch(ApiUrl + '/api/States/GetAllStates', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data)
      const filtered = data.item2.filter(state => state.isDeleted === false);
      // console.log(filtered)
      setStates(filtered);
      setStateId(filtered[0].id);
      setStateOptionValue({value: filtered[0].id, label: (filtered[0].name + '(' + filtered[0].code + ')')});
      setStateName((filtered[0].name + '(' + filtered[0].code + ')'));
    });
  }

  function GetAllGlobalEmployees(){
    fetch(ApiUrl + '/api/Employees/GetAllGlobalEmployees', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      const filtered = data.item2.filter(employee => employee.isDeleted === false && (employee.roleName === "Sales" || employee.roleName === "admin"));
      setSales(filtered);
      setSaleOptionValue({value: filtered[0].id, label: filtered[0].fullName});
      setSaleId(filtered[0].id);
      setEmployeeName(filtered[0].fullName);
    });
  }

  function GetAllInstallers(){
    fetch(ApiUrl + '/api/Installers/GetAllInstallers', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      const filtered = data.item2.filter(installer => installer.isDeleted === false);
      // console.log(filtered);
      setInstallers(filtered);
      setInstallerOptionValue({value: 0, label: 'Select...'});
      setInstallerId(0);
      setInstallerName('')
    });
  }

  function GetAllCategories(){
    fetch(ApiUrl + '/api/Categories/GetAllCategories', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      const filtered = data.item2.filter(category => category.isDeleted === false);
      setCategories(filtered);
      setCategoryId(filtered.id);
      setCategoryOptionValue({value: filtered[0].id, label: filtered[0].name});
    });
  }

  function GetAllGlobalProducts() {
    let Url = '/api/Products/GetAllGlobalFlooringProducts';
    fetch(ApiUrl + Url, {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
    })
    .then(response => response.json())
    .then(data => {
      const filtered = data.item2.filter(product => product.isDeleted === false && ((product.length !== 0 && product.length !== null) || (product.squareFoot !== 0 && product.squareFoot !== null)));
      console.log("All Flooring Products");
      console.log(filtered);
      setDataProduct(filtered);
      setProductOptionValue({value: filtered[0].id, label: filtered[0].productTypeNameCodeRollNumber})

      const ArrayProduct = [];
      
      if(ids !== null){
        console.log(ids);
  
        fetch(ApiUrl + '/api/DynamicFieldDefinitions/GetAllGlobalDynamicFieldDefinition', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(datadynam => {
          const filtered = datadynam.item2.filter(dyn => dyn.isDeleted === false);
          const updatedData = filtered.map(item => ({ ...item, value: "0" })); // Set value to 0 for each item
          console.log("Global Flooring Products");
          console.log(dataProduct);
          let Url = '/api/Products/GetAllGlobalFlooringProductByIds?productIds=' + ids;
          fetch(ApiUrl + Url, {
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
          })
          .then(response => response.json())
          .then(data => {
            
            const filtered = data.item2.filter(product => product.isDeleted === false);
            console.log("filtered");
            console.log(filtered);
      
            filtered.forEach(prodData => {
              const filteredItem = {
                categoryId: prodData.categoryId,
                categoryName: prodData.categoryName,
                productId: prodData.productId,
                productName: prodData.productTypeNameCode,
                area: 0,
                price: 0,
                pricePerUnit: 0,
                formFieldValid: true,
                dynamicFields: [],
                categoryOptionValue: {
                  value: prodData.categoryId,
                  label: prodData.categoryName
                },
                productOptionValue: {
                  value: prodData.productId,
                  label: prodData.productTypeNameCode
                }
              };
              console.log("testing");
              console.log((updatedData.filter(dynm => dynm.categoryId === prodData.categoryId)));
              (updatedData.filter(dynm => dynm.categoryId === prodData.categoryId)).forEach(field => {
                filteredItem.dynamicFields.push({
                  id: field.id,
                  fieldName: field.fieldName,
                  fieldType: field.fieldType,
                  fieldLabel: field.fieldLabel,
                  categoryId: field.categoryId,
                  isDeleted: field.isDeleted,
                  createdBy: field.createdBy,
                  createdDate: field.createdDate,
                  modifiedBy: field.modifiedBy,
                  modifiedDate: field.modifiedDate,
                  categoryName: field.categoryName,
                  categoryDescription: field.categoryDescription,
                  value: field.value
                });
              });
              ArrayProduct.push(filteredItem);
            });
            setFormFields(ArrayProduct);
          });
        });
      }

      // console.log(filtered);
    });
  }

  function GetAllCurrencies() {
    let Url = '/api/Currencies/GetAllCurrencies';
    fetch(ApiUrl + Url, {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
    })
    .then(response => response.json())
    .then(data => {
      const filtered = data.item2.filter(product => product.isDeleted === false);
      setCurrencies(filtered);
      setCurrencyOptionValue({value: filtered[0].id, label: (filtered[0].code + '(' + filtered[0].symbol + ')')})
      setCurrencyId(filtered[0].id);
    });
  }

  function GetAllCustomers(){
    fetch(ApiUrl + '/api/Customers/GetAllCustomersWithState', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      const filtered = data.item2.filter(customer => customer.isDeleted === false);
      // console.log(filtered);
      setCustomers(filtered);
      const optionNewToAdd = filtered.map((customer) => ({
        value: customer.id,
        label: (customer.name),
      }));
      setOptionsNew(optionNewToAdd);
    });
  }

  function GetInvoiceDataById(){
    if (typeof invoiceId !== "undefined" || invoiceId !== null || invoiceId !== "" || invoiceId === undefined){
      fetch(ApiUrl + '/api/Invoices/GetInvoiceDataById?Id='+ invoiceId, {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        const filtered = data.item2;
        filtered.formFields.forEach((field) => {
          field.categoryOptionValue = {value: field.categoryId, label: field.categoryName};
          field.productOptionValue = {value: field.productId, label: field.productName};
        });
        console.log("Edit");
        console.log(filtered);
        console.log(data);
        setInvoiceNumber(filtered.invoiceNumber);
        setSelectedYear(filtered.invoiceYear);
        setYearOptionValue({value: filtered.invoiceYear, label: filtered.invoiceYear});
        setSelectedNewOption({value: filtered.customerId, label: filtered.name});
        setInstallerOptionValue({value: filtered.installerId, label: filtered.installerName});
        setcustomerId(filtered.customerId);
        setName(filtered.name);
        setStreetNumber(filtered.streetNumber);
        setStreetName(filtered.streetName);
        setCity(filtered.city);
        setUnitNumber(filtered.unitNumber);
        setStateId(filtered.stateId);
        setZipCode(filtered.zipCode);
        setPhoneNumber(filtered.phoneNumber);
        setNear(filtered.near);
        setEmail(filtered.email);
        setSaleId(filtered.employeeId);
        setEmployeeName(filtered.employeeName);
        setMeasuredBy(filtered.measuredBy);
        setMeasureDate(filtered.measureDate ? new Date(filtered.measureDate) : null);
        setInstallDate(filtered.installDate ? new Date(filtered.installDate) : null);
        setInstallerId(filtered.installerId);
        setLabor(filtered.labor);
        setNote(filtered.note);
        setCurrencyId(filtered.currencyId);
        setTaxPercentage(filtered.taxPercentage);
        setFormFields(filtered.formFields);
        setDeposit(filtered.deposit);
        setId(filtered.id);
        setDataToPrint(filtered);
        setShowPrint(true);
        setInvoiceDate((new Date(filtered.invoiceDate)));
        setCompanyId(filtered.companyId);
        setCompanyOptionValue({value: filtered.companyId, label: filtered.companyCode});
      });
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    let validForm = true;
    formFields.forEach(field => {
      // console.log(field.formFieldValid);
      // Access each field object and perform operations
      if(parseInt(field.categoryId) === 0 || parseInt(field.productId) === 0 || parseFloat(field.price) === 0 ){
        field.formFieldValid = false;
        validForm = false;
      }
      else{
        saveFields();
      }
    });

    if (
        (name && name.trim() === '') ||
        (streetNumber && streetNumber.trim() === '') ||
        (streetName && streetName.trim() === '') ||
        (city && city.trim() === '') ||
        // (phoneNumber && phoneNumber.trim() === '') ||
        // phoneNumberFormatValid === false ||
        // (zipCode && zipCode.trim() === '') ||
        // zipCodeFormatValid === false ||
        (measuredBy && measuredBy.trim() === '') ||
        measureDate === null ||
        (formFields.length !== 0 && validForm === false) ||
        total === 0 || invoiceDate === null
      ) {
      if(name.trim() === '')
        setIsNameValid(false);
      else
        setIsNameValid(true);
      if(streetNumber.trim() === '')
        setIsStreetNumberValid(false);
      else{
        setIsStreetNumberValid(true);
      }
      if(streetName.trim() === '')
        setIsStreetNameValid(false);
      else
        setIsStreetNameValid(true);
      if(city.trim() === '')
        setIsCityValid(false);
      else
        setIsCityValid(true);
      if(invoiceDate === null)
        setIsInvoiceDateValid(false);
      else
        setIsInvoiceDateValid(true);
      if(phoneNumber.trim() === ''){
        // setIsPhoneNumberValid(false);
      }
      else{
        if(phoneNumberFormatValid === true){
          setIsPhoneNumberValid(true);
        }
        else{
          setIsPhoneNumberValid(false);
        }
      }
      if(zipCode.trim() === '')
      {
        // setIsZipCodeValid(false);
      }
      else{
        if(zipCodeFormatValid === true){
          setIsZipCodeValid(true);
        }
        else{
          setIsZipCodeValid(false);
        }
      }
      if(measuredBy.trim() === '')
        setIsMeasuredByValid(false);
      else
        setIsMeasuredByValid(true);
      if(measureDate === null)
        setIsMeasuredDateValid(false);
      else
        setIsMeasuredDateValid(true);
      if(formFields.length === 0 ){
        setIsFormFieldsValid(true);
      }
      else{
        formFields.forEach(field => {
          // console.log("valid form Fields");
          // console.log(field.formFieldValid);
          // console.log(field);
          // Access each field object and perform operations
          if(parseInt(field.categoryId) === 0 || parseInt(field.productId) === 0 || parseFloat(field.price) === 0 ){
            field.formFieldValid = false;
          }
          else{
            field.formFieldValid = true;
            saveFields();
          }
        });
      }
      if(formFields.length === 0 && total === 0){
        setIsValidTotal(false);
      }
      else{
        setIsValidTotal(true);
      }
      return;
    }
    else{
      setIsNameValid(true);
      setIsStreetNumberValid(true);
      setIsStreetNameValid(true);
      setIsCityValid(true);
      // setIsPhoneNumberValid(true);
      // setIsZipCodeValid(true);
      setIsMeasuredByValid(true);
      setIsMeasuredDateValid(true);
      setIsValidTotal(true);
    }
    try {
      // console.log(name + " / ");
      if (deposit !== 0) {
        setShowModal(true);
      } else {
        Save()
      }
      
      
    } catch (error) {
        console.error(error);
    }
  }

  async function Save(){
    console.log(id);
    const object = {
      id: id,
      customerId: customerId,
      name: name,
      city: city,
      stateId: stateId,
      streetNumber: streetNumber,
      streetName: streetName,
      zipCode: zipCode,
      unitNumber: unitNumber,
      phoneNumber: phoneNumber,
      near: near,
      email: email,
      EmployeeId: saleId,
      measuredBy: measuredBy,
      measureDate: measureDate,
      installDate: installDate,
      installerId: installerId,
      labor: labor,
      note: note,
      currencyId: currencyId,
      taxPercentage: taxPercentage,
      totalMaterial: totalMaterial,
      tax: totalTax,
      total: total,
      deposit: deposit,
      invoiceYear: selectedYear,
      invoiceNumber: invoiceNumber,
      BalanceCOD: (total - deposit).toFixed(2),
      formFields: formFields,
      employeeName: employeeName,
      stateName: stateName,
      installerName: installerName,
      invoiceDate: invoiceDate,
      paymentMethodId: paymentMethodId,
      paymentId: paymentId,
      companyId: companyId
    };
    // console.log(object);
    // Loop through formFields
    formFields.forEach((field) => {
      field.dynamicFields.forEach((dynamicField) => {
        if (dynamicField.fieldLabel.toLowerCase() === "width") {
          dynamicField.value = "0";
        }
        else{
          dynamicField.value = dynamicField.value.toString();
        }
      });
    });
    
    // console.log(formFields);
    const response = await fetch( ApiUrl +'/api/Invoices/Save', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      },
      body: JSON.stringify(object)
    });
    
    const data = await response.json();
    // console.log(data);
    if(data.isSuccess){
      Swal.fire({
        icon: 'success',
        title: data.message,
        showConfirmButton: false,
        timer: 2000
      }).then(() => {
        setId(data.obj.id);
        setDataToPrint(data.obj);
        setInvoiceNumber(data.obj.invoiceNumber);
        setSelectedYear(data.obj.invoiceYear);
        GetAllGlobalProducts();

        // console.log("test" + data.id);
        // console.log(data);
        if(data.obj.id !== 0){
          setShowPrint(true);
        }
        else{
          setShowPrint(false);
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: data.message,
        showConfirmButton: false,
        timer: 2000
      }).then(() => {
        if(data.obj.id !== 0){
          setShowPrint(true);
        }
        else{
          setShowPrint(false);
        }

    });
    }
  }

  function GetAllGlobalProductByIds(productIds){
    fetch(ApiUrl + '/api/Products/GetAllGlobalProductByIds?productIds=' + productIds, {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      // console.log(data.item2);
      setProductDataById(data.item2);
    });
  }

  const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match the desired format
    if (match) {
      SetPhoneNumberFormatValid(true);
      return `(${match[1]}) ${match[2]}-${match[3]}`; // Apply the format (XXX) XXX-XXXX
    }
    SetPhoneNumberFormatValid(false);
    return cleaned; // Return the input as is if it doesn't match the desired format
  };

  const SalesOptions = sales.map((sale) => ({
    value: sale.id,
    label: sale.fullName,
  }));
  const handleSaleSelectChange = (selectedOption) => {
      setSaleOptionValue(selectedOption);
      setSaleId(selectedOption.value);
      setEmployeeName(selectedOption.label);
  };

  const installersOptions = installers.map((installer) => ({
    value: installer.id,
    label: installer.fullName,
  }));
  installersOptions.unshift({
    value: 0,
    label: 'Select...',
  });
  const handleInstallerSelectChange = (selectedOption) => {
    setInstallerOptionValue(selectedOption);
    setInstallerId(selectedOption.value);
    setInstallerName(selectedOption.label);
  };

  const statesOptions = states.map((state) => ({
    value: state.id,
    label: (state.name + '(' + state.code + ')'),
  }));
  const handleStateSelectChange = (selectedOption) => {
      setStateOptionValue(selectedOption);
      setStateId(selectedOption.value);
      setStateName(selectedOption.label);
  };
  // const handleFormChange = (event, index) => {
  //   let data = [...formFields];
  //   data[index][event.target.name] = event.target.value;
  //   setFormFields(data);
  // };
  
  const addFields = () => {
    let object = {
      categoryId: 0,
      categoryName: '',
      productId: 0,
      dynamicFields: [], // Add dynamicFields array for the new formField
      price: 0,
      pricePerUnit: 0,
      area: 0,
      formFieldValid: false,
      productName: '',
    };
    setFormFields([...formFields, object]);
  };

  const saveFields = () => {
    setFormFields([...formFields]);
  };

  const removeField = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const categoriesOptions = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));
  const handleCategoriesSelectChange = (selectedOption, index) => {
    let data = [...formFields];
    data[index].categoryId = selectedOption.value;
    data[index].categoryName = selectedOption.label;
    data[index].productId = 0;
    data[index].categoryOptionValue = { value: selectedOption.value, label: selectedOption.label };
    data[index].productOptionValue = null;
    setProductOptionValue(0);
    setFormFields(data);
    setCategoryId(selectedOption.value);
    
    GetDynamicFieldDefinitionByCategoryId(parseInt(selectedOption.value, 10), index)
  };

  function GetDynamicFieldDefinitionByCategoryId(categoryIdVar, index) {
    return new Promise((resolve, reject) => {
      fetch(ApiUrl + '/api/DynamicFieldDefinitions/GetDynamicFieldDefinitionByCategoryId?Category=' + categoryIdVar, {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        data.item2.forEach((dt) => {
          dt.value = 0;
        });
        let dataform = [...formFields];
        dataform[index].dynamicFields = data.item2;
        setFormFields(dataform);
        setDynamicFields(data.item2);
        // console.log(dataform);
        resolve(); // Resolve the promise when the dynamic field definitions are set
      })
      .catch(error => {
        reject(error); // Reject the promise if an error occurs during the API call
      });
    });
  }

  const productsOptions = dataProduct.map((product) => ({
    value: product.id,
    label: (product.productTypeNameCode),
  }));
  const handleProductSelectChange = (selectedOption, index) => {
    let data = [...formFields];
    data[index].productId = selectedOption.value;
    data[index].productName = selectedOption.label;
    data[index].pricePerUnit = dataProduct.filter(x => x.productId === selectedOption.value)[0].minimumPricePerUnit;
    data[index].minimumPricePerUnit = dataProduct.filter(x => x.productId === selectedOption.value)[0].minimumPricePerUnit;
    if(selectedOption.value !== 0){
      const m = data[index].dynamicFields.filter(x => x.fieldLabel.toLowerCase() === "width");
      if(m.length > 0){
        // console.log(dataProduct.filter(x => x.productId === selectedOption.value)[0]);
        if(dataProduct.filter(x => x.productId === selectedOption.value)[0].width !== null && dataProduct.filter(x => x.productId === selectedOption.value)[0].width !== "undefined"){
          m[0].value = (selectedOption.value ? dataProduct.filter(x => x.productId === selectedOption.value)[0].width + "" : "0");
          data[index].syd = data[index].dynamicFields.reduce((accumulator, currentValue) => accumulator * currentValue, 1) / 9;
          
        }
      }
    }
    // console.log(dataProduct.filter(x => x.productId === data[index].productId)[0]);
    if(data[index].productId !== 0){
      console.log(dataProduct.filter(x => x.productId === data[index].productId)[0]);
      const modifiedFieldLabelValue = ((data[index].dynamicFields[0].fieldLabel.trim()).charAt(0).toLowerCase()) + ((data[index].dynamicFields[0].fieldLabel.trim()).slice(1));
      // console.log(modifiedFieldLabelValue);
      // console.log(Object.keys(dataProduct.filter(x => x.productId === data[index].productId)[0]).some(key => key === modifiedFieldLabelValue) ?  dataProduct.filter(x => x.productId === data[index].productId)[0][modifiedFieldLabelValue] : "0");
    }
    // console.log(dataProduct.filter(x => x.productId === data[index].productId)[0][data[index].dynamicFields[0].fieldLabel.toLowerCase()])
    setFormFields(data);
    // console.log(data);
  };

  // const getMinimumValue = (productId) => {
  //   let data = [...formFields];
  //   const MinValue = 0;
  //   if(dataProduct.length !== 0)
  //     MinValue = dataProduct.filter(x => x.productId === productId)[0].costPerUnit;
  //   if(MinValue !== null || MinValue !== 0){
  //     return MinValue;
  //   }
  //   else
  //     return 0; // Default minimum value when the condition is not met or the product is not found
  // };

  const currenciesOptions = currencies.map((currency) => ({
    value: currency.id,
    label: (currency.code + '(' + currency.symbol + ')'),
  }));
  const handleCurrenciesSelectChange = (selectedOption, index) => {
    setCurrencyOptionValue(selectedOption);
    setCategoryId(selectedOption.value);
  };

  function formatStreetNumber(streetNumber) {
    // Remove any non-digit characters from the input
    const cleanedStreetNumber = streetNumber.replace(/\D/g, '');
  
    // Return the cleaned street number
    return cleanedStreetNumber;
  }
  
  
  const formatZipCode = (input) => {
    const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
    
    const match = cleaned.match(/^(\d{5})(\d{4})?$/); // Match the desired format (5 or 9 digits)
    if (match) {
      SetZipCodeFormatValid(true);
      return match[1] + (match[2] ? `-${match[2]}` : ''); // Apply the format (XXXXX or XXXXX-XXXX)
    }
    SetZipCodeFormatValid(false);
    return cleaned; // Return the input as is if it doesn't match the desired format
  };

  const handleLaborChange = (event) => {
    const value = parseFloat(event.target.value);
    let sanitizedValue;
  
    if (isNaN(value) || value < 0) {
      sanitizedValue = 0;
      // Display an error message or perform any other necessary actions
      console.log('Invalid input. Value set to 0.');
    } else {
      sanitizedValue = value;
    }
    setLabor(sanitizedValue);
  };

  const handleDepositChange = (event) => {
    const value = event.target.value;
  
    // Validate the input value as a decimal number
    const regex = /^\d*\.?\d*$/; // Regular expression for decimal number validation
    if (!regex.test(value) && value !== '') {
      // Display an error message or perform any necessary actions
      console.log('Invalid input. Please enter a valid decimal number.');
      return;
    }
  
    setDeposit(parseFloat(value));
  };

  const handleInputChange = (newValue) => {
    console.log(newValue);
    setSelectedNewOption(newValue);
    setcustomerId(newValue === null ? 0 : newValue.value);
    setName(newValue === null ? '' : newValue.label)
    console.log((newValue === null ? 0 : newValue.value) + " / " + (newValue === null ? '' : newValue.label));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !selectedNewOption) {
      const inputValue = event.target.value.trim();

      const indexToRemove = optionsNew.findIndex((obj) => obj.value === 0);
      const filteredArray = [...optionsNew];
      if (indexToRemove !== -1) {
        filteredArray.splice(indexToRemove, 1);
      }

      if (inputValue !== '' && !optionsNew.some((option) => option.label === inputValue)) {
        const newOption = {
          value: 0,
          label: inputValue
        };

        setSelectedNewOption(newOption);
        handleInputChange(newOption);
        setOptionsNew([...filteredArray, newOption]);
      }
    }
  };

  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.trim() !== '' && !selectValue.some((option) => option.label === inputValue);

  const getNewOptionData = (inputValue, optionLabel) => ({
    value: inputValue.toLowerCase(),
    label: optionLabel
  });

  // const handleYearSelectChange = (selectedOption, index) => {
  //   setYearOptionValue(selectedOption);
  //   setSelectedYear(selectedOption.value);
  // };
  const handlePrintClick = (e) => {
    e.preventDefault();
    console.log(dataToPrint);
    const concatenatedString = dataToPrint.formFields.map(obj => obj.productId).join(',');
    console.log(concatenatedString);

    fetch(ApiUrl + '/api/Products/GetAllGlobalFlooringProductByIds?productIds=' + concatenatedString, {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.item2);
      setProductDataById(data.item2);

      const InvoicePrintComponent = (
        <InvoicePrint dataToPrint={dataToPrint} productDataById={data.item2} />
      );
  
      const newWindow = window.open("", "_blank");
      newWindow.document.open();
      newWindow.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <title>Invoice</title>
            <link rel="stylesheet" type="text/css" href="printtableTable.css">
          </head>
          <body>${ReactDOMServer.renderToString(InvoicePrintComponent)}</body>
        </html>`);
      newWindow.print();
      newWindow.document.close();

    });
  };

  const handleNewClick = () => {
    setInvoiceNumber("0");
    setInvoiceDate(null);
    setName('');
    setCity('');
    setStreetNumber('');
    setStreetName('');
    setZipCode('');
    setUnitNumber('');
    setStateId(0);
    setPhoneNumber('');
    setNear('');
    setEmail('');
    setEmployeeName('');
    setMeasuredBy('');
    setMeasureDate('');
    setId(0);
    setInstallDate(null);
    setInstallerId(0);
    setInstallerName('');
    setIsNameValid(true);
    setIsAddressValid(true);
    setIsCityValid(true);
    setIsStreetNumberValid(true);
    setIsStreetNameValid(true);
    setIsMeasuredByValid(true);
    setIsMeasuredDateValid(true);
    setIsPhoneNumberValid(true);
    setIsZipCodeValid(true);
    const filteredInstallers = installers.filter(installer => installer.isDeleted === false);
    setInstallerOptionValue({value: 0, label: 'Select...'});

    const filteredStates = states.filter(state => state.isDeleted === false);
    setStateId(filteredStates[0].id);
    setStateOptionValue({value: filteredStates[0].id, label: (filteredStates[0].name + '(' + filteredStates[0].code + ')')});
    setStateName((filteredStates[0].name + '(' + filteredStates[0].code + ')'));
    
    const filteredSales = sales.filter(employee => employee.isDeleted === false && (employee.roleName === "Sales" || employee.roleName === "admin"));
    setSaleOptionValue({value: filteredSales[0].id, label: filteredSales[0].fullName});
    setSaleId(filteredSales[0].id);
    setEmployeeName(filteredSales[0].fullName);

    setCategoryId(0);

    const filteredCategories = categories.filter(category => category.isDeleted === false);
    setCategoryId(filteredCategories.id);
    setCategoryOptionValue({value: filteredCategories[0].id, label: filteredCategories[0].name});

    // const filteredDataProduct = dataProduct.filter(product => product.isDeleted === false && ((product.length !== 0 && product.length !== null) || (product.squareFoot !== 0 && product.squareFoot !== null)));
    // setProductOptionValue({value: filteredDataProduct[0].id, label: filteredDataProduct[0].productTypeNameCodeRollNumber})

    setLabor(0);
    setTotalMaterial(0);
    setTotalTax(0);
    setTotal(0);
    setDeposit(0);

    const filteredCurrencies = currencies.filter(product => product.isDeleted === false);
    setCurrencyOptionValue({value: filteredCurrencies[0].id, label: (filteredCurrencies[0].code + '(' + filteredCurrencies[0].symbol + ')')})
    setCurrencyId(filteredCurrencies[0].id);
    setTaxPercentage(6);

    SetZipCodeFormatValid(true);
    setIsFormFieldsValid(true);
    setIsFormFieldsValid(true);
    setSelectedNewOption(null);
    // const filteredCustomers = customers.filter(customer => customer.isDeleted === false);
    // const optionNewToAdd = filteredCustomers.map((customer) => ({
    //   value: customer.id,
    //   label: (customer.name),
    // }));
    // setOptionsNew(optionNewToAdd);
    setcustomerId(0);
    setIsValidTotal(0);
    setYearOptionValue({value:currentYear, label: currentYear});
    setSelectedYear(currentYear);
    setBalance(0);
    setShowPrint(false);
    setDataToPrint({});
    setIsInvoiceDateValid(true);
    setFormFields([
      {
        categoryId: 0,
        categoryName: '',
        productId: 0,
        dynamicFields: [], // Add dynamicFields array for each formField
        price: 0,
        pricePerUnit: 0,
        area: 0,
        formFieldValid: false,
        productName: '',
      }
    ])
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    console.log("Test");
    console.log(dataToPrint);
    if(dataToPrint.email !== "" && dataToPrint.email !== "undefined" && dataToPrint.email !== null){
      const response = await fetch( ApiUrl +'/api/Invoices/SendInvoiceEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        },
        body: JSON.stringify(dataToPrint)
      });
      
      // const data = await response.json();
      if (response.ok) {
        // Email sent successfully
        const message = await response.text();
        console.log(message);
        Swal.fire({
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
        });
      } else {
        // Handle other response statuses (e.g., error)
        const error = await response.text();
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: "Email is not set!",
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
        });
      }
      
    }
    else{
      Swal.fire({
        icon: 'error',
        title: "Email is not set!",
        showConfirmButton: false,
        timer: 2000
      }).then(() => {
      });
    }
    
  };

  const formatEmail = (input) => {
    // Remove leading and trailing white spaces
    const trimmedEmail = input.trim();

    if (trimmedEmail === '' || isValidEmail(trimmedEmail)) {
      setIsEmailValid(true);
      return trimmedEmail;
    } else {
      setIsEmailValid(false);
      return input;
    }
  };

  const isValidEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const openModal = (rowId) => {
    // console.log(rowId);
    setShowModal(true);
  };

  const closeModal = () => {
      setShowModal(false);
  };

  function GetAllPaymentMethods(){
    fetch(ApiUrl + '/api/PaymentMethods/GetAllPaymentMethods', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.item2);
      const filteredData = data.item2.filter(item => item.isDeleted === false);
      setPaymentMethods(filteredData);
      setpaymentMethodOptionValue({value: filteredData[0].id, label: (filteredData[0].paymentMethodName)});
      setPaymentMethodId(filteredData[0].id);
    });
  }

  function GetAllCompanies(){
    fetch(ApiUrl + '/api/Companies/GetAllCompanies', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.item2);
      const filteredData = data.item2;
      setCompanies(filteredData);
      setCompanyOptionValue({value: filteredData[0].id, label: (filteredData[0].code)});
      setCompanyId(filteredData[0].id);
    });
  }

  const paymentMethodOptions = paymentMethods.map((paymentmethod) => ({
    value: paymentmethod.id,
    label: paymentmethod.paymentMethodName,
  }));
  const handlePaymentMethodSelectChange = (selectedOption) => {
      setpaymentMethodOptionValue(selectedOption);
      setPaymentMethodId(selectedOption.value);
  };

  const handleModalSubmit = () => {
    // Handle submit logic for adding payment method
    // using the paymentId value
    // ...
    Save();
    setShowModal(false);
  };

  const CompanyOptions = companies.map((company) => ({
    value: company.id,
    label: company.code,
  }));
  const handleCompanyChange = (selectedOption) => {
    console.log(selectedOption);
      setCompanyOptionValue(selectedOption);
      setCompanyId(selectedOption.value);
  };

  return (
    <div className='invoice'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: 'auto' }}>
          <h1>Invoice</h1>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SelectReact
            className="basic-single companyClass"
            classNamePrefix="select"
            isClearable={false}
            isSearchable={true}
            value={companyOptionValue}
            name="company"
            options={CompanyOptions}
            onChange={handleCompanyChange}
          />
          <input
            type="text"
            className={'HeaderNumber'}
            value={invoiceNumber}
            placeholder="invoiceNumber"
            name="invoiceNumber"
            id="invoiceNumber"
            onChange={(e) => setInvoiceNumber(e.target.value)}
            style={{ marginRight: '10px', direction: 'rtl' }} // Add any desired spacing between the input and select
          />
          {/* <SelectReact
            className="yearSelect"
            classNamePrefix="select"
            isClearable={false}
            isSearchable={true}
            value={yearOptionValue}
            name="year"
            options={yearOptions}
            onChange={handleYearSelectChange}
            isDisabled={true} // Set the isDisabled prop to disable the SelectReact component
          /> */}
          <DatePicker
            selected={invoiceDate}
            onChange={date => {setInvoiceDate(date); setSelectedYear(date.getFullYear())}}
            dateFormat="MMMM d, yyyy"
            placeholderText="Select Date"
            id="invoiceDate"
            name="invoiceDate"
            className={!isInvoiceDateValid ? 'CustomDatePickerInvoice InvalidInputtext' : 'CustomDatePickerInvoice validInputtext'}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={15}
            showMonthDropdown
            scrollableMonthYearDropdown
          />
        </div>
      </div>
        {canPermission && canPermission.canEdit ? (
          <form id="myForm" onSubmit={handleSubmit}>
            <div className="InvoiceForm">
              <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />

              <div className="InvoiceItem">
                <label htmlFor="name">Name:</label>
                <SelectReact
                  options={optionsNew}
                  value={selectedNewOption}
                  onChange={handleInputChange}
                  className={!isNameValid ? 'textboxField InvalidInputtext' : 'textboxField'}
                  isClearable
                  isSearchable
                  placeholder="Select or enter a value"
                  onKeyDown={handleKeyDown}
                  isValidNewOption={isValidNewOption}
                  getNewOptionData={getNewOptionData}
                />
                {/* <input type="text" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} placeholder="Name" name="name" id="name" onChange={(e) => setName(e.target.value)} /> */}
              </div>

              <div className="InvoiceItem">
                <label htmlFor="salesPerson">Sales Person:</label>
                <SelectReact
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  value={saleOptionValue}
                  name="salesPerson"
                  options={SalesOptions}
                  onChange={handleSaleSelectChange}
                />
              </div>

              <div className='InvoiceItem'>
                <label htmlFor="street">Address:</label>
                <div className="InvoiceItem">
                  <input type="text" className={!isStreetNumberValid ? 'InvalidInputtext' : 'validInputtext'} value={streetNumber} placeholder="Street No" name="street" id="street" onChange={(e) => setStreetNumber(formatStreetNumber(e.target.value))} />
                  <input type="text" className={!isStreetNameValid ? 'InvalidInputtext' : 'validInputtext'} value={streetName} placeholder="Street Name" name="streetName" id="streetName" onChange={(e) => setStreetName(e.target.value)} />
                </div>

                <div className="InvoiceItemInside">
                  <label htmlFor="city">City:</label>
                  <input type="text" className={!isCityValid ? 'InvalidInputtext' : 'validInputtext'} value={city} placeholder="City" name="city" id="city" onChange={(e) => setCity(e.target.value)} />
                </div>
              </div>

              <div className="InvoiceItem">
                <label htmlFor="measuredBy">Measured by:</label>
                <input type="text" className={!isMeasuredByValid ? 'InvalidInputtext' : 'validInputtext'} value={measuredBy} placeholder="Measured by" name="measuredBy" id="measuredBy" onChange={(e) => setMeasuredBy(e.target.value)} />
              </div>

              <div className="InvoiceItem">
                <label htmlFor="state">State:</label>
                <div className="InvoiceItemSub">
                  <SelectReact
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    value={stateOptionValue}
                    name="state"
                    options={statesOptions}
                    onChange={handleStateSelectChange}
                  />
                </div>

                <div className="InvoiceItem">
                  <label htmlFor="zip">Zip:</label>
                  <input type="text" className='validInputtext' value={zipCode} placeholder="Zip" name="zip" id="zip" onChange={(e) => setZipCode(formatZipCode(e.target.value))} />
                </div>
              </div>

              <div className="InvoiceItem">
                <label htmlFor="measureDate">Measured Date:</label>
                <DatePicker
                  selected={measureDate}
                  onChange={date => setMeasureDate(date)}
                  dateFormat="MMMM d, yyyy"
                  placeholderText="Select Date"
                  id="measureDate"
                  name="measureDate"
                  className={!isMeasuredDateValid ? 'CustomDatePickerInvoice InvalidInputtext' : 'CustomDatePickerInvoice validInputtext'}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  showMonthDropdown
                  scrollableMonthYearDropdown
                />
              </div>

              {/* <div className="InvoiceItem">
                <label htmlFor="address">Address:</label>
                <input type="text" className={!isAddressValid ? 'InvalidInputtext' : 'validInputtext'} value={address} placeholder="Address" name="address" id="address" onChange={(e) => setAddress(e.target.value)} />
              </div> */}
              
              <div className="InvoiceItem">
              <label htmlFor="unitNumber">Unit Number:</label>
                <div className="InvoiceItemSub">
                  <input type="text" className='validInputtext' value={unitNumber} placeholder="Unit #" name="unitNumber" id="unitNumber" onChange={(e) => setUnitNumber(e.target.value)} />
                </div>

                <div className="InvoiceItem">
                  <label htmlFor="phone">Phone:</label>
                  <input type="text" className='validInputtext' value={phoneNumber} placeholder="Phone" name="phone" id="phone" onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))} />
                </div>
              </div>

              <div className="InvoiceItem">
                <label htmlFor="installDate">Install Date:</label>
                <DatePicker
                  selected={installDate}
                  onChange={date => setInstallDate(date)}
                  dateFormat="MMMM d, yyyy"
                  placeholderText="Select Date"
                  id="installDate"
                  name="installDate"
                  className='CustomDatePickerInvoice'
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  showMonthDropdown
                  scrollableMonthYearDropdown
                />
              </div>

              <div className="InvoiceItem">
                <label htmlFor="near">Near:</label>
                <input type="text" value={near} placeholder="Near" name="near" id="near" onChange={(e) => setNear(e.target.value)} />
              </div>

              <div className="InvoiceItem">
                <label htmlFor="installer">Installer</label>
                <SelectReact
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  value={installerOptionValue}
                  name="installer"
                  options={installersOptions}
                  onChange={handleInstallerSelectChange}
                />
              </div>

              <div className="InvoiceItem">
                <label htmlFor="email">Email:</label>
                <input type="text" placeholder='Email' name="email" id="email" className={!isEmailValid ? 'InvalidInputtext' : 'validInputtext'} value={email} onChange={(e) => setEmail(formatEmail(e.target.value))} />
              </div>

              <div className="InvoiceItem">
                <label htmlFor="note">Note:</label>
                <textarea rows="3" value={note} placeholder="Note" name="note" id="note" onChange={(e) => setNote(e.target.value)}></textarea>
              </div>
            </div>
            <button type="button" className='addBtn' onClick={addFields}>Add</button>
      {formFields.map((form, index) => {
        // Filter the product options based on the selected category in the current row
        const proOpt = dataProduct.filter(option => option.categoryId === form.categoryId);
        // console.log("proOpt");
        // console.log(proOpt)
        const ProductOptionsNew = proOpt.map((product) => ({
          value: product.id,
          label: (product.productTypeNameCodeRollNumber),
        }));
        const filteredProductOptions = productsOptions.filter(option => option.categoryId === form.categoryId);

        const calculatePrice = (pricePerUnit) => {
          const dynamicFieldValues = form.dynamicFields.map(dynamicField => Number(dynamicField.value));
          const totalPrice = dynamicFieldValues.reduce((accumulator, currentValue) => accumulator * currentValue, 1) * pricePerUnit;
          return totalPrice.toFixed(2); // Adjust the number of decimal places as needed
        };

        const handlePricePerUnitChange = (event) => {
          const newPricePerUnit = (event.target.value < form.minimumPricePerUnit) ? form.minimumPricePerUnit : event.target.value;
          const newPrice = parseFloat(calculatePrice(newPricePerUnit));
          form.pricePerUnit = parseFloat(newPricePerUnit);
          form.price = newPrice;
          saveFields();
        };

        const handleCalculateChange = (pricePerUnit) => {
          const newPricePerUnit = pricePerUnit;
          const newPrice = parseFloat(calculatePrice(newPricePerUnit));
          form.pricePerUnit = parseFloat(newPricePerUnit);
          form.price = newPrice;
          const dynamicFieldValues = form.dynamicFields.map(dynamicField => Number(dynamicField.value));
          const area = dynamicFieldValues.reduce((accumulator, currentValue) => accumulator * currentValue, 1);
          form.area = area;
          form.syd = area / 9;
          // console.log(area)
          saveFields();
        }

        const handleSYD = (event) => {
          const value = event.target.value;
          const dynamicFieldValues = form.dynamicFields.map(dynamicField => Number(dynamicField.value));
          // console.log(dynamicFieldValues.reduce((accumulator, currentValue) => accumulator * currentValue, 1)/9);
          // if(value <= (dynamicFieldValues.reduce((accumulator, currentValue) => accumulator * currentValue, 1)/9)){
            if (dynamicFieldValues.length > 0) {
              const hasLength = form.dynamicFields.some(dynamicField => dynamicField.fieldLabel === "Length");
              const hasWidth = form.dynamicFields.some(dynamicField => dynamicField.fieldLabel === "Width");
              const hasSquarefoot= form.dynamicFields.some(dynamicField => dynamicField.fieldLabel === "SquareFoot");
            
              if (hasLength && hasWidth) {
                const lengthField = form.dynamicFields.find(dynamicField => dynamicField.fieldLabel === "Length");
                const widthField = form.dynamicFields.find(dynamicField => dynamicField.fieldLabel === "Width");
                if(value <= (dataProduct.filter(x => x.productId === form.productId)[0].width*dataProduct.filter(x => x.productId === form.productId)[0].length/9)){
                  const syd = parseFloat(value);
                  form.syd = parseFloat(syd);
                  form.area = parseFloat(parseFloat(widthField.value)*parseFloat((value*9)/(parseFloat(widthField.value))));
                  lengthField.value = (value*9)/(parseFloat(widthField.value)); // Replace "new length value" with the desired updated value
                }
                else{
                  const syd = parseFloat((dataProduct.filter(x => x.productId === form.productId)[0].width*dataProduct.filter(x => x.productId === form.productId)[0].length/9));
                  form.syd = parseFloat(syd);
                  lengthField.value = dataProduct.filter(x => x.productId === form.productId)[0].length; // Replace "new length value" with the desired updated value
                }
              }
              else{
                if(hasSquarefoot){
                  if(value <= (dataProduct.filter(x => x.productId === form.productId)[0].squareFoot/9)){
                    const lengthField = form.dynamicFields.find(dynamicField => dynamicField.fieldLabel === "SquareFoot");
                    const syd = parseFloat(value);
                    form.syd = parseFloat(syd);
                    lengthField.value = (value)*9; // Replace "new length value" with the desired updated value
                  }
                  else{
                    const lengthField = form.dynamicFields.find(dynamicField => dynamicField.fieldLabel === "SquareFoot");
                    const syd = parseFloat((dataProduct.filter(x => x.productId === form.productId)[0].squareFoot/9));
                    form.syd = parseFloat(syd);
                    lengthField.value = dataProduct.filter(x => x.productId === form.productId)[0].squareFoot; // Replace "new length value" with the desired updated value
                  }
                }
              }
            }
            
            saveFields();
          // }
        }

        // console.log(form.dynamicFields)
        if(form.categoryId !== 0 && form.productId !== 0){
          form.dynamicFields.forEach((field) => {
            if (field.fieldLabel.toLowerCase() === "width") {
              const prodData = dataProduct.find(x => x.productId === form.productId);
            
              if (prodData !== undefined && prodData.width !== null && prodData.width !== undefined) {
                field.value = prodData.width;
              } else {
                field.value = "0";
              }
            }
          });
        }
        if(form !== null){
          if(form.categoryId !== 0 && form.productId !== 0){
              form.categoryOptionValue = { value: form.categoryId, label: form.categoryName };
              form.productOptionValue = { value: form.productId, label: form.productName };
          }
        }
        
        // console.log(form);

        return (
          <div key={index}  className={form.formFieldValid === false ? 'dynamicFields InvalidInputtext' : 'dynamicFields'}>
            <div className='dynamicSubFields'>
              <SelectReact
                className="textboxField"
                classNamePrefix="select"
                isClearable={false}
                isSearchable={true}
                value={form.categoryOptionValue}
                name="category"
                id={index}
                options={categoriesOptions}
                onChange={(selectedOption) => handleCategoriesSelectChange(selectedOption, index)}
              />
            </div>
           
            <SelectReact
              className="textboxField"
              classNamePrefix="select"
              isClearable={false}
              isSearchable={true}
              value={form.productOptionValue}
              name="product"
              id={'product-' + index}
              options={ProductOptionsNew}
              onChange={(selectedOption) => handleProductSelectChange(selectedOption, index)}
            />

            {form.dynamicFields.map((dynamicField) => (
              <div className='InvoiceItemFieldsSub'>
                {/* <label htmlFor={dynamicField.fieldLabel}>{dynamicField.fieldLabel}</label> */}
                <input 
                  type={dynamicField.fieldType === "Decimal" ? "number" : "text"} 
                  name={dynamicField.fieldLabel}
                  placeholder={dynamicField.fieldLabel} 
                  // onChange={(event) => {(form.productId !== 0 ? (dynamicField.value = event.target.value) : dynamicField.value = 0); handleCalculateChange(parseFloat(form.pricePerUnit));}} 
                  disabled={dynamicField.fieldLabel.toLowerCase() === "width"}
                  value={dynamicField.fieldLabel.toLowerCase() === "width" ? (form.productId !== 0 && dataProduct.find(x => x.productId === form.productId) ? dataProduct.find(x => x.productId === form.productId).width.toString() : "0") : dynamicField.value || ""}
                  // max={form.productId !== 0 ? dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))] : 0}
                  onChange={(event) => {
                    if (form.productId !== 0) {

                      const fieldValue = dataProduct.filter(x => x.productId === form.productId)[0][((dynamicField.fieldLabel.trim()).charAt(0).toLowerCase()) + ((dynamicField.fieldLabel.trim()).slice(1))];
                      if (event.target.value <= 0) {
                        dynamicField.value = "0";
                      } else {
                        if (event.target.value <= fieldValue && event.target.value > 0) {
                          dynamicField.value = event.target.value.toString();
                        } else {
                          dynamicField.value = fieldValue.toString();
                        }
                      }
                      console.log(dynamicField);
                      console.log(formFields);
                      handleCalculateChange(parseFloat(form.pricePerUnit));
                    }
                  }}
                  // min={0}
                />
              </div>
            ))}
            
            <div className='InvoiceItemFieldsSub'>
              {/* <label htmlFor={dynamicField.fieldLabel}>{dynamicField.fieldLabel}</label> */}
              <input type="number" name="syd" placeholder="SYD" value={form.syd} onChange={(event) => {handleSYD(event);}} step="any" min={0} />
              {/* <span className='pricePerUnitMin'>min {form.productId !== 0 ? getMinimumValue(form.productId) : 0}</span> */}
            </div>

            <div className={form.categoryName.toLowerCase() === 'carpet' ? 'InvoiceItemFieldsSub ShowDiv' : "InvoiceItemFieldsSub HideDiv"}>
              {/* <label htmlFor={dynamicField.fieldLabel}>{dynamicField.fieldLabel}</label> */}
              <input type="number" name="area" placeholder="Area" value={form.area} />
            </div>

            <div className='InvoiceItemFieldsSub'>
              {/* <label htmlFor={dynamicField.fieldLabel}>{dynamicField.fieldLabel}</label> */}
              <input type="number" name="pricePerUnit" placeholder="pricePerUnit" value={form.pricePerUnit} onChange={(event) => {handlePricePerUnitChange(event);}} step="any" min={form.minimumPricePerUnit} /> {/* min={form.productId !== 0 ? getMinimumValue(form.productId) : 0} */}
              {/* <span className='pricePerUnitMin'>min {form.productId !== 0 ? getMinimumValue(form.productId) : 0}</span> */}
            </div>

            <div className='InvoiceItemFieldsSub'>
              {/* <label htmlFor={dynamicField.fieldLabel}>{dynamicField.fieldLabel}</label> */}
              <input type="number" name="price" placeholder="price" value={form.price} onChange={(event) => form.price = event.target.value} />
            </div>

            <div className='fieldWrapper '>
              <button type="button"  className='removeBtn' onClick={() => removeField(index)}>Remove</button>
            </div>
          </div>
        );
      })}
      <div className='LaborField'>

        <div className='InvoiceItem'>
          <label htmlFor="labor">Labor: </label>
          <input type="number" id='labor' name="labor" placeholder="Labor" value={labor} onChange={handleLaborChange}/>
        </div>
        <div className="InvoiceItem">
          <label htmlFor="currency">Currency:</label>
          <SelectReact
            className="basic-single"
            classNamePrefix="select"
            isClearable={false}
            isSearchable={true}
            value={currencyOptionValue}
            name="currency"
            options={currenciesOptions}
            onChange={handleCurrenciesSelectChange}
          />
        </div>

        <div className='InvoiceItem'>
          <label htmlFor="taxPercentage">Tax Percentage</label>
          <input type="number" name="taxPercentage" placeholder="Tax Percentage" value={taxPercentage} onChange={(event) => setTaxPercentage(event.target.value)} />
        </div>
      </div>
      <div className='LaborField'>
        <div className='InvoiceTotalItem'>
          <label htmlFor="material">Material: </label>
          <input type="number" id='material' name="material" placeholder="Material" value={totalMaterial}/>
        </div>
        <div className='InvoiceTotalItem'>
          <label htmlFor="tax">Tax: </label>
          <input type="number" id='tax' name="tax" placeholder="Tax" value={totalTax} />
        </div>
        <div className='InvoiceTotalItem'>
          <label htmlFor="labor">Labor: </label>
          <input type="number" id='labor' name="labor" placeholder="Labor" value={labor}/>
        </div>
        <div className='InvoiceTotalItem'>
          <label htmlFor="total">Total: </label>
          <input type="number" id='total' name="total" placeholder="Total" value={total} className={!isValidTotal ? 'InvalidInputtext' : 'validInputtext'}/>
        </div>
        <div className='InvoiceTotalItem'>
          <label htmlFor="deposit">Deposit: </label>
          <input type="number" id='deposit' name="deposit" placeholder="Deposit" value={deposit} onChange={handleDepositChange} />
        </div>
        <div className='InvoiceTotalItem'>
          <label htmlFor="balance">Balance C.O.D: </label>
          <input type="number" id='balance' name="balance" placeholder="Balance" value={balance} />
        </div>
      </div>
      <div className='SaveDiv'>
        <button type='button' className="newBtn" id="newBtn" onClick={handleNewClick}>New</button>
        <button type="button" className={!showPrint ? 'saveBtn HideDiv' : 'saveBtn'} onClick={handleSendEmail}>Send Email</button>
        <button type="button" className={!showPrint ? 'saveBtn HideDiv' : 'saveBtn'} onClick={handlePrintClick}>Print</button>
        <button type="submit" className='saveBtn'>{id == '0' ? 'Create' : 'Save'}</button>
      </div>
      </form> ) : (
          <></>
        )}
        {showModal && (
            <Modal>
              <div className="modal-header">
                <h2>Add Payment Method</h2>
                <button onClick={closeModal}>X</button>
              </div>
              <div className="modal-body newProductTypeForm">
                <div className='newProductItem' key='Action'>
                  <label>Payment Method</label>
                  <SelectReact
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  value={paymentMethodOptionValue}
                  name="salesPerson"
                  options={paymentMethodOptions}
                  onChange={handlePaymentMethodSelectChange}
                />
                </div>
                <div className="PaymentItem">
                  <label htmlFor="paymentID">Payment ID</label>
                  <input type="text" value={paymentId} placeholder="Payment ID" name="paymentID" id="paymentID" onChange={(e) => setPaymentId(e.target.value)} />
                </div>
                <div className='newProductItem' key="newProductItem">
                  <label>&nbsp;</label>
                  <button type="button" className="SaveProductBtn" id="SaveProductBtn" onClick={handleModalSubmit}>
                    Save
                  </button>
                </div>
                <br />
              </div>
            </Modal>
          )}
          <div id="modal-root"></div>
    </div>
  )
}
