import React, {useEffect, useState} from 'react'
import './productDetailReport.css'
import DatePicker from 'react-datepicker';
import { GlobalPermission, ApiUrl } from '../../../../Global';
import Swal from 'sweetalert2';
import Table from '../../../table/Table';
import ReactDOMServer from 'react-dom/server';
import PrintableTable from '../PrintableTable';

export default function ProductDetailReport() {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [canPermission, setCanPermission] = useState();
    const [flooringProducts, setFlooringProducts] = useState([]);
    const currentDateTime = new Date();

    useEffect(() => {
        const desiredPermissionPageName = 'ProductDetailReport'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);
        setFromDate(currentDateTime);
        setToDate(currentDateTime);
        GetFlooringProductSalesReport();
    }, []);

    function GetFlooringProductSalesReport(){
        fetch(ApiUrl + '/api/Products/GetFlooringProductSalesReport', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          const filtered = data.item2.filter(fp => fp.isDeleted === false);
          const mergedProducts = mergeRowsById(filtered, 'id', 'price');
          console.log(mergedProducts);
          setFlooringProducts(mergedProducts);
        });
    }

    function mergeRowsById(data, idField, columnToSum) {
        const mergedData = data.reduce((result, current) => {
          const existingRow = result.find(row => row[idField] === current[idField]);
          if (existingRow) {
            existingRow[columnToSum] += current[columnToSum];
          } else {
            result.push({ ...current });
          }
          return result;
        }, []);
      
        return mergedData;
    }

    const columns = [
        // { key: 'id', title: 'ID' },
        { key: 'rollNumber', title: 'Roll Number' },
        { key: 'name', title: 'Name' },
        // { key: 'costPerUnit', title: 'CPU' },
        { key: 'totalCost', title: 'Cost' },
        // { key: 'measuredBy', title: 'MeasuredBy' },
        // { key: 'measuredDate', title: 'measuredDate' },
        { key: 'price', title: 'Sold Price' },
        // { key: 'finished', title: 'Finished' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handlePrintClick = () => {
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        const fromMonthYear = `${fromDateObj.getMonth() + 1}/${fromDateObj.getFullYear()}`;
        const toMonthYear = `${toDateObj.getMonth() + 1}/${toDateObj.getFullYear()}`;
        const title = "Flooring Product Detail";

        const printableTableComponent = (
          <PrintableTable data={flooringProducts} columns={columns} title={title} />
        );
    
        const newWindow = window.open("", "_blank");
        newWindow.document.open();
        newWindow.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <title>Sales People</title>
              <link rel="stylesheet" type="text/css" href="printtableTable.css">
            </head>
            <body>${ReactDOMServer.renderToString(printableTableComponent)}</body>
          </html>`);
        newWindow.print();
        newWindow.document.close();
    };

  return (
    <div className='color'>
        <h1>Product Detail Report</h1>

        {canPermission && canPermission.canView ? (
            <>
                <div className="searchContainer">
                    {/* <div className="searchInputContainer">
                        <label className="searchLabel" htmlFor="searchInput">Search:</label>
                        <input
                            id="searchInput"
                            className="searchPrivateInput"
                            type="text"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div> */}
                    <button onClick={handlePrintClick} className="PrintBtn"> Print</button>
                </div>

                <Table
                    data={flooringProducts}
                    columns={columns}
                /> 
            </>
          ) : (
            <></>
        )}

    </div>
  )
}
