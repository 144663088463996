import * as React from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';
import './scheduler.css';
import { Inject, ScheduleComponent, Day, Week, WorkWeek, Month, Agenda } from '@syncfusion/ej2-react-schedule';
import { ApiUrl } from '../../../Global';

const Scheduler = () => {
  const [data, setData] = useState([
    // {
    //   Id: 1,
    //   Subject: 'Meeting - 1',
    //   StartTime: new Date(2023, 11, 27, 10, 0),
    //   EndTime: new Date(2023, 11, 28, 12, 30),
    //   IsAllDay: false
    // },
    // {
    //   Id: 2,
    //   Subject: 'Meeting - 2',
    //   StartTime: new Date(2023, 11, 28, 1, 0),
    //   EndTime: new Date(2023, 11, 28, 1, 30),
    //   IsAllDay: false
    // },
    // {
    // Description: "dddd",
    // EndTime: 'Wed Jan 03 2024 00:00:00 GMT-0500 (Eastern Standard Time)',
    // EndTimezone: null,
    // Id: 4,
    // IsAllDay: true,
    // Location: "dddd",
    // RecurrenceRule: "FREQ=DAILY;INTERVAL=2;UNTIL=20240302T050000Z;",
    // StartTime: 'Tue Jan 02 2024 00:00:00 GMT-0500 (Eastern Standard Time)',
    // StartTimezone: null,
    // Subject: "dddd"
    // },
  ]);
  
  useEffect(() => {
    GetAllSchedulers(data);
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);
  
  
  const eventSettings = { dataSource: data }

  console.log(data);

  // const handleActionBegin = async (args) => {
  //   if (args.requestType === 'eventCreate' && args.data.length > 0) {
  //     // Create event
  //     const addedEvent = args.data[0];
  //     console.log(addedEvent);
  
  //     // Convert date values to ISO 8601 format
  //     const formattedEvent = {
  //       ...addedEvent,
  //       EndTime: new Date(addedEvent.EndTime).toISOString(),
  //       StartTime: new Date(addedEvent.StartTime).toISOString(),
  //       Id: 0
  //     };
  //     console.log(formattedEvent);
  
  //     await fetch(ApiUrl + '/api/Schedulers/Save', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ` + sessionStorage.getItem("token")
  //       },
  //       body: JSON.stringify(formattedEvent)
  //     })
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log(data);
  //       });
  //   }
  //   else if (args.requestType === 'eventRemove' && args.data.length > 0) {
  //     // Delete event
  //     const deletedEvent = args.data[0];
  //     console.log(deletedEvent);
  
  //     const formattedEvent = {
  //       ...deletedEvent,
  //       EndTime: new Date(deletedEvent.EndTime).toISOString(),
  //       StartTime: new Date(deletedEvent.StartTime).toISOString()
  //     };
  
  //     console.log(deletedEvent);
  
  //     await fetch(ApiUrl + '/api/Schedulers/Delete?Id=' + deletedEvent.Id, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ` + sessionStorage.getItem("token")
  //       },
  //       body: JSON.stringify({
  //         id: deletedEvent.Id // Assuming the event object has an 'Id' property
  //       })
  //     })
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log(data);
  //       });
  //   } else if (args.requestType === 'eventChange' && args.data.length > 0) {
  //     // Update event
  //     const updatedEvent = args.data[0];
  //     console.log("update");
  //     console.log(updatedEvent);
  
  //     // Convert date values to ISO 8601 format
  //     const formattedEvent = {
  //       ...updatedEvent,
  //       EndTime: new Date(updatedEvent.EndTime).toISOString(),
  //       StartTime: new Date(updatedEvent.StartTime).toISOString(),
  //       Id: 0
  //     };
  
  //     await fetch(ApiUrl + '/api/Schedulers/Save', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ` + sessionStorage.getItem("token")
  //       },
  //       body: JSON.stringify(formattedEvent)
  //     })
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log(data);
  //       });
  //   }
  // };

  const handleActionBegin = async (args) => {
    const action = args.requestType;
    const moment = require('moment-timezone');
    const event = {
      startTime: '2023-12-27T09:00:00Z', // Assuming the event start time is stored in UTC
      endTime: '2023-12-27T10:30:00Z' // Assuming the event end time is stored in UTC
    };
    const timeZone = 'America/New_York'; // Specify the desired time zone
    
    if (action === 'eventCreate') {
      const addedEvent = args.data[0]; // Get the new event from the args
      console.log(addedEvent);
      console.log("created");
      // Convert date values to ISO 8601 format
      const formattedEvent = {
        ...addedEvent,
        EndTime: new Date(addedEvent.EndTime).toISOString(),
        StartTime: new Date(addedEvent.StartTime).toISOString(),
        Id: 0
      };
      console.log(formattedEvent);
  
      await fetch(ApiUrl + '/api/Schedulers/Save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        },
        body: JSON.stringify(formattedEvent)
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
        });
      // Send API request to create event and update the events state with the response data
      // ...
    } else if (action === 'eventChange') {
      const updatedEvent = args.data; // Get the updated event from the args
      console.log(args.data);
      console.log("updated");
      const formattedEvent = {
        ...updatedEvent,
        EndTime: new Date(updatedEvent.EndTime).toISOString(),
        StartTime: new Date(updatedEvent.StartTime).toISOString(),
      };
      console.log(formattedEvent);
  
      await fetch(ApiUrl + '/api/Schedulers/Save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        },
        body: JSON.stringify(formattedEvent)
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
        });
      // Send API request to update event and update the events state with the response data
      // ...
    } else if (action === 'eventRemove') {
      const deletedEvent = args.data[0]; // Get the deleted event from the args
      console.log(deletedEvent);
      console.log("deleted");
      const formattedEvent = {
        ...deletedEvent,
        EndTime: new Date(deletedEvent.EndTime).toISOString(),
        StartTime: new Date(deletedEvent.StartTime).toISOString()
      };
  
      console.log(deletedEvent);
  
      await fetch(ApiUrl + '/api/Schedulers/Delete?Id=' + deletedEvent.Id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        },
        body: JSON.stringify({
          id: deletedEvent.Id // Assuming the event object has an 'Id' property
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
        });
      // Send API request to delete event and update the events state
      // ...
    }
  };

  function GetAllSchedulers(){
    fetch(ApiUrl + '/api/Schedulers/GetAllSchedulers', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      const transformedData = data.item2.map(item => {
        const formattedData = {
          Id: item.id,
          Subject: item.subject,
          StartTime: new Date(item.startTime),
          EndTime: new Date(item.endTime),
          IsAllDay: item.isAllDay,
          Description: item.description,
          EndTimezone: item.endTimezone,
          Location: item.location,
          RecurrenceRule: item.recurrenceRule,
          StartTimezone: item.startTimezone,
        };
        return formattedData;
      });
  
      console.log(transformedData);
      setData(transformedData);
    });
  }

  return (
    <div className='Scheduler'>
      <ScheduleComponent currentView='Month' selectedDate= {new Date(2023, 11, 27)} eventSettings={eventSettings} actionBegin={handleActionBegin}>
        <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
      </ScheduleComponent>
    </div>
  );
};
export default Scheduler;