import React, { useEffect, useState } from 'react';
import "./salesManChart.css";
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, ResponsiveContainer, PieChart, Pie, Cell, Label } from 'recharts';
import { ApiUrl } from '../../Global';

export default function SalesManChart({ years, roleName }) {
  const [dashboardData, setDashboardData] = useState();
  const data = [
    { name: 'John', sales: 1500, amount: 5000 },
    { name: 'Jane', sales: 2000, amount: 5000 },
    { name: 'Mike', sales: 1000, amount: 5000 },
    // Add more data as needed
  ];
  const [totalAmount, setTotalAmount] = useState(0); 

  useEffect(() => {
    fetch(ApiUrl + '/api/Dashboard/GetSalemanDashboardData?years=' + years.join(','), {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
        console.log(data.item2)
        setDashboardData(data.item2);
        setTotalAmount(data.item2.reduce((sum, entry) => sum + entry.totalAmount, 0));
    });
  }, [years]);

  const formatXAxisTick = (props, lineLength) => {
    const { x, y, payload } = props;
    const name = payload.value;
    const maxCharsPerLine = Math.floor(lineLength / 6); // Assuming an average character width of 6 pixels

    // Split the name into multiple lines based on the maxCharsPerLine
    const lines = [];
    for (let i = 0; i < name.length; i += maxCharsPerLine) {
      lines.push(name.slice(i, i + maxCharsPerLine));
    }

    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <text key={index} x={0} y={index * 14} dy={16} textAnchor="end" fill="#666" fontSize={12}>
            {line}
          </text>
        ))}
      </g>
    );
  };

  const formatYAxisTick = (value) => {
    return Number.isInteger(value) ? value : '';
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Customize the tooltip content based on your requirements
      console.log(payload);
      return (
        <div className="custom-tooltip">
          <p>{`${payload[0].name}: $${payload[0].value.toLocaleString()}`}</p>
          <p>{`%${((payload[0].value*100)/totalAmount).toFixed(2)}`}</p>
        </div>
      );
    }
  
    return null;
  };

  const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    const dataEntry = dashboardData[index];
    console.log((dataEntry.totalAmount*100)/totalAmount);
    return (
      <text
        x={x}
        y={y}
        dy={8}
        textAnchor={x > cx ? 'start' : 'end'}
        fill="#8884d8"
        fontSize={13}
      >
        {dataEntry && ((dataEntry.totalAmount*100)/totalAmount).toFixed(2)}%
      </text>
    );
  };

  return (
    <>
      {(roleName === "admin" || roleName === "Sales") && (
        <div className="salesManChart">
          <h3>Sales Statistic</h3>
            <div className="salecharts">
            <ResponsiveContainer width="45%" height={300}>
              <BarChart data={dashboardData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="fullName"
                  angle={-45}
                  interval={0}
                  tick={(props) => formatXAxisTick(props, 70)} // Adjust the lineLength parameter as needed
                  height={40}
                />
                <YAxis tickFormatter={formatYAxisTick} />
                <Tooltip formatter={(value) => new Intl.NumberFormat().format(value)} />
                <Bar dataKey="invoiceCount" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
            <ResponsiveContainer width="45%" height={300}>
              <PieChart>
                <Pie
                  data={dashboardData}
                  dataKey="totalAmount"
                  nameKey="fullName"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  label={(props) => <CustomLabel {...props} />} // Use the CustomLabel component as the label
                >
                  {dashboardData &&
                    dashboardData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                      />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
          
        </div>
      )}
    </>
  );
}