import React, {useState, useEffect} from 'react'
import './productCategory.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function ProductCategory() {
    const [productCategories, setProductCategories] = useState([]);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    const [isNameValid, setIsNameValid] = useState(true);
    const [isCodeValid, setIsCodeValid] = useState(true);

    useEffect(() => {
        const desiredPermissionPageName = 'ProductCategory'; // Replace with the desired permissionPageName value
      
        const desiredRow = GlobalPermission.find(
          (item) => item.permissionPageName === desiredPermissionPageName
        );
      
        setCanPermission(desiredRow);
        GetAllProductCategories();
    }, []);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'code', title: 'Code' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
        // Perform edit action using the ID of the row being edited
        const filtered = productCategories.filter(item => item.id === id);
        setName(filtered[0].name);
        setId(filtered[0].id);
        setCode(filtered[0].code)
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/ProductCategories/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllProductCategories();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() === '') {
          if(name.trim() === '')
            setIsNameValid(false);
          else
            setIsNameValid(true);
          return;
        }
        else{
          setIsNameValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/ProductCategories/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    Name: name,
                    Code: code,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllProductCategories();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsNameValid(false);
                setIsCodeValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setName('');
        setCode('');
        setIsNameValid(true);
        setIsCodeValid(true);
    }

    function GetAllProductCategories(){
      fetch(ApiUrl + '/api/ProductCategories/GetAllProductCategories', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        setProductCategories(data.item2);
      });
    }

    const handleUndo = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/ProductCategories/Undo?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllProductCategories();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

  return (
    <div className='productCategory'>
        <h1>ProductCategory</h1>

        {canPermission && canPermission.canEdit ? (
        <form className="ColorForm" onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div className="ColorItem">
                <label htmlFor="name">Name:</label>
                <input type="text" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} placeholder="Name" name="name" id="name" onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="ColorItem">
                <label htmlFor="code">Code:</label>
                <input type="text" className={!isCodeValid ? 'InvalidInputtext' : 'validInputtext'} value={code} placeholder="Code" name="code" id="code" onChange={(e) => setCode(e.target.value)} />
            </div>
            <div className='ColorItem'>
                <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
                <button type="submit" className='saveColorBtn' >{id == '0' ? 'Create' : 'Save'}</button>
            </div>
            </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
            <Table
                data={productCategories}
                columns={columns}
                onDelete={handleDelete}
                onEdit={handleEdit}
                canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                onUndo={handleUndo}
            /> 
            ) : (
                <></>
        )}
    </div>
  )
}
