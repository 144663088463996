import React, {useState, useEffect} from 'react'
import './priceListTable.css';
import { DeleteOutline, Replay } from '@mui/icons-material';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { ApiUrl } from '../../Global';
import Swal from 'sweetalert2';

export default function PriceListTable({ data, columns, onPriceChange, restcurrentpage}) {
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const rowsPerPage = 20; // Number of rows to display per page
    const idColumnIndex = columns.findIndex((column) => column.key === "id");

    const offset = currentPage * rowsPerPage ;
    const pageCount = Math.ceil(data.length / rowsPerPage);
    const currentPageData = data.slice(offset, offset + rowsPerPage);

    const handlePriceChange = (categoryId, styleId, colorId, currencyId, price) => {
        // console.log( categoryId + "-" + styleId + "-" + colorId + "-" + price);
        onPriceChange(categoryId, styleId, colorId, currencyId, parseFloat(price));
    };

    useEffect(() => {
        if(restcurrentpage === true){
          setCurrentPage(0); // Set the currentPage to 0 (first page) when the data prop changes
        }
        console.log(data);
      }, [data]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

  return (
    <div className="table-container">
        <table>
            <thead>
                <tr>
                    {columns.map((column) => {
                    if (column.key !== "id") {
                        return <th key={column.key}>{column.title}</th>;
                    }
                    return null; // Render nothing for the Id column
                    })}
                </tr>
            </thead>
            <tbody>
                {currentPageData.map((row) => (
                    <tr key={row.id}>
                    {columns.map((column) => {
                        const showPopup = true;
                        if (column.key === "createdDate") {
                            const formattedDate = formatDate(row[column.key]);
                            return <td key={`${row.id}-${column.key}`}>{formattedDate}</td>;
                        }
                        if (column.key === "minimumPricePerUnit") {
                        return (
                            <td key={`${row.id}-${column.key}`}>
                            <input
                                type="number"
                                className='Default-Input'
                                value={row.minimumPricePerUnit}
                                onChange={(event) => handlePriceChange(row.categoryId, row.styleId, row.colorId, row.currencyId ,event.target.value)}
                                min={0}
                            />
                            </td>
                        );
                        }
                        if (column.key !== "id") {
                        
                            if (row[column.key] === true) {
                                return <td key={`${row.id}-${column.key}`}>
                                <input
                                type="checkbox"
                                checked={row[column.key]}
                                disabled
                                />
                            </td>
                            }
                            else{
                                if (row[column.key] === false) {
                                return <td key={`${row.id}-${column.key}`}>
                                <input
                                    type="checkbox"
                                    checked={row[column.key]}
                                    disabled
                                />
                                </td>
                                }
                                return <td key={`${row.id}-${column.key}`}>{row[column.key]}</td>;
                            }
                        }
                        return null; // Render nothing for the Id column
                    })}
                    </tr>
                ))}
            </tbody>
        </table>
        
        
        <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={pageCount}
            onPageChange={({ selected }) => setCurrentPage(selected)}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPage} // Set the current page for ReactPaginate
        />
    </div>
  )
}