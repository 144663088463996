import React, { useEffect, useState} from 'react'
import "./paymentsDetails.css"
import { GlobalPermission, ApiUrl } from '../../../../Global';
import DatePicker from 'react-datepicker';
import SelectReact from 'react-select';
import Swal from 'sweetalert2';
import Table from '../../../table/Table';
import PrintableTable from '../PrintableTable';
import ReactDOMServer from 'react-dom/server';

export default function PaymentsDetails() {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [withCash, setWithCash] = useState(false);
    const [canPermission, setCanPermission] = useState();
    const [isToDateValid, setIsToDateValid] = useState(true);
    const [isFromDateValid, setIsFromDateValid] = useState(true);
    const [companies, setCompanies] = useState([]);
    const [companyId, setCompanyId] = useState(0);
    const [companyOptionValue, setCompanyOptionValue] = useState();
    const [calculatedData, setCalculatedData] = useState([]);
    const [tax, setTax] = useState([]);
    const currentDateTime = new Date();

    useEffect(() => {
        const desiredPermissionPageName = 'InvoicesReport'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);
        setFromDate(currentDateTime);
        setToDate(currentDateTime);
        GetAllCompanies();
    }, []);

    const columns = [
      // { key: 'id', title: 'ID' },
      { key: 'paymentMethodName', title: 'Payment Method' },
      { key: 'totalPaymentAmount', title: 'Total' },
    ];

    function GetAllCompanies(){
      fetch(ApiUrl + '/api/Companies/GetAllCompanies', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        console.log(data.item2);
        const filteredData = data.item2;
        setCompanies(filteredData);
        setCompanyOptionValue({value: filteredData[0].id, label: (filteredData[0].name)});
        setCompanyId(filteredData[0].id);
      });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(fromDate);
        if (fromDate === null || toDate === null ) {
          if(fromDate === null)
            setIsFromDateValid(false);
          else
            setIsFromDateValid(true);
          if(toDate === null)
            setIsToDateValid(false);
          else
            setIsToDateValid(true);
          return;
        }
        else{
            setIsFromDateValid(true);
            setIsToDateValid(true);
        }
        try {
          console.log(withCash);
          const response = await fetch( ApiUrl +'/api/InvoicePayments/GetPaymentsDetail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
            },
            body: JSON.stringify({
                WithCash: withCash,
                FromDate: fromDate,
                ToDate: toDate,
                CompanyId: companyId,
            })
          });
          const data = await response.json();
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                const filteredData = data.item2.filter((item) => item.paymentMethodName !== 'Tax');
                const taxAmount = data.item2.filter((item) => item.paymentMethodName === 'Tax');
                // console.log(taxAmount)
                setTax(taxAmount);
                setCalculatedData(filteredData);
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              // setIsColorNameValid(false);
              // setIsColorHexCodeValid(false);
            });
          }
        } catch (error) {
            console.error(error);
        }
    }

  const CompanyOptions = companies.map((company) => ({
    value: company.id,
    label: company.name,
  }));
  const handleCompanyChange = (selectedOption) => {
    console.log(selectedOption);
    setCompanyOptionValue(selectedOption);
    setCompanyId(selectedOption.value);
  };

  const handlePrintClick = () => {
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);
    console.log(companyOptionValue);

    const printableTableComponent = (
      <PrintableTable data={calculatedData} columns={columns} title={"Payments Detial for " + companyOptionValue.label } />
    );
    const printableTableComponent1 = (
      <PrintableTable data={tax} columns={columns} title={"Taxes"} />
    );

    const newWindow = window.open("", "_blank");
    newWindow.document.open();
    newWindow.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <title>Sales People</title>
          <link rel="stylesheet" type="text/css" href="printtableTable.css">
        </head>
        <body>${ReactDOMServer.renderToString(printableTableComponent)}${ReactDOMServer.renderToString(printableTableComponent1)}</body>
      </html>`);
    newWindow.print();
    newWindow.document.close();
  };

  const handleFromDateChange = (date) => {
    if (toDate && date > toDate) {
      // setIsFromDateValid(false);
    } else {
      setFromDate(date);
      // setIsFromDateValid(true);
    }
  };

  const handleToDateChange = (date) => {
    if (fromDate && date < fromDate) {
      // setIsToDateValid(false);
    } else {
      setToDate(date);
      // setIsToDateValid(true);
    }
  };

  return (
    <div className='paymentDetails'>
        <h1>Payments Detail</h1>
        <form className="newInvoicesReportForm" onSubmit={handleSubmit} >
            <div className="invoiceReportForm">
                <div className='invoiceReportItem'>
                <label htmlFor="company">Company:</label>
                  <SelectReact
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    isSearchable={true}
                    value={companyOptionValue}
                    name="company"
                    options={CompanyOptions}
                    onChange={handleCompanyChange}
                  />
                </div>
                <div className="invoiceReportItem">
                    <label htmlFor="fromDate">From Date:</label>
                    <DatePicker
                        selected={fromDate}
                        onChange={handleFromDateChange}
                        dateFormat="MM/yyyy"
                        placeholderText="Select Date"
                        id="fromDate"
                        name="fromDate"
                        className={!isFromDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthYearPicker
                        scrollableMonthYearDropdown
                    />
                </div>
                <div className="invoiceReportItem">
                    <label htmlFor="fromDate">To Date:</label>
                    <DatePicker
                        selected={toDate}
                        onChange={handleToDateChange}
                        dateFormat="MM/yyyy"
                        placeholderText="Select Date"
                        id="toDate"
                        name="toDate"
                        className={!isToDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthYearPicker
                        scrollableMonthYearDropdown
                    />
                </div>
                <div className='newItemCheckBox'>
                  <label htmlFor="">&nbsp;</label>
                  <div className='newSubItem'>
                    <input type="checkbox" name='hasUnit' id='hasUnit' checked={withCash} onChange={(e) => setWithCash(e.target.checked)} />
                    <label htmlFor="hasUnit">With Cash</label>
                  </div>
                </div>
                <div className='invoiceReportItem'>
                    <label htmlFor="fromDate">&nbsp;</label>
                    <button type="submit" className='InvoicesReportSubmitBtn' >Calculate</button>
                </div>
            </div>
        </form>
        {canPermission && canPermission.canView ? (
            <>
                <div className="searchContainer">
                    {/* <div className="searchInputContainer">
                        <label className="searchLabel" htmlFor="searchInput">Search:</label>
                        <input
                            id="searchInput"
                            className="searchPrivateInput"
                            type="text"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div> */}
                    <button onClick={handlePrintClick} className="PrintBtn"> Print</button>
                </div>

                <Table
                    data={calculatedData}
                    columns={columns}
                /> 

                <br />
                <div>
                  <h2>Taxes</h2>
                  <Table
                    data={tax}
                    columns={columns}
                  /> 
                </div>
            </>
          ) : (
            <></>
        )}
    </div>
  )
}
