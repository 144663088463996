import React, { useState,useEffect } from 'react'
import './role.css'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ApiUrl } from '../../../Global';
import { DeleteOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';


export default function 
() {
  const [roles, setRoles] = useState([]);
  const [rolename, setRolename] = useState('');
  const [id, setId] = useState('0');

  useEffect(() => {
    fetch(ApiUrl + '/api/Role/GetRoles', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      setRoles(data);
    });
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(ApiUrl + '/api/Role/DeleteRole?Id=' + id, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      });
      const text = await response.text();
      console.log('response text:', text);
      const data = JSON.parse(text);
      if (response.ok) {
        window.location.reload();
      } else {
        console.error(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleEdit = async (id, name) => {
    try {
      console.log(id + " / " + name);
      setId(id);
      setRolename(name);
    } catch (error) {
      console.error(error);
    }
  }

  const handlePermission = async (id) => {
    try {
      console.log(id + " / ");
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await fetch( ApiUrl +'/api/Role/SaveRole', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
            },
            body: JSON.stringify({
                id: id,
                Name: rolename,
            })
        });
        const data = await response.json();
        if (response.ok) {
            window.location.reload();
            console.log(data);
        } else {
            console.error(data);
        }
    } catch (error) {
        console.error(error);
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'name', headerName: 'Name', width: 200 },
    {
      field: 'action',
      headerName: 'Action',
      width: 250,
      renderCell: (params) => {
        return(
          <>
            <button className='roleListEdit' onClick={() => handleEdit(params.row.id, params.row.name)}>Edit</button>
            <DeleteOutline className='roleListDelete' onClick={() => handleDelete(params.row.id)} />
            <Link to={"/rolePermissions/" + params.row.id}>
              <button className='roleListPermission'>Permission</button>
            </Link>
          </>
        )
      }
    },
  ];

  return (
    <div className='role'>
      <h1>Roles</h1>
      <form className="newUserForm" onSubmit={handleSubmit} >
        <div className="form-group">
          <label htmlFor="roleName">Role:</label>
          <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
          <input type="text" className='roleName' value={rolename} placeholder="" name="roleName" id="roleName" onChange={(e) => setRolename(e.target.value)} />
          <button type='button' className="newBtn" id="newBtn" onClick={() => { setId("0"); setRolename(""); }}>New</button>
          <button type="submit" className='saveBtn' >{id == '0' ? 'Create' : 'Save'}</button>
        </div>
      </form>
      <br />
        <DataGrid
            rows={roles}
            columns={columns}
            disableRowSelectionOnClick
            pageSize = {8}
            initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 5 },
            },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
        />
    </div>
  )
}
