import React, { useEffect, useState } from 'react'
import './supplier.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';
import PrintableTable from '../reports/PrintableTable';
import ReactDOMServer from 'react-dom/server';
import SupplierReport from '../reports/supplierReport/SupplierReport';

export default function Supplier() {
    const [name, setName] = useState('');
    const [id, setId] = useState(0);
    const [accountNumber, setAccountNumber] = useState('')
    const [data, setData] = useState([]);
    const [supplier, setSupplier] = useState();
    const [supplierSales, setSupplierSales] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [canPermission, setCanPermission] = useState();
    const [isSupplierNameValid, setIsSupplierNameValid] = useState(true);
    const [isSupplierAccountValid, setIsSupplierAccountValid] = useState(true);

    useEffect(() => {
      const desiredPermissionPageName = 'Suppliers'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
    
      setCanPermission(desiredRow);
    
      GetAllSuppliers();
      SupplierSalesPeopleReport();
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(canPermission);
        if (name.trim() === '' || accountNumber.trim() === '' ) {
          if(name.trim() === '')
            setIsSupplierNameValid(false);
          else
            setIsSupplierNameValid(true);
          if(accountNumber.trim() === '')
            setIsSupplierAccountValid(false);
          else
            setIsSupplierAccountValid(true);
          return;
      }
      else{
        setIsSupplierNameValid(true);
        setIsSupplierAccountValid(true);
      }
        try {
            const response = await fetch( ApiUrl +'/api/Suppliers/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    Name: name,
                    AccountNumber: accountNumber
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllSuppliers();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                  setIsSupplierNameValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setAccountNumber('');
        setName('');
        setIsSupplierNameValid(true);
        setIsSupplierAccountValid(true);
    }

    // const data = [
    //     { id: 1, name: 'John Doe', accountNumber: 'john@example.com', age: 25 },
    //     { id: 2, name: 'Jane Smith', accountNumber: 'jane@example.com', age: 30 },
    // ];
    
    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'accountNumber', title: 'Account Number' },
    ];

    function GetAllSuppliers(){
        fetch(ApiUrl + '/api/Suppliers/GetAllSuppliers', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
              //console.log(data)
              setData(data.item2);
        });
    }

    function SupplierSalesPeopleReport(){
        fetch(ApiUrl + '/api/Suppliers/SupplierSalesPeopleReport', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(datasale => {
              //console.log(datasale)
              setSupplierSales(datasale.item2);
        });
    }
    
    const handleDelete = async (id) => {
        // Perform delete action using the selected row IDs
        console.log(id)
        try {
            const response = await fetch(ApiUrl + '/api/Suppliers/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
              Swal.fire({
                  icon: 'success',
                  title: 'Supplier has been Deleted!',
                  showConfirmButton: false,
                  timer: 2000
                }).then(() => {
                  clearTextBoxes();
                  GetAllSuppliers();
              });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    console.error(data);
                });
            }
        } catch (error) {
            console.error(error);
        }
    };
    
    const handleEdit = (id) => {
        // Perform edit action using the ID of the row being edited
        const filtered = data.filter(item => item.id === id);
        setSupplier(filtered);
        setName(filtered[0].name);
        setId(filtered[0].id);
        setAccountNumber(filtered[0].accountNumber)
        // console.log(filtered);
    };

    const columnsPrint = [
        { key: 'name', title: 'Name' },
        { key: 'accountNumber', title: 'Account Number' },
        { key: 'salesPersonName', title: 'salesPersonName' },
        { key: 'salesPersonPhoneNumber', title: 'salesPersonPhoneNumber' },
    ];

    const handlePrintClick = () => {
        let titleForPrint = "";
        if (typeof supplier === "undefined" || supplier === null || supplier === "") {
            titleForPrint = "Sales Person";
        } else {
            titleForPrint = "Sales Person For " + data[0].supplierName;
        }

        const mergedData = Object.values(supplierSales.reduce((acc, obj) => {
            const { id, ...rest } = obj;
            if (acc[id]) {
              Object.keys(rest).forEach((key) => {
                if (acc[id][key] && acc[id][key] !== rest[key]) {
                  acc[id][key] += `, ${rest[key]}`;
                } else {
                  acc[id][key] = rest[key];
                }
              });
            } else {
              acc[id] = { ...obj };
            }
            return acc;
          }, {}));
         console.log(mergedData);

        const printableTableComponent = (
          <SupplierReport data={mergedData} columns={columnsPrint} title={titleForPrint} />
        );
    
        const newWindow = window.open("", "_blank");
        newWindow.document.open();
        newWindow.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <title>Sales People</title>
              <link rel="stylesheet" type="text/css" href="printtableTable.css">
            </head>
            <body>${ReactDOMServer.renderToString(printableTableComponent)}</body>
          </html>`);
        newWindow.print();
        newWindow.document.close();
    };

    const searchResults = data.filter((supplier) =>
        supplier.name.toLowerCase().includes(searchText.toLowerCase())
    );


    const handleUndo = async (id) => {
      try {
        const response = await fetch(ApiUrl + '/api/Suppliers/Undo?Id=' + id, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              clearTextBoxes();
              GetAllSuppliers();
          });
        } else {
          console.error(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

  return (
    <div className='supplier'>
        <h1>Supplier</h1>
        {canPermission && canPermission.canEdit ? (
          <form className="newSupplierForm" onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div className="newSupplierItem">
              <label>Supplier Name</label>
              <input type="text" placeholder="Supplier Name" name="suppliername" id="suppliername" className={!isSupplierNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="newSupplierItem">
              <label>Account Number</label>
              <input type="text" placeholder="Account Number" name="accountNumber" id="accountNumber" className={!isSupplierAccountValid ? 'InvalidInputtext' : 'validInputtext'} value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
            </div>
            <div className='newSupplierItem'>
              <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
              <button type="submit" className='saveSupplierBtn'>{id === '0' ? 'Create' : 'Save'}</button>
            </div>
          </form>
          ) : (
          <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
        <>
        <div className="searchContainer">
            <div className="searchInputContainer">
                <label className="searchLabel" htmlFor="searchInput">Search:</label>
                <input
                id="searchInput"
                className="searchSupplierInput"
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <button onClick={handlePrintClick} className="PrintBtn"> Print</button>
        </div>
        <Table
            data={searchResults}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            addedBtn="Sales People"
            canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
            canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
            onUndo={handleUndo}
        /> 
        </>
         ) : (
          <></>
        )}
    </div>
  )
}
