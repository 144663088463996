import React, {useState, useEffect} from 'react'
import './department.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';
import SelectReact from 'react-select';

export default function Department() {

    const [departments, setDepartments] = useState([]);
    const [name, setName] = useState('');
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    const [isNameValid, setIsNameValid] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [managerId, setManagerId] = useState(0);
    const [managerOptionValues, setManagerOptionValues] = useState();

    useEffect(() => {
      const desiredPermissionPageName = 'Department'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
      GetAllEmployees();
      setCanPermission(desiredRow);
      GetAllDepartments();
    }, []);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    function GetAllEmployees(){
      fetch(ApiUrl + '/api/Employees/GetAllEmployees', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        setEmployees(data.item2);
        setManagerId(data.item2[0].id)
        setManagerOptionValues({value: data.item2[0].id, label: (data.item2[0].firstName + " " + data.item2[0].middleName + " " + data.item2[0].lastName)})
      });
  }

    const handleEdit = (id) => {
      // Perform edit action using the ID of the row being edited
      const filtered = departments.filter(item => item.id === id);
      setName(filtered[0].name);
      setId(filtered[0].id);
      setManagerId(filtered[0].managerId);
      const filteredEmployee = employees.filter(employee => employee.id === filtered[0].managerId);
      setManagerOptionValues({value: filtered[0].managerId, label: (filteredEmployee[0].firstName + " " + filteredEmployee[0].middleName + " " + filteredEmployee[0].lastName)})
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/Departments/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllDepartments();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() === '') {
          if(name.trim() === '')
            setIsNameValid(false);
          else
            setIsNameValid(true);
          return;
        }
        else{
            setIsNameValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/Departments/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    Name: name,
                    ManagerId: managerId,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllDepartments();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsNameValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setName('');
        setManagerId(employees[0].id);
        setManagerOptionValues({value:employees[0].id, label: (employees[0].firstName + " " + employees[0].middleName + " " + employees[0].lastName)})
        setIsNameValid(true);
    }

    function GetAllDepartments(){
      fetch(ApiUrl + '/api/Departments/GetAllDepartments', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        setDepartments(data.item2);
      });
    }

    const handleUndo = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/Departments/Undo?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllDepartments();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const employeesOption = employees.map((employee) => ({
      value: employee.id,
      label: employee.firstName + " " + employee.middleName + " " + employee.lastName,
    }));
    const handleEmployeeSelectChange = (selectedOptions) => {
        setManagerOptionValues(selectedOptions);
        setManagerId(selectedOptions.value);
    };

  return (
    <div className='department'>
        <h1>Department</h1>
        {canPermission && canPermission.canEdit ? (
            <form className="DepartmentForm" onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
                <div className="DepartmentItem">
                    <label htmlFor="colorName">Department:</label>
                    <input type="text" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} placeholder="Name" name="name" id="name" onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="DepartmentItem">
                    <label htmlFor="manager">Manager</label>
                    <SelectReact
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        value={managerOptionValues}
                        name="manager"
                        options={employeesOption}
                        onChange={handleEmployeeSelectChange}
                    />
                </div>
                <div className='DepartmentItem'>
                    <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
                    <button type="submit" className='saveDepartmentBtn' >{id == '0' ? 'Create' : 'Save'}</button>
                </div>
            </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
            <Table
                data={departments}
                columns={columns}
                onDelete={handleDelete}
                onEdit={handleEdit}
                canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                onUndo={handleUndo}
            /> 
            ) : (
                <></>
        )}
    </div>
  )
}
