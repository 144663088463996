import React, {useState, useEffect} from 'react'
import './installer.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function Installer() {
    const [installers, setInstallers] = useState([]);
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    const [isFullNameValid, setIsFullNameValid] = useState(true);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

    useEffect(() => {
      const desiredPermissionPageName = 'Installer'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
    
      setCanPermission(desiredRow);
      GetAllInstallers();
    }, []);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'fullName', title: 'Name' },
        { key: 'phoneNumber', title: 'Phone' },
        { key: 'active', title: 'Active' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
      // Perform edit action using the ID of the row being edited
      const filtered = installers.filter(item => item.id === id);
      setFullName(filtered[0].fullName);
      setId(filtered[0].id);
      setPhoneNumber(filtered[0].phoneNumber)
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/Installers/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllInstallers();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (fullName.trim() === '' || phoneNumber.trim() === '' ) {
          if(fullName.trim() === '')
            setIsFullNameValid(false);
          else
            setIsFullNameValid(true);
          if(phoneNumber.trim() === '')
            setIsPhoneNumberValid(false);
          else
            setIsPhoneNumberValid(true);
          return;
        }
        else{
            setIsFullNameValid(true);
            setIsPhoneNumberValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/Installers/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    FullName: fullName,
                    PhoneNumber: phoneNumber,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllInstallers();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsFullNameValid(false);
                setIsPhoneNumberValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setFullName('');
        setPhoneNumber('');
        setIsFullNameValid(true);
        setIsPhoneNumberValid(true);
    }

    function GetAllInstallers(){
      fetch(ApiUrl + '/api/Installers/GetAllInstallers', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        setInstallers(data.item2);
      });
    }

    const handleUndo = async (id) => {
      try {
        const response = await fetch(ApiUrl + '/api/Installers/Undo?Id=' + id, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              clearTextBoxes();
              GetAllInstallers();
          });
        } else {
          console.error(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    const onCheckBoxChange = async (rowId) => {
        console.log(rowId);
          try {
            const response = await fetch(ApiUrl + '/api/Installers/ChangeActive?Id=' + rowId, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            //console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
              Swal.fire({
                  icon: 'success',
                  title: data.message,
                  showConfirmButton: false,
                  timer: 2000
                }).then(() => {
                    GetAllInstallers();
              });
            } else {
              console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const formatPhoneNumber = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
    
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match the desired format
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`; // Apply the format (XXX) XXX-XXXX
        }
    
        return cleaned; // Return the input as is if it doesn't match the desired format
    };
    
  return (
    <div className='installer'>
        <h1>Installer</h1>
        {canPermission && canPermission.canEdit ? (
            <form className="ColorForm" onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
                <div className="ColorItem">
                    <label htmlFor="fullName">Full Name:</label>
                    <input type="text" className={!isFullNameValid ? 'InvalidInputtext' : 'validInputtext'} value={fullName} placeholder="FullName" name="fullName" id="fullName" onChange={(e) => setFullName(e.target.value)} />
                </div>
                <div className="ColorItem">
                    <label htmlFor="phone">Phone:</label>
                    <input type="text" className={!isPhoneNumberValid ? 'InvalidInputtext' : 'validInputtext'} value={phoneNumber} placeholder="Phone" name="phone" id="phone" onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))} />
                </div>
                <div className='ColorItem'>
                    <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
                    <button type="submit" className='saveInstallerBtn' >{id == '0' ? 'Create' : 'Save'}</button>
                </div>
            </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
            <Table
                data={installers}
                columns={columns}
                onDelete={handleDelete}
                onEdit={handleEdit}
                canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                onUndo={handleUndo}
                onCheckBoxChange={onCheckBoxChange}
            /> 
            ) : (
                <></>
        )}
    </div>
  )
}
