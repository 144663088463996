import React, {useState, useEffect} from 'react'
import './saveEmployee.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import SelectReact from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';

export default function SaveEmployee() {
    const { employee } = useParams();
    const [employees, setEmployees] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('M');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [nationalities, setNationalities] = useState([]);
    const [nationalityId, setNationalityId] = useState(0);
    const [employeeStatuses, setEmployeeStatuses] = useState([]);
    const [employeeStatusId, setEmployeeStatusId] = useState(0);
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [hiredDate, setHiredDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [emergencyName, setEmergencyName] = useState('');
    const [emergencyPhone, setEmergencyPhone] = useState('');
    const [roles, setRoles] = useState([]);
    const [roleId, setRoleId] = useState(0);
    const [departments, setDepartments] = useState([]);
    const [departmentId, setdepartmentId] = useState(0);
    const [active, setActive] = useState(true);
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    const [isFirstNameValid, setIsFirstNameValid] = useState(true);
    const [isMiddleNameValid, setIsMiddleNameValid] = useState(true);
    const [isLastNameValid, setIsLastNameValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [genderOptionValue, setGenderOptionValue] = useState();
    const [nationalityOptionValue, setNationalityOptionValue] = useState();
    const [employeeStatusOptionValue, setEmployeeStatusOptionValue] = useState();
    const [roleOptionValue, setRoleOptionValue] = useState();
    const [departmentOptionValue, setDepartmentOptionValue] = useState();
    const [educationalBackgrounds, setEducationalBackgrounds] = useState([]);
    const [educationalBackgroundOptionValues, setEducationalBackgroundOptionValues] = useState([]);
    const [educationalBackgroundIds, setEducationalBackgroundIds] = useState([]);
    const [roleName, setRoleName] = useState('');
    const [educationalBackgroundIdsArray, setEducationalBackgroundIdsArray] = useState([]);
    const [supervisorId, setsupervisorId] = useState(0);
    const [supervisorOptionValue, setSupervisorOptionValue] = useState();
    const [phoneNumberFormatValid, SetPhoneNumberFormatValid] = useState(true);
    const [isDateOfBirthValid, setIsDateOfBirthValid] = useState(true);
    const [isStartDateValid, setIsStartDateValid] = useState(true);
    const [isHiredDateValid, setIsHiredDateValid] = useState(true);

    useEffect(() => {
        const desiredPermissionPageName = 'Employee'; // Replace with the desired permissionPageName value
      
        const desiredRow = GlobalPermission.find(
          (item) => item.permissionPageName === desiredPermissionPageName
        );
      
        const fetchData = async () => {
          await GetAllEducationalBackgrounds();
          setGenderOptionValue({ value: 'M', label: 'Male' });
          setCanPermission(desiredRow);
      
            await GetAllGlobalEmployees();
      
          // Perform operations that depend on educationalBackgrounds
          const ArrayToPushEducation = [];
          educationalBackgroundIdsArray.forEach((id) => {
            const findEducation = educationalBackgrounds.find((x) => x.id === id);//
            if (findEducation) {
              ArrayToPushEducation.push({ value: id, label: findEducation.degree });
            }
          });
        //   console.log(ArrayToPushEducation);
          setEducationalBackgroundOptionValues(ArrayToPushEducation);
      
          GetAllGlobalNationalities();
          GetAllEmploymentStatuses();
          GetAllRoles();
          GetAllDepartments();
        };
      
        fetchData();
    }, []);

    useEffect(() => {
        const ArrayToPushEducation = [];
        educationalBackgroundIdsArray.forEach((id) => {
        const findEducation = educationalBackgrounds.find((x) => x.id === id);//
        if (findEducation) {
            ArrayToPushEducation.push({ value: id, label: findEducation.degree });
        }
        });
        // console.log(ArrayToPushEducation);
        setEducationalBackgroundOptionValues(ArrayToPushEducation);
    }, [educationalBackgroundIdsArray]);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'fullName', title: 'Full Name' },
        { key: 'dateofbirth', title: 'D.O.B' },
        { key: 'hiredDate', title: 'Hired Date' },
        { key: 'active', title: 'Active' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    function GetAllGlobalEmployees(){
        fetch(ApiUrl + '/api/Employees/GetAllGlobalEmployees', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
            if (typeof employee === "undefined" || employee === null || employee === "") {
                console.log(data.item2);
                setEmployees(data.item2);
                setsupervisorId(data.item2[0].id);
                setSupervisorOptionValue({value: data.item2[0].id, label: (data.item2[0].firstName + " " + data.item2[0].middleName + " " + data.item2[0].lastName )})
            } else {
                const filteredData = data.item2.filter((item) => parseInt(item.id) === parseInt(employee));
                const filteredEmployeeCombobox = data.item2.filter((item) => parseInt(item.id) !== parseInt(employee));
                setEmployees(data.item2.filter((item) => parseInt(item.id) !== parseInt(employee)));
                // console.log(filteredData);
                setId(filteredData[0].id);
                setFirstName(filteredData[0].firstName);
                setMiddleName(filteredData[0].middleName);
                setLastName(filteredData[0].lastName);
                setEmail(filteredData[0].email);
                setPhoneNumber(filteredData[0].phoneNumber);
                setEmergencyName(filteredData[0].emergencyName);
                setEmergencyPhone(filteredData[0].emergencyPhone);
                setNationalityId(filteredData[0].nationalityId)
                setNationalityOptionValue({value: filteredData[0].nationalityId, label: ( filteredData[0].nationalityName) });
                setEmployeeStatusId(filteredData[0].employeeStatusId);
                setEmployeeStatusOptionValue({value: filteredData[0].employeeStatusId, label: ( filteredData[0].employeeStatusName) });
                setRoleId(filteredData[0].roleId);
                setRoleName(filteredData[0].roleName);
                setRoleOptionValue({value: filteredData[0].roleId, label: ( filteredData[0].roleName) });
                setdepartmentId(filteredData[0].departmentId);
                setDepartmentOptionValue({value: filteredData[0].departmentId, label: ( filteredData[0].departmentName) });
                const dobString = filteredData[0].dateOfBirth;
                const dobDate = new Date(dobString);
                setDateOfBirth(dobDate);
                const hiredString = filteredData[0].hiredDate;
                const hiredDate = new Date(hiredString);
                setHiredDate(hiredDate);
                const startDateString = filteredData[0].startDate;
                const startDateDate = new Date(startDateString);
                setStartDate(startDateDate);
                if(filteredData[0].endDate !== null){
                    const endDateString = filteredData[0].endDate;
                    const endDateDate = new Date(endDateString);
                    setEndDate(endDateDate);
                }
                if(filteredData[0].educationalBackgroundIds !== 'undefined' && filteredData[0].educationalBackgroundIds !== null && filteredData[0].educationalBackgroundIds !== "" ){
                    const educationalBackgroundIdsArray = filteredData[0].educationalBackgroundIds.split(',').map(Number); 
                    setEducationalBackgroundIdsArray(educationalBackgroundIdsArray);
                    setEducationalBackgroundIds(educationalBackgroundIdsArray);
                }
                if(typeof filteredData[0].supervisorId === "undefined" && filteredData[0].supervisorId === null && filteredData[0].supervisorId === ""){
                    setsupervisorId(filteredData[0].supervisorId);
                    setSupervisorOptionValue({value: filteredData[0].id, label: (filteredData[0].firstName + " " + filteredData[0].middleName + " " + filteredData[0].lastName )})
                }
                else{
                    setsupervisorId(filteredEmployeeCombobox[0].id);
                    setSupervisorOptionValue({value: filteredEmployeeCombobox[0].id, label: (filteredEmployeeCombobox[0].firstName + " " + filteredEmployeeCombobox[0].middleName + " " + filteredEmployeeCombobox[0].lastName )})
                }
                
                setIsFirstNameValid(true);
                setIsMiddleNameValid(true);
                setIsLastNameValid(true);
                setIsEmailValid(true);
                setIsPhoneNumberValid(true);
            }
        });
    }
    function GetAllGlobalNationalities(){
        fetch(ApiUrl + '/api/Nationalities/GetAllGlobalNationalities', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            setNationalities(data.item2);
            if (typeof employee === "undefined" || employee === null || employee === ""){
                setNationalityId(data.item2[0].id)
                setNationalityOptionValue({value: data.item2[0].id, label: ( data.item2[0].name) });
            }
            
        });
    }
    function GetAllEmploymentStatuses(){
        fetch(ApiUrl + '/api/EmploymentStatuses/GetAllEmploymentStatuses', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          // console.log(data)
            setEmployeeStatuses(data.item2);
            if (typeof employee === "undefined" || employee === null || employee === ""){
                setEmployeeStatusId(data.item2[0].id);
                setEmployeeStatusOptionValue({value: data.item2[0].id, label: ( data.item2[0].name) });
            }
        });
    }
    function GetAllRoles(){
        fetch(ApiUrl + '/api/Role/GetRoles', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data);
            setRoles(data);
            if (typeof employee === "undefined" || employee === null || employee === ""){
                setRoleId(data[0].id);
                setRoleName(data[0].name);
                setRoleOptionValue({value: data[0].id, label: ( data[0].name) });
            }
        });
    }
    function GetAllDepartments(){
        fetch(ApiUrl + '/api/Departments/GetAllDepartments', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            setDepartments(data.item2);
            if (typeof employee === "undefined" || employee === null || employee === ""){
                setdepartmentId(data.item2[0].id);
                setDepartmentOptionValue({value: data.item2[0].id, label: ( data.item2[0].name) });
            }
        });
    }
    function GetAllEducationalBackgrounds(){
        fetch(ApiUrl + '/api/EducationalBackgrounds/GetAllEducationalBackgrounds', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
        //   console.log(data)
            setEducationalBackgrounds(data.item2);
            
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (firstName.trim() === '' || lastName.trim() === '' || middleName.trim() === '' || email.trim() === '' 
            || phoneNumber.trim() === '' || phoneNumberFormatValid === false || dateOfBirth === null || hiredDate === null 
            || startDate === null) {
            if(firstName.trim() === '')
                setIsFirstNameValid(false);
            else
                setIsFirstNameValid(true);
            if(lastName.trim() === '')
                setIsLastNameValid(false);
            else
                setIsLastNameValid(true);
            if(middleName.trim() === '')
                setIsMiddleNameValid(false);
            else
                setIsMiddleNameValid(true);
            if(email.trim() === '')
                setIsEmailValid(false);
            else
                setIsEmailValid(true);
            if(phoneNumber.trim() === ''){
                setIsPhoneNumberValid(false);
            }
            else{
                if(phoneNumberFormatValid === true){
                    setIsPhoneNumberValid(true);
                }
                else{
                    setIsPhoneNumberValid(false);
                }
            }
            if(dateOfBirth === null)
                setIsDateOfBirthValid(false);
            else
                setIsDateOfBirthValid(true);
            if(hiredDate === null)
                setIsHiredDateValid(false);
            else
                setIsHiredDateValid(true);
            if(startDate === null)
                setIsStartDateValid(false);
            else
                setIsStartDateValid(true);
          return;
        }
        else{
            setIsFirstNameValid(true);
            setIsLastNameValid(true);
            setIsMiddleNameValid(true);
            setIsEmailValid(true);
            setIsPhoneNumberValid(true);
            setIsDateOfBirthValid(true);
            setIsHiredDateValid(true);
            setIsStartDateValid(true);
        }
        try {
            const ebIds = educationalBackgroundIds.map(edId => edId.toString()).join(',');
            const response = await fetch( ApiUrl +'/api/Employees/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    Id: id,
                    FirstName: firstName,
                    LastName: lastName,
                    MiddleName: middleName,
                    Gender: gender,
                    Email: email,
                    PhoneNumber: phoneNumber,
                    NationalityId: nationalityId,
                    EmployeeStatusId: employeeStatusId,
                    DateOfBirth: dateOfBirth,
                    HiredDate: hiredDate,
                    startDate: startDate,
                    endDate: endDate,
                    emergencyName: emergencyName,
                    emergencyPhone: emergencyPhone,
                    RoleId: roleId,
                    DepartmentId: departmentId,
                    Active: active,
                    EducationalBackgroundIds: ebIds,
                    RoleName: roleName,
                    SupervisorId: supervisorId
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllGlobalEmployees();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsFirstNameValid(false);
                setIsLastNameValid(false);
                setIsMiddleNameValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }
    function clearTextBoxes(){
        setId(0);
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setEmergencyName('');
        setEmergencyPhone('');
        setFirstName('');
        setIsFirstNameValid(true);
        setIsMiddleNameValid(true);
        setIsLastNameValid(true);
        setIsEmailValid(true);
        setIsPhoneNumberValid(true);
    }

    const genderOptions = [{value: 'M', label: 'Male'}, {value: 'F', label: 'Female'}, {value: 'O', label: 'Others'}]
    const handleGenderSelectChange = (selectedOption) => {
        setGenderOptionValue(selectedOption);
        setGender(selectedOption.value);
    };
      
    const nationalitiesOptions = nationalities.map((nationality) => ({
        value: nationality.id,
        label: nationality.name,
    }));
    const handleNationalitySelectChange = (selectedOption) => {
        setNationalityOptionValue(selectedOption);
        setNationalityId(selectedOption.value);
    };

    const employeeStatusOptions = employeeStatuses.map((status) => ({
        value: status.id,
        label: status.name,
    }));
    const handleEmployeeStatusSelectChange = (selectedOption) => {
        setEmployeeStatusOptionValue(selectedOption);
        setEmployeeStatusId(selectedOption.value);
    };

    const roleOptions = roles.map((role) => ({
        value: role.id,
        label: role.name,
    }));
    const handleRoleSelectChange = (selectedOption) => {
        setRoleOptionValue(selectedOption);
        setRoleId(selectedOption.value);
        setRoleName(selectedOption.label);
    };

    const departmentOptions = departments.map((department) => ({
        value: department.id,
        label: department.name,
    }));
    const handleDepartmentSelectChange = (selectedOption) => {
        setDepartmentOptionValue(selectedOption);
        setdepartmentId(selectedOption.value);
    };

    const educationalBackgroundtOptions = educationalBackgrounds.map((educationalBackground) => ({
        value: educationalBackground.id,
        label: educationalBackground.degree,
    }));
    const handleEducationalBackgroundsSelectChange = (selectedOptions) => {
        setEducationalBackgroundOptionValues(selectedOptions);
        setEducationalBackgroundIds(selectedOptions.map(option => option.value));
        // console.log(educationalBackgroundIds);
    };

    const employeesOptions = employees.map((employee) => ({
        value: employee.id,
        label: employee.firstName + " " + employee.middleName + " " + employee.lastName,
    }));
    const handleEmployeesSelectChange = (selectedOptions) => {
        setSupervisorOptionValue(selectedOptions);
        setsupervisorId(selectedOptions.value);
    };

    const formatPhoneNumber = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
    
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match the desired format
        if (match) {
            SetPhoneNumberFormatValid(true);
            return `(${match[1]}) ${match[2]}-${match[3]}`; // Apply the format (XXX) XXX-XXXX
        }

        SetPhoneNumberFormatValid(false);
        return cleaned; // Return the input as is if it doesn't match the desired format
    };


  return (
    <div className='SaveEmployee'>
        <h1>SaveEmployee</h1>
        {canPermission && canPermission.canEdit ? (
            <form className="SaveEmployeeForm" onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
                <div className="SaveEmployeeItem">
                    <label htmlFor="firstName">FirstName:</label>
                    <input type="text" className={!isFirstNameValid ? 'InvalidInputtext' : 'validInputtext'} value={firstName} placeholder="First Name" name="firstName" id="firstName" onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div className="SaveEmployeeItem">
                    <label htmlFor="middleName">Middle Name:</label>
                    <input type="text" className={!isMiddleNameValid ? 'InvalidInputtext' : 'validInputtext'} value={middleName} placeholder="Middle Name" name="middleName" id="middleName" onChange={(e) => setMiddleName(e.target.value)} />
                </div>
                <div className="SaveEmployeeItem">
                    <label htmlFor="lastName">Last Name:</label>
                    <input type="text" className={!isLastNameValid ? 'InvalidInputtext' : 'validInputtext'} value={lastName} placeholder="Last Name" name="lastName" id="lastName" onChange={(e) => setLastName(e.target.value)} />
                </div>
                <div className="SaveEmployeeItem">
                    <label htmlFor="email">Email:</label>
                    <input type="text" className={!isEmailValid ? 'InvalidInputtext' : 'validInputtext'} value={email} placeholder="Email" name="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="SaveEmployeeItem">
                    <label htmlFor="phoneNumber">Phone Number:</label>
                    <input type="text" className={!isPhoneNumberValid ? 'InvalidInputtext' : 'validInputtext'} value={phoneNumber} placeholder="Phone Number" name="phoneNumber" id="phoneNumber" onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))} />
                </div>
                <div className="SaveEmployeeItem">
                    <label htmlFor="gender">Gender</label>
                    <SelectReact
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        value={genderOptionValue}
                        name="gender"
                        options={genderOptions}
                        onChange={handleGenderSelectChange}
                    />
                </div>
                <div className="SaveEmployeeItem">
                    <label htmlFor="nationality">Nationality:</label>
                    <SelectReact
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        value={nationalityOptionValue}
                        name="nationality"
                        options={nationalitiesOptions}
                        onChange={handleNationalitySelectChange}
                    />
                </div>
                <div className="SaveEmployeeItem">
                    <label htmlFor="employeeStatus">Employee Status:</label>
                    <SelectReact
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        value={employeeStatusOptionValue}
                        name="employeeStatus"
                        options={employeeStatusOptions}
                        onChange={handleEmployeeStatusSelectChange}
                    />
                </div>

                <div className="SaveEmployeeItem">
                    <label htmlFor="role">Role:</label>
                    <SelectReact
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        value={roleOptionValue}
                        name="role"
                        options={roleOptions}
                        onChange={handleRoleSelectChange}
                    />
                </div>

                <div className="SaveEmployeeItem">
                    <label htmlFor="department">Department:</label>
                    <SelectReact
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        value={departmentOptionValue}
                        name="department"
                        options={departmentOptions}
                        onChange={handleDepartmentSelectChange}
                    />
                </div>

                <div className="SaveEmployeeItem">
                    <label htmlFor="educationalBackground">Educational Background:</label>
                    <SelectReact
                        className="basic-single"
                        isClearable={false}
                        isSearchable={true}
                        isMulti={true} // Enable multi-select
                        value={educationalBackgroundOptionValues} // Use the updated state variable
                        name="educationalBackground"
                        options={educationalBackgroundtOptions}
                        onChange={handleEducationalBackgroundsSelectChange} // Use the updated function
                    />
                </div>

                <div className="SaveEmployeeItem">
                    <label htmlFor="supervisor">Supervisor:</label>
                    <SelectReact
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={false}
                        isSearchable={true}
                        value={supervisorOptionValue}
                        name="supervisor"
                        options={employeesOptions}
                        onChange={handleEmployeesSelectChange}
                    />
                </div>

                <div className="SaveEmployeeItem">
                    <label htmlFor="dateOfBirth">D.O.B:</label>
                    <DatePicker
                        selected={dateOfBirth}
                        onChange={date => setDateOfBirth(date)}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        className={!isDateOfBirthValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthDropdown
                        scrollableMonthYearDropdown
                    />
                </div>

                <div className="SaveEmployeeItem">
                    <label htmlFor="hiredDate">Hired Date:</label>
                    <DatePicker
                        selected={hiredDate}
                        onChange={date => setHiredDate(date)}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        id="hiredDate"
                        name="hiredDate"
                        className={!isHiredDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthDropdown
                        scrollableMonthYearDropdown
                    />
                </div>

                <div className="SaveEmployeeItem">
                    <label htmlFor="startDate">Start Date:</label>
                    <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        id="startDate"
                        name="startDate"
                        className={!isStartDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthDropdown
                        scrollableMonthYearDropdown
                    />
                </div>

                <div className="SaveEmployeeItem">
                    <label htmlFor="endDate">End Date:</label>
                    <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select Date"
                        id="endDate"
                        name="endDate"
                        className="CustomDatePicker"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthDropdown
                        scrollableMonthYearDropdown
                    />
                </div>

                <div className="SaveEmployeeItem">
                    <label htmlFor="emergencyName">Emergency Name:</label>
                    <input type="text" value={emergencyName} placeholder="Emergency Name" name="emergencyName" id="emergencyName" onChange={(e) => setEmergencyName(e.target.value)} />
                </div>
                <div className="SaveEmployeeItem">
                    <label htmlFor="emergencyPhone">Emergency Phone:</label>
                    <input type="text" value={emergencyPhone} placeholder="Emergency Phone" name="emergencyPhone" id="emergencyPhone" onChange={(e) => setEmergencyPhone(e.target.value)} />
                </div>

                <div className='SaveEmployeeItem'>
                    <label>&nbsp;</label>
                    <button type="submit" className='saveBtn' >{id == '0' ? 'Create' : 'Save'}</button>
                </div>
            </form> ) : (
            <></>
        )}
        <br />
    </div>
  )
}
