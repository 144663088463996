import React, {useState, useEffect} from 'react'
import './employee.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';
import SelectReact from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function Employee() {
    const [employees, setEmployees] = useState([]);
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    // const [link, setLink] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const desiredPermissionPageName = 'Employee'; // Replace with the desired permissionPageName value
      
        const desiredRow = GlobalPermission.find(
          (item) => item.permissionPageName === desiredPermissionPageName
        );
        // setLink("/SaveEmployee")
        setCanPermission(desiredRow);
        GetAllGlobalEmployees();
    }, []);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'fullName', title: 'Full Name' },
        { key: 'genderName', title: 'Gender' },
        // { key: 'dateOfBirth', title: 'D.O.B' },
        { key: 'phoneNumber', title: 'Phone' },
        { key: 'roleName', title: 'Role' },
        { key: 'departmentName', title: 'Department' },
        { key: 'employeeStatusName', title: 'Status' },
        { key: 'hiredDate', title: 'Hired Date' },
        { key: 'active', title: 'Active' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    function GetAllGlobalEmployees(){
        fetch(ApiUrl + '/api/Employees/GetAllGlobalEmployees', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          console.log(data.item2)
          setEmployees(data.item2);
        });
    }
    const handleEdit = (id) => {
        navigate('/SaveEmployee/'+ id); // Replace '/edit-page' with the desired page URL
    }
    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/employees/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                GetAllGlobalEmployees();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleUndo = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/employees/Undo?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                GetAllGlobalEmployees();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const onCheckBoxChange = async (rowId) => {
      console.log(rowId);
      try {
        const response = await fetch(ApiUrl + '/api/Employees/ChangeActive?Id=' + rowId, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        //console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              GetAllGlobalEmployees();
          });
        } else {
          console.error(data);
        }
    } catch (error) {
        console.error(error);
    }
  };

  return (
    <div className='employee'>
      <div className="employeeTitleContainer">
        <h1 className="employeeTitle">Employees</h1>
        <Link to="/SaveEmployee">
          <button className="employeeAddButton">Create</button>
        </Link>
      </div>
      {canPermission && canPermission.canView ? (
        <Table
          data={employees}
          columns={columns}
          onDelete={handleDelete}
          onEdit={handleEdit}
          canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
          canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
          onUndo={handleUndo}
          onCheckBoxChange={onCheckBoxChange}
          // viewLink = "Employee"
          // viewLinkPath = {link}
        /> 
      ) : (
        <></>
      )}
    </div>
  )
}
