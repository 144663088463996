import React, {useEffect, useState} from 'react'
import "./featuredInfo.css"
import { ArrowDownward, ArrowUpward, MonetizationOn } from '@mui/icons-material'

import FunctionsIcon from '@mui/icons-material/Functions';
import EngineeringIcon from '@mui/icons-material/Engineering';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SellIcon from '@mui/icons-material/Sell';
import { ApiUrl } from '../../Global';

export default function FeaturedInfo({years, roleName}) {
    const [dashboardData, setDashboardData] = useState();
    // console.log("featured");
    // console.log(roleName);
    useEffect(() => {
        console.log("Featured: ");
        console.log(years);
        if(roleName === "admin" || roleName === "Sales"){
            fetch(ApiUrl + '/api/Dashboard/GetDashboardData?years=' + years.join(','), {
                headers: {
                  'Authorization': `Bearer ` + sessionStorage.getItem("token")
                }
            })
            .then(response => response.json())
            .then(data => {
                console.log(data.item2)
                setDashboardData(data.item2);
            });
        }
        
    }, [years,roleName]);

  return (
    <div className='featured'>
        {(roleName === "admin" || roleName === "Sales") && (
            <>
                <div className="featuredItem">
                    <span className="featuredTitle"><WidgetsIcon className='featuredIcon' /></span>
                    <div className="featuredMoneyContainer">
                        <span className="featuredMoney">
                            ${dashboardData && new Intl.NumberFormat().format(dashboardData[0].totalMaterial.toFixed(2))}
                        </span>
                        <span className="featuredMoneyRate">
                            {/* -11.4 <ArrowDownward className='featuredIcon negative' /> */}
                        </span>
                    </div>
                    <span className='featuredSub'>Material</span>
                </div>
                <div className="featuredItem">
                    <span className="featuredTitle"><EngineeringIcon className='featuredIcon' /></span>
                    <div className="featuredMoneyContainer">
                        <span className="featuredMoney">
                            ${dashboardData && new Intl.NumberFormat().format(dashboardData[0].labor.toFixed(2))}
                        </span>
                       
                        <span className="featuredMoneyRate">
                            {/* -1.4 <ArrowDownward className='featuredIcon negative' /> */}
                        </span>
                    </div>
                    <span className='featuredSub'>Labor</span>
                </div>
                <div className="featuredItem">
                    <span className="featuredTitle"><MonetizationOn className='featuredIcon' /></span>
                    <div className="featuredMoneyContainer">
                        <span className="featuredMoney">
                            ${dashboardData && new Intl.NumberFormat().format(dashboardData[0].taxAmount.toFixed(2))}
                        </span>
                        {/* <span className="featuredMoney">${dashboardData && dashboardData[0].taxAmount.toFixed(2)}</span> */}
                        <span className="featuredMoneyRate">
                            {/* +2.4 <ArrowUpward className='featuredIcon' /> */}
                        </span>
                    </div>
                    <span className='featuredSub'>Tax</span>
                </div>
                <div className="featuredItem">
                    <span className="featuredTitle"><SellIcon className='featuredIcon' /></span>
                    <div className="featuredMoneyContainer">
                        <span className="featuredMoney">
                            ${dashboardData && new Intl.NumberFormat().format(dashboardData[0].total.toFixed(2))}
                        </span>
                        {/* <span className="featuredMoney">${dashboardData && dashboardData[0].total.toFixed(2)}</span> */}
                        <span className="featuredMoneyRate">
                            {/* +2.4 <ArrowUpward className='featuredIcon' /> */}
                        </span>
                    </div>
                    <span className='featuredSub'>Total</span>
                </div>
            </>
        )}
        
    </div>
  )
}
