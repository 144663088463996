import "./Login.css"
import React,{ useState } from "react"
import { useNavigate } from "react-router-dom";
import { ApiUrl } from "./Global";

export const Login = () => {
    const navigate = useNavigate();
    const [username, setUserName] = useState('');
    const [pass, setPass] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch( ApiUrl +'/api/Auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: pass
                })
            });
            const data = await response.json();
            if (response.ok) {
                // login successful
                console.log(response)
                const encodedPayload = data.message.split('.')[1];
                const decodedPayload = atob(encodedPayload);
                const payloadObj = JSON.parse(decodedPayload);
                const userId = payloadObj['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
                sessionStorage.setItem("UserId", userId);
                sessionStorage.setItem("username", username);
                sessionStorage.setItem("token", data.message);
                sessionStorage.setItem("authenticated", true);
                console.log(data);
                window.location.reload();
            } else {
                // login failed
                const errorMessage = document.querySelector('.error-message');
                errorMessage.textContent = data.message; // update the error message with the data message
                errorMessage.style.display = 'block';
                // console.error(data);
            }
        } catch (response) {
            console.error(response);
        }
    }
    return (
        <div className="Login">
            <img src="images/carpet-for-less-logo-41308e5f-360w.webp" alt="" className="" />
            <div className="auth-form-container">
                <h2>Login Form</h2>
                <form className="login-form" onSubmit={handleSubmit}>
                    <label htmlFor="username">UserName</label>
                    <input value={username} type="text" placeholder="username" name="username" id="username" onChange={(e) => setUserName(e.target.value)} />
                    <label htmlFor="password">password</label>
                    <input value={pass} type="password" placeholder="********" name="password" id="password" onChange={(e) => setPass(e.target.value)} />
                    <button type="submit">Login</button>
                </form>
                <br />
                <div className="error-message" style={{ display: 'none' }}>Invalid username or password</div>
                <button className="link-btn">Forget Password?</button>
            </div>
        </div>

    )
}