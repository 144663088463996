import './App.css';
import { Login } from './Login';
import React, {useState, useEffect} from 'react';
import { Default } from './Default';
import Topbar from './components/Topbar/Topbar';
import Sidebar from './components/Sidebar/Sidebar';
import Home from './components/pages/home/Home';
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserList from './components/pages/userList/UserList';
import User from './components/pages/user/User';
import NewUser from './components/pages/user/NewUser';
import Role from './components/pages/Role/Role';
import RolePermission from './components/pages/rolePermission/RolePermission';
import Permission from './components/pages/permission/Permission';
import Customer from './components/pages/customer/Customer';
import State from './components/pages/state/State';
import Supplier from './components/pages/supplier/Supplier';
import SalesPerson from './components/pages/salesPerson/SalesPerson';
import Private from './components/pages/private/Private';
import Estimate from './components/pages/estimate/Estimate';
import InvoiceScan from './components/pages/invoiceScan/InvoiceScan';
import Color from './components/pages/color/Color';
import WareHouse from './components/pages/wareHouse/WareHouse';
import Category from './components/pages/category/Category';
import ProductType from './components/pages/productType/ProductType';
import DynamicFieldDefinition from './components/pages/dynamicFieldDefinition/DynamicFieldDefinition';
import MeasureUnit from './components/pages/measureUnit/MeasureUnit';
import Inventory from './components/pages/inventory/Inventory';
import Product from './components/pages/product/Product';
import Style from './components/pages/style/Style';
import Department from './components/pages/department/Department';
import Position from './components/pages/position/Position';
import Employee from './components/pages/employee/Employee';
import Nationality from './components/pages/nationality/Nationality';
import Country from './components/pages/country/Country';
import EmploymentStatus from './components/pages/employmentStatus/EmploymentStatus';
import EducationalBackground from './components/pages/educationalBackground/EducationalBackground';
import SaveEmployee from './components/pages/employee/SaveEmployee';
import Invoice from './components/pages/invoice/Invoice';
import Installer from './components/pages/installer/Installer';
import InvoiceList from './components/pages/invoice/InvoiceList';
import Payment from './components/pages/invoice/payment/Payment';
import ProductCategory from './components/pages/productCategory/ProductCategory';
import InvoicesReport from './components/pages/reports/invoicesReports/InvoicesReport';
import Scheduler from './components/pages/scheduler/Scheduler';
import SupplierInvoice from './components/pages/supplier/supplierInvoice/SupplierInvoice';
import SupplierInvoiceList from './components/pages/supplierInvoiceList/SupplierInvoiceList';
import FlooringProductList from './components/pages/flooringProducts/FlooringProductList';
import ProductDetailReport from './components/pages/reports/ProductDetailReports/ProductDetailReport';
import ProductPriceList from './components/pages/productPriceList/ProductPriceList';
import PaymentMethod from './components/pages/paymentMethod/PaymentMethod';
import PaymentsDetails from './components/pages/reports/paymentsDetails/PaymentsDetails';
import InvoicesPaymentDetailReport from './components/pages/reports/InvoicesPaymentDetailReport/InvoicesPaymentDetailReport';
import SupplierPayment from './components/pages/supplier/payment/SupplierPayment';
import SupplierInvoiceReport from './components/pages/reports/supplierInvoiceReports/SupplierInvoiceReport';
import InstallerPayment from './components/pages/installer/installerPayment/InstallerPayment';

function App() {
  //const navigate = useNavigate();
    const [authenticated, setauthenticated] = useState(null);

    useEffect(() => {
        const loggedInUser = sessionStorage.getItem("authenticated");
        if (loggedInUser) {
            setauthenticated(loggedInUser);
        }
        else{
            //navigate("/login");
        }
    }, []);
    if (!authenticated) {
      // Redirect
      return (
        <Router>
          <Login />
        </Router>
      );
    } else { 
      return (
        <React.StrictMode>
          <Router>
          <Topbar />
          <div className='container'>
            <Sidebar />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/users' element={<UserList />} />
              <Route path='/user/:userId' element={<User />} />
              <Route path='/newUser' element={<NewUser />} />
              <Route path='/roles' element={<Role />} />
              <Route path='/rolePermissions/:roleId' element={<RolePermission />} />
              <Route path='/permissions' element={<Permission />} />
              <Route path='/customers' element={<Customer />} />
              <Route path='/states' element={<State />} />
              <Route path='/suppliers' element={<Supplier />} />
              <Route path='/salesPeople' element={<SalesPerson />} />
              <Route path="/salesPeople/:supplier" element={<SalesPerson />} />
              <Route path="/private" element={<Private />} />
              <Route path="/estimates" element={<Estimate />} />
              <Route path="/invoicesScan" element={<InvoiceScan />} />
              <Route path="/colors" element={<Color />} />
              <Route path="/wareHouses" element={<WareHouse />} />
              <Route path="/categories" element={<Category />} />
              <Route path="/productType" element={<ProductType />} />
              <Route path="/dynamicFieldDefinitions" element={<DynamicFieldDefinition />} />
              <Route path="/measureUnits" element={<MeasureUnit />} />
              <Route path="/inventories" element={<Inventory />} />
              <Route path="/products" element={<Product />} />
              <Route path="/products/:product" element={<Product />} />
              <Route path="/styles" element={<Style />} />
              <Route path="/departments" element={<Department />} />
              <Route path="/positions" element={<Position />} />
              <Route path="/employees" element={<Employee />} />
              <Route path="/nationalities" element={<Nationality />} />
              <Route path="/countries" element={<Country />} />
              <Route path="/employmentStatuses" element={<EmploymentStatus />} />
              <Route path="/educationalBackgrounds" element={<EducationalBackground />} />
              <Route path='/SaveEmployee' element={<SaveEmployee />} />
              <Route path="/SaveEmployee/:employee" element={<SaveEmployee />} />
              <Route path="/invoices" element={<Invoice />} />
              <Route path="/installers" element={<Installer />} />
              <Route path="/invoiceList" element={<InvoiceList />} />
              <Route path="/invoices/:invoiceId" element={<Invoice />} />
              <Route path="/payments/:invoiceId" element={<Payment />} />
              <Route path="/productCategories" element={<ProductCategory />} />
              <Route path="/invoicesReport" element={<InvoicesReport />} />
              <Route path="/scheduler" element={<Scheduler />} />
              <Route path="/supplierInvoice" element={<SupplierInvoice />} />
              <Route path="/supplierInvoiceList" element={<SupplierInvoiceList />} />
              <Route path="/supplierInvoice/:supplierInvoiceId" element={<SupplierInvoice />} />
              <Route path="/flooringProductList" element={<FlooringProductList />} />
              <Route path="/productDetailReport" element={<ProductDetailReport />} />
              <Route path="/productPriceList" element={<ProductPriceList />} />
              <Route path="/paymentMethods" element={<PaymentMethod />} />
              <Route path="/paymentsDetails" element={<PaymentsDetails />} />
              <Route path="/InvoicesPaymentDetailReport" element={<InvoicesPaymentDetailReport />} />
              <Route path="/supplierPayments/:supplierInvoiceId" element={<SupplierPayment />} />
              <Route path="/supplierInvoiceReport" element={<SupplierInvoiceReport />} />
              <Route path="/installerPayment" element={<InstallerPayment />} />
            </Routes>
          </div>
          </Router>
        </React.StrictMode>
      );
  }
}

export default App;
