import React, {useState, useEffect} from 'react'
import './productType.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';
import SelectReact from 'react-select';


export default function ProductType() {
    const [id, setId] = useState(0);
    const [name, setName] = useState('');//pageName
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [supplierId, setSupplierId] = useState('');
    const [categoryId, setCategoryId] = useState(0);
    const [isDeleted, setIsDeleted] = useState(0);
    const [isNameValid, setIsNameValid] = useState(true);
    const [isCodeValid, setIsCodeValid] = useState(true);
    const currentDateTime = new Date();
    const [searchQuery, setSearchQuery] = useState('');
    const [canPermission, setCanPermission] = useState();
    const [productTypes, setProductTypes] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [dynamicFields, setDynamicFields] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [colors, setColors] = useState([]);
    const [colorId, setColorId] = useState(0);
    const [units, setUnits] = useState([]);
    const [unitId, setUnitId] = useState(0);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [selectedCategoriesTable, setSelectedCategoriesTable] = useState([]);
    const [columns, setColumns] = useState([]);
    const [dynamicFieldsMenus, setDynamicFieldsMenus] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [styles, setStyles] = useState([]);
    const [styleId, setStyleId] = useState(0);
    const [productCategories, setProductCategories] = useState([]);
    const [productCategoryId, setProductCategoryId] = useState(0);
    const [thickness, setThickness] = useState('');
    const [colorOptionValue, setColorOptionValue] = useState();
    const [styleOptionValue, setStyleOptionValue] = useState();
    const [productCategoryOptionValue, setProductCategoryOptionValue] = useState();



    useEffect(() => {
        const desiredPermissionPageName = 'ProductType'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);
        GetAllProductTypes();
        GetAllSuppliers();
        GetAllCategories();
        GetAllColors();
        GetAllUnits();
        GetAllStyles();
        GetAllProductCategories();
    }, [searchText]);

    useEffect(() => {
        const selectedCategory1 = categories.find((category) => category.id === categoryId);
        setSelectedCategory(selectedCategory1);
    }, [categoryId, categories]);

    useEffect(() => {
        const filteredProductTypes = productTypes.filter((p) => p.categoryId === selectedCategoryId);
        setSelectedCategoriesTable(filteredProductTypes);
        const categoryToColumns = categories.find((category) => category.id === selectedCategoryId);
        
        if (categoryToColumns !== null && categoryToColumns !== undefined) {
          const columnsUpdate = [
            { key: 'id', title: 'ID' },
            { key: 'name', title: 'Name' },
            { key: 'code', title: 'Code' },
            { key: 'description', title: 'Description' },
            { key: 'supplierName', title: 'Supplier' },
            ...(categoryToColumns.hasColor ? [{ key: 'colorName', title: 'Color' }] : []),
            ...(categoryToColumns.hasStyle ? [{ key: 'styleName', title: 'Style' }] : []),
            { key: 'categoryName', title: 'Category' },
            ...(categoryToColumns.hasUnit ? [{ key: 'measureUnitName', title: 'Measure' }] : []),
          ];
          // Add dynamic field columns
          dynamicFieldsMenus.forEach((field) => {
            if (field.fieldLabel) {
              const key = field.fieldLabel.charAt(0).toLowerCase() + field.fieldLabel.slice(1);
              columnsUpdate.push({ key: key, title: field.fieldLabel });
            }
          });
      
          setColumns(columnsUpdate);
        }
      }, [selectedCategoryId, productTypes, dynamicFieldsMenus]);

    function GetAllProductTypes(){
        fetch(ApiUrl + '/api/ProductTypes/GetAllGlobalProductTypes', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2)
            const filteredData = data.item2.filter(item =>
                item.name.toLowerCase().includes(searchText.toLowerCase()) ||
                item.code.toLowerCase().includes(searchText.toLowerCase()) ||
                item.supplierName.toLowerCase().includes(searchText.toLowerCase()) ||
                item.colorName.toLowerCase().includes(searchText.toLowerCase())
            );
            setProductTypes(filteredData);
            // setProductTypes(data.item2);
        });
    }

    function GetAllSuppliers(){
        fetch(ApiUrl + '/api/Suppliers/GetAllSuppliers', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data)
            const filteredSuppliers = data.item2.filter(supplier => supplier.isDeleted === false);
            setSuppliers(filteredSuppliers);
            setSupplierId(filteredSuppliers[0].id);
        });
    }
    function GetAllCategories(){
        fetch(ApiUrl + '/api/Categories/GetAllCategories', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2)
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setCategories(filtered);
            // setCategories(data.item2);
            GetDynamicFieldDefinitionByCategoryId(data.item2[0].id);
            GetDynamicFieldDefinitionByCategoryIdForMenus(data.item2[0].id);
            setCategoryId(data.item2[0].id);
            setSelectedCategoryId(data.item2[0].id);
        });
    }
    function GetAllColors(){
        fetch(ApiUrl + '/api/Colors/GetAllColors', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data.item2)
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setColors(filtered);
            // setColors(data.item2);
            setColorId(filtered[0].id);
            setColorOptionValue({value: filtered[0].id, label: filtered[0].name + "(" + filtered[0].hex_code + ")"});
        });
    }
    function GetAllUnits(){
        fetch(ApiUrl + '/api/MeasureUnits/GetAllMeasureUnits', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2);
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setUnits(filtered);
            setUnitId(filtered[0].id);
        });
    }
    function GetAllStyles(){
        fetch(ApiUrl + '/api/Styles/GetAllStyles', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2);
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setStyles(filtered);
            // setStyles(data.item2);
            setStyleId(filtered[0].id);
            setStyleOptionValue({value: filtered[0].id, label: filtered[0].name + "(" + filtered[0].code + ")"});
        });
    }

    function GetAllProductCategories(){
        fetch(ApiUrl + '/api/ProductCategories/GetAllProductCategories', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2);
            const filtered = data.item2.filter(est => est.isDeleted === false);
            setProductCategories(filtered);
            setProductCategoryId(filtered[0].id);
            setProductCategoryOptionValue({value: filtered[0].id, label: filtered[0].name + "(" + filtered[0].code + ")"});
        });
    }

    const handleNew = async (e) => {
        e.preventDefault();
        clearTextBoxes();
    }
    function clearTextBoxes(){
        setId(0);
        setName('');
        setCode('');
        setDescription('');
        setSupplierId(suppliers[0].id);
        setCategoryId(categories[0].id);
        setColorId(colors[0].id);
        setColorOptionValue({value: colors[0].id, label: colors[0].name + "(" + colors[0].hex_code + ")"});
        setUnitId(units[0].id);
        setStyleId(styles[0].id);
        setStyleOptionValue({value: styles[0].id, label: styles[0].name + "(" + styles[0].code + ")"});
        setThickness('');
        setDynamicFields([]); // Clear the dynamicFields state by setting it to an empty array
        GetDynamicFieldDefinitionByCategoryId(categories[0].id);
        const selectedCategory1 = categories.find((category) => category.id === categoryId);
        setSelectedCategory(selectedCategory1);
        // console.log(categoryId);
        // console.log(selectedCategory1);
    }

    const handleEdit = async (id) => {
        const filtered = productTypes.filter(item => item.id === id);
        try {
            await GetDynamicFieldDefinitionByCategoryId(filtered[0].categoryId);
        } catch (error) {
            // Handle any errors that occurred during the API call
            console.error(error);
            return;
        }
        console.log(filtered);
        setId(id);
        setName(filtered[0].name);
        setCode(filtered[0].code);
        setDescription(filtered[0].description);
        setSupplierId(filtered[0].supplierId);
        setCategoryId(filtered[0].categoryId);
        setColorId(filtered[0].colorId);
        setColorOptionValue({value: filtered[0].colorId, label: filtered[0].colorName + "(" + filtered[0].colorHexCode + ")"});
        setUnitId(filtered[0].measureUnitId);
        setStyleId(filtered[0].styleId);
        setStyleOptionValue({value: filtered[0].styleId, label: filtered[0].styleName + "(" + filtered[0].styleCode + ")"});
        setThickness(filtered[0].thickness);
        setProductCategoryId(filtered[0].productCategoryId);
        setProductCategoryOptionValue({value: filtered[0].productCategoryId, label: filtered[0].productCategoryName + "(" + filtered[0].productCategoryCode + ")"});

        const updatedDynamicFields = dynamicFields.map(dynamicField => {
            const dynamicFieldLabel = dynamicField.fieldLabel.charAt(0).toLowerCase() + dynamicField.fieldLabel.substring(1);
            if (filtered[0][dynamicFieldLabel]) {
              return {
                ...dynamicField,
                value: filtered[0][dynamicFieldLabel]
              };
            }
            return dynamicField;
        });
          
        setDynamicFields(updatedDynamicFields);
        // console.log(filtered);
        // console.log(dynamicFields);
    }

    const handleDelete = async (id) => {
        try {
            const response = await fetch(ApiUrl + '/api/ProductTypes/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllProductTypes();
                });
            } else {
              console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(productCategoryId);
        if (name.trim() === '' || code.trim() === '' || description.trim() === '') {
            if(name.trim() === '')
                setIsNameValid(false);
            else
                setIsNameValid(true);
            if(code.trim() === '')
                setIsCodeValid(false);
            else
                setIsCodeValid(true);
            return;
        }
        else{
            setIsNameValid(true);
            setIsCodeValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/ProductTypes/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    productType:{
                    id: id,
                    name: name,
                    code: code,
                    description: description,
                    supplierId: supplierId,
                    categoryId: categoryId,
                    productCategoryId: productCategoryId,
                    styleId: styleId,
                    colorId: colorId,
                    thickness: thickness,
                    measureUnitId: unitId,
                    createdBy: '0',
                    CreatedDate: currentDateTime
                },
                dynamicFieldDefinitionViews: dynamicFields})
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                }).then( async () => {
                    clearTextBoxes();
                    GetAllProductTypes();
                });
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function handleChangeCategory(categoryIdVar) {
        setDynamicFields([]); // Clear the dynamicFields state before fetching new fields
        setCategoryId(parseInt(categoryIdVar, 10));
        GetDynamicFieldDefinitionByCategoryId(parseInt(categoryIdVar, 10));
    }
    function handleChangeCategoryForMenus(categoryIdMenus) {
        setId(0);
        setName('');
        setCode('');
        setDescription('');
        setSupplierId(suppliers[0].id);
        setCategoryId(categories[0].id);
        setColorId(colors[0].id);
        setColorOptionValue({value: colors[0].id, label: colors[0].name + "(" + colors[0].hex_code + ")"});
        setUnitId(units[0].id);
        setStyleId(styles[0].id);
        setStyleOptionValue({value: styles[0].id, label: styles[0].name + "(" + styles[0].code + ")"});

        setDynamicFieldsMenus([]); // Clear the dynamicFields state before fetching new fields
        setSelectedCategoryId(parseInt(categoryIdMenus, 10));
        GetDynamicFieldDefinitionByCategoryIdForMenus(parseInt(categoryIdMenus, 10));
        handleChangeCategory(parseInt(categoryIdMenus, 10));
    }
      
    function GetDynamicFieldDefinitionByCategoryId(categoryIdVar) {
        return new Promise((resolve, reject) => {
          fetch(ApiUrl + '/api/DynamicFieldDefinitions/GetDynamicFieldDefinitionByCategoryId?Category=' + categoryIdVar, {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          })
          .then(response => response.json())
          .then(data => {
            setDynamicFields(data.item2);
            resolve(); // Resolve the promise when the dynamic field definitions are set
          })
          .catch(error => {
            reject(error); // Reject the promise if an error occurs during the API call
          });
        });
    }
    function GetDynamicFieldDefinitionByCategoryIdForMenus(categoryIdMenus) {
        fetch(ApiUrl + '/api/DynamicFieldDefinitions/GetDynamicFieldDefinitionByCategoryId?Category=' + categoryIdMenus, {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            // console.log(data.item2);
            setDynamicFieldsMenus(data.item2);
        });
    }

    const colorsOptions = colors.map((color) => ({
        value: color.id,
        label: color.name + "(" + color.hex_code + ")",
    }));
    const handleColorSelectChange = (selectedOption) => {
        setColorOptionValue(selectedOption);
        setColorId(selectedOption.value);
    };

    const stylesOptions = styles.map((style) => ({
        value: style.id,
        label: style.name + "(" + style.code + ")",
    }));
    const handleStyleSelectChange = (selectedOption) => {
        setStyleOptionValue(selectedOption);
        setStyleId(selectedOption.value);
    };

    const productCategorysOptions = productCategories.map((productCategory) => ({
        value: productCategory.id,
        label: productCategory.name + "(" + productCategory.code + ")",
    }));
    const handleProductCategorySelectChange = (selectedOption) => {
        setProductCategoryOptionValue(selectedOption);
        setProductCategoryId(selectedOption.value);
    };

  return (
    <div className='ProductType'>
        <h1>ProductType</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newProductTypeForm" onSubmit={handleSubmit} >
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />

            <div className='newProductTypeItem'>
                <label htmlFor='supplier'>Supplier</label>
                <select className='newStateSelect' name="supplier" id="supplier" value={supplierId} onChange={(e) => setSupplierId(e.target.value)}>
                    {suppliers.map((supplier) => (
                        <option key={supplier.id} value={supplier.id}>{supplier.name}</option>
                    ))}
                </select>
            </div>

            <div className='newProductTypeItem'>
                <label htmlFor='categories'>Categories</label>
                <select className='newStateSelect' name="categories" id="categories" value={categoryId} onChange={(e) => handleChangeCategory(e.target.value)}>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>
            </div>

            <div className="newProductTypeItem">
                <label htmlFor='description'>Description</label>
                <input type="text" placeholder='Description' name="description" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>

            <div className="newProductTypeItem">
                <label htmlFor="color">Color</label>
                <SelectReact
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  value={colorOptionValue}
                  name="color"
                  options={colorsOptions}
                  onChange={handleColorSelectChange}
                />
            </div>

            {/* {selectedCategory !== null && selectedCategory !== undefined ? (
                selectedCategory.hasColor === true ? (
                    // Render the color field
                    <div className='newProductTypeItemSelect'>
                    <label htmlFor='color'>Color</label>
                    <select className='newStateSelect' name="color" id="color" value={colorId} onChange={(e) => setColorId(e.target.value)}>
                        {colors.map((color) => (
                        <option key={color.id} value={color.id}>{color.name + "(" + color.hex_code + ")"}</option>
                        ))}
                    </select>
                    </div>
                ) : null
                ) : (
                <></>
            )} */}

            <div className="newProductTypeItem">
                <label htmlFor="style">Style</label>
                <SelectReact
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  value={styleOptionValue}
                  name="style"
                  options={stylesOptions}
                  onChange={handleStyleSelectChange}
                />
            </div>

            {/* {selectedCategory !== null && selectedCategory !== undefined ? (
                selectedCategory.hasStyle === true ? (
                    // Render the unit field
                    <div className='newProductTypeItemSelect'>
                    <label htmlFor='style'>Style</label>
                    <select className='newStateSelect' name="style" id="style" value={styleId} onChange={(e) => setStyleId(e.target.value)}>
                        {styles.map((style) => (
                        <option key={style.id} value={style.id}>{style.name}</option>
                        ))}
                    </select>
                    </div>
                ) : null
                ) : (
                <></>
            )} */}

            <div className="newProductTypeItem">
                <label htmlFor="productCategory">Type</label>
                <SelectReact
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={true}
                  value={productCategoryOptionValue}
                  name="productCategory"
                  options={productCategorysOptions}
                  onChange={handleProductCategorySelectChange}
                />
            </div>

            {/* <div className='newProductTypeItemSelect'>
                <label htmlFor='productCategories'>Type</label>
                <select className='newStateSelect' name="productCategories" id="productCategories" value={productCategoryId} onChange={(e) => setProductCategoryId(e.target.value)}>
                    {productCategories.map((type) => (
                        <option key={type.id} value={type.id}>{type.name + "(" + type.code + ")"}</option>
                    ))}
                </select>
            </div> */}

            <div className="newProductTypeItem">
                <label htmlFor='name'>Name</label>
                <input type="text" placeholder="Name" name="name" id="name" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="newProductTypeItem">
                <label htmlFor='code'>Code</label>
                <input type="text" placeholder="Code" name="code" id="code" className={!isCodeValid ? 'InvalidInputtext' : 'validInputtext'} value={code} onChange={(e) => setCode(e.target.value)} />
            </div>

            {selectedCategory !== null && selectedCategory !== undefined ? (
                selectedCategory.hasThickness === true ? (
                    // Render the unit field
                    <div className='newProductTypeItemSelect'>
                        <label htmlFor='hasThickness'>Thickness</label>
                        <input type="text" placeholder="hasThickness" name="hasThickness" id="hasThickness" className='validInputtext' value={thickness} onChange={(e) => setThickness(e.target.value)} />
                    </div>
                ) : null
                ) : (
                <></>
            )}

            {selectedCategory !== null && selectedCategory !== undefined ? (
                selectedCategory.hasUnit === true ? (
                    // Render the unit field
                    <div className='newProductTypeItemSelect'>
                    <label htmlFor='unit'>Unit</label>
                    <select className='newStateSelect' name="unit" id="unit" value={unitId} onChange={(e) => setUnitId(e.target.value)}>
                        {units.map((unit) => (
                        <option key={unit.id} value={unit.id}>{unit.name}</option>
                        ))}
                    </select>
                    </div>
                ) : null
                ) : (
                <></>
            )}
            
            {dynamicFields.map((dynamicField) => (
                <div className='newProductTypeItemSelect' key={`${dynamicField.categoryId}-${dynamicField.fieldLabel}`}>
                    <label>{dynamicField.fieldLabel}</label>
                    <input
                    type={dynamicField.fieldType == "Decimal" ? 'number' : 'text'}
                    placeholder={dynamicField.fieldLabel}
                    name={dynamicField.fieldLabel}
                    id={`${dynamicField.fieldLabel}`}
                    value={dynamicField.value || ''}
                    onChange={(e) => {
                        const updatedFields = dynamicFields.map((field) => {
                        if (field.id === dynamicField.id) {
                            return { ...field, value: e.target.value, createdBy: '0', createdDate: currentDateTime };
                        }
                        return field;
                        });
                        setDynamicFields(updatedFields);
                        // console.log(updatedFields);
                    }}
                    />
                </div>
            ))}
            
            <div className='newProductTypeItemSelect'>
                <button type='button' className="newProductTypeBtn" id="newProductTypeBtn" onClick={handleNew}>New</button>
                <button type='submit' className="newProductTypeButton">{id == '0' ? 'Create' : 'Save'}</button>
            </div>
        </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
            <>
            <div className="categoryTabs">
                {categories.map((category) => (
                    <div
                        key={category.id}
                        className={`categoryTab ${selectedCategoryId === category.id ? 'activeCategory' : ''}`}
                        onClick={() => handleChangeCategoryForMenus(category.id)}
                    >
                        {category.name}
                    </div>
                ))}
            </div>
            <br />

            <div className="searchInvoiceScanContainer">
                <div className="searchInputInvoiceScanContainer">
                    <label className="searchLabel" htmlFor="searchInput">Search:</label>
                    <input
                    id="searchInput"
                    className="searchInvoiceScanInput"
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
            </div>
            <Table
                data={selectedCategoriesTable}
                columns={columns}
                onDelete={handleDelete}
                onEdit={handleEdit}
                canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
            />
        </>
        ) : (
            <></>
        )}
    </div>
  )
}
