import React, { useEffect, useState } from 'react'
import './style.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function Style() {
    const [styles, setStyles] = useState([]);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [id, setId] = useState('0');
    const [canPermission, setCanPermission] = useState();
    const [isNameValid, setIsNameValid] = useState(true);
    const [isCodeValid, setIsCodeValid] = useState(true);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        const desiredPermissionPageName = 'Style'; // Replace with the desired permissionPageName value
      
        const desiredRow = GlobalPermission.find(
          (item) => item.permissionPageName === desiredPermissionPageName
        );
      
        setCanPermission(desiredRow);
        GetAllStyles();
    }, [searchText]);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'code', title: 'Code' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
        // Perform edit action using the ID of the row being edited
        const filtered = styles.filter(item => item.id === id);
        setName(filtered[0].name);
        setId(filtered[0].id);
        setCode(filtered[0].code);
        setDescription(filtered[0].description);
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/Styles/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllStyles();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() === '' || code.trim() === '' ) {
            if(name.trim() === '')
                setIsNameValid(false);
            else
                setIsNameValid(true);
            // if(code.trim() === '')
            //     setIsCodeValid(false);
            // else
            //     setIsCodeValid(true);
            return;
        }
        else{
            setIsNameValid(true);
            // setIsCodeValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/Styles/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    Name: name,
                    Code: code,
                    Description: description,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    // setIsCodeValid(true);
                    setIsNameValid(true);
                    clearTextBoxes();
                    GetAllStyles();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsNameValid(false);
                // setIsCodeValid(false);
            });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setName('');
        setCode('');
        setDescription('');
        // setIsCodeValid(true);
        setIsNameValid(true);
    }

    function GetAllStyles(){
      fetch(ApiUrl + '/api/Styles/GetAllStyles', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        const filteredData = data.item2.filter(item =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.code.toLowerCase().includes(searchText.toLowerCase())
        );
        setStyles(filteredData);
      });
    }

    const handleUndo = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/Styles/Undo?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllStyles();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

  return (
    <div className='style'>
        <h1>Style</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newStyleForm" onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div className="newStyleItem">
                <label htmlFor="stateName">Name:</label>
                <input type="text" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} placeholder="Name" name="name" id="name" onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="newStyleItem">
                <label htmlFor="stateName">Code:</label>
                <input type="text" className='validInputtext' value={code} placeholder="Code" name="code" id="code" onChange={(e) => setCode(e.target.value)} />
            </div>
            <div className='newStyleItem'>
                <label htmlFor="stateName">&nbsp;</label>
                <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
            </div>
            <div className='newStyleItem'>
                <label htmlFor="stateName">&nbsp;</label>
                <button type="submit" className='saveStyleBtn' >{id == '0' ? 'Create' : 'Save'}</button>
            </div>
            </form> ) : (
            <></>
        )}
        <div className="form-group">
            <textarea id="message" className='message' name="message" value={description} placeholder="Enter your description" onChange={(e) => setDescription(e.target.value)}></textarea>
        </div>
        <br />
        {canPermission && canPermission.canView ? (
          <>
            <div className="searchContainer">
              <div className="searchInputContainer">
                  <label className="searchLabel" htmlFor="searchInput">Search:</label>
                  <input
                  id="searchInput"
                  className="searchPrivateInput"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  />
              </div>
            </div>
            <Table
              data={styles}
              columns={columns}
              onDelete={handleDelete}
              onEdit={handleEdit}
              canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
              canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
              onUndo={handleUndo}
            /> 
          </>
        
        ) : (
          <></>
        )}
    </div>
  )
}
