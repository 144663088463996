import React, {useState, useEffect} from 'react'
import './supplierInvoiceList.css'
import Table from '../../table/Table'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import SelectReact from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function SupplierInvoiceList() {
  const [supplierInvoices, setSupplierInvoices] = useState([]);
  const [id, setId] = useState(0);
  const [canPermission, setCanPermission] = useState();
  const navigate = useNavigate();
  const [link, setLink] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFromDateValid, setIsFromDateValid] = useState(true);
  const [isToDateValid, setIsToDateValid] = useState(true);
  const [type, setType] = useState(3);
  const [searchText, setSearchText] = useState('');

    useEffect(() => {
      const desiredPermissionPageName = 'SupplierInvoiceList'; // Replace with the desired permissionPageName value
      
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
      const currentDateTime = new Date();
      currentDateTime.setMonth(0); // Set the month to January (0 represents January)
      currentDateTime.setDate(1); // Set the day to the 1st
      currentDateTime.setHours(0, 0, 0, 0); // Set the time to the beginning of the day (midnight)

      setFromDate(currentDateTime);
      setToDate(new Date());

      // setLink("/SaveEmployee")
      setLink("/supplierPayments");
      setCanPermission(desiredRow);
      GetAllSupplierInvoices();
    }, []);
    
    async function GetAllSupplierInvoices(){
      const currentDate = new Date();
      const beginningOfYear = new Date(currentDate.getFullYear(), 0, 1);
      const formattedFromDate = fromDate ? fromDate.toLocaleDateString('en-US') : beginningOfYear.toLocaleDateString('en-US');
      const formattedToDate = toDate ? toDate.toLocaleDateString('en-US') : currentDate.toLocaleDateString('en-US');
      const apiUrl = ApiUrl + '/api/SupplierInvoices/GetAllSupplierInvoicesFiltered';
      const url = `${apiUrl}?type=${type}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`;

      const response = await fetch( url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
        },
      });
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setSupplierInvoices(data.item2);
      }
      else{
        setSupplierInvoices([]);
      }
    }

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'supplierName', title: 'Supplier'},
        { key: 'invoiceNumber', title: 'Invoice Number' },
        { key: 'invoiceDate', title: 'Invoice Date' },
        { key: 'salesRepName', title: 'Sales Rep' },
        // { key: 'measuredBy', title: 'MeasuredBy' },
        // { key: 'measuredDate', title: 'measuredDate' },
        { key: 'subTotal', title: 'Sub Total' },
        { key: 'tax', title: 'Tax' },
        { key: 'shipping', title: 'Shipping' },
        { key: 'deposit', title: 'Deposit' },
        { key: 'totalInvoice', title: 'Total' },
        { key: 'isPaid', title: 'Paid' },
        { key: 'paidAmount', title: 'Paid Amount' },
    ];

    const handleDelete = async (id) => {
        console.log(id);
        try {
          const response = await fetch(ApiUrl + '/api/SupplierInvoices/Delete?supplierInvoiceId=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                GetAllSupplierInvoices();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleEdit = (id) => {
        // navigate('/SaveEmployee/'+ id); // Replace '/edit-page' with the desired page URL
        console.log(id);
        navigate('/supplierInvoice/'+ id); // Replace '/edit-page' with the desired page URL
    }

    const handleFromDateChange = (date) => {
      if (toDate && date > toDate) {
        // setIsFromDateValid(false);
      } else {
        setFromDate(date);
        // setIsFromDateValid(true);
      }
    };
  
    const handleToDateChange = (date) => {
      if (fromDate && date < fromDate) {
        // setIsToDateValid(false);
      } else {
        setToDate(date);
        // setIsToDateValid(true);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(fromDate);
      if (fromDate === null || toDate === null ) {
        if(fromDate === null)
          setIsFromDateValid(false);
        else
          setIsFromDateValid(true);
        if(toDate === null)
          setIsToDateValid(false);
        else
          setIsToDateValid(true);
        return;
      }
      else{
          setIsFromDateValid(true);
          setIsToDateValid(true);
      }
      try {

        const formattedFromDate = fromDate.toLocaleDateString('en-US');
        const formattedToDate = toDate.toLocaleDateString('en-US');

        const apiUrl = ApiUrl + '/api/SupplierInvoices/GetAllSupplierInvoicesFiltered';
        const url = `${apiUrl}?type=${type}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`;

        const response = await fetch( url, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
          },
        });
        const data = await response.json();
        console.log(data);
        if (response.ok) {
          setSupplierInvoices(data.item2);
        }
        else{
          setSupplierInvoices([]);
        }
      } catch (error) {
          console.error(error);
      }
    }

    return (
      <div className='SupplierInvoiceList'>
        <h1>SupplierInvoiceList</h1>
        {canPermission && canPermission.canEdit ? (
          <>
            <div className="employeeTitleContainer">
              <Link to="/invoices">
                <button className="employeeAddButton">Create</button>
              </Link>
            </div>
            <form className="newInvoicesReportForm" onSubmit={handleSubmit} >
              <div className="invoiceReportForm">
                <div className="invoiceReportItem">
                  <label>Type</label>
                  <div className="newInvoiceReportRadio">
                    <input type="radio" name="type" id="all" value="3" checked={type === 3} onChange={(e) => setType(3)} />
                    <label htmlFor="all">All</label>
                    <input type="radio" name="type" id="unPaid" value="1" checked={type === 1} onChange={(e) => setType(1)} />
                    <label htmlFor="unPaid">UnPaid</label>
                    <input type="radio" name="type" id="paid" value="2" checked={type === 2} onChange={(e) => setType(2)} />
                    <label htmlFor="paid">Paid</label>
                  </div>
                </div>
                <div className="invoiceReportItem">
                  <label htmlFor="fromDate">From Date:</label>
                  <DatePicker
                    selected={fromDate}
                    onChange={handleFromDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select Date"
                    id="fromDate"
                    name="fromDate"
                    className={!isFromDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                    // showMonthYearPicker
                    scrollableMonthYearDropdown
                  />
                </div>
                <div className="invoiceReportItem">
                  <label htmlFor="toDate">To Date:</label>
                  <DatePicker
                    selected={toDate}
                    onChange={handleToDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select Date"
                    id="toDate"
                    name="toDate"
                    className={!isToDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={15}
                    // showMonthYearPicker
                    scrollableMonthYearDropdown
                  />
                </div>
                <div className='invoiceReportItem'>
                  <label htmlFor="fromDate">&nbsp;</label>
                  <button type="submit" className='InvoicesReportSubmitBtn' >Filter</button>
                </div>
              </div>
            </form>
          </> ) 
          : 
          <></>
        }
        {canPermission && canPermission.canView ? (
          <Table
            data={supplierInvoices}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
            canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
            // onUndo={handleUndo}
            // onCheckBoxChange={onCheckBoxChange}
            viewLink = "Payment"
            viewLinkPath = {link}
            /> 
        ) : (
          <></>
        )}
      </div>
    )
}
