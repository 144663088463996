import React, {useState, useEffect, useRef} from 'react'
import "./paymentMethod.css"
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function PaymentMethod() {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paymentMethodName, setPaymentMethodName] = useState('');
    const [description, setDescription] = useState('');
    const [id, setId] = useState('0');
    const [canPermission, setCanPermission] = useState();
    const [isPaymentMethodNameValid, setIsPaymentMethodNameValid] = useState(true);
    const [searchText, setSearchText] = useState('');
    const inputRef = useRef(null); // Create a ref for the input field

    useEffect(() => {
        const desiredPermissionPageName = 'PaymentMethods'; // Replace with the desired permissionPageName value
      
        const desiredRow = GlobalPermission.find(
          (item) => item.permissionPageName === desiredPermissionPageName
        );
      
        setCanPermission(desiredRow);
        GetAllPaymentMethods();
    }, [searchText]);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'paymentMethodName', title: 'Payment Method' },
        { key: 'description', title: 'Description' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
        // Perform edit action using the ID of the row being edited
        const filtered = paymentMethods.filter(item => item.id === id);
        setPaymentMethodName(filtered[0].paymentMethodName);
        setId(filtered[0].id);
        setDescription(filtered[0].description)
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/PaymentMethods/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllPaymentMethods();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (paymentMethodName.trim() === '') {
          if(paymentMethodName.trim() === '')
            setIsPaymentMethodNameValid(false);
          else
            setIsPaymentMethodNameValid(true);
          // if(hex_Code.trim() === '')
          //   setIsColorHexCodeValid(false);
          // else
          //   setIsColorHexCodeValid(true);
          return;
        }
        else{
            setIsPaymentMethodNameValid(true);
          // setIsColorHexCodeValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/PaymentMethods/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    PaymentMethodName: paymentMethodName,
                    Description: description,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllPaymentMethods();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsPaymentMethodNameValid(false);
                // setIsColorHexCodeValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setPaymentMethodName('');
        setDescription('');
        setIsPaymentMethodNameValid(true);
        // setIsColorHexCodeValid(true);
        inputRef.current.focus(); // Set focus inside the success callback
    }

    function GetAllPaymentMethods(){
        fetch(ApiUrl + '/api/PaymentMethods/GetAllPaymentMethods', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          console.log(data.item2);
          const filteredData = data.item2.filter(item =>
            item.paymentMethodName.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase())
          );
          setPaymentMethods(filteredData);
        //   console.log("Color Page");
        });
    }

    const handleUndo = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/PaymentMethods/Undo?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllPaymentMethods();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

  return (
    <div className='paymentMethod'>
        <h1>Payment Method</h1>

        {canPermission && canPermission.canEdit ? (
        <form className="PaymentMethodForm" onSubmit={handleSubmit}>
        <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div className="PaymentMethodItem">
                <label htmlFor="PaymentMethodName">Color:</label>
                <input type="text" className={!isPaymentMethodNameValid ? 'InvalidInputtext' : 'validInputtext'} ref={inputRef} value={paymentMethodName} placeholder="Name" name="PaymentMethodName" id="PaymentMethodName" onChange={(e) => setPaymentMethodName(e.target.value)} />
            </div>
            <div className="PaymentMethodItem">
                <label htmlFor="description">Description:</label>
                <input type="text" className='validInputtext' value={description} placeholder="Description" name="description" id="colorHex_code" onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className='PaymentMethodItem'>
                <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
                <button type="submit" className='savePaymentMethodBtn' >{id == '0' ? 'Create' : 'Save'}</button>
            </div>
            </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
            <>
            <div className="searchContainer">
                <div className="searchInputContainer">
                    <label className="searchLabel" htmlFor="searchInput">Search:</label>
                    <input
                    id="searchInput"
                    className="searchPrivateInput"
                    type="text"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
            </div>
            <Table
                data={paymentMethods}
                columns={columns}
                onDelete={handleDelete}
                onEdit={handleEdit}
                canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                onUndo={handleUndo}
            /> 
            </>
            ) : (
                <></>
        )}
      
    </div>
  )
}
