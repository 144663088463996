import React, {useState, useEffect} from 'react'
import './productPriceList.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import SelectReact from 'react-select';
import DatePicker from 'react-datepicker';
import PriceListTable from '../../table/PriceListTable';
import ReactDOMServer from 'react-dom/server';
import PrintableTable from '../reports/PrintableTable';

export default function ProductPriceList() {
  const [productPriceList, setproductPriceList] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [canPermission, setCanPermission] = useState();
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [categories, setCategories] = useState([]);
  const [restcurrentpage, setRestcurrentpage] = useState(false);
  const [listToSave, setListToSave] = useState([]);

  useEffect(() => {
    const desiredPermissionPageName = 'ProductPriceList'; // Replace with the desired permissionPageName value
  
    const desiredRow = GlobalPermission.find(
      (item) => item.permissionPageName === desiredPermissionPageName
    );
    setCanPermission(desiredRow);

    GetAllCategories();
  }, []);

  useEffect(() => {
    console.log(listToSave);
  },[listToSave]);

  useEffect(() => {
    setSelectedData([]);
    setproductPriceList([]);
    if(selectedCategoryId !== 0){
      GetAllFlooringProductPriceListByCategoryId(selectedCategoryId);
    }
  }, [selectedCategoryId]);
  
  function GetAllCategories() {
    fetch(ApiUrl + '/api/Categories/GetAllCategories', {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      }
    })
    .then(response => response.json())
    .then(data => {
      var filteredCategories = data.item2.filter(x => x.isDeleted === false);
      setCategories(filteredCategories);
      setSelectedCategoryId(filteredCategories[0].id);
    });
  }

  function GetAllFlooringProductPriceListByCategoryId(categoryId){
    fetch(ApiUrl + '/api/ProductPriceLists/GetAllFlooringProductPriceListByCategoryId?categoryId='+categoryId, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem("token")
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data.item2);
      setproductPriceList(data.item2);
      setSelectedData(data.item2.filter(ca => ca.categoryId === categoryId));
    });
  }

  const columns = [
    { key: 'id', title: 'ID' },
    { key: 'styleName', title: 'Style' },
    { key: 'colorName', title: 'Color' },
    { key: 'currencyCode', title: 'Currency' },
    { key: 'minimumPricePerUnit', title: 'Price Per Unit' },
  ];

  function handleChangeCategoryForMenus(categoryIdMenus) {
    setSelectedData([]);
    setproductPriceList([]);
    setSelectedCategoryId(parseInt(categoryIdMenus, 10));
    setRestcurrentpage(true);
  }

  const onPriceChange = async (categoryId, styleId, colorId, currencyId, price) => {
    console.log(categoryId + "-" + styleId + "-" + colorId + "-" + price);
    setRestcurrentpage(false);
  
    const itemExists = listToSave.some(item => item.categoryId === categoryId && item.styleId === styleId && item.colorId === colorId);
    const m = productPriceList.find(x => x.categoryId === categoryId && x.styleId === styleId && x.colorId === colorId);
    const Id = m.id;

    if (itemExists) {
      const updatedList = listToSave.map(item => {
        if (item.categoryId === categoryId && item.styleId === styleId && item.colorId === colorId) {
          return {
            ...item,
            id: Id,
            minimumPricePerUnit: price
          };
        }
        return item;
      });
  
      setListToSave(updatedList);
    } else {
      const newItem = {
        Id,
        categoryId,
        styleId,
        colorId,
        currencyId,
        minimumPricePerUnit: price
      };
  
      setListToSave(prevList => [...prevList, newItem]);
    }
  
    const updatedProduct = productPriceList.map(item => {
      if (item.categoryId === categoryId && item.styleId === styleId && item.colorId === colorId) {
        return {
          ...item,
          minimumPricePerUnit: price
        };
      }
      return item;
    });
  
    setproductPriceList(updatedProduct);
    setSelectedData(updatedProduct.filter(c => c.categoryId === selectedCategoryId));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(listToSave);
    try {
      const response = await fetch( ApiUrl +'/api/ProductPriceLists/SaveList', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
          },
          body: JSON.stringify(listToSave)
      });
      const data = await response.json();
      console.log(data);
      if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
          });
      } else {
        Swal.fire({
          icon: 'error',
          title: data.message,
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          // setIsColorHexCodeValid(false);
        });
      }
    } catch (error) {
        console.error(error);
    }
  }

  const handlePrintClick = (e) => {
    e.preventDefault();
    const printableTableComponent = (
      <PrintableTable data={productPriceList} columns={columns} title={"Product Price List"} />
    );
  
    const newWindow = window.open("", "_blank");
    newWindow.document.open();
    newWindow.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <title>Sales People</title>
          <link rel="stylesheet" type="text/css" href="printtableTable.css">
        </head>
        <body>${ReactDOMServer.renderToString(printableTableComponent)}</body>
      </html>`);
    newWindow.print();
    newWindow.document.close();
  };

  return (
    <div className='ProductPriceList'>
        <h1>ProductPriceList</h1>
        {canPermission && canPermission.canView ? (
          <>
            <div className="categoryTabs">
              {categories.map((category) => (
                <div
                  key={category.id}
                  className={`categoryTab ${selectedCategoryId === category.id ? 'activeCategory' : ''}`}
                  onClick={() => handleChangeCategoryForMenus(category.id)}
                >
                  {category.name}
                </div>
              ))}
            </div>
            <form className="ProductPriceListForm" onSubmit={handleSubmit}>
              <button type="button" className='printBtn' onClick={handlePrintClick}>Print</button>
              <button type="submit" className='savePriceBtn' >Save</button>
            </form>
            <PriceListTable
              data={selectedData}
              columns={columns}
              onPriceChange={onPriceChange}
              restcurrentpage={restcurrentpage}
            /> 
          </>
        ) : (
        <></>
      )}
    </div>
  )
}
