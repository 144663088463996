import React, { useEffect, useState } from 'react'
import "./chart.css"
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ApiUrl } from '../../Global';


export default function Chart({title, data, dataKey, grid, years, roleName}) {
  const [incomeChartData, setIncomeChartData] = useState([]);

  useEffect(() => {
    console.log("Featured: ");
    console.log(years);
    fetch(ApiUrl + '/api/Dashboard/GetIncomeChartDashboard?years=' + years.join(','), {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
    })
    .then(response => response.json())
    .then(data => {
        console.log(data.item2)
        const dataarr = data.item2.map((obj) => ({
          name: obj.name,
          "Total": (obj.total),
        }));
        console.log(dataarr);
        setIncomeChartData(dataarr);
    });
  }, [years]);

  return (
    <>
      {(roleName === "admin" || roleName === "Sales") && (
        <div className='chart'>
          <h3 className="chartTitle">{title}</h3>
          <ResponsiveContainer width="100%" aspect={4 / 1}>
              <LineChart data={incomeChartData}>
                  <XAxis dataKey="name" stroke='#5550bd' />
                  <Line type="monotone" dataKey={dataKey} stroke='#5550bd' />
                  <Tooltip formatter={(value) => new Intl.NumberFormat().format(value)} />
                  {grid && <CartesianGrid stroke='#e0dfdf' strokeDasharray="5 5" />}
              </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
        
  )
}
