import React, { useEffect, useState } from 'react'
import './estimate.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function Estimate() {
  const [streetNumber, setStreetNumber] = useState('');
  const [streetName, setStreetName] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [stateId, setStateId] = useState(0);
  const [id, setId] = useState(0);
  const [states, setStates] = useState([]);
  const [isStreetNumberValid, setIsStreetNumberValid] = useState(true);
  const [isStreetNameValid, setIsStreetNameValid] = useState(true);
  const [isCityValid, setIsCityValid] = useState(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');//searchStreetName
  const [searchStreetName, setSearchStreetName] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [searchZipCode, setSearchZipCode] = useState('');
  const [canPermission, setCanPermission] = useState();
  const [isFileValid, setIsFileValid] = useState(true);

  useEffect(() => {
    const desiredPermissionPageName = 'Estimates'; // Replace with the desired permissionPageName value
    
    const desiredRow = GlobalPermission.find(
      (item) => item.permissionPageName === desiredPermissionPageName
    );
    
    setCanPermission(desiredRow);

    GetAllStates();
    GetAllEstimates();
  }, [searchText, searchStreetName, searchCity, searchZipCode]);

  const handleNew = async (e) => {
    e.preventDefault();
    clearTextBoxes();
  }

  function clearTextBoxes(){
    setStreetNumber('');
    setStreetName('');
    setCity('');
    setZipCode('');
    setStateId(states[0].id);
    setSelectedFile(null); // Reset the selected file to null
    setId(0);
    setIsStreetNumberValid(true);
    setIsStreetNameValid(true);
    setIsCityValid(true);
    // setIsZipCodeValid(true);
    setIsFileValid(true);
  }

  function GetAllStates(){
    fetch(ApiUrl + '/api/States/GetAllStates', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
          // console.log(data)
          const filteredEstimate = data.item2.filter(est => est.isDeleted === false);
          setStates(filteredEstimate);
          setStateId(data.item2[0].id);
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (streetNumber.trim() === '' || streetName.trim() === '' ||
    city.trim() === '' || selectedFile == null) {
        if(streetNumber.trim() === '')
            setIsStreetNumberValid(false);
        else
            setIsStreetNumberValid(true);
        if(streetName.trim() === '')
            setIsStreetNameValid(false);
        else
            setIsStreetNameValid(true);
        if(city.trim() === '')
            setIsCityValid(false);
        else
            setIsCityValid(true);
        // if(zipCode.trim() === '')
        //     setIsZipCodeValid(false);
        // else
        //     setIsZipCodeValid(true);
        if(selectedFile == null)
          setIsFileValid(false);
        else
          setIsFileValid(true);
        return;
    }
    else{
        setIsStreetNumberValid(true);
        setIsStreetNameValid(true);
        setIsCityValid(true);
        // setIsZipCodeValid(true);
        setIsFileValid(true);
    }
    try {
      console.log("passed")
      const formData = new FormData();
      formData.append('id', id);
      formData.append('streetNumber', streetNumber);
      formData.append('streetName', streetName);
      formData.append('city', city);
      formData.append('zipCode', zipCode);
      formData.append('stateId', stateId);
      formData.append('file', selectedFile); // Assuming you have a variable named 'selectedFile' containing the file data
  
      const response = await fetch(ApiUrl + '/api/Estimates/Save', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        },
        body: formData,
      });
  
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Estimate has been saved',
          showConfirmButton: false,
          timer: 2000
        }).then(() => {
          clearTextBoxes();
          GetAllEstimates();
        });
      } else {
        const errorData = await response.json();
        console.error(errorData);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const columns = [
    { key: 'id', title: 'ID' },
    { key: 'streetNumber', title: 'Street Number' },
    { key: 'streetName', title: 'Street Name' },
    { key: 'city', title: 'City' },
    { key: 'zipCode', title: 'Zip Code' },
    { key: 'stateName', title: 'State Name' },
    { key: 'fileName', title: 'File Name' },
  ];

  const handleDelete = async (id) => {
    // Perform delete action using the selected row IDs
    //console.log(id)
    try {
        const response = await fetch(ApiUrl + '/api/Estimates/Delete?Id=' + id, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        //console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: 'Estimates has been Deleted!',
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              clearTextBoxes();
              GetAllEstimates();
          });
        } else {
          console.error(data);
        }
    } catch (error) {
        console.error(error);
    }
};

const handleEdit = (id) => {
    // console.log(supplier);
    const filtered = data.filter(item => item.id === id);
    setStreetNumber(filtered[0].streetNumber);
    setStreetName(filtered[0].streetName)
    setCity(filtered[0].city);
    setZipCode(filtered[0].zipCode);
    setStateId(filtered[0].stateId);
    setId(id);
    setStateId(filtered[0].stateId);
};

function GetAllEstimates() {
  let Url = '/api/Estimates/GetAllEstimates';

  fetch(ApiUrl + Url, {
    headers: {
      'Authorization': `Bearer ` + sessionStorage.getItem("token")
    }
  })
  .then(response => response.json())
  .then(data => {
    const filteredData = data.item2.filter(item => {
  if (searchText && searchStreetName && searchCity && searchZipCode) {
    // Apply filter based on all search boxes
    return (
      item.streetNumber.toLowerCase().includes(searchText.toLowerCase()) &&
      item.streetName.toLowerCase().includes(searchStreetName.toLowerCase()) &&
      item.city.toLowerCase().includes(searchCity.toLowerCase()) &&
      item.zipCode.includes(searchZipCode)
    );
  } else if (searchText && searchStreetName && searchCity) {
    // Apply filter based on searchText, searchStreetName, and searchCity
    return (
      item.streetNumber.toLowerCase().includes(searchText.toLowerCase()) &&
      item.streetName.toLowerCase().includes(searchStreetName.toLowerCase()) &&
      item.city.toLowerCase().includes(searchCity.toLowerCase())
    );
  } else if (searchText && searchStreetName) {
    // Apply filter based on searchText and searchStreetName
    return (
      item.streetNumber.toLowerCase().includes(searchText.toLowerCase()) &&
      item.streetName.toLowerCase().includes(searchStreetName.toLowerCase())
    );
  } else if (searchText && searchCity) {
    // Apply filter based on searchText and searchCity
    return (
      item.streetNumber.toLowerCase().includes(searchText.toLowerCase()) &&
      item.city.toLowerCase().includes(searchCity.toLowerCase())
    );
  } else if (searchStreetName && searchCity) {
    // Apply filter based on searchStreetName and searchCity
    return (
      item.streetName.toLowerCase().includes(searchStreetName.toLowerCase()) &&
      item.city.toLowerCase().includes(searchCity.toLowerCase())
    );
  } else if (searchText && searchZipCode) {
    // Apply filter based on searchText and searchZipCode
    return (
      item.streetNumber.toLowerCase().includes(searchText.toLowerCase()) &&
      item.zipCode.includes(searchZipCode)
    );
  } else if (searchStreetName && searchZipCode) {
    // Apply filter based on searchStreetName and searchZipCode
    return (
      item.streetName.toLowerCase().includes(searchStreetName.toLowerCase()) &&
      item.zipCode.includes(searchZipCode)
    );
  } else if (searchCity && searchZipCode) {
    // Apply filter based on searchCity and searchZipCode
    return (
      item.city.toLowerCase().includes(searchCity.toLowerCase()) &&
      item.zipCode.includes(searchZipCode)
    );
  } else if (searchText) {
    // Apply filter based on searchText only
    return item.streetNumber.toLowerCase().includes(searchText.toLowerCase());
  } else if (searchStreetName) {
    // Apply filter based on searchStreetName only
    return item.streetName.toLowerCase().includes(searchStreetName.toLowerCase());
  } else if (searchCity) {
    // Apply filter based on searchCity only
    return item.city.toLowerCase().includes(searchCity.toLowerCase());
  } else if (searchZipCode) {
    // Apply filter based on searchZipCode only
    return item.zipCode.includes(searchZipCode);
  } else {
    // No search filter applied, return all data
    return true;
  }
});

    setData(filteredData);
  });
}
  
const formatStreetNumber = (input) => {
  const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters

  const match = cleaned.match(/^(\d{1,5})$/); // Match the desired format (1-5 digits)
  if (match) {
    return match[1]; // Return the matched digits
  }

  return cleaned; // Return the input as is if it doesn't match the desired format
};


const formatZipCode = (input) => {
  const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters

  const match = cleaned.match(/^(\d{5})(\d{4})?$/); // Match the desired format (5 or 9 digits)
  if (match) {
    return match[1] + (match[2] ? `-${match[2]}` : ''); // Apply the format (XXXXX or XXXXX-XXXX)
  }

  return cleaned; // Return the input as is if it doesn't match the desired format
};

const handleFile = (id) => {
  let Url = '/api/Estimates/GetEstimatePathById?Id=' + id;
  
    fetch(ApiUrl + Url, {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        window.open(`${ApiUrl}/api/Estimates/files/${data.item2.fileName}`, '_blank');
      });
};

  return (
    <div className='estimate'>
        <h1>Estimate</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newEstimateForm" onSubmit={handleSubmit} >
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div className="newEstimateItem">
                <label htmlFor='streetNumber'>Street Number</label>
                <input type="text" placeholder="Street Number" name="streetNumber" id="streetNumber" className={!isStreetNumberValid ? 'InvalidInputtext' : 'validInputtext'} value={streetNumber} onChange={(e) => setStreetNumber(formatStreetNumber(e.target.value))} />
            </div>
            <div className="newEstimateItem">
                <label htmlFor='streetName'>Street Name</label>
                <input type="text" placeholder="Street Name" name="streetName" id="streetName" className={!isStreetNameValid ? 'InvalidInputtext' : 'validInputtext'} value={streetName} onChange={(e) => setStreetName(e.target.value)} />
            </div>
            <div className="newEstimateItem">
                <label htmlFor='city'>City</label>
                <input type="text" placeholder='City' name="city" id="city" className={!isCityValid ? 'InvalidInputtext' : 'validInputtext'} value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div className='newEstimateItem'>
                <label htmlFor='state'>State</label>
                <select className='newStateSelect' name="state" id="state" value={stateId} onChange={(e) => setStateId(e.target.value)}>
                    {states.map((state) => (
                        <option key={state.id} value={state.id}>{state.name + ', ' + state.code}</option>
                    ))}
                </select>
            </div>
            <div className="newEstimateItem">
                <label htmlFor='zipCode'>Zip Code</label>
                <input type="text" placeholder='Zip Code' name="zipCode" id="zipCode" className='validInputtext' value={zipCode} onChange={(e) => setZipCode(formatZipCode(e.target.value))} />
            </div>
            <div className="newEstimateItem">
              <label htmlFor="file">Upload File</label>
              <input type="file" name="file" id="file" className={!isFileValid ? 'InvalidInputtext' : 'validInputtext'} onChange={handleFileChange} />
            </div>
            <div className='newEstimateItem'>
                <label> &nbsp;</label>
            </div>
            <div className='newEstimateItem'>
                <label> &nbsp;</label>
            </div>
            <div className='newEstimateItem'>
                <label> &nbsp;</label>
                <button type='button' className="newEstimateBtn" id="newEstimateBtn" onClick={handleNew}>New</button>
            </div>
            <div className='newEstimateItem'>
                <label> &nbsp;</label>
                <button type='submit' className="newEstimateButton">{id == '0' ? 'Create' : 'Save'}</button>
            </div>
        </form> ) : (
          <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
        <>
        <div className="searchEstimateContainer">
            <div className="searchInputContainer">
                {/* <label className="searchLabel" htmlFor="searchInput">Search:</label> */}
                <input
                id="searchInput"
                className="searchSalesInput"
                type="text"
                placeholder="Search Street Number"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <div className="searchInputContainer">
                {/* <label className="searchLabel" htmlFor="searchInput">Search:</label> */}
                <input
                id="searchInput"
                className="searchSalesInput"
                type="text"
                placeholder="Search Street Name"
                value={searchStreetName}
                onChange={(e) => setSearchStreetName(e.target.value)}
                />
            </div>
            <div className="searchInputContainer">
              <input
                id="searchInput"
                className="searchSalesInput"
                type="text"
                placeholder="Search City"
                value={searchCity}
                onChange={(e) => setSearchCity(e.target.value)}
              />
            </div>
            <div className="searchInputContainer">
              <input
                id="searchInput"
                className="searchSalesInput"
                type="text"
                placeholder="Search ZipCode"
                value={searchZipCode}
                onChange={(e) => setSearchZipCode(e.target.value)}
              />
            </div>
            {/* <button onClick={handlePrintClick} className="PrintBtn"> Print</button> */}
        </div>
        <Table
          data={data}
          columns={columns}
          onDelete={handleDelete}
          onEdit={handleEdit}
          onFile={handleFile}
          viewFile="File"
          canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
          canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
        />
        </> ) : (
          <></>
        )}
    </div>
  )
}
