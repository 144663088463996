import React, {useState, useEffect} from 'react'
import './employmentStatus.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function EmploymentStatus() {
    const [employmentStatuses, setEmploymentStatuses] = useState([]);
    const [name, setName] = useState('');
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    const [isNameValid, setIsNameValid] = useState(true);

    useEffect(() => {
      const desiredPermissionPageName = 'EmploymentStatus'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
    
      setCanPermission(desiredRow);
      GetAllEmploymentStatuses();
    }, []);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
      // Perform edit action using the ID of the row being edited
      const filtered = employmentStatuses.filter(item => item.id === id);
      setName(filtered[0].name);
      setId(filtered[0].id);
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/EmploymentStatuses/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllEmploymentStatuses();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name.trim() === '') {
          if(name.trim() === '')
            setIsNameValid(false);
          else
            setIsNameValid(true);
          return;
        }
        else{
            setIsNameValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/EmploymentStatuses/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    Id: id,
                    Name: name,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllEmploymentStatuses();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsNameValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setName('');
        setIsNameValid(true);
    }

    function GetAllEmploymentStatuses(){
      fetch(ApiUrl + '/api/EmploymentStatuses/GetAllEmploymentStatuses', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        setEmploymentStatuses(data.item2);
      });
    }

    const handleUndo = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/EmploymentStatuses/Undo?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllEmploymentStatuses();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

  return (
    <div className='employmentStatus'>
        <h1>EmploymentStatus</h1>
        {canPermission && canPermission.canEdit ? (
            <form className="EmploymentStatusForm" onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
                <div className="EmploymentStatusItem">
                    <label htmlFor="name">Name:</label>
                    <input type="text" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={name} placeholder="Name" name="name" id="name" onChange={(e) => setName(e.target.value)} />
                </div>
                <div className='EmploymentStatusItem'>
                    <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
                    <button type="submit" className='saveEmpStatusBtn' >{id == '0' ? 'Create' : 'Save'}</button>
                </div>
            </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
            <Table
                data={employmentStatuses}
                columns={columns}
                onDelete={handleDelete}
                onEdit={handleEdit}
                canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                onUndo={handleUndo}
            /> 
            ) : (
                <></>
        )}
    </div>
  )
}
