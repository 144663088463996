import React, { useState, useEffect } from 'react'
import '../invoicesReports/invoicesReport.css';
import DatePicker from 'react-datepicker';
import { GlobalPermission, ApiUrl } from '../../../../Global';
import Swal from 'sweetalert2';
import Table from '../../../table/Table';
import PrintableTable from '../PrintableTable';
import ReactDOMServer from 'react-dom/server';
import SelectReact from 'react-select';

export default function InvoicesReport() {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [type, setType] = useState(1);
    const [canPermission, setCanPermission] = useState();
    const [globalInvoices, setGlobalInvoices] = useState([]);
    const [filteredGlobalInvoices, setFilteredGlobalInvoices] = useState([]);
    const [isFromDateValid, setIsFromDateValid] = useState(true);
    const [isToDateValid, setIsToDateValid] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [customers, setCustomers] = useState([]);
    const [customerId, setCustomerId] = useState(0);
    const [customerOptionValue, setCustomerOptionValue] = useState();
    const currentDateTime = new Date();

    useEffect(() => {
        const desiredPermissionPageName = 'InvoicesReport'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);
        setFromDate(currentDateTime);
        setToDate(currentDateTime);
        GetAllCustomers();
    }, []);
    useEffect(() => {
        const filteredData = globalInvoices.filter(item =>
            item.invoiceNumberYear.toLowerCase().includes(searchText.toLowerCase()) ||
            item.customerName.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredGlobalInvoices(filteredData);
    }, [searchText]);

    function GetAllCustomers(){
      fetch(ApiUrl + '/api/Customers/GetAllCustomersWithState', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        const filtered = data.item2.filter(customer => customer.isDeleted === false);
        // console.log(filtered);
        setCustomers(filtered);
      });
    }

    const customersOptions = customers.map((cust) => ({
      value: cust.id,
      label: cust.name,
    }));
    const handleCustomerSelectChange = (selectedOption) => {
      console.log(selectedOption);
      if(selectedOption === null){
        setCustomerOptionValue();
        setCustomerId(0);
      }
      else{
        setCustomerOptionValue(selectedOption);
        setCustomerId(selectedOption.value);
      }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(fromDate);
        if (fromDate === null || toDate === null ) {
          if(fromDate === null)
            setIsFromDateValid(false);
          else
            setIsFromDateValid(true);
          if(toDate === null)
            setIsToDateValid(false);
          else
            setIsToDateValid(true);
          return;
        }
        else{
            setIsFromDateValid(true);
            setIsToDateValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/Invoices/GetAllGlobalInvoicesReport', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    Type: type,
                    FromDate: fromDate,
                    ToDate: toDate,
                    CustomerId: customerId,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    console.log(data.item2);
                    setGlobalInvoices(data.item2);
                    setFilteredGlobalInvoices(data.item2);
                    if (data.item2 && data.item2.length > 0) {
                        const arrayToAddTotal = data.item2;

                        const total = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.total, 0).toFixed(2);
                        const labor = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.labor, 0).toFixed(2);
                        const totalMaterial = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.totalMaterial, 0).toFixed(2);
                        const taxAmount = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.taxAmount, 0).toFixed(2);
                        const deposit = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.deposit, 0).toFixed(2);
                        const cod = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.balanceCOD, 0).toFixed(2);
                        const paidAmount = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.paidAmount, 0).toFixed(2);
                        const unPaid = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.unPaid, 0).toFixed(2);

                        const totalColumn = { balanceCOD: cod, createdBy: '', createdDate: '', currencyId: 1, customerCity: ''
                            , customerId: 0, customerName: '', customerPhoneNumber: 0, customerStateId: 1, customerStateName: ''
                            , customerStreetName: '', customerStreetNumber: '', customerZipCode: '', deposit: deposit, employeeId: 0
                            , employeeIsDeleted: false, employeeName: '', employeePhoneNumber: '', id: 0, installDate: ''
                            , installerId: 0, installerName: '', invoiceNumber: '', invoiceNumberYear: 'Total', invoiceYear: 0
                            , isDeleted: false, isPaid: false, labor: labor, measuredBy: '', measuredDate: '', modifiedBy: null
                            , modifiedDate: null, near: '', paidAmount: paidAmount, taxAmount: taxAmount, taxPercentage: 0, total: total
                            , totalMaterial: totalMaterial, unPaid: unPaid};
                        arrayToAddTotal.push(totalColumn);
                    }
                    
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                // setIsColorNameValid(false);
                // setIsColorHexCodeValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const columns = [
        // { key: 'id', title: 'ID' },
        { key: 'invoiceNumberYear', title: 'Invoice Number' },
        { key: 'customerName', title: 'Customer' },
        { key: 'employeeName', title: 'SalesPerson' },
        // { key: 'measuredBy', title: 'MeasuredBy' },
        // { key: 'measuredDate', title: 'measuredDate' },
        { key: 'labor', title: 'Labor' },
        { key: 'totalMaterial', title: 'Total Material' },
        { key: 'taxAmount', title: 'Tax Amount' },
        { key: 'total', title: 'Total' },
        { key: 'deposit', title: 'Deposit' },
        { key: 'balanceCOD', title: 'C.O.D' },
        { key: 'paidAmount', title: 'Paid Amount' },
        { key: 'unPaid', title: 'UnPaid' },
        { key: 'isPaid', title: 'Paid' },
        { key: 'companyCode', title: 'company' },
    ];

    const columnsToPrint = [
        // { key: 'id', title: 'ID' },
        { key: 'invoiceNumberYear', title: 'Invoice Number' },
        { key: 'customerName', title: 'Customer' },
        { key: 'employeeName', title: 'SalesPerson' },
        // { key: 'measuredBy', title: 'MeasuredBy' },
        // { key: 'measuredDate', title: 'measuredDate' },
        { key: 'labor', title: 'labor' },
        { key: 'totalMaterial', title: 'Total Material' },
        { key: 'taxAmount', title: 'Tax Amount' },
        { key: 'total', title: 'Total' },
        { key: 'deposit', title: 'Deposit' },
        { key: 'balanceCOD', title: 'C.O.D' },
        { key: 'paidAmount', title: 'Paid Amount' },
        { key: 'unPaid', title: 'UnPaid' },
        // { key: 'isPaid', title: 'Paid' },
        { key: 'companyCode', title: 'company' },

    ];

    const handlePrintClick = () => {
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        const fromMonthYear = `${fromDateObj.getMonth() + 1}/${fromDateObj.getFullYear()}`;
        const toMonthYear = `${toDateObj.getMonth() + 1}/${toDateObj.getFullYear()}`;
        const title = `${type === 1 ? 'UnPaid' : (type === 2 ? 'Paid' : 'All')} Invoices From ${fromMonthYear} to ${toMonthYear}`;

        const printableTableComponent = (
          <PrintableTable data={globalInvoices} columns={columnsToPrint} title={title} />
        );
    
        const newWindow = window.open("", "_blank");
        newWindow.document.open();
        newWindow.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <title>Sales People</title>
              <link rel="stylesheet" type="text/css" href="printtableTable.css">
            </head>
            <body>${ReactDOMServer.renderToString(printableTableComponent)}</body>
          </html>`);
        newWindow.print();
        newWindow.document.close();
    };

    const handleFromDateChange = (date) => {
      if (toDate && date > toDate) {
        // setIsFromDateValid(false);
      } else {
        setFromDate(date);
        // setIsFromDateValid(true);
      }
    };
  
    const handleToDateChange = (date) => {
      if (fromDate && date < fromDate) {
        // setIsToDateValid(false);
      } else {
        setToDate(date);
        // setIsToDateValid(true);
      }
    };

  return (
    <div className='invoicesReport'>
        <h1>InvoicesReports</h1>
        <form className="newInvoicesReportForm" onSubmit={handleSubmit} >
            <div className="invoiceReportForm">
                <div className="invoiceReportItem">
                    <label>Type</label>
                    <div className="newInvoiceReportRadio">
                        <input type="radio" name="type" id="unPaid" value="1" checked={type === 1} onChange={(e) => setType(1)} />
                        <label htmlFor="unPaid">UnPaid</label>
                        <input type="radio" name="type" id="paid" value="2" checked={type === 2} onChange={(e) => setType(2)} />
                        <label htmlFor="paid">Paid</label>
                        <input type="radio" name="type" id="all" value="3" checked={type === 3} onChange={(e) => setType(3)} />
                        <label htmlFor="all">All</label>
                    </div>
                </div>
                <div className="invoiceReportItem">
                    <label htmlFor="fromDate">From Date:</label>
                    <DatePicker
                        selected={fromDate}
                        onChange={handleFromDateChange}
                        dateFormat="MM/yyyy"
                        placeholderText="Select Date"
                        id="fromDate"
                        name="fromDate"
                        className={!isFromDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthYearPicker
                        scrollableMonthYearDropdown
                    />
                </div>
                <div className="invoiceReportItem">
                    <label htmlFor="fromDate">To Date:</label>
                    <DatePicker
                        selected={toDate}
                        onChange={handleToDateChange}
                        dateFormat="MM/yyyy"
                        placeholderText="Select Date"
                        id="toDate"
                        name="toDate"
                        className={!isToDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthYearPicker
                        scrollableMonthYearDropdown
                    />
                </div>
                <div className='invoiceReportItem'>
                    <label htmlFor="fromDate">&nbsp;</label>
                    <button type="submit" className='InvoicesReportSubmitBtn' >Calculate</button>
                </div>
            </div>
            <div>
              <div className="invoiceReportItemOption">
                <label htmlFor="Customer">Customer:</label>
                <SelectReact
                  className="quarter"
                  classNamePrefix="select"
                  isClearable
                  isSearchable={true}
                  value={customerOptionValue}
                  name="Customer"
                  options={customersOptions}
                  onChange={handleCustomerSelectChange}
                />
              </div>
            </div>
        </form>
        {canPermission && canPermission.canView ? (
            <>
                <div className="searchContainer">
                    <div className="searchInputContainer">
                        <label className="searchLabel" htmlFor="searchInput">Search:</label>
                        <input
                            id="searchInput"
                            className="searchPrivateInput"
                            type="text"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                    <button onClick={handlePrintClick} className="PrintBtn"> Print</button>
                </div>

                <Table
                    data={filteredGlobalInvoices}
                    columns={columns}
                /> 
            </>
          ) : (
            <></>
      )}
    </div>
  )
}
