import React, {useState, useEffect} from 'react'
import '../supplierInvoiceReports/supplierInvoiceReport.css';
import DatePicker from 'react-datepicker';
import { GlobalPermission, ApiUrl } from '../../../../Global';
import Swal from 'sweetalert2';
import Table from '../../../table/Table';
import PrintableTable from '../PrintableTable';
import ReactDOMServer from 'react-dom/server';
import SelectReact from 'react-select';

export default function SupplierInvoiceReport() {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [type, setType] = useState(1);
    const [canPermission, setCanPermission] = useState();
    const [globalSupplierInvoices, setGlobalSupplierInvoices] = useState([]);
    const [filteredGlobalSupplierInvoices, setFilteredGlobalSupplierInvoices] = useState([]);
    const [isFromDateValid, setIsFromDateValid] = useState(true);
    const [isToDateValid, setIsToDateValid] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [supplierId, setSupplierId] = useState(0);
    const [supplierOptionValue, setSupplierOptionValue] = useState();
    const currentDateTime = new Date();

    useEffect(() => {
        const desiredPermissionPageName = 'supplierInvoiceReport'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);
        setFromDate(currentDateTime);
        setToDate(currentDateTime);
        GetAllSuppliers();
    }, []);

    useEffect(() => {
        const filteredData = globalSupplierInvoices.filter(item =>
            (item.invoiceNumberYear && item.invoiceNumberYear.toLowerCase().includes(searchText.toLowerCase())) ||
            (item.supplierName && item.supplierName.toLowerCase().includes(searchText.toLowerCase()))
          );
          
        setFilteredGlobalSupplierInvoices(filteredData);
    }, [searchText]);

    function GetAllSuppliers(){
        fetch(ApiUrl + '/api/Suppliers/GetAllSuppliers', {
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        })
        .then(response => response.json())
        .then(data => {
          const filtered = data.item2.filter(supplier => supplier.isDeleted === false);
          // console.log(filtered);
          setSuppliers(filtered);
        });
    }

    const suppliersOptions = suppliers.map((supp) => ({
        value: supp.id,
        label: supp.name,
    }));
    const handleSupplierSelectChange = (selectedOption) => {
        console.log(selectedOption);
        if(selectedOption === null){
            setSupplierOptionValue();
            setSupplierId(0);
        }
        else{
            setSupplierOptionValue(selectedOption);
            setSupplierId(selectedOption.value);
        }
    };

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'supplierName', title: 'Supplier'},
        { key: 'invoiceNumber', title: 'Invoice Number' },
        { key: 'invoiceDate', title: 'Invoice Date' },
        { key: 'salesRepName', title: 'Sales Rep' },
        // { key: 'measuredBy', title: 'MeasuredBy' },
        // { key: 'measuredDate', title: 'measuredDate' },
        { key: 'subTotal', title: 'Sub Total' },
        { key: 'tax', title: 'Tax' },
        { key: 'shipping', title: 'Shipping' },
        { key: 'deposit', title: 'Deposit' },
        { key: 'totalInvoice', title: 'Total' },
        { key: 'isPaid', title: 'Paid' },
        { key: 'paidAmount', title: 'Paid Amount' },
    ];

    const columnsToPrint = [
        // { key: 'id', title: 'ID' },
        { key: 'supplierName', title: 'Supplier'},
        { key: 'invoiceNumber', title: 'Invoice Number' },
        { key: 'invoiceDate', title: 'Invoice Date' },
        { key: 'salesRepName', title: 'Sales Rep' },
        // { key: 'measuredBy', title: 'MeasuredBy' },
        // { key: 'measuredDate', title: 'measuredDate' },
        { key: 'subTotal', title: 'Sub Total' },
        { key: 'tax', title: 'Tax' },
        { key: 'shipping', title: 'Shipping' },
        { key: 'deposit', title: 'Deposit' },
        { key: 'totalInvoice', title: 'Total' },
        { key: 'isPaid', title: 'Paid' },
        { key: 'paidAmount', title: 'Paid Amount' },
        { key: 'unPaid', title: 'unPaid' },
    ];

    const handlePrintClick = () => {
        const fromDateObj = new Date(fromDate);
        const toDateObj = new Date(toDate);
        const fromMonthYear = `${fromDateObj.getMonth() + 1}/${fromDateObj.getFullYear()}`;
        const toMonthYear = `${toDateObj.getMonth() + 1}/${toDateObj.getFullYear()}`;
        const title = `${type === 1 ? 'UnPaid' : (type === 2 ? 'Paid' : 'All')} Invoices From ${fromMonthYear} to ${toMonthYear}`;

        const printableTableComponent = (
          <PrintableTable data={globalSupplierInvoices} columns={columnsToPrint} title={title} />
        );
    
        const newWindow = window.open("", "_blank");
        newWindow.document.open();
        newWindow.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <title>Sales People</title>
              <link rel="stylesheet" type="text/css" href="printtableTable.css">
            </head>
            <body>${ReactDOMServer.renderToString(printableTableComponent)}</body>
          </html>`);
        newWindow.print();
        newWindow.document.close();
    };

    const handleFromDateChange = (date) => {
      if (toDate && date > toDate) {
        // setIsFromDateValid(false);
      } else {
        setFromDate(date);
        // setIsFromDateValid(true);
      }
    };
  
    const handleToDateChange = (date) => {
      if (fromDate && date < fromDate) {
        // setIsToDateValid(false);
      } else {
        setToDate(date);
        // setIsToDateValid(true);
      }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(fromDate);
        if (fromDate === null || toDate === null ) {
          if(fromDate === null)
            setIsFromDateValid(false);
          else
            setIsFromDateValid(true);
          if(toDate === null)
            setIsToDateValid(false);
          else
            setIsToDateValid(true);
          return;
        }
        else{
            setIsFromDateValid(true);
            setIsToDateValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/SupplierInvoices/GetAllGlobalSupplierInvoicesReport', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    Type: type,
                    FromDate: fromDate,
                    ToDate: toDate,
                    SupplierId: supplierId,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    console.log(data.item2);
                    setGlobalSupplierInvoices(data.item2);
                    setFilteredGlobalSupplierInvoices(data.item2);
                    if (data.item2 && data.item2.length > 0) {
                        const arrayToAddTotal = data.item2;

                        const totalInvoice = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.totalInvoice, 0).toFixed(2);
                        // const labor = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.labor, 0).toFixed(2);
                        const subTotal = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.subTotal, 0).toFixed(2);
                        const tax = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.tax, 0).toFixed(2);
                        const shipping = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.shipping, 0).toFixed(2);
                        const deposit = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.deposit, 0).toFixed(2);
                        const paidAmount = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.paidAmount, 0).toFixed(2);
                        const unPaid = arrayToAddTotal.reduce((accumulator, obj) => accumulator + obj.unPaid, 0).toFixed(2);

                        const totalColumn = { bol: '', carrier: '', createdBy: '', createdDate: '', customerNumber: '', customerPO: ''
                            , customerId: 0, customerName: '', customerPhoneNumber: 0, customerStateId: 1, customerStateName: ''
                            , deposit: deposit, fob: '', invoiceDate: null, invoiceNumber: '', isDeleted: ''
                            , isPaid: '', modifiedBy: '', modifiedDate: '', id: 0, paidAmount: paidAmount
                            , salesRepId: '', salesRepName: '', shipFrom: '', shippedToAddress: '', shippedToCompanyName: ''
                            , shipping: shipping, soldToAddress: '', soldToCompanyDBA: '', soldToCompanyName: '', subTotal: subTotal
                            , supplierId: null, supplierName: 'Total', tax: tax, terms: '', totalInvoice: totalInvoice
                            , unPaid: unPaid, wareHouseId: '', wareHouseName: ''};
                        arrayToAddTotal.push(totalColumn);
                    }
                    
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                // setIsColorNameValid(false);
                // setIsColorHexCodeValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='supplierInvoicesReport'>
            <h1>Supplier Invoice Report</h1>
            <form className="newInvoicesReportForm" onSubmit={handleSubmit} >
            <div className="invoiceReportForm">
                <div className="invoiceReportItem">
                    <label>Type</label>
                    <div className="newInvoiceReportRadio">
                        <input type="radio" name="type" id="unPaid" value="1" checked={type === 1} onChange={(e) => setType(1)} />
                        <label htmlFor="unPaid">UnPaid</label>
                        <input type="radio" name="type" id="paid" value="2" checked={type === 2} onChange={(e) => setType(2)} />
                        <label htmlFor="paid">Paid</label>
                        <input type="radio" name="type" id="all" value="3" checked={type === 3} onChange={(e) => setType(3)} />
                        <label htmlFor="all">All</label>
                    </div>
                </div>
                <div className="invoiceReportItem">
                    <label htmlFor="fromDate">From Date:</label>
                    <DatePicker
                        selected={fromDate}
                        onChange={handleFromDateChange}
                        dateFormat="MM/yyyy"
                        placeholderText="Select Date"
                        id="fromDate"
                        name="fromDate"
                        className={!isFromDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthYearPicker
                        scrollableMonthYearDropdown
                    />
                </div>
                <div className="invoiceReportItem">
                    <label htmlFor="fromDate">To Date:</label>
                    <DatePicker
                        selected={toDate}
                        onChange={handleToDateChange}
                        dateFormat="MM/yyyy"
                        placeholderText="Select Date"
                        id="toDate"
                        name="toDate"
                        className={!isToDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        showMonthYearPicker
                        scrollableMonthYearDropdown
                    />
                </div>
                <div className='invoiceReportItem'>
                    <label htmlFor="fromDate">&nbsp;</label>
                    <button type="submit" className='InvoicesReportSubmitBtn' >Calculate</button>
                </div>
            </div>
            <div>
              <div className="invoiceReportItemOption">
                <label htmlFor="Customer">Supplier:</label>
                <SelectReact
                  className="quarter"
                  classNamePrefix="select"
                  isClearable
                  isSearchable={true}
                  value={supplierOptionValue}
                  name="Customer"
                  options={suppliersOptions}
                  onChange={handleSupplierSelectChange}
                />
              </div>
            </div>
        </form>
        {canPermission && canPermission.canView ? (
            <>
                <div className="searchContainer">
                    <div className="searchInputContainer">
                        <label className="searchLabel" htmlFor="searchInput">Search:</label>
                        <input
                            id="searchInput"
                            className="searchPrivateInput"
                            type="text"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                    <button onClick={handlePrintClick} className="PrintBtn"> Print</button>
                </div>

                <Table
                    data={filteredGlobalSupplierInvoices}
                    columns={columns}
                /> 
            </>
            ) : (
                <></>
            )}
        </div>
    )
}
