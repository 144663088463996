import React, { useState, useEffect } from 'react';
import './table.css';
import { DeleteOutline, Replay } from '@mui/icons-material';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { ApiUrl } from '../../Global';
import Swal from 'sweetalert2';

const Table = ({ data, columns, onDelete, onEdit, addedBtn, canEdit, canDelete, viewFile, onFile, viewLink, viewLinkPath, onCheckBoxChange, onPopUp, popUp, onUndo, pageName, onCreate, onPriceChange, restcurrentpage }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 20; // Number of rows to display per page
  const idColumnIndex = columns.findIndex((column) => column.key === "id");

  const offset = currentPage * rowsPerPage;
  const pageCount = Math.ceil(data.length / rowsPerPage);
  const currentPageData = data.slice(offset, offset + rowsPerPage);

  useEffect(() => {
    if(restcurrentpage === true){
      setCurrentPage(0); // Set the currentPage to 0 (first page) when the data prop changes
    }
    console.log(data);
  }, [data]);

  const handleRowSelection = (event, id) => {
    const checked = event.target.checked;

    if (checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowId) => rowId !== id)
      );
    }
  };

  const handleDelete = () => {
    selectedRows.forEach((id) => {
      onDelete(id);
    });
    setSelectedRows([]);
  };//

  const handleCreateInvoice = () => {
    // console.log(selectedRows.join(', '));
    onCreate(selectedRows.join(', '));
    // setSelectedRows([]);
  };

  const handleDeleteRow = (id) => {
    const row = data.find((row) => row.id === id);
    if(!row.isDeleted){
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          onDelete(id);
        }
      })
    }
  };
  const handleUndoRow = (id) => {
    console.log(id);
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, revert it!'
      }).then((result) => {
        if (result.isConfirmed) {
          onUndo(id);
        }
      })
  };

  const handleEdit = (id) => {
    onEdit(id);
  };

  const handleViewFile = (id) => {
    let Url = '/api/Estimates/GetEstimatePathById?Id=' + id;
  
    fetch(ApiUrl + Url, {
      headers: {
        'Authorization': `Bearer ` + sessionStorage.getItem('token'),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        window.open(`${ApiUrl}/api/Estimates/files/${data.item2.fileName}`, '_blank');
      });
  };

  const handleFile = (id) => {
    onFile(id);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return ''; // Return empty string if dateString is undefined or empty
    }
    
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleCheckboxChange = (rowId) => {
    onCheckBoxChange(rowId);
  };
  const handlePriceChange = (categoryId, styleId, colorId, currencyId, price) => {
    // console.log( categoryId + "-" + styleId + "-" + colorId + "-" + price);
    onPriceChange(categoryId, styleId, colorId, currencyId, parseFloat(price));
  };
  const handlePopUp = (rowId) => {
    // const row = data.find((row) => row.id === rowId);
    // console.log(row);
    // if(!row.finished && !row.isDeleted)
      onPopUp(rowId);
  };

  const formatDatePayment = (dateString) => {
    const dateParts = dateString.split('T')[0].split('-');
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1;
    const day = parseInt(dateParts[2]);
    const formattedDate = new Date(year, month, day);
    
    return formattedDate.toLocaleDateString();
  };

  return (
    <div className="table-container">
      <div className="buttonContainer">
        {(selectedRows.length > 0 && pageName === "FlooringProductList") && (
          <div className="deleteButtonContainer">
            <button className="CreateButton" onClick={handleCreateInvoice}>
              Create New Invoice
            </button>
          </div>
        )}
        {selectedRows.length > 0 && (
          <div className="deleteButtonContainer">
            <button className="deleteButton" onClick={handleDelete}>
              Delete Selected Rows
            </button>
          </div>
        )}
      </div>
      <table>
      <thead>
          <tr>
            {idColumnIndex !== -1 && (
              <th>
                <input
                  type="checkbox"
                  checked={selectedRows.length === data.length}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setSelectedRows(checked ? data.map((row) => row.id) : []);
                  }}
                />
              </th>
            )}
            {columns.map((column) => {
              if (column.key !== "id") {
                return <th key={column.key}>{column.title}</th>;
              }
              return null; // Render nothing for the Id column
            })}
            {idColumnIndex !== -1 && 
              <th>Actions</th>
            }
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((row) => (
            <tr key={row.id}>
              {idColumnIndex !== -1 && (
                <td>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(row.id)}
                    onChange={(event) => handleRowSelection(event, row.id)}
                  />
                </td>
              )}
              {columns.map((column) => {
                const showPopup = true;

                if (column.key === "createdDate" || column.key === "dateOfBirth" || column.key === "hiredDate" || column.key === "startDate" || column.key === "endDate" || column.key === "measuredDate" || column.key === "installDate" || column.key === "paymentDate" || column.key === "invoiceDate" ) {
                  const formattedDate = formatDate(row[column.key]);
                  return <td key={`${row.id}-${column.key}`}>{formattedDate}</td>;
                }
                if (column.key === "finished" || column.key === "active" || column.key === "supervisorStatus" || column.key === "managerStatus") {
                  return (
                    <td key={`${row.id}-${column.key}`}>
                      <input
                        type="checkbox"
                        checked={row[column.key] === "Pending" ? false : (row[column.key] === "Accepted" ? true : (row[column.key] === "Rejected" ? false : row[column.key]))}
                        onChange={() => handleCheckboxChange(row.id)}
                        disabled={row.isDeleted === true ? true : false}
                      />
                    </td>
                  );
                }
                if (column.key === "totalPaymentAmount") {
                  const totalPaymentAmount = parseFloat(row[column.key]);
                  const formattedTotalPaymentAmount = totalPaymentAmount.toLocaleString();
                  return <td key={`${row.id}-${column.key}`}>{formattedTotalPaymentAmount}</td>;
                }
                if (column.key === "minimumPricePerUnit") {
                  return (
                    <td key={`${row.id}-${column.key}`}>
                      <input
                        type="number"
                        className='Default-Input'
                        value={row.minimumPricePerUnit}
                        onChange={(event) => handlePriceChange(row.categoryId, row.styleId, row.colorId, row.currencyId ,event.target.value)}
                        min={0}
                      />
                    </td>
                  );
                }
                if(column.key === 'total'){
                  return <td key={`${row.id}-${column.key}`}>{row.total !== undefined && row.total !== null ? row.total.toLocaleString() : ''}</td>
                }
                if(column.key === 'paidAmount'){
                  return <td key={`${row.id}-${column.key}`}>{ row.paidAmount !== undefined && row.paidAmount !== null ? row.paidAmount.toLocaleString() : ''}</td>
                }
                if(column.key === 'unPaidAmount'){
                  return <td key={`${row.id}-${column.key}`}>{row.unPaidAmount !== undefined && row.unPaidAmount !== null ? row.unPaidAmount.toLocaleString() : ''}</td>
                }
                if(column.key === 'deposit'){
                  return <td key={`${row.id}-${column.key}`}>{row.deposit !== undefined && row.deposit !== null ? row.deposit.toLocaleString() : ''}</td>
                }
                if(column.key === 'totalMaterial'){
                  return <td key={`${row.id}-${column.key}`}>{row.totalMaterial !== undefined && row.totalMaterial !== null ? row.totalMaterial.toLocaleString() : ''}</td>
                }
                if(column.key === 'labor'){
                  return <td key={`${row.id}-${column.key}`}>{row.labor !== undefined && row.labor !== null ? row.labor.toLocaleString() : ''}</td>
                }
                if(column.key === 'subTotal'){
                  return <td key={`${row.id}-${column.key}`}>{row.subTotal !== undefined && row.subTotal !== null ? row.subTotal.toLocaleString() : ''}</td>
                }
                if(column.key === 'invoicePaymentView'){
                  return <td key={`${row.invoiceId}-${column.key}`}>
                    <ul>
                    {row.invoicePaymentView.map((row1) => (
                        <li>{row1.paymentMethodName} {row1.paymentType} {row1.paymentAmount ? row1.paymentAmount.toLocaleString() : ''} {formatDatePayment(row1.paymentDate)}</li>
                    ))}
                    </ul>
                  </td>
                }
                if (column.key !== "id") {
                  if (column.key === "description") {
                    // Display only the first 20 characters of the description
                    const truncatedDescription = row[column.key].slice(0, 20);
                    if (row[column.key].length > 20) {
                      return <td key={`${row.id}-${column.key}`} style={{width:'30%'}}>{truncatedDescription}...</td>;
                    } else {
                      return <td key={`${row.id}-${column.key}`}>{truncatedDescription}</td>;
                    }
                  } else {
                    if (column.key === "title") {
                      const truncatedDescription = row[column.key].slice(0, 30);
                      if (row[column.key].length > 30) {
                        return <td key={`${row.id}-${column.key}`}>{truncatedDescription}...</td>;
                      } else {
                        return <td key={`${row.id}-${column.key}`}>{truncatedDescription}</td>;
                      }
                    } else {
                      if (row[column.key] === true) {
                        return <td key={`${row.id}-${column.key}`}>
                        <input
                          type="checkbox"
                          checked={row[column.key]}
                          disabled
                        />
                      </td>
                      }
                      else{
                        if (row[column.key] === false) {
                          return <td key={`${row.id}-${column.key}`}>
                          <input
                            type="checkbox"
                            checked={row[column.key]}
                            disabled
                          />
                        </td>
                        }
                        return <td key={`${row.id}-${column.key}`}>{row[column.key]}</td>;
                      }
                    }
                  }
                }
                return null; // Render nothing for the Id column
              })}
              <td className="columnActions">
                {canEdit ? 
                  <button
                    // className="tableListEdit"
                    className={row.hasOwnProperty("isDeleted") ? (row.isDeleted === true ? "tableListEdit disableEditButton" : "tableListEdit") : "tableListEdit"}
                    onClick={ () => row.hasOwnProperty("isDeleted") ? (row.isDeleted === true ? null : handleEdit(row.id)) : handleEdit(row.id)}
                  >
                    Edit
                  </button>
                :  <></>}
                
                {canDelete ?
                  <>
                    {row.isDeleted == false ?
                      <DeleteOutline
                        className={row.isDeleted === true ? "tableListDelete disableDeleteButton" : (row.hasOwnProperty("finished") ? (row.finished === true ? "tableListDelete disableDeleteButton" : "tableListDelete") : "tableListDelete")}
                        onClick={() => row.hasOwnProperty("finished") ? (row.finished === true ? null : handleDeleteRow(row.id)) : handleDeleteRow(row.id)  }
                      /> : <></>
                    }
                    
                    {row.isDeleted == true ? 
                      <Replay
                        className="tableListReplay"
                        onClick={() => handleUndoRow(row.id)}
                      /> : <></>
                    }

                    {row.isDeleted === undefined ?
                      <DeleteOutline
                        className="tableListDelete"
                        onClick={() => handleDeleteRow(row.id)}
                      /> : <></>
                    }
                  </> :
                  <></>
                }
                {addedBtn && addedBtn !== "" && (
                  <Link to={`/salesPeople/${row.id}`}>
                  <button className="tableListAdded">
                    {addedBtn}
                  </button>
                </Link>
                )}
                {viewLink && viewLinkPath && (
                  <Link to={`${viewLinkPath}/${row.id}`}>
                    <button className="tableListAdded">
                      {viewLink}
                    </button>
                  </Link>
                )}
                {viewFile && viewFile !== "" && (
                  <button className="tableListAdded" onClick={() => handleFile(row.id)}>
                    {viewFile}
                  </button>
                )}
                {popUp ? 
                  <button
                    className={row.hasOwnProperty("finished") ? (row.finished === true ? "tableListAdded disableButton" : (row.hasOwnProperty("isDeleted") ? (row.isDeleted === true ? "tableListAdded disableButton" : "tableListAdded") : "tableListAdded") ) : (row.hasOwnProperty("isDeleted") ? (row.isDeleted === true ? "tableListAdded disableButton" : "tableListAdded") : "tableListAdded")}
                    onClick={() => handlePopUp(row.id)}
                    disabled={row.hasOwnProperty("finished") ? (row.finished === true ? true : (row.hasOwnProperty("isDeleted") ? row.isDeleted : false) ) : (row.hasOwnProperty("isDeleted") ? row.isDeleted : false)} // Disable the button if "finished" is true
                  >
                    Action
                  </button> : <></>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      
      <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={pageCount}
        onPageChange={({ selected }) => setCurrentPage(selected)}
        containerClassName={'pagination'}
        activeClassName={'active'}
        forcePage={currentPage} // Set the current page for ReactPaginate
      />
    </div>
  );
};

export default Table;