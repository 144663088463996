import React , {useState, useEffect} from 'react'
import './invoiceList.css'
import Table from '../../table/Table'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import SelectReact from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function InvoiceList() {
  const [invoices, setInvoices] = useState([]);
  const [id, setId] = useState(0);
  const [canPermission, setCanPermission] = useState();
  const navigate = useNavigate();
  const [link, setLink] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFromDateValid, setIsFromDateValid] = useState(true);
  const [isToDateValid, setIsToDateValid] = useState(true);
  const [type, setType] = useState(3);
  const [searchText, setSearchText] = useState('');
  const currentDateTime = new Date();

  useEffect(() => {
    const desiredPermissionPageName = 'InvoiceList'; // Replace with the desired permissionPageName value
  
    const desiredRow = GlobalPermission.find(
      (item) => item.permissionPageName === desiredPermissionPageName
    );

    const currentDateTime = new Date();
    currentDateTime.setMonth(0); // Set the month to January (0 represents January)
    currentDateTime.setDate(1); // Set the day to the 1st
    currentDateTime.setHours(0, 0, 0, 0); // Set the time to the beginning of the day (midnight)
    if(fromDate === null && toDate === null){
      setFromDate(currentDateTime);
      setToDate(new Date());
    }

    // setLink("/SaveEmployee")
    setLink("/payments")
    setCanPermission(desiredRow);
    GetAllGlobalInvoices();
  }, [searchText]);

  function GetAllGlobalInvoices(){
    const currentDateTime = new Date();
    currentDateTime.setMonth(0); // Set the month to January (0 represents January)
    currentDateTime.setDate(1); // Set the day to the 1st
    currentDateTime.setHours(0, 0, 0, 0); // Set the time to the beginning of the day (midnight)
    fetch(ApiUrl + '/api/Invoices/GetAllGlobalInvoicesFiltered', {
      method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
        },
        body: JSON.stringify({
            Type: type,
            FromDate: currentDateTime,
            ToDate: new Date(),
        })
    })
    .then(response => response.json())
    .then(data => {
      // const filtered = data.item2.filter((d) => d.isDeleted === false);
      const filtered = data.item2.filter(item => item.isDeleted ===false &&
        (item.customerName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.invoiceNumber.toLowerCase().includes(searchText.toLowerCase()))
      );
      console.log(data.item2);
      setInvoices(filtered);
    });
  }

  const columns = [
    { key: 'id', title: 'ID' },
    { key: 'invoiceNumberYear', title: 'Invoice Number' },
    { key: 'invoiceDate', title: 'Invoice Date' },
    { key: 'customerName', title: 'Customer' },
    // { key: 'employeeName', title: 'SalesPerson' },
    // { key: 'measuredBy', title: 'MeasuredBy' },
    { key: 'labor', title: 'labor' },
    { key: 'totalMaterial', title: 'totalMaterial' },
    { key: 'taxAmount', title: 'taxAmount' },
    { key: 'total', title: 'total' },
    { key: 'deposit', title: 'deposit' },
    { key: 'balanceCOD', title: 'C.O.D' },
    { key: 'paidAmount', title: 'Paid Amount' },
    { key: 'unPaid', title: 'UnPaid' },
    { key: 'isPaid', title: 'Paid' },
    { key: 'companyCode', title: 'CO' },
  ];

  const handleEdit = (id) => {
    // navigate('/SaveEmployee/'+ id); // Replace '/edit-page' with the desired page URL
    // console.log(id);
    navigate('/invoices/'+ id); // Replace '/edit-page' with the desired page URL
  }
  const handleDelete = async (id) => {
    console.log(id);
    try {
      const response = await fetch(ApiUrl + '/api/Invoices/Delete?invoiceId=' + id, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      });
      const text = await response.text();
      console.log('response text:', text);
      const data = JSON.parse(text);
      if (response.ok) {
        Swal.fire({
            icon: 'success',
            title: data.message,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            GetAllGlobalInvoices();
        });
      } else {
        console.error(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleUndo = async (id) => {
    
  }

  const onCheckBoxChange = async (rowId) => {
    console.log(rowId);
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(fromDate);
    if (fromDate === null || toDate === null ) {
      if(fromDate === null)
        setIsFromDateValid(false);
      else
        setIsFromDateValid(true);
      if(toDate === null)
        setIsToDateValid(false);
      else
        setIsToDateValid(true);
      return;
    }
    else{
        setIsFromDateValid(true);
        setIsToDateValid(true);
    }
    try {
      const response = await fetch( ApiUrl +'/api/Invoices/GetAllGlobalInvoicesFiltered', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
        },
        body: JSON.stringify({
            Type: type,
            FromDate: fromDate,
            ToDate: toDate,
        })
      });
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        setInvoices(data.item2);
      }
      else{
        setInvoices([]);
      }
    } catch (error) {
        console.error(error);
    }
  }

  const handleFromDateChange = (date) => {
    if (toDate && date > toDate) {
      // setIsFromDateValid(false);
    } else {
      setFromDate(date);
      // setIsFromDateValid(true);
    }
  };

  const handleToDateChange = (date) => {
    if (fromDate && date < fromDate) {
      // setIsToDateValid(false);
    } else {
      setToDate(date);
      // setIsToDateValid(true);
    }
  };

  return (
    <div className='InvoiceList'>
      <h1 className="employeeTitle">Invoice List</h1>

      {canPermission && canPermission.canEdit ? (
        <>
          <div className="employeeTitleContainer">
            <Link to="/invoices">
              <button className="employeeAddButton">Create</button>
            </Link>
          </div>
          <form className="newInvoicesReportForm" onSubmit={handleSubmit} >
            <div className="invoiceReportForm">
              <div className="invoiceReportItem">
                <label>Type</label>
                <div className="newInvoiceReportRadio">
                  <input type="radio" name="type" id="all" value="3" checked={type === 3} onChange={(e) => setType(3)} />
                  <label htmlFor="all">All</label>
                  <input type="radio" name="type" id="unPaid" value="1" checked={type === 1} onChange={(e) => setType(1)} />
                  <label htmlFor="unPaid">UnPaid</label>
                  <input type="radio" name="type" id="paid" value="2" checked={type === 2} onChange={(e) => setType(2)} />
                  <label htmlFor="paid">Paid</label>
                </div>
              </div>
              <div className="invoiceReportItem">
                <label htmlFor="fromDate">From Date:</label>
                <DatePicker
                  selected={fromDate}
                  onChange={handleFromDateChange}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select Date"
                  id="fromDate"
                  name="fromDate"
                  className={!isFromDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  // showMonthYearPicker
                  scrollableMonthYearDropdown
                />
              </div>
              <div className="invoiceReportItem">
                <label htmlFor="toDate">To Date:</label>
                <DatePicker
                  selected={toDate}
                  onChange={handleToDateChange}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select Date"
                  id="toDate"
                  name="toDate"
                  className={!isToDateValid ? 'CustomDatePicker InvalidInputtext' : 'CustomDatePicker validInputtext'}
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={15}
                  // showMonthYearPicker
                  scrollableMonthYearDropdown
                />
              </div>
              <div className='invoiceReportItem'>
                <label htmlFor="fromDate">&nbsp;</label>
                <button type="submit" className='InvoicesReportSubmitBtn' >Filter</button>
              </div>
            </div>
          </form>
        </> ) 
        : 
        <></>
      }
      {canPermission && canPermission.canView ? (
        <>
        <div className="searchContainer">
            <div className="searchInputContainer">
                <label className="searchLabel" htmlFor="searchInput">Search:</label>
                <input
                id="searchInput"
                className="searchPrivateInput"
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
        </div>
        <Table
          data={invoices}
          columns={columns}
          onDelete={handleDelete}
          onEdit={handleEdit}
          canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
          canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
          onUndo={handleUndo}
          onCheckBoxChange={onCheckBoxChange}
          viewLink = "Payment"
          viewLinkPath = {link}
        /> 
        </>
        
      ) : (
        <></>
      )}
    </div>
  )
}
