import React, {useState, useEffect, useRef } from 'react'
import './color.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function Color() {

    const [colors, setColors] = useState([]);
    const [colorName, setColorName] = useState('');
    const [hex_Code, setHex_code] = useState('');
    const [id, setId] = useState('0');
    const [canPermission, setCanPermission] = useState();
    const [isColorNameValid, setIsColorNameValid] = useState(true);
    const [isColorHexCodeValid, setIsColorHexCodeValid] = useState(true);
    const [searchText, setSearchText] = useState('');
    const inputRef = useRef(null); // Create a ref for the input field


    useEffect(() => {
      const desiredPermissionPageName = 'Colors'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
    
      setCanPermission(desiredRow);
      GetAllColors();
    }, [searchText]);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'hex_code', title: 'Hex_Code' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
      // Perform edit action using the ID of the row being edited
      const filtered = colors.filter(item => item.id === id);
      setColorName(filtered[0].name);
      setId(filtered[0].id);
      setHex_code(filtered[0].hex_code)
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/Colors/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllColors();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (colorName.trim() === '' || hex_Code.trim() === '' ) {
          if(colorName.trim() === '')
            setIsColorNameValid(false);
          else
            setIsColorNameValid(true);
          // if(hex_Code.trim() === '')
          //   setIsColorHexCodeValid(false);
          // else
          //   setIsColorHexCodeValid(true);
          return;
        }
        else{
          setIsColorNameValid(true);
          // setIsColorHexCodeValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/Colors/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    Name: colorName,
                    Hex_code: hex_Code,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllColors();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsColorNameValid(false);
                // setIsColorHexCodeValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
      setId(0);
      setColorName('');
      setHex_code('');
      setIsColorNameValid(true);
      // setIsColorHexCodeValid(true);
      inputRef.current.focus(); // Set focus inside the success callback
    }

    function GetAllColors(){
      fetch(ApiUrl + '/api/Colors/GetAllColors', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        console.log(data.item2);
        const filteredData = data.item2.filter(item =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.hex_code.toLowerCase().includes(searchText.toLowerCase())
        );
        setColors(filteredData);
        console.log("Color Page");
      });
    }

    const handleUndo = async (id) => {
      try {
        const response = await fetch(ApiUrl + '/api/Colors/Undo?Id=' + id, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ` + sessionStorage.getItem("token")
          }
        });
        const text = await response.text();
        console.log('response text:', text);
        const data = JSON.parse(text);
        if (response.ok) {
          Swal.fire({
              icon: 'success',
              title: data.message,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              clearTextBoxes();
              GetAllColors();
          });
        } else {
          console.error(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

  return (
    <div className='color'>
      <h1>Color</h1>
      {canPermission && canPermission.canEdit ? (
      <form className="ColorForm" onSubmit={handleSubmit}>
      <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
          <div className="ColorItem">
            <label htmlFor="colorName">Color:</label>
            <input type="text" className={!isColorNameValid ? 'InvalidInputtext' : 'validInputtext'} ref={inputRef} value={colorName} placeholder="Name" name="colorName" id="colorName" onChange={(e) => setColorName(e.target.value)} />
          </div>
          <div className="ColorItem">
            <label htmlFor="colorHex_code">Code:</label>
            <input type="text" className='validInputtext' value={hex_Code} placeholder="Code" name="colorHex_code" id="colorHex_code" onChange={(e) => setHex_code(e.target.value)} />
          </div>
          <div className='ColorItem'>
            <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
            <button type="submit" className='saveColorBtn' >{id == '0' ? 'Create' : 'Save'}</button>
          </div>
        </form> ) : (
        <></>
      )}
      <br />
      {canPermission && canPermission.canView ? (
        <>
        <div className="searchContainer">
            <div className="searchInputContainer">
                <label className="searchLabel" htmlFor="searchInput">Search:</label>
                <input
                id="searchInput"
                className="searchPrivateInput"
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
        </div>
          <Table
              data={colors}
              columns={columns}
              onDelete={handleDelete}
              onEdit={handleEdit}
              canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
              canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
              onUndo={handleUndo}
          /> 
        </>
          ) : (
            <></>
      )}
      
    </div>
  )
}
