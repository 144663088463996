import React, {useState, useEffect} from 'react'
import './educationalBackground.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function EducationalBackground() {
    const [educationalBackgrounds, setEducationalBackgrounds] = useState([]);
    const [degree, setDegree] = useState('');
    const [certification, setCertification] = useState('');
    const [id, setId] = useState(0);
    const [canPermission, setCanPermission] = useState();
    const [isDegreeValid, setIsDegreeValid] = useState(true);
    const [isCertificationValid, setIsCertificationValid] = useState(true);

    useEffect(() => {
      const desiredPermissionPageName = 'EducationalBackground'; // Replace with the desired permissionPageName value
    
      const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
      );
    
      setCanPermission(desiredRow);
      GetAllEducationalBackgrounds();
    }, []);

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'degree', title: 'Degree' },
        { key: 'certification', title: 'Certification' },
        { key: 'isDeleted', title: 'Deleted' },
    ];

    const handleEdit = (id) => {
      // Perform edit action using the ID of the row being edited
      const filtered = educationalBackgrounds.filter(item => item.id === id);
      setDegree(filtered[0].degree);
      setCertification(filtered[0].certification);
      setId(filtered[0].id);
    }

    const handleDelete = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/EducationalBackgrounds/Delete?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllEducationalBackgrounds();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (degree.trim() === '' || certification.trim() === '' ) {
            if(degree.trim() === '')
                setIsDegreeValid(false);
            else
                setIsDegreeValid(true);
            if(certification.trim() === '')
                setIsCertificationValid(false);
            else
                setIsCertificationValid(true);
          return;
        }
        else{
            setIsDegreeValid(true);
            setIsCertificationValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/EducationalBackgrounds/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    Degree: degree,
                    Certification: certification,
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllEducationalBackgrounds();
                });
            } else {
              Swal.fire({
                icon: 'error',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                setIsDegreeValid(false);
              });
            }
        } catch (error) {
            console.error(error);
        }
    }

    function clearTextBoxes(){
        setId(0);
        setDegree('');
        setCertification('');
        setIsDegreeValid(true);
        setIsCertificationValid(true);
    }

    function GetAllEducationalBackgrounds(){
      fetch(ApiUrl + '/api/EducationalBackgrounds/GetAllEducationalBackgrounds', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        // console.log(data)
        setEducationalBackgrounds(data.item2);
      });
    }

    const handleUndo = async (id) => {
        try {
          const response = await fetch(ApiUrl + '/api/EducationalBackgrounds/Undo?Id=' + id, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          });
          const text = await response.text();
          console.log('response text:', text);
          const data = JSON.parse(text);
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: data.message,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                clearTextBoxes();
                GetAllEducationalBackgrounds();
            });
          } else {
            console.error(data);
          }
        } catch (error) {
          console.error(error);
        }
    }

  return (
    <div className='educationalBackground'>
        <h1>EducationalBackground</h1>
        {canPermission && canPermission.canEdit ? (
            <form className="educationalBackgroundForm" onSubmit={handleSubmit}>
                <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
                <div className="educationalBackgroundItem">
                    <label htmlFor="degree">Degree:</label>
                    <input type="text" className={!isDegreeValid ? 'InvalidInputtext' : 'validInputtext'} value={degree} placeholder="Degree" name="degree" id="degree" onChange={(e) => setDegree(e.target.value)} />
                </div>
                <div className="educationalBackgroundItem">
                    <label htmlFor="certification">Certification:</label>
                    <input type="text" className={!isCertificationValid ? 'InvalidInputtext' : 'validInputtext'} value={certification} placeholder="Certification" name="certification" id="certification" onChange={(e) => setCertification(e.target.value)} />
                </div>
                <div className='educationalBackgroundItem'>
                    <button type='button' className="newBtn" id="newBtn" onClick={clearTextBoxes}>New</button>
                    <button type="submit" className='saveEducationalBtn' >{id == '0' ? 'Create' : 'Save'}</button>
                </div>
            </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
            <Table
                data={educationalBackgrounds}
                columns={columns}
                onDelete={handleDelete}
                onEdit={handleEdit}
                canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
                canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
                onUndo={handleUndo}
            /> 
            ) : (
                <></>
        )}
    </div>
  )
}
