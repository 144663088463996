import React, { useEffect, useState } from 'react'
import './salesPerson.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import Table from '../../table/Table';
import Swal from 'sweetalert2';
import PrintableTable from '../reports/PrintableTable';
import ReactDOMServer from 'react-dom/server';
import { useParams } from 'react-router-dom';

export default function SalesPerson() {
    const { supplier } = useParams();
    const [salesName, setSalesName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [id, setId] = useState(0);
    const [supplierId, setSupplierId] = useState();
    const [suppliers, setSuppliers] = useState([]);
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [canPermission, setCanPermission] = useState();
    const [isNameValid, setIsNameValid] = useState(true);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const currentDateTime = new Date();

    useEffect(() => {
        const desiredPermissionPageName = "SalesPerson"; // Replace with the desired permissionPageName value
      
        const desiredRow = GlobalPermission.find(
          (item) => item.permissionPageName === desiredPermissionPageName
        );
      
        setCanPermission(desiredRow);

        GetAllSuppliers();
        GetAllGlobalSalesPeople();
        //console.log(supplierId);
    }, [supplier, searchText]);

    useEffect(() => {
      if (suppliers.length > 0) {
        setSupplierId(suppliers[0].id);
      }
    }, [suppliers]);

    const handleNew = async (e) => {
        e.preventDefault();
        setId(0);
        setSalesName('');
        setPhoneNumber('');
        setSupplierId(suppliers[0].id);
        setIsNameValid(true);
        setIsPhoneValid(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (salesName.trim() === '' || phoneNumber.trim() === '') {
          if(salesName.trim() === '')
            setIsNameValid(false);
          else
            setIsNameValid(true);
          if(phoneNumber.trim() === '')
            setIsPhoneValid(false);
          else
            setIsPhoneValid(true);
          return;
      }
      else{
          setIsNameValid(true);
          setIsPhoneValid(true);
      }
        try {
            const response = await fetch( ApiUrl +'/api/SalesPeople/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    name: salesName,
                    phoneNumber: phoneNumber,
                    supplierId: supplierId,
                    createdBy: '0',
                    CreatedDate: currentDateTime
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    ClearTextBoxes();
                    GetAllGlobalSalesPeople();
                });
            } else {
                console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    function GetAllSuppliers() {
      fetch(ApiUrl + '/api/Suppliers/GetAllSuppliers', {
        headers: {
          'Authorization': `Bearer ` + sessionStorage.getItem("token")
        }
      })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        const filteredSuppliers = data.item2.filter(supplier => supplier.isDeleted === false);
        setSuppliers(filteredSuppliers);
        //console.log("test " + supplierId)
      });
    }

    function GetAllGlobalSalesPeople() {
        let Url = '/api/SalesPeople/GetAllGlobalSalesPeople';
        //console.log(supplier);
      
        if (typeof supplier === "undefined" || supplier === null || supplier === "") {
          Url = '/api/SalesPeople/GetAllGlobalSalesPeople';
        } else {
          Url = '/api/SalesPeople/GetAllSalesPeopleBySupplier?supplierId=' + supplier;
        }
      
        fetch(ApiUrl + Url, {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          })
          .then(response => response.json())
          .then(data => {
            //console.log(data);
            const filteredData = data.item2.filter(item =>
              item.name.toLowerCase().includes(searchText.toLowerCase()) ||
              item.supplierName.toLowerCase().includes(searchText.toLowerCase())
            );
            setData(filteredData);
          });
    }

    function ClearTextBoxes(){
        setId(0);
        setSalesName('');
        setPhoneNumber('');
        setSupplierId(suppliers[0].id);
    }

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'phoneNumber', title: 'Phone Number' },
        { key: 'supplierName', title: 'Supplier' },
    ];

    const handleDelete = async (id) => {
        // Perform delete action using the selected row IDs
        //console.log(id)
        try {
            const response = await fetch(ApiUrl + '/api/SalesPeople/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            //console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
              Swal.fire({
                  icon: 'success',
                  title: data.message,
                  showConfirmButton: false,
                  timer: 2000
                }).then(() => {
                  ClearTextBoxes();
                  GetAllGlobalSalesPeople();
              });
            } else {
              console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleEdit = (id) => {
        // console.log(supplier);
        const filtered = data.filter(item => item.id === id);
        setSalesName(filtered[0].name);
        setPhoneNumber(filtered[0].phoneNumber)
        setId(filtered[0].id);
        setSupplierId(filtered[0].supplierId)
    };

    const columnsPrint = [
        { key: 'name', title: 'Name' },
        { key: 'phoneNumber', title: 'Phone Number' },
        { key: 'supplierName', title: 'Supplier' },
        { key: 'note', title: 'Note' },
    ];

    const handlePrintClick = () => {
        let titleForPrint = "";
        if (typeof supplier === "undefined" || supplier === null || supplier === "") {
            titleForPrint = "Sales Person";
          } else {
            titleForPrint = "Sales Person For " + data[0].supplierName;
          }

        const printableTableComponent = (
          <PrintableTable data={data} columns={columnsPrint} title={titleForPrint} />
        );
    
        const newWindow = window.open("", "_blank");
        newWindow.document.open();
        newWindow.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <title>Sales People</title>
              <link rel="stylesheet" type="text/css" href="printtableTable.css">
            </head>
            <body>${ReactDOMServer.renderToString(printableTableComponent)}</body>
          </html>`);
        newWindow.print();
        newWindow.document.close();
      };


      const formatPhoneNumber = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
    
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match the desired format
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`; // Apply the format (XXX) XXX-XXXX
        }
    
        return cleaned; // Return the input as is if it doesn't match the desired format
      };

  return (
    <div className='salesPerson'>
        <h1>Sales Person</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newSalesPersonForm" onSubmit={handleSubmit} >
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div className="newSalesPersonItem">
                <label>Name</label>
                <input type="text" placeholder="salesName" name="salesName" id="salesName" className={!isNameValid ? 'InvalidInputtext' : 'validInputtext'} value={salesName} onChange={(e) => setSalesName(e.target.value)} />
            </div>
            <div className="newSalesPersonItem">
                <label>Phone Number</label>
                <input type="text" placeholder="Phone Number" name="phoneNumber" id="phoneNumber" className={!isPhoneValid ? 'InvalidInputtext' : 'validInputtext'} value={phoneNumber} onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))} />
            </div>
            {!supplier && supplier !== "" && (
                <div className="newSalesPersonItem">
                    <label>Supplier</label>
                    <select className="newSalesPersonSelect" name="supplier" id="supplier" value={supplierId} onChange={(e) => setSupplierId(e.target.value)}>
                    {suppliers.map((supp) => (
                        <option key={supp.id} value={supp.id}>{supp.name}</option>
                    ))}
                    </select>
                </div>
            )}
            
            <div className='newSalesPersonItem'>
                <button type='button' className="newSalesPersonBtn" id="newSalesPersonBtn" onClick={handleNew}>New</button>
                <button type='submit' className="newSalesPersonButton">{id == '0' ? 'Create' : 'Save'}</button>
            </div>
        </form> ): (
        <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
        <>
        <div className="searchContainer">
            <div className="searchInputContainer">
                <label className="searchLabel" htmlFor="searchInput">Search:</label>
                <input
                id="searchInput"
                className="searchSalesInput"
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                />
            </div>
            <button onClick={handlePrintClick} className="PrintBtn"> Print</button>
        </div>
        <Table
            data={data}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
            canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
        />
        </> ) : (
          <></>
        )}
    </div>
  )
}
