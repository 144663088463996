import React, {useState, useEffect} from 'react'
import "./user.css"
import { useParams, useNavigate  } from 'react-router-dom';
import { ApiUrl, WebUrl } from '../../../Global';

export default function User() {
    const navigate = useNavigate();
    const { userId } = useParams();
    const [user, setUser] = useState();
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [gender, setGender] = useState();
    const [active, setActive] = useState();
    const [roleName, setRoleName] = useState('');
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        fetch( ApiUrl + '/api/Auth/GetUserByIdAsync?Id=' + userId, {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
        })
        .then(response => response.json())
        .then(data => {
            setUser(data); 
            setUsername(data.username);
            setEmail(data.email);
            setPass(data.password);
            setPhoneNumber(data.phoneNumber);
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setAddress(data.address ==  null ? "" : data.address);
            setGender(data.gender);
            setActive(data.active);
            setRoleName(data.roleName);
            console.log(data)
        });
    }, [userId]);

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if (token) {
          fetch(ApiUrl + '/api/Role/GetRoles', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          })
          .then(response => response.json())
          .then(data => {
            console.log(data)
            // Sort roles alphabetically by name
            const sortedRoles = data.sort((a, b) => a.name.localeCompare(b.name));
            setRoles(sortedRoles);
          })
          .catch(error => console.log(error));
        }
      }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(active);
        try {
            const response = await fetch( ApiUrl +'/api/Auth/UpdateUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    Id: userId,
                    username: username,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    password: pass,
                    phoneNumber: phoneNumber,
                    address: address,
                    gender: gender,
                    active: Boolean(active),
                    roleName: roleName,
                })
            });
            const data = await response.json();
            if (response.ok) {
                console.log(data);
                navigate('/users'); // navigate to new page
            } else {
                // login failed
                console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

  return (
    <div className="newUser">
    <h1 className="newUserTitle">Edit User</h1>
    <form className="newUserForm" onSubmit={handleSubmit} >
        <div className="newUserItem">
            <label>Username</label>
            <input type="text" placeholder="john" name="username" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="newUserItem">
            <label>First Name</label>
            <input type="text" placeholder="John Smith" name="firstName" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div className="newUserItem">
            <label>Last Name</label>
            <input type="text" placeholder="John Smith" name="lastName" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div className="newUserItem">
            <label>Email</label>
            <input type="email" placeholder="john@gmail.com" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="newUserItem">
            <label>Password</label>
            <input type="password" placeholder="password" name="pass" id="pass" value={pass} onChange={(e) => setPass(e.target.value)} />
        </div>
        <div className="newUserItem">
            <label>Phone Number</label>
            <input type="text" placeholder="+1 123 456 78" name="phoneNumber" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
        </div>
        <div className="newUserItem">
            <label>Address</label>
            <input type="text" placeholder="New York | USA" name="address" id="address" value={address} onChange={(e) => setAddress(e.target.value)} />
        </div>
        <div className="newUserItem">
            <label>Gender</label>
            <div className="newUserGender">
                <input type="radio" name="gender" id="male" value="male" checked={gender === 1} onChange={(e) => setGender(1)} />
                <label htmlFor="male">Male</label>
                <input type="radio" name="gender" id="female" value="female" checked={gender === 2} onChange={(e) => setGender(2)} />
                <label htmlFor="female">Female</label>
                <input type="radio" name="gender" id="other" value="other" checked={gender === 3} onChange={(e) => setGender(3)} />
                <label htmlFor="other">Other</label>
            </div>
        </div>
        <div className="newUserItem">
            <label>Role</label>
            <select className="newUserSelect" name="role" id="role" value={roleName} onChange={(e) => setRoleName(e.target.value)}>
                {roles.map((role) => (
                <option key={role.id} value={role.name}>{role.name}</option>
                ))}
            </select>
        </div>
        <div className="newUserItem">
            <label>Active</label>
            <select className="newUserSelect" name="active" id="active" value={active} onChange={(e) => {setActive(e.target.value);console.log(active);}}>
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>
        </div>
        <div className="newUserItem">
            &nbsp;
        </div>
        <div className='newUserItem'>
            <br />
            <button type='submit' className="newUserButton">Update</button>
        </div>
    </form>
    </div>
  )
}
