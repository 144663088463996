import React, { useEffect, useState } from 'react'
import './customer.css'
import { ApiUrl, GlobalPermission } from '../../../Global';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeleteOutline } from '@mui/icons-material';
import Swal from 'sweetalert2';
import Table from '../../table/Table';

export default function Customer() {
    const [customerName, setCustomerName] = useState('');//pageName
    const [phoneNumber, setPhoneNumber] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [streetName, setStreetName] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [stateId, setStateId] = useState(0);
    const [email, setEmail] = useState('');
    const [id, setId] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [states, setStates] = useState([]);
    const [isCustomerNameValid, setCustomerNameValid] = useState(true);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
    const [isStreetNumberValid, setIsStreetNumberValid] = useState(true);
    const [isStreetNameValid, setIsStreetNameValid] = useState(true);
    const [isCityValid, setIsCityValid] = useState(true);
    const [isZipCodeValid, setIsZipCodeValid] = useState(true);
    const currentDateTime = new Date();
    const [searchQuery, setSearchQuery] = useState('');
    const [canPermission, setCanPermission] = useState();
    const [isEmailValid, setIsEmailValid] = useState(true);

    useEffect(() => {
        const desiredPermissionPageName = 'Customers'; // Replace with the desired permissionPageName value
    
        const desiredRow = GlobalPermission.find(
        (item) => item.permissionPageName === desiredPermissionPageName
        );
        
        setCanPermission(desiredRow);

        GetAllCustomers();
    }, []);

    useEffect(() => {
        GetAllStates();
    }, []);

    const handleNew = async (e) => {
        e.preventDefault();
        clearTextBoxes();
    }

    const columns = [
        { key: 'id', title: 'ID' },
        { key: 'name', title: 'Name' },
        { key: 'phoneNumber', title: 'Phone Number' },
        { key: 'email', title: 'Email' },
        { key: 'streetNumber', title: 'Street Number' },
        { key: 'streetName', title: 'Street Name' },
        { key: 'city', title: 'City' },
        { key: 'stateCode', title: 'State Code' },
        { key: 'zipCode', title: 'ZipCode' },
    ];

    const handleDelete = async (id) => {
        try {
            const response = await fetch(ApiUrl + '/api/Customers/Delete?Id=' + id, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ` + sessionStorage.getItem("token")
              }
            });
            const text = await response.text();
            console.log('response text:', text);
            const data = JSON.parse(text);
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Customer has been Deleted',
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllCustomers();
                });
            } else {
              console.error(data);
            }
          } catch (error) {
            console.error(error);
          }
    }

    function clearTextBoxes(){
        setCustomerName('');
        setPhoneNumber('');
        setStreetNumber('');
        setStreetName('');
        setCity('');
        setZipCode('');
        setEmail('');
        setId(0);
        setStateId(states[0].id);
    }
    function GetAllCustomers(){
        fetch(ApiUrl + '/api/Customers/GetAllCustomersWithState', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          })
          .then(response => response.json())
          .then(data => {
              console.log(data)
              setCustomers(data.item2);
          });
      }

    const handleEdit = async (id) => {
        const filtered = customers.filter(item => item.id === id);
        setCustomerName(filtered[0].name);
        setPhoneNumber(filtered[0].phoneNumber);
        setStreetNumber(filtered[0].streetNumber);
        setStreetName(filtered[0].streetName);
        setCity(filtered[0].city);
        setZipCode(filtered[0].zipCode);
        setEmail(filtered[0].email);
        setId(id);
        setStateId(filtered[0].stateId);
        // console.log('Edit')
    }

    function GetAllStates(){
        fetch(ApiUrl + '/api/States/GetAllStates', {
            headers: {
              'Authorization': `Bearer ` + sessionStorage.getItem("token")
            }
          })
          .then(response => response.json())
          .then(data => {
              // console.log(data)
              const filteredSuppliers = data.item2.filter(supplier => supplier.isDeleted === false);
              setStates(filteredSuppliers);
              setStateId(filteredSuppliers[0].id);
          });
      }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (customerName.trim() === '' || phoneNumber.trim() === '' || streetNumber.trim() === '' || streetName.trim() === '' ||
        city.trim() === '' || zipCode.trim() === '' || !isEmailValid) {
            if(customerName.trim() === '')
                setCustomerNameValid(false);
            else
                setCustomerNameValid(true);
            if(phoneNumber.trim() === '')
                setIsPhoneNumberValid(false);
            else
                setIsPhoneNumberValid(true);
            if(streetNumber.trim() === '')
                setIsStreetNumberValid(false);
            else
                setIsStreetNumberValid(true);
            if(streetName.trim() === '')
                setIsStreetNameValid(false);
            else
                setIsStreetNameValid(true);
            if(city.trim() === '')
                setIsCityValid(false);
            else
                setIsCityValid(true);
            if(zipCode.trim() === '')
                setIsZipCodeValid(false);
            else
                setIsZipCodeValid(true);
            return;
        }
        else{
            setCustomerNameValid(true);
            setIsPhoneNumberValid(true);
            setIsStreetNumberValid(true);
            setIsStreetNameValid(true);
            setIsCityValid(true);
            setIsZipCodeValid(true);
        }
        try {
            const response = await fetch( ApiUrl +'/api/Customers/Save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + sessionStorage.getItem("token")
                },
                body: JSON.stringify({
                    id: id,
                    name: customerName,
                    phoneNumber: phoneNumber,
                    streetNumber: streetNumber,
                    streetName: streetName,
                    city: city,
                    zipCode: zipCode,
                    email: email,
                    stateId: stateId,
                    createdBy: '0',
                    CreatedDate: currentDateTime
                })
            });
            const data = await response.json();
            if (response.ok) {
                Swal.fire({
                    icon: 'success',
                    title: 'Customer has been saved',
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    clearTextBoxes();
                    GetAllCustomers();
                });
            } else {
                console.error(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };



    const formatStreetNumber = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
      
        const match = cleaned.match(/^(\d{1,5})$/); // Match the desired format (1-5 digits)
        if (match) {
          return match[1]; // Return the matched digits
        }
      
        return cleaned; // Return the input as is if it doesn't match the desired format
    };
      
      
    const formatZipCode = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
      
        const match = cleaned.match(/^(\d{5})(\d{4})?$/); // Match the desired format (5 or 9 digits)
        if (match) {
          return match[1] + (match[2] ? `-${match[2]}` : ''); // Apply the format (XXXXX or XXXXX-XXXX)
        }
      
        return cleaned; // Return the input as is if it doesn't match the desired format
    };


    const formatPhoneNumber = (input) => {
        const cleaned = input.replace(/\D/g, ''); // Remove non-digit characters
    
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match the desired format
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`; // Apply the format (XXX) XXX-XXXX
        }
    
        return cleaned; // Return the input as is if it doesn't match the desired format
    };

    const formatEmail = (input) => {
        // Remove leading and trailing white spaces
        const trimmedEmail = input.trim();
    
        if (trimmedEmail === '' || isValidEmail(trimmedEmail)) {
          setIsEmailValid(true);
          return trimmedEmail;
        } else {
          setIsEmailValid(false);
          return input;
        }
    };
    
    const isValidEmail = (email) => {
        // Regular expression to validate email format
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

  return (
    <div className='customer'>
        <h1>Customers</h1>
        {canPermission && canPermission.canEdit ? (
        <form className="newCustomerForm" onSubmit={handleSubmit} >
            <input type="hidden" name="id" value={id} onChange={(e) => setId(e.target.value)} />
            <div className="newCustomerItem">
                <label htmlFor='customerName'>Customer Name</label>
                <input type="text" placeholder="Customer Name" name="customerName" id="customerName" className={!isCustomerNameValid ? 'InvalidInputtext' : 'validInputtext'} value={customerName} onChange={(e) => setCustomerName(e.target.value)} />
            </div>
            <div className="newCustomerItem">
                <label htmlFor='phoneNumber'>Phone Number</label>
                <input type="text" placeholder="Phone Number" name="phoneNumber" id="phoneNumber" className={!isPhoneNumberValid ? 'InvalidInputtext' : 'validInputtext'} value={phoneNumber} onChange={(e) => setPhoneNumber(formatPhoneNumber(e.target.value))} />
            </div>
            <div className="newCustomerItem">
                <label htmlFor='email'>Email</label>
                <input type="text" placeholder='Email' name="email" id="email" className={!isEmailValid ? 'InvalidInputtext' : 'validInputtext'} value={email} onChange={(e) => setEmail(formatEmail(e.target.value))} />
            </div>
            <div className="newCustomerItem">
                <label htmlFor='streetNumber'>Street Number</label>
                <input type="text" placeholder="Street Number" name="streetNumber" id="streetNumber" className={!isStreetNumberValid ? 'InvalidInputtext' : 'validInputtext'} value={streetNumber} onChange={(e) => setStreetNumber(formatStreetNumber(e.target.value))} />
            </div>
            <div className="newCustomerItem">
                <label htmlFor='streetName'>Street Name</label>
                <input type="text" placeholder="Street Name" name="streetName" id="streetName" className={!isStreetNameValid ? 'InvalidInputtext' : 'validInputtext'} value={streetName} onChange={(e) => setStreetName(e.target.value)} />
            </div>
            <div className="newCustomerItem">
                <label htmlFor='city'>City</label>
                <input type="text" placeholder='City' name="city" id="city" className={!isCityValid ? 'InvalidInputtext' : 'validInputtext'} value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div className='newCustomerItem'>
                <label htmlFor='state'>State</label>
                <select className='newStateSelect' name="state" id="state" value={stateId} onChange={(e) => setStateId(e.target.value)}>
                    {states.map((state) => (
                        <option key={state.id} value={state.id}>{state.name + ', ' + state.code}</option>
                    ))}
                </select>
            </div>
            <div className="newCustomerItem">
                <label htmlFor='zipCode'>Zip Code</label>
                <input type="text" placeholder='Zip Code' name="zipCode" id="zipCode" className={!isZipCodeValid ? 'InvalidInputtext' : 'validInputtext'} value={zipCode} onChange={(e) => setZipCode(formatZipCode(e.target.value))} />
            </div>
            
            <div className='newCustomerItem'>
                <label> &nbsp;</label>
                <button type='button' className="newCustomerBtn" id="newCustomerBtn" onClick={handleNew}>New</button>
            </div>
            <div className='newCustomerItem'>
                <label> &nbsp;</label>
                <button type='submit' className="newCustomerButton">{id == '0' ? 'Create' : 'Save'}</button>
            </div>
        </form> ) : (
            <></>
        )}
        <br />
        {canPermission && canPermission.canView ? (
        <Table
            data={customers}
            columns={columns}
            onDelete={handleDelete}
            onEdit={handleEdit}
            viewEstimateFileBtn="File"
            canEdit={canPermission && canPermission.canEdit ? canPermission.canEdit : false}
            canDelete={canPermission && canPermission.canDelete ? canPermission.canDelete : false}
        />
        ) : (
            <></>
        )}
    </div>
  )
}
